import { Circle, CircleOutlined } from '@mui/icons-material'
import Button, { ButtonVariant } from 'components/button'
import Modal from 'components/modal/Modal'
import Typography, { Variant } from 'components/typography'
import React, { ReactNode } from 'react'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  isMirroredField: boolean
  label: ReactNode
  onConfirm: VoidFunction
  onMirrorConfirm: VoidFunction
}

function MirroredTransactionEditConfirmation({
  isOpen,
  onClose,
  label,
  isMirroredField,
  onConfirm,
  onMirrorConfirm,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="p-8"
      containerClassName="max-w-[800px]"
      iconComponent={
        <span className="flex text-blue-800 items-center text-[0.875rem] font-bold">
          <Circle className="w-[12px] h-[12px]" />
          |
          <CircleOutlined className="w-[12px] h-[12px]" />
        </span>
      }>
      <div className="flex flex-col justify-between h-full mt-4 w-full px-4">
        <Typography variant={Variant.Body}>{label}</Typography>
        <div className="flex w-full items-center justify-center gap-3 mt-10">
          {isMirroredField ? (
            <>
              <Button className="min-w-[80px]" onClick={onConfirm} variant={ButtonVariant.Secondary}>
                No
              </Button>
              <Button className="min-w-[80px]" onClick={onMirrorConfirm}>
                Yes
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} variant={ButtonVariant.Secondary}>
                Cancel
              </Button>
              <Button onClick={onConfirm}>Yes, I am sure</Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default MirroredTransactionEditConfirmation
