import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { addPropsToRenderer } from 'organisms/formWithSubmission'
import { Control, UseFormSetValue } from 'react-hook-form'
import { SelectOptions } from 'types/common.types'

import { TRANSFER_PRICING_FIELDS_VALUE } from '../BasicInfo'
import { FIELDS_IDS, TESTING_METHODOLOGY_OPTIONS } from '../constants/basicInfo.constants'
import { FIELDS } from '../constants/basicInfo.fields'

export const getFields = ({
  transactionResponse,
  TP_OPTIONS,
  control,
  pliMethodsOptions,
  pliRequired,
}: getFieldsProps) => {
  return addPropsToRenderer(FIELDS, [
    {
      id: FIELDS_IDS.TRANSACTION_VIEW,
      props: {
        currentTransaction: transactionResponse,
      },
    },
    {
      id: FIELDS_IDS.TP_METHOD,
      props: {
        label: 'Transfer pricing method',
        menuPlacement: 'top',
        fieldClassName: 'mr-6',
        options: TP_OPTIONS,
        required: true,
      },
    },
    {
      id: FIELDS_IDS.PLI_METHODS,
      props: {
        label: 'PLI',
        menuPlacement: 'top',
        options: pliMethodsOptions,
        required: true,
        hide: !pliRequired,
        fieldClassName: 'mr-6',
      },
    },
    {
      id: FIELDS_IDS.TESTING_METHODOLOGY,
      props: {
        label: 'Testing Methodology',
        menuPlacement: 'top',
        options: TESTING_METHODOLOGY_OPTIONS,
        required: true,
        hide: !pliRequired,
      },
    },
    {
      id: FIELDS_IDS.TESTED_PARTY,
      props: {
        control: control,
        transactionResponse: transactionResponse,
        required: true,
        menuPlacement: 'top',
        label: 'Select tested party',
      },
    },
  ])
}

interface getFieldsProps {
  transactionResponse?: TransactionByIdResponse
  TP_OPTIONS?: SelectOptions[]
  control: Control<TRANSFER_PRICING_FIELDS_VALUE>
  setValue: UseFormSetValue<TRANSFER_PRICING_FIELDS_VALUE>
  pliRequired: boolean
  pliMethodsOptions: SelectOptions[]
}
