import React, { useCallback, useState } from 'react'

import MirroredTransactionEditConfirmation from './MirroredTransactionEditConfirmationModal'

const createPromise = (): [Promise<MirrorConfirmationStatus>, (value: MirrorConfirmationStatus) => void] => {
  let resolver: ((value: MirrorConfirmationStatus) => void) | null = null
  const promise = new Promise<MirrorConfirmationStatus>(resolve => {
    resolver = resolve
  })
  if (!resolver) throw new Error('Resolver should be initialized')
  return [promise, resolver]
}

const useMirrorConfirmation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState<string | React.ReactNode>('')
  const [isMirroredField, setIsMirroredField] = useState(false)
  const [resolver, setResolver] = useState<((value: MirrorConfirmationStatus) => void) | null>(null)

  const getMirrorConfirmation = useCallback(async (text: string | React.ReactNode, mirroredField: boolean) => {
    setLabel(text)
    setIsMirroredField(!!mirroredField)
    setIsOpen(true)
    const [promise, resolve] = createPromise()
    setResolver(() => resolve)
    return promise
  }, [])

  const onClick = useCallback(
    (status: MirrorConfirmationStatus) => {
      setIsOpen(false)
      if (resolver) resolver(status)
    },
    [resolver]
  )

  const onClose = useCallback(() => {
    onClick('close')
  }, [onClick])

  const onConfirm = useCallback(() => {
    onClick('confirm')
  }, [onClick])

  const onMirrorConfirm = useCallback(() => {
    onClick('mirrorConfirm')
  }, [onClick])

  const MirrorConfirmationModal = useCallback(
    () => (
      <>
        {isOpen ? (
          <MirroredTransactionEditConfirmation
            label={label}
            isMirroredField={isMirroredField}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            onMirrorConfirm={onMirrorConfirm}
          />
        ) : null}
      </>
    ),
    [isMirroredField, isOpen, label, onClose, onConfirm, onMirrorConfirm]
  )

  return { getMirrorConfirmation, MirrorConfirmationModal }
}

type MirrorConfirmationStatus = 'close' | 'mirrorConfirm' | 'confirm'

export default useMirrorConfirmation
