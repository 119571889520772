import { useQuery } from '@tanstack/react-query'
import Loading from 'components/loading'
import Tabs from 'components/tabs/Tabs'
import { QUERIES } from 'constants/query'
import { useTransactionById } from 'hooks/useTransactionById'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { getTabsData } from './helpers/economicAnalysis.helpers'

const EconomicAnalysis = ({ selectedTransactionId }: EconomicAnalysisProps) => {
  const { query, pathname, replace } = useRouter()
  const {
    isLoading: transactionByIdLoading,
    transactionResponse,
    refetch: transactionByIdRefetch,
  } = useTransactionById({
    selectedTransactionId,
    key: 'economicAnalysis',
  })

  const {
    data,
    refetch: economicAnalysisTransactionRefetch,
    isFetching: economicAnalysisTransactionFetching,
  } = useQuery([QUERIES.ECONOMIC_ANALYSIS_TRANSACTION.key, selectedTransactionId], {
    queryFn: () => QUERIES.ECONOMIC_ANALYSIS_TRANSACTION.function(selectedTransactionId),
    enabled: !!selectedTransactionId,
  })

  const economicAnalysisTransactionResponse = data?.data

  const onTabChange: (idx: number) => void = useCallback(
    idx => {
      replace({
        pathname: pathname,
        query: {
          ...query,
          economicAnalysisTab: [
            ECONOMIC_ANALYSIS_TAB_TYPES.basicInfo,
            ECONOMIC_ANALYSIS_TAB_TYPES.methodSelection,
            ECONOMIC_ANALYSIS_TAB_TYPES.financialData,
            ECONOMIC_ANALYSIS_TAB_TYPES.comparabilityFactors,
            ECONOMIC_ANALYSIS_TAB_TYPES.benchmarkingAnalysis,
            ECONOMIC_ANALYSIS_TAB_TYPES.controlledTransactionsData,
            ECONOMIC_ANALYSIS_TAB_TYPES.uncontrolledTransactionsData,
            ECONOMIC_ANALYSIS_TAB_TYPES.conclusion,
          ][idx],
        },
      })
    },
    [pathname, query, replace]
  )

  const tabData = useMemo(
    () =>
      getTabsData({
        transactionResponse,
        transactionByIdRefetch,
        economicAnalysisTransactionRefetch,
        economicAnalysisTransactionFetching,
        economicAnalysisTransactionResponse: economicAnalysisTransactionResponse || [],
        transactionByIdLoading,
        onTabChange,
      }),
    [
      transactionResponse,
      transactionByIdRefetch,
      economicAnalysisTransactionRefetch,
      economicAnalysisTransactionFetching,
      economicAnalysisTransactionResponse,
      transactionByIdLoading,
      onTabChange,
    ]
  )

  const tabIndex = useMemo(() => {
    const { economicAnalysisTab } = query

    const index = [
      ECONOMIC_ANALYSIS_TAB_TYPES.basicInfo,
      ECONOMIC_ANALYSIS_TAB_TYPES.methodSelection,
      ECONOMIC_ANALYSIS_TAB_TYPES.financialData,
      ECONOMIC_ANALYSIS_TAB_TYPES.comparabilityFactors,
      ECONOMIC_ANALYSIS_TAB_TYPES.benchmarkingAnalysis,
      ECONOMIC_ANALYSIS_TAB_TYPES.controlledTransactionsData,
      ECONOMIC_ANALYSIS_TAB_TYPES.uncontrolledTransactionsData,
      ECONOMIC_ANALYSIS_TAB_TYPES.conclusion,
    ].findIndex(element => {
      return element == economicAnalysisTab
    })

    if (index !== -1 && !!tabData?.[index] && !tabData?.[index].disabled && !tabData?.[index].hide) {
      return index
    }
    return 0
  }, [query, tabData])

  if (transactionByIdLoading) {
    return (
      <div className="h-[80vh] flex flex-col justify-center items-center">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Tabs
        key={tabIndex}
        initialActiveTabIndex={tabIndex}
        tabType="pill"
        tabsData={tabData}
        onTabChange={onTabChange}
      />
    </>
  )
}

export enum ECONOMIC_ANALYSIS_TAB_TYPES {
  basicInfo = 'basicInfo',
  methodSelection = 'methodSelection',
  financialData = 'financialData',
  comparabilityFactors = 'comparabilityFactors',
  benchmarkingAnalysis = 'benchmarkingAnalysis',
  controlledTransactionsData = 'controlledTransactionsData',
  uncontrolledTransactionsData = 'uncontrolledTransactionsData',
  conclusion = 'conclusion',
}

interface EconomicAnalysisProps {
  selectedTransactionId: number
}

export default EconomicAnalysis
