import { Tooltip } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { editTransaction, generateAiConclusionByType, getConclusionByType } from 'api/transactions/createTransaction'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import { ROUTES } from 'constants/routes'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import useTemplatesMap from 'hooks/useTemplatesMap/useTemplatesMap'
import Lottie from 'lottie-react'
import { useRouter } from 'next/router'
import MuiTextField from 'organisms/fieldRenderers/fields/muiTextField'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'
import useMirrorConfirmation from 'views/transactions/createTransaction/components/MirroredTransactionEditConfirmation/useMirrorConfirmation'

const Conclusion = ({
  transactionResponse,
  transactionRefetch,
  attributesFetching,
  transactionResponseLoading,
}: ConclusionProps): JSX.Element => {
  const Router = useRouter()

  const isEdit = Router.query.isEdit === 'true'

  const [selectedAIDescription, setSelectedAIDescription] = useState<number>()
  const [aIDescriptions, setAIDescriptions] = useState<(string | null)[]>()
  const [aiButtonDisabled, setAiButtonDisabled] = useState(false)
  const [aiButtonClicked, setAiButtonClicked] = useState(false)

  const { refetchChecklist } = useReportWizardContext()

  const getDefaultConculsionMutation = useMutation(getConclusionByType)
  const getAiConculsionMutation = useMutation(generateAiConclusionByType)
  const { functionalConclusionTemplateOptions } = useTemplatesMap({
    transaction: transactionResponse?.id || 0,
  })

  const { control, getValues, setValue, watch, formState } = useForm<FA_ConclusionField>({
    defaultValues: {
      conclusion: transactionResponse?.conclusion || '',
    },
  })
  const { setIsDirty } = useRoutingHandlerState()

  useEffect(() => {
    setIsDirty(!!Object.keys(formState.dirtyFields).length)

    return () => {
      setIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields, Object.keys(formState.dirtyFields).length, formState.isDirty, setIsDirty])
  const isMirrored = !!transactionResponse?.mirrored_with_transaction?.id
  const { getMirrorConfirmation, MirrorConfirmationModal } = useMirrorConfirmation()
  const selectedTemplate = watch('template')
  const conclusion = watch('conclusion')
  const editTransactionMutation = useMutation(editTransaction, {
    onSuccess: () => {
      setIsDirty(false)
      transactionRefetch()
      refetchChecklist()
      toast.success('Conclusion updated successfully', {
        autoClose: 3000,
      })
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const handleSave = useCallback(async () => {
    if (transactionResponse) {
      let mirror_functional_analysis_conclusion = false
      if (isMirrored) {
        const status = await getMirrorConfirmation('Do you want to copy conclusion to mirrored transactions?', true)
        if (status == 'close') return
        if (status == 'mirrorConfirm') mirror_functional_analysis_conclusion = true
      }
      editTransactionMutation.mutate({
        conclusion: getValues('conclusion') || '',
        id: transactionResponse.id,
        mirror_functional_analysis_conclusion: mirror_functional_analysis_conclusion || undefined,
      })
    } else {
      toast.warn('No transaction data found', {
        autoClose: 3000,
      })
    }
  }, [editTransactionMutation, getMirrorConfirmation, getValues, isMirrored, transactionResponse])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  useEffect(() => {
    if (!selectedTemplate?.value && functionalConclusionTemplateOptions.length) {
      setValue('template', functionalConclusionTemplateOptions[0])
    }
  }, [functionalConclusionTemplateOptions, selectedTemplate?.value, setValue])

  useEffect(() => {
    setAIDescriptions(undefined)
    setSelectedAIDescription(undefined)
  }, [selectedTemplate?.value])

  const generateAutomatedLanguage = () => {
    if (!selectedTemplate) {
      toast.warn('Select Template First')
      return
    }
    if (aIDescriptions && Object.keys(aIDescriptions).length) {
      if (aIDescriptions[aIDescriptions.length - 1]) {
        const newSelection = ((selectedAIDescription || 0) + 1) % 3
        setSelectedAIDescription(newSelection)
        setValue('conclusion', aIDescriptions[newSelection] || '')
      } else {
        setAiButtonDisabled(true)
        getAiConculsionMutation.mutate(
          {
            transactionId: transactionResponse?.id || 0,
            template_name: String(selectedTemplate.value),
            conclusionType: 'functional_analysis',
          },
          {
            onSuccess: data => {
              const aiRecommendations = [aIDescriptions[0], ...data.recommendations]
              setAiButtonClicked(true)
              setAIDescriptions(aiRecommendations)
              setSelectedAIDescription(1)
              setValue('conclusion', aiRecommendations[1] || '')
              setAiButtonDisabled(false)
            },
            onError: (error: unknown) => {
              getToastErrorMessage(error as AxiosError)
              setAiButtonDisabled(false)
            },
          }
        )
      }
    } else {
      // load integral default language first
      setAiButtonDisabled(true)
      getDefaultConculsionMutation.mutate(
        {
          transactionId: transactionResponse?.id || 0,
          template_name: String(selectedTemplate.value),
          conclusionType: 'functional_analysis',
        },
        {
          onSuccess: data => {
            if (data.conclusion) {
              setAiButtonClicked(true)
              setAIDescriptions([data.conclusion, null, null])
              setSelectedAIDescription(0)
              setValue('conclusion', data.conclusion)
              setAiButtonDisabled(false)
            }
          },
          onError: (error: unknown) => {
            getToastErrorMessage(error as AxiosError)
            setAiButtonDisabled(false)
          },
        }
      )
    }
  }

  const getResponsesUsed = useCallback(() => {
    if (typeof selectedAIDescription == 'number' && aIDescriptions && aIDescriptions.length) {
      return ` (${(selectedAIDescription % 3) + 1}/${aIDescriptions.length})`
    }
    return ''
  }, [selectedAIDescription, aIDescriptions])
  const loading =
    editTransactionMutation.isLoading || attributesFetching || transactionResponseLoading || !transactionResponse
  return (
    <div className="relative">
      {loading && <Loading className="absolute inset-0 bg-white flex items-center justify-center z-10" />}
      <MuiTextField
        id="conclusion"
        title="Conclusion"
        focusColor="#ffffff"
        disabled={editTransactionMutation.isLoading}
        control={control}
        className="relative"
        placeholder="Enter conclusion ..."
        // placeholder="Write your own function analysis conclusion or generate an automated conclusion..."
        rightComponent={
          false ? (
            <div className="flex gap-4 items-center justify-end">
              <SelectDropDown
                placeholder="Select Template"
                id="template"
                options={functionalConclusionTemplateOptions}
                control={control}
                className="w-min-[96rem] shrink-0"
              />
              <Tooltip
                title={
                  selectedTemplate
                    ? 'Generate automated functional analysis conclusion'
                    : 'Select Template first to use Erica AI'
                }
                placement="top">
                <div>
                  <Button
                    disabled={!selectedTemplate}
                    onClick={generateAutomatedLanguage}
                    className="whitespace-nowrap h-[38px]"
                    variant={ButtonVariant.AIGradient}
                    icon={IconsType.sparkle}>
                    ERICA AI {getResponsesUsed()}
                  </Button>
                </div>
              </Tooltip>
            </div>
          ) : undefined
        }>
        {aiButtonDisabled && (
          <div className="absolute inset-0 flex justify-center items-center opacity-90 bg-white">
            <Lottie animationData={LoadingStars} className="w-full" />
          </div>
        )}
      </MuiTextField>
      {aiButtonClicked && <AITextDisclaimer />}
      <div className="flex items-center justify-between mt-4">
        <Button
          isDefaultSize={false}
          onClick={handleCancel}
          iconCLass="w-5 h-5 transform rotate-180"
          iconPathClassName="stroke-blue900"
          icon={IconsType.arrowRight}
          variant={ButtonVariant.Secondary}>
          Back
        </Button>
        {isEdit && (
          <Button
            disabled={!conclusion || conclusion === transactionResponse?.conclusion}
            icon={IconsType.save}
            onClick={handleSave}>
            Save
          </Button>
        )}
      </div>
      <MirrorConfirmationModal />
    </div>
  )
}

type FA_ConclusionField = {
  conclusion: string
  template?: SelectOptions
}

interface ConclusionProps {
  transactionResponse?: TransactionByIdResponse
  transactionRefetch: () => void
  attributesFetching: boolean
  transactionResponseLoading: boolean
}

export default Conclusion
