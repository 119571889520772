import { HomeWorkOutlined } from '@mui/icons-material'
import { ChecklistResponse } from 'api/documents/types'
import ContextualToken from 'assets/icons/contextualToken'
import File from 'assets/icons/file'
import TransactionManagement from 'assets/icons/transactionManagement'
import { ROUTES } from 'constants/routes'
import { ReactNode } from 'react'
import { createQueryParams } from 'utils/utils'
import { ECONOMIC_ANALYSIS_TAB_TYPES } from 'views/transactions/createTransaction/organisms/economicAnalysis/EconomicAnalysis'
import { FAR_TAB_TYPES } from 'views/transactions/createTransaction/organisms/functionalAnalysis/FunctionalAnalysis'

import { ChecklistAccordionProps } from './components/ChecklistAccordion'
import { ChecklistItemProps } from './components/ChecklistItem'
import { ChecklistParentAccordionProps } from './components/ChecklistParentAccordion'

export const getReportWizardModules = ({
  active,
  complete,
  disabled,
}: {
  active: moduleKeyType
  complete: Record<moduleKeyType, boolean>
  disabled: Partial<Record<moduleKeyType, boolean>>
}): {
  icon: ReactNode
  title: string
  completed: boolean
  active: boolean
  key: moduleKeyType
  disabled?: boolean
  disabledTooltipText?: string
}[] => {
  return [
    {
      key: 'company_overview',
      title: 'Company Overview',
      completed: complete.company_overview,
      disabled: disabled.company_overview,
      active: active == 'company_overview',
      icon: <ContextualToken className="w-[18px] h-[18px]" />,
    },
    {
      key: 'local_entity_overview',
      title: 'Legal Entity Overview',
      completed: complete.local_entity_overview,
      disabled: disabled.local_entity_overview,
      active: active == 'local_entity_overview',
      icon: <HomeWorkOutlined className="w-[18px] h-[18px] text-fuchsia-400" />,
    },
    {
      key: 'transactions',
      title: 'Transactions',
      completed: complete.transactions,
      disabled: disabled.transactions,
      disabledTooltipText: 'Please select template before selecting transactions',
      active: active == 'transactions',
      icon: <TransactionManagement pathClassName="fill-teal-400" className="w-[18px] h-[18px] " />,
    },
    {
      key: 'appendices',
      title: 'Appendices',
      completed: complete.appendices,
      disabled: disabled.appendices,
      disabledTooltipText: 'Please select transactions',
      active: active == 'appendices',
      icon: <File className="w-[18px] h-[18px]" pathClassName="fill-orange-500" />,
    },
  ]
}

export const getOverviewConfig = (
  checklistData: ChecklistResponse | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (
    | {
        type: 'expandable'
        props: ChecklistAccordionProps
      }
    | {
        type: 'single'
        props: ChecklistItemProps
      }
  )[]
} => {
  const companyOverview = checklistData?.checklist.organization
  return {
    tabConfig: {
      isComplete: !!(
        companyOverview?.business_strategy_and_group_competitor_factors.is_present &&
        companyOverview?.descr_of_products_services.is_present &&
        companyOverview?.group_business_overview.is_present &&
        companyOverview?.org_far_overview.is_present &&
        companyOverview.industry_analysis.industry_description.is_present &&
        companyOverview.industry_analysis.industry_attributes_desc.is_present &&
        companyOverview.industry_analysis.attributes_implication_desc.is_present &&
        companyOverview.industry_analysis.industry_name.is_present
      ),
      totalTasks: 5,
      completedTasks: [
        companyOverview?.business_strategy_and_group_competitor_factors.is_present,
        companyOverview?.descr_of_products_services.is_present,
        companyOverview?.group_business_overview.is_present,
        companyOverview?.org_far_overview.is_present,
        !!(
          companyOverview?.industry_analysis.industry_description.is_present &&
          companyOverview?.industry_analysis.industry_attributes_desc.is_present &&
          companyOverview?.industry_analysis.attributes_implication_desc.is_present &&
          companyOverview?.industry_analysis.industry_name.is_present
        ),
      ].filter(val => !!val).length,
    },
    listConfig: [
      {
        type: 'single',
        props: {
          added: !!companyOverview?.group_business_overview.is_present,
          label: 'Organization Business Overview',
          href: ROUTES.ORGANIZATION,
          skippable: companyOverview?.group_business_overview.skippable,
          skipped: companyOverview?.group_business_overview.skip,
          skipKey: 'organization.group_business_overview',
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.descr_of_products_services.is_present,
          label: 'Description of Products & Services',
          href: ROUTES.ORGANIZATION,
          skippable: companyOverview?.descr_of_products_services.skippable,
          skipped: companyOverview?.descr_of_products_services.skip,
          skipKey: 'organization.descr_of_products_services',
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.business_strategy_and_group_competitor_factors.is_present,
          label: 'Business Strategy & Group Competitor Factors',
          href: ROUTES.ORGANIZATION,
          skippable: companyOverview?.business_strategy_and_group_competitor_factors.skippable,
          skipped: companyOverview?.business_strategy_and_group_competitor_factors.skip,
          skipKey: 'organization.business_strategy_and_group_competitor_factors',
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.org_far_overview.is_present,
          label: 'Org FAR Overview',
          href: ROUTES.ORGANIZATION,
          skippable: companyOverview?.org_far_overview.skippable,
          skipped: companyOverview?.org_far_overview.skip,
          skipKey: 'organization.org_far_overview',
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!(
            companyOverview?.industry_analysis.industry_description.is_present &&
            companyOverview?.industry_analysis.industry_attributes_desc.is_present &&
            companyOverview?.industry_analysis.attributes_implication_desc.is_present &&
            companyOverview?.industry_analysis.industry_name.is_present
          ),
          label: 'Industry Analysis',
          href: ROUTES.ORGANIZATION,
          isOpen: false,
          subSections: [
            {
              label: 'Industry Name',
              added: !!companyOverview?.industry_analysis.industry_name.is_present,
              href: ROUTES.ORGANIZATION,
              skippable: companyOverview?.industry_analysis.industry_name.skippable,
              skipKey: 'organization.industry_analysis.industry_name',
              skipped: companyOverview?.industry_analysis.industry_name.skip,
            },
            {
              label: 'Industry Description',
              added: !!companyOverview?.industry_analysis.industry_description.is_present,
              href: ROUTES.ORGANIZATION,
              skippable: companyOverview?.industry_analysis.industry_description.skippable,
              skipKey: 'organization.industry_analysis.industry_description',
              skipped: companyOverview?.industry_analysis.industry_description.skip,
            },
            {
              label: 'Industry Attributes',
              added: !!companyOverview?.industry_analysis.industry_attributes_desc.is_present,
              href: ROUTES.ORGANIZATION,
              skippable: companyOverview?.industry_analysis.industry_attributes_desc.skippable,
              skipKey: 'organization.industry_analysis.industry_attributes_desc',
              skipped: companyOverview?.industry_analysis.industry_attributes_desc.skip,
            },
            {
              label: 'Implication of Industry Attributes on Controlled Transaction',
              added: !!companyOverview?.industry_analysis.attributes_implication_desc.is_present,
              href: ROUTES.ORGANIZATION,
              skippable: companyOverview?.industry_analysis.attributes_implication_desc.skippable,
              skipKey: 'organization.industry_analysis.attributes_implication_desc',
              skipped: companyOverview?.industry_analysis.attributes_implication_desc.skip,
            },
          ],
        },
      },
    ],
  }
}

export const getLEOverviewConfig = (
  checklistData: ChecklistResponse | undefined,
  leId: number | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (
    | {
        type: 'expandable'
        props: ChecklistAccordionProps
      }
    | {
        type: 'single'
        props: ChecklistItemProps
      }
  )[]
} => {
  const legalEntityCheckilist = checklistData?.checklist.legal_entity

  const basicInfoComplete = !!legalEntityCheckilist?.basic_info.is_present
  const managementStructureComplete = !!(
    legalEntityCheckilist?.management_structure.legal_organization_chart.is_present &&
    legalEntityCheckilist?.management_structure.legal_structure_file.is_present &&
    legalEntityCheckilist?.management_structure.team_structure.is_present
  )

  const coreBusionessInfoComplete = !!(
    legalEntityCheckilist?.core_business_info.business_activities_description.is_present &&
    legalEntityCheckilist?.core_business_info.business_restruct_intangibles_transfers_and_other.is_present &&
    legalEntityCheckilist?.core_business_info.entity_far_overview.is_present &&
    legalEntityCheckilist?.core_business_info.key_competitors.is_present &&
    legalEntityCheckilist?.core_business_info.local_industry_analysis.is_present &&
    legalEntityCheckilist?.core_business_info.local_market_business_strategy.is_present &&
    legalEntityCheckilist?.core_business_info.local_market_and_economic_circumstances.is_present &&
    legalEntityCheckilist?.core_business_info.tax_structure.is_present
  )
  return {
    tabConfig: {
      isComplete: !!(basicInfoComplete && managementStructureComplete && coreBusionessInfoComplete),
      totalTasks: 3,
      completedTasks: [basicInfoComplete, managementStructureComplete, coreBusionessInfoComplete].filter(val => !!val)
        .length,
    },
    listConfig: [
      {
        type: 'single',
        props: {
          added: !!basicInfoComplete,
          label: 'Basic Legal Entity Info',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 0,
          })}`,
          skippable: legalEntityCheckilist?.basic_info.skippable,
          skipped: legalEntityCheckilist?.basic_info.skip,
          skipKey: 'legal_entity.basic_info',
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!managementStructureComplete,
          label: 'Management Structure',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 1,
          })}`,
          isOpen: false,
          subSections: [
            {
              label: 'LE ownership chart',
              added: !!legalEntityCheckilist?.management_structure.legal_structure_file.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
              skippable: legalEntityCheckilist?.management_structure.legal_structure_file.skippable,
              skipped: legalEntityCheckilist?.management_structure.legal_structure_file.skip,
              skipKey: 'legal_entity.management_structure.legal_structure_file',
            },
            {
              label: 'Corporate/Group Legal Entity Organization Chart',
              added: !!legalEntityCheckilist?.management_structure.legal_organization_chart.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
              skippable: legalEntityCheckilist?.management_structure.legal_organization_chart.skippable,
              skipped: legalEntityCheckilist?.management_structure.legal_organization_chart.skip,
              skipKey: 'legal_entity.management_structure.legal_organization_chart',
            },
            {
              label: 'Team Structure',
              added: !!legalEntityCheckilist?.management_structure.team_structure.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
              skippable: legalEntityCheckilist?.management_structure.team_structure.skippable,
              skipped: legalEntityCheckilist?.management_structure.team_structure.skip,
              skipKey: 'legal_entity.management_structure.team_structure',
            },
          ],
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!coreBusionessInfoComplete,
          label: 'Core Business info',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 2,
          })}`,
          isOpen: false,
          subSections: [
            {
              label: 'Business Activities Description',
              added: !!legalEntityCheckilist?.core_business_info.business_activities_description.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.business_activities_description.skippable,
              skipped: legalEntityCheckilist?.core_business_info.business_activities_description.skip,
              skipKey: 'legal_entity.core_business_info.business_activities_description',
            },
            {
              label: 'Local Market Economic Circumstances',
              added: !!legalEntityCheckilist?.core_business_info.local_market_and_economic_circumstances.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.local_market_and_economic_circumstances.skippable,
              skipped: legalEntityCheckilist?.core_business_info.local_market_and_economic_circumstances.skip,
              skipKey: 'legal_entity.core_business_info.local_market_and_economic_circumstances',
            },
            {
              label: 'Industry Analysis',
              added: !!legalEntityCheckilist?.core_business_info.local_industry_analysis.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.local_industry_analysis.skippable,
              skipped: legalEntityCheckilist?.core_business_info.local_industry_analysis.skip,
              skipKey: 'legal_entity.core_business_info.local_industry_analysis',
            },
            {
              label: 'Key Competitors',
              added: !!legalEntityCheckilist?.core_business_info.key_competitors.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.key_competitors.skippable,
              skipped: legalEntityCheckilist?.core_business_info.key_competitors.skip,
              skipKey: 'legal_entity.core_business_info.key_competitors',
            },
            {
              label: 'Local Market Business Strategy',
              added: !!legalEntityCheckilist?.core_business_info.local_market_business_strategy.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.local_market_business_strategy.skippable,
              skipped: legalEntityCheckilist?.core_business_info.local_market_business_strategy.skip,
              skipKey: 'legal_entity.core_business_info.local_market_business_strategy',
            },
            {
              label: 'Business Restructuring, Intangibles Transfers & Other Relevant Considerations',
              added:
                !!legalEntityCheckilist?.core_business_info.business_restruct_intangibles_transfers_and_other
                  .is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable:
                legalEntityCheckilist?.core_business_info.business_restruct_intangibles_transfers_and_other.skippable,
              skipped: legalEntityCheckilist?.core_business_info.business_restruct_intangibles_transfers_and_other.skip,
              skipKey: 'legal_entity.core_business_info.business_restruct_intangibles_transfers_and_other',
            },
            {
              label: 'Tax Structures',
              added: !!legalEntityCheckilist?.core_business_info.tax_structure.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.tax_structure.skippable,
              skipped: legalEntityCheckilist?.core_business_info.tax_structure.skip,
              skipKey: 'legal_entity.core_business_info.tax_structure',
            },
            {
              label: 'Entity FAR Information',
              added: !!legalEntityCheckilist?.core_business_info.entity_far_overview.is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
              skippable: legalEntityCheckilist?.core_business_info.entity_far_overview.skippable,
              skipped: legalEntityCheckilist?.core_business_info.entity_far_overview.skip,
              skipKey: 'legal_entity.core_business_info.entity_far_overview',
            },
          ],
        },
      },
    ],
  }
}

export const getTransactionConfig = (
  checklistData: ChecklistResponse | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (ChecklistParentAccordionProps & {
    subSections: (
      | {
          type: 'expandable'
          props: ChecklistAccordionProps
          hidden?: boolean
        }
      | {
          type: 'single'
          props: ChecklistItemProps
          hidden?: boolean
        }
    )[]
  })[]
} => {
  const tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  } = {
    isComplete: true,
    totalTasks: 0,
    completedTasks: 0,
  }
  const listConfig: (ChecklistParentAccordionProps & {
    subSections: (
      | {
          type: 'expandable'
          props: ChecklistAccordionProps
          hidden?: boolean
        }
      | {
          type: 'single'
          props: ChecklistItemProps
          hidden?: boolean
        }
    )[]
  })[] = []
  const txnChecklist = checklistData?.checklist.transactions

  if (txnChecklist?.length) {
    txnChecklist.forEach((txn, txnIndex) => {
      const txnData: ChecklistParentAccordionProps & {
        subSections: (
          | {
              type: 'expandable'
              props: ChecklistAccordionProps
              hidden?: boolean
            }
          | {
              type: 'single'
              props: ChecklistItemProps
              hidden?: boolean
            }
        )[]
      } = {
        added: false,
        isOpen: false,
        label: txn.name,
        subSections: [
          {
            type: 'single',
            props: {
              added: !!txn.basic_info.is_present,
              label: 'Transaction Basic Info',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'basicInfo',
              })}`,
              skippable: txn.basic_info.skippable,
              skipped: txn.basic_info.skip,
              skipKey: `transactions[${txnIndex}].basic_info`,
              additionalSkipData: {
                key: `transactions[${txnIndex}].id`,
                value: txn.id,
              },
            },
          },
          {
            type: 'single',
            props: {
              added: !!txn.transaction_description.is_present,
              label: 'Transaction Description',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'basicInfo',
              })}`,
              skippable: txn.transaction_description.skippable,
              skipped: txn.transaction_description.skip,
              skipKey: `transactions[${txnIndex}].transaction_description`,
              additionalSkipData: {
                key: `transactions[${txnIndex}].id`,
                value: txn.id,
              },
            },
          },
          {
            type: 'single',
            props: {
              added: !!txn.intercompany_agreement.is_present,
              label: 'Intercompany Flows and Agreements',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'intercompanyAgreement',
              })}`,
              skippable: txn.intercompany_agreement.skippable,
              skipped: txn.intercompany_agreement.skip,
              skipKey: `transactions[${txnIndex}].intercompany_agreement`,
              additionalSkipData: {
                key: `transactions[${txnIndex}].id`,
                value: txn.id,
              },
            },
          },
          {
            type: 'expandable',
            props: {
              added: false,
              label: 'Functional Analysis',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'functionalAnalysis',
              })}`,
              isOpen: false,
              subSections: [
                {
                  label: 'Functions',
                  added: !!txn.functional_analysis.functions.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                    farTab: FAR_TAB_TYPES.function,
                  })}`,
                  skippable: txn.functional_analysis.skippable?.functions,
                  skipped: txn.functional_analysis.skip?.functions,
                  skipKey: `transactions[${txnIndex}].functional_analysis.functions`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Assets',
                  added: !!txn.functional_analysis.assets.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                    farTab: FAR_TAB_TYPES.assets,
                  })}`,
                  skippable: txn.functional_analysis.skippable?.assets,
                  skipped: txn.functional_analysis.skip?.assets,
                  skipKey: `transactions[${txnIndex}].functional_analysis.assets`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Risks',
                  added: !!txn.functional_analysis.risks.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                    farTab: FAR_TAB_TYPES.risks,
                  })}`,
                  skippable: txn.functional_analysis.skippable?.risks,
                  skipped: txn.functional_analysis.skip?.risks,
                  skipKey: `transactions[${txnIndex}].functional_analysis.risks`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Conclusion',
                  added: !!txn.functional_analysis.conclusion.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                    farTab: FAR_TAB_TYPES.conclusion,
                  })}`,
                  skippable: txn.functional_analysis.conclusion.skippable,
                  skipped: txn.functional_analysis.conclusion.skip,
                  skipKey: `transactions[${txnIndex}].functional_analysis.conclusion`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
              ],
            },
          },
          {
            type: 'expandable',
            props: {
              added: false,
              label: 'Economic Analysis',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'economicAnalysis',
              })}`,
              isOpen: false,
              subSections: [
                {
                  label: 'Basic Info',
                  added: !!txn.economic_analysis.basic_info,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                  skippable: txn.economic_analysis.basic_info.skippable,
                  skipped: txn.economic_analysis.basic_info.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.basic_info`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Method Selection Analysis',
                  added: !!txn.economic_analysis.rejection_matrix.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.methodSelection,
                  })}`,
                  skippable: txn.economic_analysis.rejection_matrix.skippable,
                  skipped: txn.economic_analysis.rejection_matrix.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.rejection_matrix`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Tested Party (or Parties) Financials',
                  hidden: typeof txn.economic_analysis.financial_results_for_tested_party?.is_present !== 'boolean',
                  added: !!txn.economic_analysis.financial_results_for_tested_party?.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.financialData,
                  })}`,
                  skippable: txn.economic_analysis.financial_results_for_tested_party?.skippable,
                  skipped: txn.economic_analysis.financial_results_for_tested_party?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.financial_results_for_tested_party`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Tested Party (or Parties) Benchmarking Analysis',
                  hidden: typeof txn.economic_analysis.benchmarkings?.is_present !== 'boolean',
                  added: !!txn.economic_analysis.benchmarkings?.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.benchmarkingAnalysis,
                  })}`,
                  skippable: txn.economic_analysis.benchmarkings?.skippable,
                  skipped: txn.economic_analysis.benchmarkings?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.benchmarkings`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Comparability Factor Selection',
                  hidden:
                    typeof txn.economic_analysis.comparability_factors?.comparability_factors.is_present !== 'boolean',
                  added: !!txn.economic_analysis.comparability_factors?.comparability_factors.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.comparabilityFactors,
                  })}`,
                  skippable: txn.economic_analysis.comparability_factors?.comparability_factors?.skippable,
                  skipped: txn.economic_analysis.comparability_factors?.comparability_factors?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.comparability_factors.comparability_factors`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Controlled Transaction Data',
                  hidden:
                    typeof txn.economic_analysis.comparability_factors?.controlled_transactions.is_present !==
                    'boolean',
                  added: !!txn.economic_analysis.comparability_factors?.controlled_transactions.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.controlledTransactionsData,
                  })}`,
                  skippable: txn.economic_analysis.comparability_factors?.controlled_transactions?.skippable,
                  skipped: txn.economic_analysis.comparability_factors?.controlled_transactions?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.comparability_factors.controlled_transactions`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Uncontrolled Transaction Data',
                  hidden:
                    typeof txn.economic_analysis.comparability_factors?.uncontrolled_transactions.is_present !==
                    'boolean',
                  added: !!txn.economic_analysis.comparability_factors?.uncontrolled_transactions.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.uncontrolledTransactionsData,
                  })}`,
                  skippable: txn.economic_analysis.comparability_factors?.uncontrolled_transactions?.skippable,
                  skipped: txn.economic_analysis.comparability_factors?.uncontrolled_transactions?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.comparability_factors.uncontrolled_transactions`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Conclusion Results',
                  hidden: typeof txn.economic_analysis.comparability_factors?.conclusion.is_present !== 'boolean',
                  added: !!txn.economic_analysis.comparability_factors?.conclusion.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.uncontrolledTransactionsData,
                  })}`,
                  skippable: txn.economic_analysis.comparability_factors?.conclusion?.skippable,
                  skipped: txn.economic_analysis.comparability_factors?.conclusion?.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.comparability_factors.conclusion`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
                {
                  label: 'Conclusion',
                  added: !!txn.economic_analysis.conclusion.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                    economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.conclusion,
                  })}`,
                  skippable: txn.economic_analysis.conclusion.skippable,
                  skipped: txn.economic_analysis.conclusion.skip,
                  skipKey: `transactions[${txnIndex}].economic_analysis.conclusion`,
                  additionalSkipData: {
                    key: `transactions[${txnIndex}].id`,
                    value: txn.id,
                  },
                },
              ],
            },
          },
        ],
        href: `${ROUTES.TRANSACTION_MANAGEMENT}/${txn?.id}`,
      }
      let allAdded = true
      txnData.subSections.forEach(section => {
        if (section.hidden) return
        if (section.type === 'expandable') {
          let childrenAdded = true
          section.props.subSections.forEach(item => {
            if (!childrenAdded || item.hidden) return
            childrenAdded = childrenAdded && item.added
          })
          section.props.added = childrenAdded
          allAdded = allAdded && childrenAdded
        } else {
          allAdded = allAdded && section.props.added
        }
      })
      txnData.added = allAdded
      tabConfig.totalTasks = tabConfig.totalTasks + 1
      tabConfig.isComplete = tabConfig.isComplete && allAdded
      if (allAdded) {
        tabConfig.completedTasks = tabConfig.completedTasks + 1
      }
      listConfig.push(txnData)
    })
  } else {
    tabConfig.isComplete = false
  }

  return {
    tabConfig,
    listConfig,
  }
}

export const getAppendicesConfig = (checklistData: ChecklistResponse | undefined) => {
  function hasIsPresentTrue(jsonString: string): boolean {
    const regex = /"is_present"\s*:\s*true/
    return regex.test(jsonString)
  }

  return {
    relevant_tax_rulings: {
      hidden: !checklistData?.checklist.appendices.relevant_tax_rulings,
      data: checklistData?.checklist.appendices.relevant_tax_rulings,
    },
    legal_entity: {
      hidden: !hasIsPresentTrue(JSON.stringify(checklistData?.checklist.appendices.legal_entity || {})),
      data: checklistData?.checklist.appendices.legal_entity,
    },
    transactions: {
      hidden: !hasIsPresentTrue(JSON.stringify(checklistData?.checklist.appendices.transactions || {})),
      data: checklistData?.checklist.appendices.transactions.filter(txn => hasIsPresentTrue(JSON.stringify(txn))),
    },
    other_appendices: {
      hidden: false,
      data: checklistData?.checklist.appendices.other_appendices,
    },
  }
}

export type moduleKeyType = 'company_overview' | 'local_entity_overview' | 'transactions' | 'appendices'
