import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'

const TableWrapper = ({
  rightComponent,
  title,
  icon,
  children,
  className,
  titleDescription,
  isWhiteBackground = false,
  isTableDefaultHeight = true,
  childClassName,
  style,
  textColor,
  iconPathClassName,
}: ITableWrapperProps): JSX.Element => {
  return (
    <div className={cx('border border-solid border-neutral300 rounded-xl overflow-hidden', className)}>
      <div
        style={style}
        className={cx(
          'px-4 py-[0.375rem] flex flex-col justify-center border-b border-solid border-t-0 border-l-0 border-r-0 border-neutral300 rounded-t-xl min-h-[3rem]',
          {
            'bg-white': isWhiteBackground,
            'bg-blue-50': !isWhiteBackground,
          }
        )}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            {icon &&
              getIcons(icon, {
                className: 'w-[1.125rem] h-[1.125rem]',
                pathClassName: cx({
                  'fill-blue900': !iconPathClassName,
                  [iconPathClassName as string]: iconPathClassName,
                }),
              })}
            <div className=" flex flex-col">
              {title && (
                <Typography
                  className={cx({
                    'text-neutral800': !textColor,
                    [textColor as string]: textColor,
                  })}
                  variant={Variant.Body}
                  type="semibold">
                  {title}
                </Typography>
              )}
              {titleDescription && (
                <Typography
                  className={cx({
                    'text-neutral800': !textColor,
                    [textColor as string]: textColor,
                  })}
                  variant={Variant.Footnote}>
                  {titleDescription}
                </Typography>
              )}
            </div>
          </div>
          {rightComponent}
        </div>
      </div>
      <div
        className={cx(
          {
            'overflow-y-auto': isTableDefaultHeight,
          },
          childClassName
        )}>
        {children}
      </div>
    </div>
  )
}

interface ITableWrapperProps {
  rightComponent?: React.ReactNode
  title?: string | JSX.Element
  titleDescription?: string | JSX.Element
  icon?: IconsType
  children: React.ReactNode
  className?: string
  isWhiteBackground?: boolean
  childClassName?: string
  isTableDefaultHeight?: boolean
  style?: React.CSSProperties
  textColor?: string
  iconPathClassName?: string
}

export default TableWrapper
