import ModernRightArrow from 'assets/icons/modernRightArrow'
import cx from 'classnames'

const ArrowDivider = ({ children, rtl = false, chidrenPosition = 'top' }: ArrowDividerProps) => {
  return (
    <div className="flex items-center flex-col gap-3 w-full ">
      {chidrenPosition !== 'bottom' && children}
      <ModernRightArrow
        className={cx(rtl ? 'rotate-180' : '')}
        pathClassName={cx(rtl ? 'fill-[#EAB308]' : 'fill-[#16A34A]')}
      />
      {chidrenPosition == 'bottom' && children}
    </div>
  )
}

interface ArrowDividerProps {
  children?: React.ReactNode
  rtl?: boolean
  chidrenPosition?: 'top' | 'bottom'
}

export default ArrowDivider
