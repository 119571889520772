import Typography, { Variant } from 'components/typography'
import { SelectOptions } from 'types/common.types'

const DropDown = ({ options, handleSelectedValue }: DropDownProps) => {
  return (
    <div className="absolute top-[2.875rem] left-0 w-[264px] h-[155px] bg-white border border-solid border-neutral300 rounded-2xl shadow-dropdownShadow z-20 overflow-y-auto">
      <div className="p-4 h-full w-full z-20 text-left">
        {options.map(option => (
          <Typography
            onClick={handleSelectedValue(option.value as string)}
            key={option.value}
            variant={Variant.Body}
            className="text-neutral800 p-2 hover:bg-neutral100 hover:cursor-pointer">
            {option.label}
          </Typography>
        ))}
      </div>
    </div>
  )
}

interface DropDownProps {
  options: SelectOptions[]
  handleSelectedValue: (value: string) => () => void
}

export default DropDown
