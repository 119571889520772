import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'
import { formatAsPercent } from 'utils/numberUtils'

import { getDataByYear, mappedYearsFinancialStructureData } from '../helpers/financialData.helpers'
import { FinancialDataTableData } from '../types/financialData.types'

export const usGaapConfig = ({
  financial_structure,
  year,
  pliMethod,
  readonly = false,
}: Props): FinancialDataTableData[] => {
  const selectedPliMethod = pliMethod

  if (!selectedPliMethod) return []
  const dataByYear = getDataByYear(financial_structure || [])

  const getLast3Years = (year: number) => {
    return [year - 2, year - 1, year]
  }

  const [yearCurrentMinus2, yearCurrentMinus1, yearCurrent] = getLast3Years(year).map(year =>
    mappedYearsFinancialStructureData(dataByYear, year)
  )

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    required: boolean,
    formula?: string,
    ...values: (number | null | undefined | string)[]
  ): FinancialDataTableData => ({
    name,
    [getLast3Years(year)[0]?.toString()]: String(values?.[0] ?? ''),
    [getLast3Years(year)[1]?.toString()]: String(values?.[1] ?? ''),
    [getLast3Years(year)[2]?.toString()]: String(values?.[2] ?? ''),
    isEditable: !is_calculated,
    key,
    formula,
    isEditAllowed: !readonly,
    required: required && !is_calculated,
  })

  const tableData: FinancialDataTableData[] = [
    ...selectedPliMethod.required_fields.map(field => {
      return {
        ...field,
        _requiredField: true,
      }
    }),
    ...selectedPliMethod.optional_fields.map(field => {
      return {
        ...field,
        _requiredField: false,
      }
    }),
  ]
    .filter(field => field.type != 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        field._requiredField,
        selectedPliMethod.formulae[field.name],
        yearCurrentMinus2?.[field.name],
        yearCurrentMinus1?.[field.name],
        yearCurrent?.[field.name]
      )
    })
  tableData.push({
    ...createFinancialDataEntry(
      selectedPliMethod.name + '(%)',
      selectedPliMethod.abbreviation,
      true,
      false,
      selectedPliMethod.formulae[selectedPliMethod.name],
      String(yearCurrentMinus2?.['profit_level_indicator'] ?? '')
        ? formatAsPercent(String(yearCurrentMinus2?.['profit_level_indicator'] ?? 'none'), 2)
        : '',
      String(yearCurrentMinus1?.['profit_level_indicator'] ?? '')
        ? formatAsPercent(String(yearCurrentMinus1?.['profit_level_indicator'] ?? 'none'), 2)
        : '',
      String(yearCurrent?.['profit_level_indicator'] ?? '')
        ? formatAsPercent(String(yearCurrent?.['profit_level_indicator'] ?? 'none'), 2)
        : ''
    ),
    unformatted: true,
  })

  return tableData
}

interface Props {
  year: number
  pliMethod?: TransactionPliMethod
  financial_structure?: { [key: string]: number | null | string }[]
  readonly?: boolean
}
