import { Info } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { reorderComparabilityFactors } from 'api/transactions/comparabilityMetrics'
import { SelectedComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import { getCFReorderColumns } from './reorderComparabilityFactors.columns'

type Props = {
  comparabilityFactorsList: SelectedComparabilityFactor[]
  isOpen: boolean
  onClose: VoidFunction
  refetchSelectedFactors: VoidFunction
  economicAnalysisId: number
}

const ReorderComparabilityFactors = ({
  comparabilityFactorsList,
  isOpen,
  onClose,
  refetchSelectedFactors,
  economicAnalysisId,
}: Props) => {
  const [reorderedCF, setReorderedCF] = useState<SelectedComparabilityFactor[]>(comparabilityFactorsList)

  const reorderCFRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedAttributes = [...reorderedCF]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setReorderedCF([...swappedAttributes])
    },
    [reorderedCF]
  )

  useEffect(() => {
    setReorderedCF(comparabilityFactorsList)
  }, [comparabilityFactorsList])

  const comparabilityFactorsColumns = useMemo(() => {
    return getCFReorderColumns()
  }, [])

  const { refetchChecklist } = useReportWizardContext()

  const reorderCFMutation = useMutation(reorderComparabilityFactors)

  const handleReorderSave = useCallback(() => {
    reorderCFMutation.mutate(
      {
        economic_analysis: economicAnalysisId,
        selected_comparability_factors: reorderedCF.map(factor => factor.id),
      },
      {
        onSuccess: () => {
          toast.success('Reordered Comparability Factors')
          onClose()
          refetchChecklist()
          refetchSelectedFactors()
        },
        onError: error => {
          getToastErrorMessage(error as AxiosError)
        },
      }
    )
  }, [reorderCFMutation, economicAnalysisId, reorderedCF, onClose, refetchChecklist, refetchSelectedFactors])

  return (
    <Modal
      containerClassName="p-6 max-w-[52.5rem] w-full text-start relative"
      className="!gap-6 relative"
      isOpen={isOpen}
      onClose={onClose}
      title="Reorder Selected Factors">
      {reorderCFMutation.isLoading && (
        <Loading className="absolute inset-0 flex items-center justify-center z-10 bg-white" />
      )}
      <BorderlessBox className="w-full h-max-[70vh]">
        <Typography variant={Variant.Callout} className=" text-gray-700 flex items-center mb-1">
          <Info className="text-[#fb9800] w-4 h-4 mr-1" /> All the factors will appear in the final report the way you
          reorder them
        </Typography>
        <NewTable
          classes={{
            table: { head: '!bg-blue50 py-0' },
            container: 'rounded-xl',
          }}
          columns={comparabilityFactorsColumns}
          data={reorderedCF}
          reorderRow={reorderCFRow}
          showHeader={false}
        />
      </BorderlessBox>
      <Button
        disabled={reorderCFMutation.isLoading || reorderedCF == comparabilityFactorsList}
        className="ms-auto"
        onClick={handleReorderSave}
        icon={IconsType.save}
        isDefaultSize={false}
        iconPathClassName={
          reorderCFMutation.isLoading || reorderedCF == comparabilityFactorsList ? 'fill-gray-500' : 'fill-white'
        }>
        Save
      </Button>
    </Modal>
  )
}

export default ReorderComparabilityFactors
