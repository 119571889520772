import { Tooltip } from '@mui/material'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import CheckboxActive from 'assets/icons/checkboxActive'
import CheckboxInactive from 'assets/icons/checkboxInactive'
import classNames from 'classnames'
import FlagReader from 'components/flagReader/FlagReader'
import Typography, { Variant } from 'components/typography'
import { sortBy } from 'lodash'
import React, { useMemo } from 'react'
import { components as Component, GroupBase, OptionProps } from 'react-select'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter, truncateString } from 'utils/utils'

const TxnSelectAndReorderOption = (props: OptionProps<SelectOptions, true, GroupBase<SelectOptions>>) => {
  const txn = props.data.additionalData as TransactionResponse
  const sortedEntitiesByActive = useMemo(
    () =>
      sortBy(
        txn.individual_transactions.map(txn => txn.associated_entity),
        et => (et.active_status == 'dormant' ? 1 : 0)
      ),
    [txn.individual_transactions]
  )
  const firstEntity = sortedEntitiesByActive[0]
  const firstEntityName = firstEntity.name
  return (
    <Component.Option
      {...props}
      className={classNames('!border !border-solid !rounded-lg !border-gray-800 !mb-2 w-full !px-4 !py-4', {
        '!bg-transparent': !props.isFocused,
        '!bg-[#ffffff10]': props.isFocused,
      })}>
      <div className="flex text-start items-center w-full gap-2">
        {props.isSelected ? (
          <CheckboxActive className="w-[18px] h-[18px]" />
        ) : (
          <CheckboxInactive className="w-[18px] h-[18px]" />
        )}
        <div className="flex w-full items-center gap-2">
          <Typography variant={Variant.Callout} className="text-gray-300  w-full max-w-[42%]">
            {txn.name}
          </Typography>
          <div className="flex flex-col items-start w-full max-w-[24%]">
            <Tooltip title={capitalizeFirstLetter(txn.primary_entity.name)} placement="top">
              <div className={classNames('text-neutral800  flex')}>
                <FlagReader
                  country={txn.primary_entity.country}
                  otherText={truncateString(
                    capitalizeFirstLetter(txn.primary_entity.name_abbreviation || txn.primary_entity.name),
                    30
                  )}
                  textClassName={'text-gray-300 !font-normal max-w-[150px] truncate !text-callout'}
                />
              </div>
            </Tooltip>
            {txn.primary_entity.active_status == 'dormant' && (
              <div className="bg-red-100 mt-0.5 min-h-[20px] py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
                Inactive
              </div>
            )}
          </div>
          <div className="flex w-full items-center max-w-[24%]">
            <div className="flex flex-col items-start">
              <Tooltip title={capitalizeFirstLetter(firstEntityName)} placement="top">
                <div className={classNames('text-neutral800 flex')}>
                  <FlagReader
                    country={firstEntity.country}
                    otherText={truncateString(
                      capitalizeFirstLetter(firstEntity.name_abbreviation || firstEntity.name),
                      30
                    )}
                    textClassName={'text-gray-300  max-w-[150px] truncate !font-normal !text-callout'}
                  />
                </div>
              </Tooltip>
              {firstEntity.active_status == 'dormant' && (
                <div className="bg-red-100 mt-0.5 min-h-[20px] py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
                  Inactive
                </div>
              )}
            </div>
            {sortedEntitiesByActive.length > 1 && (
              <Tooltip
                title={
                  <>
                    {sortedEntitiesByActive.map((entity, idx) => {
                      const entityName = entity.name_abbreviation || entity.name
                      return (
                        idx !== 0 && (
                          <div
                            className={classNames('flex flex-col py-2 self-stretch px-3 gap-1', {
                              'border-0 border-t border-gray-700 border-solid': idx > 1,
                            })}>
                            <Typography
                              key={`relatedParty-${entity.id}`}
                              variant={Variant.Callout}
                              type="semibold"
                              className={classNames('text-gray-300 max-w-[100%] truncate gap-1 flex')}>
                              <FlagReader country={entity.country} />
                              {capitalizeFirstLetter(entityName)}
                            </Typography>
                            {entity.active_status == 'dormant' && (
                              <div className="bg-red-100 min-h-[20px] w-min py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
                                Inactive
                              </div>
                            )}
                          </div>
                        )
                      )
                    })}
                  </>
                }
                classes={{
                  tooltip:
                    'p-0 bg-[#1A193B] w-[200px] max-h-[258px] overflow-y-auto rounded-lg border border-solid border-neutral-500',
                }}
                placement="top">
                <span className="flex items-center cursor-pointer ml-2">
                  {sortedEntitiesByActive.length > 1 ? (
                    <span className="bg-orange-100 rounded-[0.25rem] px-1 py-[2px] text-orange-700 text-footnote">
                      +{sortedEntitiesByActive.length - 1}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </Tooltip>
            )}
          </div>
          {txn.economic_analysis?.transfer_pricing_method_abbreviation ? (
            <Typography variant={Variant.Callout} className="text-gray-300 w-full max-w-[10%] text-end">
              {txn.economic_analysis?.transfer_pricing_method_abbreviation}
            </Typography>
          ) : (
            <Typography variant={Variant.Callout} type="semibold" className="text-gray-500 w-full max-w-[10%] text-end">
              NA
            </Typography>
          )}
        </div>
      </div>
    </Component.Option>
  )
}

export default TxnSelectAndReorderOption
