import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import { renderToString } from 'react-dom/server'
import { Editor as RawEditor } from 'tinymce'

export const setupExhibitButton = (editor: RawEditor, callback?: VoidFunction) => {
  editor.ui.registry.addIcon(
    'exibitIcon',
    renderToString(getIcons(IconsType.labelIcon, { className: 'w-5 h-5', pathClassName: 'fill-black' }))
  )
  editor.ui.registry.addIcon(
    'exibitIconDisabled',
    renderToString(
      getIcons(IconsType.labelIcon, {
        className: 'w-5 h-5',
        pathClassName: 'stroke-gray-400 fill-gray-400',
      })
    )
  )

  editor.ui.registry.addButton('exhibitButton', {
    icon: 'exibitIconDisabled',
    tooltip:
      'Select text and click the button to add an automatically numbered exhibit. To remove a exhibit, place the cursor inside the exhibit and click on the button. Exhibits will be auto numbered in the final PDF',
    enabled: false,
    onSetup: buttonApi => {
      const editorEventCallback = () => {
        const selectedRange: Range | null = editor.selection.getRng()
        const selectedText: string = selectedRange ? selectedRange.toString().trim() : ''

        const cursorStartContainer = selectedRange.startContainer
        const cursorEndContainer = selectedRange.endContainer

        let isExhibitRelated = false

        // Check if any ancestor or descendant of the selected range contains a exhibit
        if (selectedRange) {
          const selectedNodes = selectedRange.cloneContents().querySelectorAll('.exhibit')
          const ancestorWithExhibit = selectedRange.commonAncestorContainer.parentElement?.closest('.exhibit')

          if (selectedNodes.length > 0 || ancestorWithExhibit) {
            isExhibitRelated = true
          }
        }
        let isExhibit = false
        // Check if cursor is on a exhibit
        if (selectedRange) {
          const isCursorOnExhibit =
            cursorStartContainer == cursorEndContainer &&
            ((cursorEndContainer as HTMLElement)?.parentElement?.classList.contains('exhibit') ||
              (cursorStartContainer as Element)?.classList?.contains('exhibit'))
          if (isCursorOnExhibit) {
            isExhibit = true // Overwrite the previous flag if cursor is on a exhibit
          }
        }

        // Enable or disable the button based on the presence of selected text and exhibit relations
        const buttonEnabled =
          (cursorStartContainer == cursorEndContainer && !isExhibitRelated && !!selectedText.length) ||
          isExhibit ||
          (!selectedText && selectedRange && selectedRange.collapsed)
        buttonApi.setEnabled(buttonEnabled)
        buttonApi.setIcon(buttonEnabled ? 'exibitIcon' : 'exibitIconDisabled')
      }

      editor.on('NodeChange', editorEventCallback)

      // Call the event callback to set initial button state
      editorEventCallback()

      return () => {
        editor.off('NodeChange', editorEventCallback)
      }
    },

    onAction: (): void => {
      editor.undoManager.transact(() => {
        const selectedRange: Range | null = editor.selection.getRng()
        const selectedText: string = selectedRange ? selectedRange.toString().trim() : ''

        const cursorStartContainer = selectedRange.startContainer
        const cursorEndContainer = selectedRange.endContainer
        const isCursorOnExhibit =
          cursorStartContainer == cursorEndContainer &&
          (cursorStartContainer.parentElement?.classList.contains('exhibit') ||
            (cursorStartContainer as Element)?.classList?.contains('exhibit') ||
            cursorStartContainer.parentElement?.closest('.exhibit') !== null)
        if (isCursorOnExhibit) {
          let exhibitNode = cursorStartContainer.parentElement?.closest('.exhibit')
          if ((cursorStartContainer as Element)?.classList?.contains('exhibit') && !exhibitNode) {
            exhibitNode = cursorStartContainer as Element
          }
          if (exhibitNode) {
            const parent = exhibitNode.parentElement
            if (parent) {
              const cursorPosition = editor?.selection?.getSel()?.getRangeAt(0)?.startOffset || 0
              const clonedContent = exhibitNode.innerHTML
              parent.insertBefore(document.createRange().createContextualFragment(clonedContent.trim()), exhibitNode)
              parent.removeChild(exhibitNode)
              const newRange = editor.dom.createRng()
              newRange.setStart(parent.childNodes[cursorPosition], 0)
              newRange.collapse(true)
              editor.selection.setRng(newRange)
            }
          }
        } else if (!selectedText && selectedRange && selectedRange.collapsed) {
          editor.dom.select('.exhibit').forEach(exhibit => {
            if (!(exhibit?.textContent?.trim && exhibit.textContent.trim())) {
              exhibit.remove()
            }
          })
          const oldNewExhibits = editor.dom.select('.latestExhibit')
          oldNewExhibits.forEach(element => element.classList.remove('latestExhibit'))
          const newExhibit = document.createElement('div')
          newExhibit.classList.add('exhibit', 'latestExhibit')
          newExhibit.innerHTML = '&#xFEFF;'

          editor.selection.setContent(newExhibit.outerHTML)
          const newRange = editor.dom.createRng()
          const latestExhibit = editor.dom.select('.latestExhibit')
          newRange.setStart(latestExhibit[0], 1)
          newRange.collapse(true)
          editor.selection.setRng(newRange)
        } else if (selectedText !== '') {
          const wrappedText = `<div class="exhibit">${selectedText}</div>`
          editor.selection.setContent(wrappedText)
          editor.selection.collapse()
        }
      })
    },
  })

  callback && callback()
}
