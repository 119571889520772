import { Dialog, Transition } from '@headlessui/react'
import { ThumbUpAltOutlined } from '@mui/icons-material'
import cx from 'classnames'
import Button from 'components/button'
import { StepIds } from 'hooks/useFTUTour/types'
import useFTUTour from 'hooks/useFTUTour/useFTUTour'
import React, { Fragment, useEffect, useMemo } from 'react'

const TourCompleteModal = () => {
  const { step, stepLoading, setStepLoading, isTourActive, handleNextStepChange } = useFTUTour()

  const isCurrentStep = useMemo(() => {
    return step?.id === StepIds.Done
  }, [step?.id])

  useEffect(() => {
    if (isCurrentStep && stepLoading) {
      setStepLoading(false)
    }
  }, [isCurrentStep, setStepLoading, stepLoading])

  const modalOpen = isCurrentStep && !stepLoading && isTourActive

  return (
    <Transition appear show={!!modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 commonIntegralModalClass" onClose={() => console.log('Dialog closed')}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className={cx('transform  rounded-xl bg-white transition-all')}>
                <div className={cx('flex flex-col items-center rounded-lg bg-[#0A0929] p-6 w-[528px] gap-[22px]')}>
                  <div className="w-full flex flex-col items-center gap-4">
                    <div className="bg-[#32316080] p-6 rounded-full w-[76px] h-[76px]">
                      <ThumbUpAltOutlined className="w-7 h-7 text-orange-500" />
                    </div>
                    <div className="flex flex-col text-white items-center self-stretch gap-3">
                      <div className="h-[25px] text-center text-white text-xs font-semibold leading-[19px]">
                        WELL DONE!
                      </div>
                      <div className="flex flex-col items-center self-stretch gap-1">
                        <div className="text-body font-medium	">You have successfully completed the product tour.</div>
                        <div className="text-footnote">You will be redirected back to your account</div>
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={handleNextStepChange}
                    className="flex !flex-row items-center !gap-1 !py-0  !bg-indigo-950 hover:!bg-indigo-900 focus:!bg-indigo-900 !text-indigo-300">
                    Done
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default TourCompleteModal
