import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateBenchmarkTransactionEntities } from 'api/benchmarks'
import { Benchmark } from 'api/benchmarks/types'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button from 'components/button'
import Modal from 'components/modal'
import { QUERIES } from 'constants/query'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import SelectDropDownWithCheckBox from 'organisms/fieldRenderers/fields/selectWithCheckbox'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { comparableSearchOptions } from '../../config/benhMarkAnalyis.formConfig'
import { getBenchMarkOptionsWithYears, getFilteredBenchmarkList } from '../../helpers/benchMarkAnalysis.helpers'
import { BENCHMARK_FORM_FIELDS, TestedPartyBenchmarksTableData } from '../../types'
import AddBenchmarkModal from '../addBenchmarkModal'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  benchmarksList?: Benchmark[]
  excludedBenchmarks?: number[]
  year: number
  testedPartiesOptions: SelectOptions[]
  economicAnalysisTransactionResponse?: economic_analysis
  selectedRow?: TestedPartyBenchmarksTableData
  saveCallback: VoidFunction
}

const BenchmarkAddEntitiesModal = ({
  isOpen,
  onClose,
  benchmarksList,
  excludedBenchmarks,
  year,
  testedPartiesOptions,
  economicAnalysisTransactionResponse,
  selectedRow,
  saveCallback,
}: Props) => {
  const [isAddBenchmarkModal, setAddBenchmarkModal] = useState<boolean>(false)

  const filteredBenchmarks = useMemo(() => {
    if (!excludedBenchmarks?.length) return benchmarksList
    return benchmarksList?.filter(benchmark => !excludedBenchmarks.includes(benchmark.id))
  }, [benchmarksList, excludedBenchmarks])

  const comparableSearchOptionsWithCount = useMemo(
    () => comparableSearchOptions(filteredBenchmarks),
    [filteredBenchmarks]
  )

  const { control, watch, reset, formState } = useForm<BENCHMARK_FORM_FIELDS>({
    defaultValues: {
      comparableSearchOptions: comparableSearchOptionsWithCount[0],
    },
  })

  const selectMenuButtonProps = {
    buttonLabel: 'Add New',
    buttonAction: () => setAddBenchmarkModal(true),
  }

  useEffect(() => {
    if (selectedRow) {
      reset({
        comparableSearchOptions: selectedRow.benchmarkingData?.region
          ? { label: selectedRow.benchmarkingData?.region, value: selectedRow.benchmarkingData?.region }
          : comparableSearchOptionsWithCount[0],
        selectedBechmark: {
          value: selectedRow.benchmarkId,
          label: selectedRow.benchmarkingData?.name || selectedRow.benchmarkId,
        },
        selectedLEs: selectedRow.legalEntites.map(entity => {
          return {
            label: entity.name_abbreviation || entity.name,
            value: entity.id,
          }
        }),
      })
    } else {
      reset({
        comparableSearchOptions: comparableSearchOptionsWithCount[0],
      })
    }
  }, [comparableSearchOptionsWithCount, reset, selectedRow])

  const comparableSearchValue = watch('comparableSearchOptions')
  const selectedBenchmark = watch('selectedBechmark')
  const selectedLe = watch('selectedLEs')
  const filteredBenchmarkList = useMemo(
    () =>
      getFilteredBenchmarkList({
        benchmarksList: filteredBenchmarks,
        comparableSearchValue,
      }),
    [filteredBenchmarks, comparableSearchValue]
  )

  const benchmarkListOptions = useMemo(
    () => getBenchMarkOptionsWithYears(filteredBenchmarkList || []),
    [filteredBenchmarkList]
  )
  const { refetchChecklist } = useReportWizardContext()

  const updateBenchmarkTransactionEntitiesMutation = useMutation(updateBenchmarkTransactionEntities, {
    onSuccess: () => {
      onClose()
      saveCallback()
      refetchChecklist()
      toast.success('Updated Benchmark for selected entities')
    },
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
  })

  const handleSave = useCallback(() => {
    if (!economicAnalysisTransactionResponse?.id || !selectedBenchmark?.value) return
    updateBenchmarkTransactionEntitiesMutation.mutate({
      economic_analysis: economicAnalysisTransactionResponse.id,
      benchmarking: Number(selectedBenchmark.value),
      tested_parties: (selectedLe || []).map(opt => Number(opt.value)),
    })
  }, [
    economicAnalysisTransactionResponse,
    selectedBenchmark?.value,
    selectedLe,
    updateBenchmarkTransactionEntitiesMutation,
  ])

  const queryClient = useQueryClient()

  const handleAddBenchmark = useCallback(
    (benchmark: Benchmark) => {
      queryClient.setQueriesData<Benchmark[]>([QUERIES.GET_ALL_BENCHMARKS.key, year], olddata => {
        return [...(olddata || []), benchmark]
      })
      reset(values => {
        return {
          ...values,
          comparableSearchOptions: benchmark.region
            ? { label: benchmark.region, value: benchmark.region }
            : comparableSearchOptionsWithCount[0],
          selectedBechmark: {
            value: benchmark.id,
            label: benchmark.name,
          },
        }
      })
    },
    [comparableSearchOptionsWithCount, queryClient, reset, year]
  )

  const disableButton =
    !selectedBenchmark?.value ||
    updateBenchmarkTransactionEntitiesMutation.isLoading ||
    !selectedLe?.length ||
    !Object.keys(formState.dirtyFields).length

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={'p-4 w-[980px] max-w-[100vw] text-start'}
      title={'Add legal entities to a benchmark'}>
      <div className="flex items-center gap-6 w-full">
        <SELECT_DROPDOWN
          label="Region of the comparable search"
          control={control}
          id="comparableSearchOptions"
          options={comparableSearchOptionsWithCount}
          disabled={!!selectedRow}
          className="w-1/2"
        />
        <SELECT_DROPDOWN
          label="Benchmark"
          required
          control={control}
          id="selectedBechmark"
          options={benchmarkListOptions}
          showSelectMenuButton
          selectMenuButtonProps={selectMenuButtonProps}
          isCustomFilter
          placeholder="Select a Benchmark to continue"
          className="w-1/2"
          disabled={!!selectedRow}
        />
      </div>
      <SelectDropDownWithCheckBox
        label="Legal entities"
        control={control}
        id="selectedLEs"
        options={testedPartiesOptions}
        placeholder="Select a Tested Parties"
        menuPlacement="top"
      />
      <Button
        onClick={handleSave}
        className="self-end"
        icon={IconsType.save}
        iconPathClassName="fill-white"
        disabled={disableButton}
        isDefaultSize={false}>
        Save
      </Button>
      {isAddBenchmarkModal && (
        <AddBenchmarkModal
          setAddBenchmarkModal={setAddBenchmarkModal}
          isAddBenchmarkModal={isAddBenchmarkModal}
          year={year}
          handleBenchmarkAddSuccess={handleAddBenchmark}
        />
      )}
    </Modal>
  )
}

export default BenchmarkAddEntitiesModal
