/* eslint-disable @typescript-eslint/no-explicit-any */
import DummyUserProfilePicture from 'assets/images/empty-user-image.png'
import { cloneDeep } from 'lodash'
import _find from 'lodash/find'
import _reduce from 'lodash/reduce'
import _remove from 'lodash/remove'
import { MutableRefObject } from 'react'

import { EditorAuthor, EditorComment, EditorRef } from '../types'

/*
  TinyMCE related comments related functions
  For more information and documentation please refer to the below link
  Ref : https://www.tiny.cloud/docs/tinymce/6/comments-callback-mode/
*/

const getUser = (userId: number, users: EditorAuthor[]) => {
  const user = _find(users, user => user.id === userId)
  return user
}

const generateUniqueWord = () => {
  // Get the current timestamp
  const timestamp = new Date().getTime()

  // Generate a random number (between 10000 and 99999)
  const randomPart = Math.floor(Math.random() * 90000) + 10000

  // Concatenate the timestamp and random number
  const uniqueWord = `${timestamp}${randomPart}`

  return uniqueWord
}

export const createComment = ({ ref, done, editorRef, author }: CommentsHandlerArguments) => {
  const { content, createdAt } = ref
  const conversationUid = `mce-conversation_${generateUniqueWord()}`
  const conversations = [...(editorRef?.current?.comments || [])]

  conversations.push({
    uid: conversationUid,
    author: author.id || 0,
    content,
    createdAt,
    modifiedAt: createdAt,
    replies: [],
  })

  editorRef.current.comments = conversations

  done({ conversationUid })
}

export const replyToComment = ({ ref, done, fail, editorRef, author }: CommentsHandlerArguments) => {
  const { content, createdAt, conversationUid } = ref
  const conversations = [...(editorRef?.current?.comments || [])]
  const commentUid = `mce-reply_${generateUniqueWord()}`
  const thread = _find(conversations, comment => comment.uid === conversationUid)

  if (thread) {
    thread.replies.push({
      author: author.id || 0,
      content,
      createdAt,
      modifiedAt: createdAt,
      uid: commentUid,
    })

    editorRef.current.comments = conversations
    done({ commentUid })
  }

  fail('No thread found against the content')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const editComment = ({ ref, done, fail, editorRef, author }: CommentsHandlerArguments) => {
  const reason = ''
  let commentEdited = false

  const { conversationUid, commentUid, content, modifiedAt } = ref
  const conversations = [...(editorRef?.current?.comments || [])]
  const thread = _find(conversations, conversation => conversation.uid === conversationUid)

  if (thread) {
    if (conversationUid === commentUid) {
      // If the first comment is being edited
      thread.content = content
      thread.modifiedAt = modifiedAt
      commentEdited = true
    } else if (thread.replies) {
      const comment = _find(thread.replies, comment => comment.uid === commentUid)
      if (comment) {
        comment.content = content
        comment.modifiedAt = modifiedAt
        commentEdited = true
      }
    }

    if (commentEdited) {
      done({ canEdit: true })
    } else {
      done({ canEdit: false, reason })
    }
  } else {
    fail('Thread not present')
  }

  fail('Unknown Error')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteComment = ({ ref, done, fail, editorRef, author }: CommentsHandlerArguments) => {
  let reason = ''
  let commentRemoved = false

  const { commentUid, conversationUid } = ref
  const conversations = [...(editorRef?.current?.comments || [])]
  const thread = _find(conversations, conversation => conversation.uid === conversationUid)

  if (thread?.replies && thread.replies.length) {
    const comment = _find(thread.replies, comment => comment.uid === commentUid)
    if (author.id === comment?.author) {
      _remove(thread?.replies, comment)
    } else {
      reason = 'User not authorised to delete this comment'
    }
    commentRemoved = true
  }

  editorRef.current.comments = conversations

  if (commentRemoved) {
    done({ canDelete: true })
  } else {
    done({ canDelete: false, reason })
  }

  fail('Unknown error')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteCommentThread = ({ ref, done, fail, editorRef, author }: CommentsHandlerArguments) => {
  let reason = ''
  let commentRemoved = false

  const { conversationUid } = ref
  const conversations = [...(editorRef?.current?.comments || [])]
  const thread = _find(conversations, conversation => conversation.uid === conversationUid)

  if (author.id === thread?.author) {
    _remove(conversations, thread)
  } else {
    reason = 'User not authorised to delete this conversation'
  }
  commentRemoved = true

  editorRef.current.comments = conversations

  if (commentRemoved) {
    done({ canDelete: true })
  } else {
    done({ canDelete: false, reason })
  }

  fail('Unknown error')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteAllCommentThreads = ({ ref, done, fail, editorRef, author }: CommentsHandlerArguments) => {
  // TODO: Will be working on this once the permissions to delete all conversations is clear
  return {}
}

export const lookupAComment = ({ ref, done, fail, editorRef, author, prevAuthors }: CommentsHandlerArguments) => {
  const { conversationUid } = ref
  const lookup = async () => {
    const conversations = [...(editorRef?.current?.comments || [])]
    const thread = _find(conversations, comment => comment.uid === conversationUid)

    if (thread) {
      const threadCreatorId = thread.author
      const threadCreator = getUser(threadCreatorId, [...prevAuthors, author])
      return {
        conversation: {
          uid: conversationUid,
          comments: _reduce(
            thread?.replies,
            (filteredComments: (EditorComment & { authorName?: string; authorAvatar?: string })[], comment) => {
              const commenterId = comment.author
              const commenter = getUser(commenterId, [...prevAuthors, author])
              filteredComments.push({
                ...comment,
                content: comment.content,
                authorName: commenter?.fullName || commenter?.username || 'Unknown',
                authorAvatar: commenter?.avatarUrl || DummyUserProfilePicture.src,
              })
              return filteredComments
            },
            [
              {
                id: thread.id,
                author: thread.author,
                createdAt: thread.createdAt,
                modifiedAt: thread.modifiedAt,
                content: thread.content,
                uid: thread.uid,
                authorName: threadCreator?.fullName || threadCreator?.username || 'Unknown',
                authorAvatar: threadCreator?.avatarUrl || DummyUserProfilePicture.src,
              },
            ]
          ),
        },
      }
    }
  }

  lookup()
    .then(data => {
      console.log(`Lookup success ${conversationUid}`, data)
      done(data)
    })
    .catch(err => {
      console.error(`Lookup failure ${conversationUid}`, err)
      fail(err)
    })
}

export const fetchComments = ({ ref, done, fail, editorRef, author, prevAuthors }: CommentsHandlerArguments) => {
  const conversationUids = ref as string[]
  const lookup = async () => {
    const conversations = cloneDeep(editorRef?.current?.comments || [])
    const response: Record<string, any> = {}
    const threads = conversations.filter(conv => conversationUids.includes(conv.uid))
    threads.forEach(thread => {
      const threadCreatorId = thread.author
      const threadCreator = getUser(threadCreatorId, [...prevAuthors, author])
      response[thread.uid] = {
        uid: thread.uid,
        comments: _reduce(
          thread?.replies,
          (filteredComments: (EditorComment & { authorName?: string; authorAvatar?: string })[], comment) => {
            const commenterId = comment.author
            const commenter = getUser(commenterId, [...prevAuthors, author])
            filteredComments.push({
              ...comment,
              content: comment.content,
              authorName: commenter?.fullName || commenter?.username || 'Unknown',
              authorAvatar: commenter?.avatarUrl || DummyUserProfilePicture.src,
            })
            return filteredComments
          },
          [
            {
              id: thread.id,
              author: thread.author,
              createdAt: thread.createdAt,
              modifiedAt: thread.modifiedAt,
              content: thread.content,
              uid: thread.uid,
              authorName: threadCreator?.fullName || threadCreator?.username || 'Unknown',
              authorAvatar: threadCreator?.avatarUrl || DummyUserProfilePicture.src,
            },
          ]
        ),
      }
    })
    return response
  }

  lookup()
    .then(data => {
      console.log(`Fetch success ${conversationUids.join(',')}`, data)
      done({ conversations: data })
    })
    .catch(err => {
      console.error(`Fetch failure ${conversationUids.join(',')}`, err)
      fail(err)
    })
}

interface CommentsHandlerArguments {
  ref: any
  done: any
  fail: any
  author: EditorAuthor
  prevAuthors: EditorAuthor[]
  editorRef: MutableRefObject<EditorRef>
}
