import { useQuery } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { attributes } from 'api/transactions/getTransaction/types'
import Loading from 'components/loading'
import { QUERIES } from 'constants/query'

import FunctionSelector from './organisms/functionSelector'
import { segregateAttributesFromTransactions } from './organisms/functionSelector/components/addFunctionsModal/helpers'
const FunctionalFunctions = ({
  transactionResponse = {} as TransactionByIdResponse,
  tabKey,
  attributesFetching,
  transactionResponseLoading,
  selectedTransactionId,
  transactionRefetch,
  attributesOnTabKey,
}: FunctionalAnalysisProps) => {
  const {
    data,
    isLoading,
    refetch: functionProfileRefetch,
  } = useQuery(
    [QUERIES.GET_ALL_FUNCTIONAL_PROFILE_ATTRIBUTE.key, tabKey, transactionResponse?.functional_profile?.id],
    {
      queryFn: () =>
        QUERIES.GET_ALL_FUNCTIONAL_PROFILE_ATTRIBUTE.function({
          attributeType: tabKey,
          id: transactionResponse?.functional_profile?.id,
        }),
    }
  )

  const functionsProfileAttributesResponse = data?.data

  const { selectedAttributes, unselectedAttributes } = segregateAttributesFromTransactions({
    tabKey,
    attributesOnTabKey,
    functionsProfileAttributesResponse: functionsProfileAttributesResponse || [],
  })
  const loading = isLoading || attributesFetching || transactionResponseLoading || !transactionResponse

  return (
    <div className="relative">
      {loading && <Loading className="absolute inset-0 bg-white flex items-center justify-center z-20" />}
      <FunctionSelector
        currentTransaction={transactionResponse}
        currentAttributes={attributesOnTabKey}
        selectedTransactionId={selectedTransactionId}
        transactionRefetch={transactionRefetch}
        functionProfileRefetch={functionProfileRefetch}
        selectedAttributes={selectedAttributes}
        unselectedAttributes={unselectedAttributes}
        tabKey={tabKey}
      />
    </div>
  )
}

interface FunctionalAnalysisProps {
  selectedTransactionId: number
  transactionResponse?: TransactionByIdResponse
  tabKey: string
  transactionRefetch: VoidFunction
  attributesOnTabKey: attributes[]
  attributesFetching?: boolean
  transactionResponseLoading: boolean
}

export default FunctionalFunctions
