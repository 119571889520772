import cx from 'classnames'
import Typography, { Variant } from 'components/typography'

const NoContentContainer = ({ title, className, subtitle, titleClassName }: NoContentContainerProps): JSX.Element => {
  return (
    <div className={cx('bg-neutral100 p-10 rounded-2xl flex flex-col text-center', className)}>
      <Typography variant={Variant.Body} type="semibold" className={cx('text-neutral500', titleClassName)}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant={Variant.Body} type="semibold" className={cx('text-neutral500', titleClassName)}>
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

interface NoContentContainerProps {
  title: string
  className?: string
  titleClassName?: string
  subtitle?: string
}

export default NoContentContainer
