import { useQuery } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { QUERIES } from 'constants/query'
export const useTransactionById = ({ selectedTransactionId, key }: useTransactionByIdProps) => {
  const {
    data: transactionResponse,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<TransactionByIdResponse>([QUERIES.GET_TRANSACTION_BY_ID.key, selectedTransactionId, key], {
    queryFn: () => QUERIES.GET_TRANSACTION_BY_ID.function(selectedTransactionId),
    enabled: !!selectedTransactionId,
  })

  return {
    transactionResponse,
    isLoading,
    isFetching,
    refetch,
  }
}

interface useTransactionByIdProps {
  selectedTransactionId: number
  key?: string
}
