import { useQuery } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import Loading from 'components/loading'
import Tabs from 'components/tabs/Tabs'
import { QUERIES } from 'constants/query'
import { useFilterAttributesByType } from 'hooks/useFilterAttributesByType'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { getTabsData } from './helpers/functionalAnalysis.helpers'

const FunctionalAnalysis = ({
  selectedTransactionId,
  transactionResponse,
  refetchTransaction,
  transactionRefetching,
}: FunctionalAnalysisProps): JSX.Element => {
  const { query, replace, pathname } = useRouter()

  const {
    data: attributesData,
    refetch: attributesRefecth,
    isFetching: attributesFetching,
  } = useQuery([QUERIES.GET_ATTRIBUTES_BY_TRANSACTION.key, selectedTransactionId], {
    queryFn: () => QUERIES.GET_ATTRIBUTES_BY_TRANSACTION.function({ transactionId: selectedTransactionId }),
  })

  const { assetAttributes, functionAttributes, riskAttributes } = useFilterAttributesByType(attributesData || [])

  const transactionRefetch = useCallback(() => {
    refetchTransaction()
    attributesRefecth()
  }, [attributesRefecth, refetchTransaction])

  const tabData = useMemo(
    () =>
      getTabsData({
        transactionResponseLoading: transactionRefetching,
        transactionResponse,
        selectedTransactionId,
        transactionRefetch,
        assetAttributes,
        functionAttributes,
        riskAttributes,
        attributesFetching,
      }),
    [
      transactionRefetching,
      transactionResponse,
      selectedTransactionId,
      transactionRefetch,
      assetAttributes,
      functionAttributes,
      riskAttributes,
      attributesFetching,
    ]
  )

  const tabIndex = useMemo(() => {
    const { farTab } = query

    const index = [
      FAR_TAB_TYPES.function,
      FAR_TAB_TYPES.assets,
      FAR_TAB_TYPES.risks,
      FAR_TAB_TYPES.conclusion,
    ].findIndex(element => {
      return element == farTab
    })
    return index == -1 ? 0 : index
  }, [query])

  const onTabChange: (idx: number, label: string) => void = useCallback(
    idx => {
      replace({
        pathname: pathname,
        query: {
          ...query,
          farTab: [FAR_TAB_TYPES.function, FAR_TAB_TYPES.assets, FAR_TAB_TYPES.risks, FAR_TAB_TYPES.conclusion][idx],
        },
      })
    },
    [pathname, query, replace]
  )

  if (transactionRefetching) {
    return (
      <div className="h-[80vh] flex flex-col justify-center items-center">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Tabs
        onTabChange={onTabChange}
        key={tabIndex}
        initialActiveTabIndex={tabIndex}
        tabType="pill"
        tabsData={tabData}
      />
    </>
  )
}

interface FunctionalAnalysisProps {
  selectedTransactionId: number
  transactionResponse?: TransactionByIdResponse
  refetchTransaction: () => void
  transactionRefetching: boolean
}

export enum FAR_TAB_TYPES {
  function = 'function',
  assets = 'assets',
  risks = 'risks',
  conclusion = 'conclusion',
}
export default FunctionalAnalysis
