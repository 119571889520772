import { Circle } from '@mui/icons-material'
import GreenTick from 'assets/icons/greenTick'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

export type ChecklistAccordionProps = {
  isOpen: boolean
  label: string
  href?: string
  skipped?: boolean
  skippable?: boolean
  skipKey?: string
  additionalSkipData?: {
    key: string
    value: string | number | boolean
  }
  added: boolean
  subSections: {
    label: string
    href?: string
    skipped?: boolean
    skipKey?: string
    additionalSkipData?: {
      key: string
      value: string | number | boolean
    }
    added: boolean
    hidden?: boolean
    skippable?: boolean
  }[]
  bgClass?: string
}

const ChecklistAccordion = ({
  skipped,
  href,
  label,
  added,
  subSections,
  skippable,
  skipKey,
  additionalSkipData,
  isOpen,
  bgClass = 'bg-[rgb(16,15,46)]',
}: ChecklistAccordionProps) => {
  const [open, setOpen] = useState(isOpen)
  const router = useRouter()
  const { setSkipFieldsRefValue, setIsExpanded, handleSkipClick } = useReportWizardContext()

  const handleClick = useCallback(() => {
    href && router.push(href)
    setIsExpanded(false)
  }, [href, setIsExpanded, router])

  useEffect(() => {
    subSections.forEach(section => {
      if (section.skippable && section.skipKey && section.skipped) {
        setSkipFieldsRefValue(`${section.skipKey}`, !!section.skipped)
        if (section.additionalSkipData) {
          setSkipFieldsRefValue(`${section.additionalSkipData.key}`, section.additionalSkipData.value)
        }
      }
    })
    if (skippable && skipKey && skipped) {
      setSkipFieldsRefValue(`${skipKey}`, !!skipped)
      if (additionalSkipData) {
        setSkipFieldsRefValue(`${additionalSkipData.key}`, additionalSkipData.value)
      }
    }
  }, [additionalSkipData, setSkipFieldsRefValue, skipKey, skippable, skipped, subSections])

  const handleSkip = useCallback(
    (
      key?: string,
      additionalSkipData?: {
        key: string
        value: string | number | boolean
      }
    ) => {
      if (key) {
        handleSkipClick(key, additionalSkipData)
      }
    },
    [handleSkipClick]
  )

  return (
    <div className={cx('py-3 px-4', bgClass)}>
      <div className="w-full flex items-center">
        <svg
          onClick={() => setOpen(o => !o)}
          className={cx('me-2', {
            'rotate-180': !open,
            hidden: !!skipped,
          })}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none">
          <rect y="12.5" width="12" height="12" rx="2" transform="rotate(-90 0 12.5)" fill="#A5B4FC" />
          <path
            d="M9.33398 8.16536L6.00065 4.83203L2.66732 8.16536"
            stroke="#312E81"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Typography
          onClick={handleClick}
          variant={Variant.Callout}
          type="semibold"
          className={cx('text-indigo-400 max-w-[470px]', {
            'cursor-pointer': !!href,
          })}>
          {label}
          {added && <GreenTick className="inline w-4 h-4 ms-2 relative top-1" pathClassName="fill-green-600" />}
        </Typography>
        {added ? null : skippable ? (
          skipped ? (
            <Typography variant={Variant.Footnote} type="semibold" className="text-gray-500 ms-2">
              Skipped
            </Typography>
          ) : (
            <Typography
              onClick={() => handleSkip(skipKey, additionalSkipData)}
              variant={Variant.Footnote}
              type="semibold"
              className="text-indigo-400 ms-auto cursor-pointer">
              Skip
            </Typography>
          )
        ) : (
          <></>
        )}
      </div>
      {!skipped &&
        subSections.map(section => {
          if (section.hidden) return null
          return (
            <div
              key={section.label}
              className={cx('w-full ps-1 flex items-center transition-all duration-300', {
                'mt-3 max-h-16 opacity-100': open,
                'mt-0 max-h-0 opacity-0 overflow-hidden': !open,
              })}>
              <Circle className="w-1 h-1 text-indigo-400 me-2" />
              <Typography
                onClick={() => {
                  if (!section.href) return
                  router.push(section.href)
                  setIsExpanded(false)
                }}
                variant={Variant.Callout}
                type="semibold"
                className={cx('text-indigo-400 max-w-[450px]', {
                  'cursor-pointer': !!section.href,
                })}>
                {section.label}
                {section.added && (
                  <GreenTick className="inline w-4 h-4 ms-2 relative top-1" pathClassName="fill-green-600" />
                )}
              </Typography>
              {section.added ? null : section.skippable ? (
                section.skipped ? (
                  <Typography variant={Variant.Footnote} type="semibold" className="text-gray-500 ms-2">
                    Skipped
                  </Typography>
                ) : (
                  <Typography
                    onClick={() => handleSkip(section.skipKey, section.additionalSkipData)}
                    variant={Variant.Footnote}
                    type="semibold"
                    className="text-indigo-400 ms-auto cursor-pointer">
                    Skip
                  </Typography>
                )
              ) : (
                <></>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default ChecklistAccordion
