import { ColumnDef } from '@tanstack/react-table'
import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { FinancialDataTableData } from '../types/financialData.types'

export const usGaapColumns = (year: number, gaap?: string): ColumnDef<FinancialDataTableData>[] => {
  const columns: ColumnDef<FinancialDataTableData>[] = [
    {
      header: gaap ? `Per ${gaap}` : '',
      accessorKey: 'name',
      accessorFn: row => row.name,
      size: 240,
      cell: EditableTableLabelCell,
      meta: {
        isReadOnly: true,
      },
    },
    {
      header: 'Formulae',
      accessorFn: row => row.formula || '--',
      cell: data => {
        return (
          <Typography
            className={classNames(
              'flex items-center text-start justify-start border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 mr-2 whitespace-nowrap'
            )}
            variant={Variant.Callout}
            type="semibold">
            {data.row.original.formula?.replace(/\s+/g, '') || '--'}
          </Typography>
        )
      },
      meta: {
        isReadOnly: true,
      },
      size: 150,
    },
    {
      accessorKey: year.toString(),
      accessorFn: row => row[year.toString()],
      header: `${year.toString()} *`,
      size: 200,
    },
    {
      accessorKey: (year - 1).toString(),
      accessorFn: row => row[(year - 1).toString()],
      size: 200,
    },
    {
      accessorKey: (year - 2).toString(),
      accessorFn: row => row[(year - 2).toString()],
      size: 200,
    },
  ]

  return columns
}
