import { requiredSchema } from 'schemas'
import { requiredDropdownOptionSchema } from 'schemas/schemas'
import { SelectOptions } from 'types/common.types'
import * as yup from 'yup'

import { CurrencyRequiredForValueTypes, FIELD_IDS } from '../constants/armsLengthRangeConclusion.constants'

export const armsLengthRangeConclusionSchema = yup.object({
  [FIELD_IDS.NAME]: requiredSchema,
  [FIELD_IDS.ARMS_LENGTH_RANGE]: requiredDropdownOptionSchema,
  [FIELD_IDS.VALUE]: requiredDropdownOptionSchema,
  [FIELD_IDS.CURRENCY]: yup.object().when(FIELD_IDS.VALUE, {
    is: (val: SelectOptions | undefined) => {
      return !!val?.value && CurrencyRequiredForValueTypes.includes(String(val?.value))
    },
    then: schema =>
      schema
        .shape({
          label: yup.string().required('This field is required'),
          value: yup.string().required('This field is required'),
        })
        .required('This field is required'),
    otherwise: schema => {
      return schema.nullable()
    },
  }),
})
