/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import _isEmpty from 'lodash/isEmpty'
import { Dispatch, KeyboardEventHandler, SetStateAction, useMemo } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { GroupBase, MultiValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { SelectOptions } from 'types/common.types'

import { selectStyle } from './styles'

function MultiSelectTextInput<T>({
  control,
  options,
  id,
  defaultValue,
  placeholder,
  label,
  className,
  required,
  disabled = false,
  menuPosition = 'absolute',
  menuPlacement = 'auto',
  handleChange,
  inputValue,
  setInputValue,
}: SelectFieldProps<T & FieldValues>) {
  const animatedComponents = makeAnimated()

  const components: Partial<SelectComponents<SelectOptions, true, GroupBase<SelectOptions>>> = useMemo(() => {
    return { ...animatedComponents, DropdownIndicator: null }
  }, [animatedComponents])

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={id as string}
      render={({ field: { onChange, onBlur, value, ref }, formState }) => {
        const { errors } = formState
        const newValue = value?.map((opt: SelectOptions) => options?.find(option => option.value == opt.value) || opt)

        const createOption = (label: string) => ({
          label,
          value: label,
        })
        const handleKeyDown: KeyboardEventHandler = event => {
          if (!inputValue) return

          switch (event.key) {
            case 'Enter':
            case 'Tab':
              setInputValue('')
              onChange([...(newValue || []), createOption(inputValue)])
              event.preventDefault()
          }
        }

        return (
          <div className={cx('flex flex-col items-start w-full', className)}>
            {label && (
              <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                {label}
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <CreatableSelect<SelectOptions, true>
              isClearable={true}
              menuPosition={menuPosition}
              menuPlacement={menuPlacement}
              ref={ref}
              blurInputOnSelect
              onBlur={onBlur}
              onChange={val => {
                onChange(val)
                handleChange && handleChange(val)
              }}
              inputValue={inputValue}
              onInputChange={(newValue, action) => {
                action.action == 'input-change' && setInputValue(newValue)
              }}
              value={newValue}
              menuIsOpen={false}
              onKeyDown={handleKeyDown}
              options={options}
              isDisabled={disabled}
              isMulti
              menuShouldScrollIntoView
              components={components}
              styles={selectStyle(Boolean(!_isEmpty(errors[id])))}
              placeholder={placeholder}
              defaultValue={defaultValue}
              className="w-full"
            />
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              !_isEmpty(errors[id]) && (
                <Typography variant={Variant.Callout} className="pt-[0.125rem] text-red500">
                  This field is required
                </Typography>
              )
            }
          </div>
        )
      }}
    />
  )
}

interface SelectFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  defaultValue?: SelectOptions
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  options?: SelectOptions[]
  className?: string
  disabled?: boolean
  required?: boolean
  menuPosition?: 'absolute' | 'fixed'
  menuPlacement?: 'top' | 'bottom' | 'auto'
  handleChange?: (val: MultiValue<SelectOptions>) => void
  setInputValue: Dispatch<SetStateAction<string>>
  inputValue: string
}

export default MultiSelectTextInput
