export const FIELDS_IDS = {
  NAME: 'name',
  REGION: 'region',
  COMBINED_BENCHMARKING_FILE: 'combined_benchmarking_file',
  BENCHMARK_STUDIES_FILE: 'benchmark_studies_file',
  BENCHMARK_STUDIES_FILE_NAME: 'benchmark_studies_file_name',
  COMPANY_INFO_FILE: 'company_info_file',
  COMPANY_INFO_FILE_NAME: 'company_info_file_name',
  ACCEPTED_REJECTION_MATRIX_FILE: 'accepted_rejection_matrix_file',
  ACCEPTED_REJECTION_MATRIX_FILE_NAME: 'accepted_rejection_matrix_file_name',
  QUARTERLY_ANALYSIS: 'quarterly_analysis',
} as const

export const CHARACTER_LIMITS = {
  [FIELDS_IDS.NAME]: 150,
}

export const LABEL_MAP: Record<FILE_IDS, string> = {
  combined_benchmarking_file: 'Complete Benchmark Study',
  benchmark_studies_file: 'Comparable Companies Search Strategy',
  accepted_rejection_matrix_file: 'Accepted/Rejection Matrix',
  company_info_file: 'Business Description of Accepted Companies',
} as const

export const FILES_ORDER = [
  'combined_benchmarking_file',
  'benchmark_studies_file',
  'accepted_rejection_matrix_file',
  'company_info_file',
] as const

export const complianceRegions = [
  {
    code: 'global',
    label: 'Global',
  },
  {
    code: 'americas',
    label: 'Americas',
  },
  {
    code: 'north_america',
    label: 'North America',
  },
  {
    code: 'south_america',
    label: 'South America',
  },
  {
    code: 'latam',
    label: 'LATAM',
  },
  {
    code: 'united_states',
    label: 'United States',
  },
  {
    code: 'brazil',
    label: 'Brazil',
  },
  {
    code: 'apac',
    label: 'APAC',
  },
  {
    code: 'australia',
    label: 'Australia',
  },
  {
    code: 'india',
    label: 'India',
  },
  {
    code: 'china',
    label: 'China',
  },
  {
    code: 'emea',
    label: 'EMEA',
  },
  {
    code: 'europe',
    label: 'Europe',
  },
  {
    code: 'western_europe',
    label: 'Western Europe',
  },
  {
    code: 'italy',
    label: 'Italy',
  },
  {
    code: 'poland',
    label: 'Poland',
  },
  {
    code: 'russia',
    label: 'Russia',
  },
]

export type FILE_IDS =
  | typeof FIELDS_IDS.COMBINED_BENCHMARKING_FILE
  | typeof FIELDS_IDS.BENCHMARK_STUDIES_FILE
  | typeof FIELDS_IDS.COMPANY_INFO_FILE
  | typeof FIELDS_IDS.ACCEPTED_REJECTION_MATRIX_FILE
