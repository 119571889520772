import axios from 'api'

import { FiltersResponse } from './types'
export const getLegalEntityAndTransactionsFiltersData = (year?: number) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }

  return axios
    .get<FiltersResponse>(`/api/v1/org/{organization}/metrics/main_dashboard_metrics/get_available_filters/`, {
      params,
    })
    .then(res => res.data)
}
