import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { SelectedComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import Typography, { Variant } from 'components/typography'

export const getCFReorderColumns = (): ColumnDef<SelectedComparabilityFactor>[] => {
  return [
    {
      accessorKey: 'comparability_factor_full_name',
      size: 300,
      cell(props) {
        const name = props.row.original.comparability_factor_full_name
        return (
          <Tooltip title={name}>
            <div>
              <Typography variant={Variant.Callout} className="flex items-center gap-1 my-0.5" type="semibold">
                {name.slice(0, 75)}
                {name.length > 75 ? '...' : ''}
              </Typography>
            </div>
          </Tooltip>
        )
      },
    },
  ]
}
