import { getTourSteps } from './helpers'

export type FTUTourContextType = {
  step: ReturnType<typeof getTourSteps>[0] | undefined
  isTourActive: boolean
  returnUrl?: string
  stepLoading: boolean
  setStepLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleNextStepChange: () => void
  handlePrevStepChange: () => void
  totalSteps: number
  currentStepNumber: number
}

export type FTUTourSections = ''

export enum StepTypes {
  Modal = 'modal',
  Video = 'video',
  Tooltip = 'tooltip',
}

export enum StepIds {
  Start = 'start',
  Organization = 'organization',
  MasterFile = 'MasterFile',
  MarketAnalysis = 'MarketAnalysis',
  LEActions = 'LEActions',
  LECreate = 'LECreate',
  LEList = 'LEList',
  Txns = 'Txns',
  TxnsBasicInfo = 'TxnsBasicInfo',
  TxnsList = 'TxnsList',
  ICFlow = 'ICFlow',
  FAR = 'FAR',
  FARConclusion = 'FARConclusion',
  EconomicBasicInfo = 'EconomicBasicInfo',
  MethodSelection = 'MethodSelection',
  FinancialData = 'FinancialData',
  BenchmarkingData = 'BenchmarkingData',
  EconomicConclusion = 'EconomicConclusion',
  Reports = 'Reports',
  ReportsReview = 'ReportsReview',
  Benchmarks = 'Benchmarks',
  AddBenchmarks = 'AddBenchmarks',
  Done = 'Done',
}

export enum TourHighlightIds {
  Organization = 'organizationTourHighlight',
  MarketAnalysis = 'MarketAnalysisTourHighlight',
  MasterFile = 'MasterFileTourHighlight',
  LEActions = 'LEActionsHighlight',
  LECreate = 'LECreateHighlight',
  LEList = 'LEListHighlight',
  TXNS = 'TXNSHighlight',
  TXNSList = 'TXNSListHighlight',
  TXNBasicInfo = 'TXNBasicInfoHighlight',
  Reports = 'ReportsHighlight',
  Layout = 'LayoutHighlight',
  Benchmarks = 'BenchmarksHighlight',
  AddBenchmarks = 'AddBenchmarksHighlight',
}

export enum TourTooltipPositioningType {
  ByID = 'byId',
  ByOffset = 'byOffset',
  ByPercentage = 'byPercentage',
}
