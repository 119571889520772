import { ColumnDef } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import React, { SetStateAction } from 'react'

import { FinancialData } from '../../../types/financialData.types'

type Props = {
  isEdit: boolean
  setIsEdit: (value: SetStateAction<boolean>) => void
  balanceSheetData: FinancialData[]
  setBalanceSheetData: React.Dispatch<React.SetStateAction<FinancialData[]>>
  balanceSheetColumns: ColumnDef<FinancialData>[]
  setBalanceSheetDirty: (value: SetStateAction<boolean>) => void
  balanceSheetAvgData: FinancialData[]
  balanceSheetAvgColumns: ColumnDef<FinancialData>[]
  readonly: boolean
}

const BalanceSheet = ({
  isEdit,
  setIsEdit,
  balanceSheetData,
  setBalanceSheetData,
  balanceSheetColumns,
  setBalanceSheetDirty,
  balanceSheetAvgColumns,
  balanceSheetAvgData,
  readonly,
}: Props) => {
  if (isEdit) {
    return (
      <BorderlessBox label="Edit Balance Sheet" className="gap-4" variant="white">
        <NewEditableTable
          data={balanceSheetData}
          setData={setBalanceSheetData}
          className="mt-4"
          isCommaFormatted
          columns={balanceSheetColumns}
          setTableAsDirty={setBalanceSheetDirty}
        />
      </BorderlessBox>
    )
  } else {
    return (
      <BorderlessBox
        label="Balance Sheet Averages"
        className="gap-4"
        variant="white"
        topRightComponents={
          !readonly ? (
            <Button
              isDefaultSize={false}
              variant={ButtonVariant.Secondary}
              iconPathClassName="stroke-blue-900"
              className="!bg-blue-50 !rounded-[17.92px] !w-[2rem] !h-[2rem] !p-[0.5rem] -my-2 !border-blue-200"
              icon={IconsType.edit}
              onClick={() => setIsEdit(true)}
            />
          ) : undefined
        }>
        <NewEditableTable
          data={balanceSheetAvgData}
          className="mt-4"
          isCommaFormatted
          columns={balanceSheetAvgColumns}
          emptyState={
            !readonly ? (
              <div className="w-full h-40 flex flex-col items-center justify-center">
                <Typography variant={Variant.Heading2} type="semibold" className="text-gray-700 mt-2">
                  Add Information to view average values
                </Typography>
                <Button
                  variant={ButtonVariant.Secondary}
                  className="!bg-teal-50 !border-teal-600 text-teal-600 mt-7"
                  icon={IconsType.plus}
                  onClick={() => setIsEdit(true)}
                  isDefaultSize={false}
                  iconPathClassName="stroke-teal-600">
                  Add
                </Button>
              </div>
            ) : (
              <div className="w-full h-40 flex flex-col items-center justify-center">
                <Typography variant={Variant.Heading2} type="semibold" className="text-gray-700 mt-2">
                  Data not present
                </Typography>
              </div>
            )
          }
        />
      </BorderlessBox>
    )
  }
}

export default BalanceSheet
