import { Info } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { reorderControlledOrUncontrolledTransactions } from 'api/transactions/comparableResults'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import { ControlledTransactionDataTableData } from '../../ControlledTransactionsData'
import { getComparabilityTableColumns } from '../../helpers/controlledTransactionsData.helper'

type Props = {
  transactionsList: ControlledTransactionDataTableData[]
  isOpen: boolean
  onClose: VoidFunction
  refetchTransactions: VoidFunction
  economicAnalysisId: number
}

const ReorderControlledTransactions = ({
  transactionsList,
  isOpen,
  onClose,
  refetchTransactions,
  economicAnalysisId,
}: Props) => {
  const [reorderedTransactions, setReorderedTransactions] =
    useState<ControlledTransactionDataTableData[]>(transactionsList)

  const reorderCFRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedAttributes = [...reorderedTransactions]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setReorderedTransactions([...swappedAttributes])
    },
    [reorderedTransactions]
  )

  useEffect(() => {
    setReorderedTransactions(transactionsList)
  }, [transactionsList])

  const controlledComparabilityTableColumns = useMemo(() => {
    return getComparabilityTableColumns({ showActionsColumn: false })
  }, [])

  const { refetchChecklist } = useReportWizardContext()

  const reorderTransactionsMutation = useMutation(reorderControlledOrUncontrolledTransactions)

  const handleReorderSave = useCallback(() => {
    reorderTransactionsMutation.mutate(
      {
        economic_analysis: economicAnalysisId,
        is_controlled_transaction: true,
        comparables_data: reorderedTransactions.map(transaction => transaction.id),
      },
      {
        onSuccess: () => {
          toast.success('Controlled Transactions Reordered Successfully!!')
          onClose()
          refetchTransactions()
          refetchChecklist()
        },
        onError: error => {
          getToastErrorMessage(error as AxiosError)
        },
      }
    )
  }, [
    reorderTransactionsMutation,
    economicAnalysisId,
    reorderedTransactions,
    onClose,
    refetchTransactions,
    refetchChecklist,
  ])

  return (
    <Modal
      containerClassName="p-6 max-w-[67rem] w-full text-start relative"
      className="!gap-6 relative"
      isOpen={isOpen}
      onClose={onClose}
      title="Reorder Transactions">
      {reorderTransactionsMutation.isLoading && (
        <Loading className="absolute inset-0 flex items-center justify-center z-10 bg-white" />
      )}
      <BorderlessBox className="w-full h-max-[70vh]" label="List of Controlled Transactions">
        <NewTable
          classes={{
            table: { head: '!bg-blue50 py-0' },
            container: 'rounded-xl',
          }}
          columns={controlledComparabilityTableColumns}
          data={reorderedTransactions}
          reorderRow={reorderCFRow}
        />
        <Typography variant={Variant.Callout} className=" text-gray-700 flex items-center mt-1">
          <Info className="text-[#fb9800] w-4 h-4 mr-1" /> All the transactions will appear in the final report the way
          you reorder them
        </Typography>
      </BorderlessBox>
      <Button
        disabled={reorderTransactionsMutation.isLoading || reorderedTransactions == transactionsList}
        className="ms-auto"
        onClick={handleReorderSave}
        icon={IconsType.save}
        isDefaultSize={false}
        iconPathClassName={
          reorderTransactionsMutation.isLoading || reorderedTransactions == transactionsList
            ? 'fill-gray-500'
            : 'fill-white'
        }>
        Save
      </Button>
    </Modal>
  )
}

export default ReorderControlledTransactions
