import { ColumnDef } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { TransactionTableItem } from './AddComparableControlledTransaction.types'

export const getTransactionTableColumns: (
  handleDeleteRow: (indexToDelete: number) => void
) => ColumnDef<TransactionTableItem>[] = (handleDeleteRow: (indexToDelete: number) => void) => {
  return [
    {
      accessorKey: 'label',
      header: 'No.',
      cell: EditableTableLabelCell,
      size: 100,
      meta: {
        isReadOnly: true,
      },
    },
    {
      accessorKey: 'value',
      header: 'Value*',
      size: 300,
    },
    {
      header: 'Volume*',
      accessorKey: 'volume',
      size: 300,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 50,
      cell: ({ row, table }) => {
        const isDeleteDisabled = table.getRowModel().rows.length === 1
        return (
          <div className="!bg-white !rounded-r !border-r  flex items-center border-0 border-y w-full border-solid border-neutral-200 h-[39px] mb-1">
            <Button
              onClick={() => handleDeleteRow(row.index)}
              isDefaultSize={false}
              variant={ButtonVariant.Tertiary}
              iconCLass="w-4 h-4"
              disabled={isDeleteDisabled}
              iconPathClassName={isDeleteDisabled ? 'stroke-gray-400' : 'stroke-orange-600'}
              className="flex justify-end w-full border-0 !border-l !border-solid !border-neutral-200 !rounded-none h-[27px]"
              icon={IconsType.delete}></Button>
          </div>
        )
      },
    },
  ]
}

export const getTransactionData: ({ length }: { length?: number }) => TransactionTableItem[] = ({ length }) => {
  const rowsCount = length || 0

  return Array.from({ length: rowsCount }, (_, index) => ({
    isEditable: true,
    isEditAllowed: true,
    key: `${index + 1}`,
    label: `${index + 1}`,
    value: '',
    volume: '',
  }))
}
