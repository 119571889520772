import { Tooltip } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { updateEconomicAnalysisTransaction } from 'api/transactions'
import { generateAiConclusionByType, getConclusionByType } from 'api/transactions/createTransaction'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import AutoAwesomeCharacter from 'assets/icons/autoAwesomeCharacter'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import useTemplatesMap from 'hooks/useTemplatesMap/useTemplatesMap'
import Lottie from 'lottie-react'
import { useRouter } from 'next/router'
import { TEXTAREA_FIELD } from 'organisms/fieldRenderers'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { ConclusionTable } from './components/conclusionTable/ConclusionTable'

const Conclusion = ({
  transactionResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionFetching,
}: ConclusionProps): JSX.Element => {
  const Router = useRouter()

  const isEdit = Router.query.isEdit === 'true'

  const { conclusion, id, transaction } = economicAnalysisTransactionResponse[0]
  const { economicConclusionTemplateOptions } = useTemplatesMap({
    transaction: transactionResponse?.id || transaction || 0,
  })
  const { control, getValues, setValue, watch, formState } = useForm<FIELDS_VALUES>({
    defaultValues: {
      conclusion: conclusion || '',
    },
  })
  const { setIsDirty } = useRoutingHandlerState()

  useEffect(() => {
    setIsDirty(!!Object.keys(formState.dirtyFields).length)

    return () => {
      setIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields, Object.keys(formState.dirtyFields).length, formState.isDirty, setIsDirty])
  const selectedTemplate = watch('template')
  const formConclusion = watch('conclusion')
  const [selectedAIDescription, setSelectedAIDescription] = useState<number>()
  const [aIDescriptions, setAIDescriptions] = useState<(string | null)[]>()
  const [aiButtonDisabled, setAiButtonDisabled] = useState(false)
  const [aiButtonClicked, setAiButtonClicked] = useState(false)

  const showConclusions =
    !!economicAnalysisTransactionResponse?.[0]?.required_sections.includes('comparability_factors')

  const { refetchChecklist } = useReportWizardContext()

  const getDefaultConculsionMutation = useMutation(getConclusionByType)
  const getAiConculsionMutation = useMutation(generateAiConclusionByType)

  const updateEconomicAnalysisConclusionMutation = useMutation(updateEconomicAnalysisTransaction, {
    onSuccess: () => {
      setIsDirty(false)
      economicAnalysisTransactionRefetch()
      toast.success('Conclusion updated successfully', {
        autoClose: 1000,
      })
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const handleSave = useCallback(() => {
    if (id) {
      updateEconomicAnalysisConclusionMutation.mutate({
        conclusion: getValues('conclusion') || '',
        id: id,
      })
    } else {
      toast.warn('No transaction data found', {
        autoClose: 3000,
      })
    }
  }, [id, updateEconomicAnalysisConclusionMutation, getValues])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  useEffect(() => {
    if (!selectedTemplate?.value && economicConclusionTemplateOptions.length) {
      setValue('template', economicConclusionTemplateOptions[0])
    }
  }, [economicConclusionTemplateOptions, selectedTemplate?.value, setValue])

  useEffect(() => {
    setAIDescriptions(undefined)
    setSelectedAIDescription(undefined)
  }, [selectedTemplate?.value])

  const generateAutomatedLanguage = () => {
    if (!selectedTemplate) {
      toast.warn('Select Template First')
      return
    }
    if (aIDescriptions && Object.keys(aIDescriptions).length) {
      if (aIDescriptions[aIDescriptions.length - 1]) {
        const newSelection = ((selectedAIDescription || 0) + 1) % 3
        setSelectedAIDescription(newSelection)
        setValue('conclusion', aIDescriptions[newSelection] || '')
      } else {
        setAiButtonDisabled(true)
        getAiConculsionMutation.mutate(
          {
            transactionId: transactionResponse?.id || 0,
            conclusionType: 'economic_analysis',
            template_name: String(selectedTemplate.value),
          },
          {
            onSuccess: data => {
              const aiRecommendations = [aIDescriptions[0], ...data.recommendations]
              setAIDescriptions(aiRecommendations)
              setAiButtonClicked(true)
              setSelectedAIDescription(1)
              setValue('conclusion', aiRecommendations[1] || '')
              setAiButtonDisabled(false)
            },
            onError: (error: unknown) => {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    } else {
      // load integral default language first
      setAiButtonDisabled(true)
      getDefaultConculsionMutation.mutate(
        {
          transactionId: transactionResponse?.id || 0,
          conclusionType: 'economic_analysis',
          template_name: String(selectedTemplate.value),
        },
        {
          onSuccess: data => {
            if (data.conclusion) {
              setAIDescriptions([data.conclusion, null, null])
              setAiButtonClicked(true)
              setSelectedAIDescription(0)
              setValue('conclusion', data.conclusion)
              setAiButtonDisabled(false)
            }
          },
          onError: (error: unknown) => {
            getToastErrorMessage(error as AxiosError)
            setAiButtonDisabled(false)
          },
        }
      )
    }
  }

  const getResponsesUsed = useCallback(() => {
    if (typeof selectedAIDescription == 'number' && aIDescriptions && aIDescriptions.length) {
      return ` (${(selectedAIDescription % 3) + 1}/${aIDescriptions.length})`
    }
    return ''
  }, [selectedAIDescription, aIDescriptions])
  const disableSave =
    updateEconomicAnalysisConclusionMutation.isLoading ||
    economicAnalysisTransactionFetching ||
    !formConclusion ||
    formConclusion === conclusion
  return (
    <div className="w-full">
      <div className="w-full overflow-y-auto" style={{ maxHeight: 'calc(100vh - 22.5rem)' }}>
        <BorderlessBox>
          {showConclusions && (
            <ConclusionTable economicAnalysisTransactionResponse={economicAnalysisTransactionResponse} />
          )}
          <div className="w-full">
            <div className="flex items-center w-full">
              <Typography type="semibold" className="text-gray-700" variant={Variant.Callout}>
                Conclusion Description
              </Typography>
              {!showConclusions && false && (
                <div className="flex gap-4 ms-auto items-center justify-end">
                  <SelectDropDown
                    placeholder="Select Template"
                    id="template"
                    options={economicConclusionTemplateOptions}
                    control={control}
                    className="w-min-[96rem] shrink-0"
                  />
                  <Tooltip
                    title={
                      selectedTemplate
                        ? 'Generate automated economic analysis conclusion'
                        : 'Select Template first to use Erica AI'
                    }
                    placement="top">
                    <div>
                      <Button
                        disabled={!selectedTemplate}
                        onClick={generateAutomatedLanguage}
                        className="whitespace-nowrap h-[38px] flex gap-1 !flex-row items-center !border-blue-100 !bg-gradient-to-r from-blue-50 to-[#dde9f7]"
                        variant={ButtonVariant.Secondary}>
                        <AutoAwesomeCharacter className="w-[18px] h-[18px]" /> ERICA AI {getResponsesUsed()}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            <TEXTAREA_FIELD
              control={control}
              description={
                aiButtonDisabled ? (
                  <div className="absolute inset-0 flex justify-center items-center overflow-hidden opacity-90 bg-white">
                    <Lottie animationData={LoadingStars} className="w-full" />
                  </div>
                ) : null
              }
              rows={6}
              containerClassName="mt-2 relative"
              id="conclusion"
            />
          </div>
          {aiButtonClicked && <AITextDisclaimer />}
        </BorderlessBox>
      </div>
      <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
        <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
          <Button
            isDefaultSize={false}
            onClick={handleCancel}
            iconCLass="w-5 h-5 transform rotate-180"
            iconPathClassName="stroke-blue900"
            icon={IconsType.arrowRight}
            className="!gap-1"
            variant={ButtonVariant.Secondary}>
            Back
          </Button>
          {isEdit && (
            <Button icon={IconsType.save} onClick={handleSave} disabled={disableSave}>
              Save
            </Button>
          )}
        </div>
      </Portal>
    </div>
  )
}

interface ConclusionProps {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionFetching: boolean
  economicAnalysisTransactionResponse: economic_analysis[]
  economicAnalysisTransactionRefetch: () => void
}

export type FIELDS_VALUES = {
  conclusion: string
  template?: SelectOptions
}

export default Conclusion
