import { ColumnDef } from '@tanstack/react-table'
import {
  ArmsLengthOptionType,
  ComparableResultConclusion,
  CreateComparableResultsPayload,
} from 'api/transactions/comparableResults/types'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { ConclusionTableItem } from '../EditCreateArmsLengthRangeConclusion'

export const getConclusionTableColumns: () => ColumnDef<ConclusionTableItem>[] = () => {
  return [
    {
      accessorKey: 'label',
      cell: EditableTableLabelCell,
      size: 100,
      meta: {
        isReadOnly: true,
      },
    },
    {
      accessorKey: 'value',
      size: 50,
    },
  ]
}

export const getConclusionTableData: ({
  type,
  editable,
  comparableResultConclusion,
}: {
  type?: ArmsLengthOptionType
  editable?: boolean
  comparableResultConclusion?: ComparableResultConclusion
}) => ConclusionTableItem[] = ({ type, editable = true, comparableResultConclusion }) => {
  if (!type?.required_fields.length) return []

  return type.required_fields.map(field => {
    return {
      isEditable: true,
      isEditAllowed: !!editable,
      key: field.value,
      label: field.label,
      value: String(comparableResultConclusion?.[field.value] || ''),
    }
  })
}

export const getConclusionsPayload: (data: ConclusionTableItem[]) => CreateComparableResultsPayload['data'] = data => {
  const payload: CreateComparableResultsPayload['data'] = {}
  data.forEach(item => {
    if ((item.value != '0' && isNaN(Number(item.value))) || item.value == '') {
      throw new Error(`Please enter a valid number for ${item.label}`)
    }
    payload[item.key] = item.value || ''
  })
  return payload
}
