import { TabData } from 'components/tabs/Tabs'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'

import LocalEntityTab from './Components/LocalEntityTab/LocalEntityTab'
import OtherAppendicesTab from './Components/OtherAppendicesTab/OtherAppendicesTabNew'
import RelavantTaxDocsTab from './Components/RelavantTaxDocsTab/RelavantTaxDocsTab'
import TransactionAppendixTab from './Components/TransactionAppendixTab/TransactionAppendixTab'

export const getAppendixChecklistTabs = (data: ReturnType<typeof getAppendicesConfig>): TabData[] => {
  return [
    {
      label: 'Relevant Tax Rulings',
      hide: data.relevant_tax_rulings.hidden,
      content: <RelavantTaxDocsTab data={data.relevant_tax_rulings.data} />,
    },
    {
      label: 'Legal Entity',
      hide: data.legal_entity.hidden,
      content: <LocalEntityTab data={data.legal_entity.data} />,
    },
    {
      label: 'Transactions',
      hide: data.transactions.hidden,
      content: <TransactionAppendixTab data={data.transactions.data} />,
    },
    {
      label: 'Other Appendices',
      content: (
        <>
          <OtherAppendicesTab data={data.other_appendices.data} />
        </>
      ),
    },
  ]
}
