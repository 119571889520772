import { useQuery } from '@tanstack/react-query'
import cx from 'classnames'
import { QUERIES } from 'constants/query'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { RootState } from 'store'

import { disabledForRoutes } from './constants'
import { getYearOptions } from './helpers/yearSelector.helper'

function YearSelector({ containerClassname }: { containerClassname?: string }) {
  const { query, pathname, push } = useRouter()
  const { year } = useSelector((state: RootState) => state.app)

  const orgId =
    query?.orgId && !!Number(query?.orgId)
      ? (query?.orgId as string)
      : typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem('orgId') || localStorage.getItem('orgId')
      : null

  const { data } = useQuery([QUERIES.GET_HOME_PAGE_DATA.key, orgId], {
    queryFn: () => QUERIES.GET_HOME_PAGE_DATA.function({ orgId }),
    enabled: !!orgId,
  })

  const isDisabled = useMemo(() => disabledForRoutes.includes(pathname), [pathname])

  const onChange = (year: number) => {
    push({
      pathname: pathname,
      query: {
        ...query,
        year: year,
      },
    })
  }

  const options = useMemo(() => (data ? getYearOptions(data) : []), [data])

  if (!year) return null

  return (
    <ReactSelect
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          boxShadow: 'none',
        }),
      }}
      onChange={selected => selected && onChange(selected?.value)}
      value={{ value: year, label: `Year ${year}` }}
      options={options}
      isDisabled={isDisabled || !orgId || !options.length}
      isSearchable={false}
      classNames={{
        singleValue: () => {
          return 'text-orange-600 text-[0.8rem] font-semibold  overflow-visible  '
        },
        control(props) {
          return (
            'min-h-0 items-center rounded-xl  bg-orange-50 border-solid border-orange-100 hover:border-orange-400 py-[0.1875rem]' +
            (props.isDisabled ? ' pointer-events-none' : ' cursor-pointer')
          )
        },
        container(props) {
          return cx(
            'h-[23.5px] shrink-0 z-[11]',
            containerClassname,
            props.isDisabled ? ' cursor-not-allowed pointer-events-auto	' : 'cursor-pointer'
          )
        },
        dropdownIndicator() {
          return 'p-0 mr-2 !text-orange-600'
        },
        indicatorSeparator() {
          return 'hidden'
        },
        indicatorsContainer(props) {
          return props.isDisabled ? 'hidden ' : 'scale-75'
        },
        valueContainer(props) {
          return props.isDisabled ? 'px-2' : 'ps-2 pe-0'
        },
        menuList() {
          return 'text-neutral-600 text-[0.8rem] font-semibold p-0'
        },
        option(props) {
          return `px-4 py-1.5  ${
            props.isSelected ? 'bg-orange-600' : `hover:!bg-orange-100 ${props.isFocused ? '!bg-orange-50' : ''} `
          }`
        },
      }}
    />
  )
}

export default YearSelector
