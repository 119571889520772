import { useMutation } from '@tanstack/react-query'
import { otherAppendixType } from 'api/documents/types'
import { uploadBulkAppendices } from 'api/legalEntity/appendixFiles'
import { UpdateAppendixFile } from 'api/legalEntity/types/appendices'
import { IconsType } from 'assets/types'
import classNames from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import IconPlaceHolder from 'components/iconPlaceholder'
import Loading from 'components/loading'
import Typography, { Variant } from 'components/typography'
import useFileUploader from 'hooks/useFileUploader'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import OtherAppendicesTableRow from './Components/OtherAppendicesTableRow'

type Props = {
  data: ReturnType<typeof getAppendicesConfig>['other_appendices']['data']
}

const OtherAppendicesTab = ({ data }: Props) => {
  const { refetchChecklist, legalEntity } = useReportWizardContext()
  const methods = useForm<OtherAppendicesTabFieldValues>({
    defaultValues: {
      files: [],
    },
  })

  const { reset, getValues, watch, setFocus } = methods

  const files = watch('files')

  useEffect(() => {
    if (data) {
      reset({
        files: data.map(file => ({
          oldData: file,
          newData: {
            file: undefined,
            name: file.file_name || '',
          },
        })),
      })
    } else {
      reset({
        files: [],
      })
    }
  }, [data, reset])

  const handleNewUpload = useCallback(
    (file: File) => {
      if (file) {
        reset({
          files: [
            ...getValues().files,
            {
              newData: {
                file,
                name: file.name,
              },
            },
          ],
        })
      }
    },
    [getValues, reset]
  )

  const reorderDocuments = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const files = getValues().files
      const draggedFile = files[draggedRowIndex]
      const reorderedFiles = files.filter((_, i) => i !== draggedRowIndex)
      reorderedFiles.splice(targetRowIndex, 0, draggedFile)
      reset({
        files: reorderedFiles,
      })
    },
    [getValues, reset]
  )

  const handleDeleteFile = useCallback(
    (index: number) => {
      reset({
        files: getValues().files.filter((_, i) => i !== index),
      })
    },
    [getValues, reset]
  )

  const { handleClick, ref } = useFileUploader({
    onFileSelected: handleNewUpload,
    accept: '.pdf',
    isMultiple: true,
  })

  const uploadBulkAppendicesMutation = useMutation(uploadBulkAppendices)

  const validateFiles = useCallback(() => {
    return files.findIndex(file => !file.newData?.name)
  }, [files])

  const handleSubmit = useCallback(() => {
    if (uploadBulkAppendicesMutation.isLoading || !legalEntity) return
    const errorIndex = validateFiles()
    if (errorIndex !== -1) {
      toast.warning('Please enter file name for all files')
      setFocus(`files.${errorIndex}.newData.name`)
      return
    }
    const payload = [...files]
      .map((file, index) => {
        return {
          file: file,
          order: index,
        }
      })
      .sort((a, b) => (a.file.newData?.file ? (b.file.newData?.file ? 0 : -1) : 1))
    const filesData: UpdateAppendixFile[] = payload.map((file): UpdateAppendixFile => {
      return {
        id: file.file.oldData?.id,
        legal_entity: legalEntity,
        order: file.order,
        name: file.file.newData?.name || '',
      }
    })
    const newFiles = payload.filter(file => file.file.newData?.file).map(file => file.file.newData?.file) as File[]

    uploadBulkAppendicesMutation.mutate(
      { files: newFiles, filesData },
      {
        onSuccess: () => {
          refetchChecklist()
          toast.success('Appendices uploaded/updated successfully')
        },
      }
    )
  }, [files, legalEntity, refetchChecklist, setFocus, uploadBulkAppendicesMutation, validateFiles])

  const isNewData = useMemo(() => {
    if (data?.length !== files.length) {
      return true
    }
    for (let i = 0; i < data.length; i++) {
      if (!files[i].oldData?.id) {
        return true
      }
      if (
        data[i].id &&
        files[i].oldData?.id &&
        (data[i].id !== files[i].oldData?.id || data[i].file_name !== files[i].newData?.name)
      ) {
        return true
      }
      if (data[i].id && data[i].id == files[i].oldData?.id && data[i].file_name !== files[i].newData?.name) {
        return true
      }
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, files, JSON.stringify(files)])

  return (
    <BorderlessBox className="!p-3 gap-4 relative" bgClass="bg-[rgb(16,15,46)]">
      <Typography variant={Variant.Callout} type="semibold" className="text-white">
        Supporting Appendices
      </Typography>
      <div className="w-full space-y-2">
        <Typography variant={Variant.Callout} type="semibold" className="text-gray-300">
          Upload File
        </Typography>
        <div
          onClick={handleClick}
          ref={ref}
          className=" rounded-lg  flex  w-full bg-[#161532] gap-3 items-center !flex-row p-3">
          <IconPlaceHolder
            icon={IconsType.upload}
            iconPathClassName="stroke-indigo-900"
            className="!bg-indigo-100 h-9 w-9"
          />
          <div className="flex flex-col gap-2  items-start cursor-pointer">
            <Button
              className="!p-[0rem] !h-[unset] !text-container-text !text-indigo-400 font-semibold"
              variant={ButtonVariant.Tertiary}>
              Click to Upload
            </Button>
            <Typography className="pt-0 text-gray-400" variant={Variant.ContainerText}>
              {`${'.pdf'} Max 25MB`}
            </Typography>
          </div>
        </div>
      </div>
      {!!files.length && (
        <>
          <div className="w-full space-y-1">
            <Typography variant={Variant.Callout} type="semibold" className="text-white">
              Uploaded Appendices
            </Typography>
            <FormProvider {...methods}>
              {files.map((file, index) => {
                return (
                  <OtherAppendicesTableRow
                    key={`${index}-${file.oldData?.id || file.newData?.file?.name}`}
                    opt={file}
                    index={index}
                    handleDeleteFile={handleDeleteFile}
                    reorderRow={reorderDocuments}></OtherAppendicesTableRow>
                )
              })}
            </FormProvider>
          </div>
        </>
      )}
      {!!(files.length || data?.length) && (
        <Button
          disabled={!isNewData || uploadBulkAppendicesMutation.isLoading}
          onClick={handleSubmit}
          isDefaultSize={false}
          icon={IconsType.save}
          iconPathClassName={classNames({
            'fill-indigo-900': !uploadBulkAppendicesMutation.isLoading,
            'fill-gray-400': uploadBulkAppendicesMutation.isLoading,
          })}
          size={ButtonSize.Small}
          className="flex !flex-row-reverse items-center ms-auto !gap-1 !py-0  bg-indigo-400 hover:!bg-indigo-500 !text-indigo-950">
          Save
        </Button>
      )}
      {uploadBulkAppendicesMutation.isLoading && (
        <Loading className="absolute inset-0 bg-[#ffffff01] backdrop-blur-sm !mt-0	 flex items-center justify-center" />
      )}
    </BorderlessBox>
  )
}

export type OtherAppendicesTabFieldValues = {
  files: {
    oldData?: otherAppendixType
    newData?: {
      file?: File
      name?: string
    }
  }[]
}

export default OtherAppendicesTab
