import { WarningAmber } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { CellContext } from '@tanstack/react-table'
import GreenTick from 'assets/icons/greenTick'
import { IconsType } from 'assets/types'
import classNames from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'

import { UncontrolledTransactionDataTableData } from '../UncontrolledTransactionsData'

export const getComparabilityTableColumns: (params: {
  entity1Label: string
  entity2Label: string
  showActionsColumn?: boolean
  onClickEdit?: (row: UncontrolledTransactionDataTableData) => void
  deleteFileHandler?: (row: UncontrolledTransactionDataTableData) => void
}) => ColumnDef<UncontrolledTransactionDataTableData>[] = ({
  entity1Label,
  entity2Label,
  onClickEdit,
  showActionsColumn = true,
  deleteFileHandler,
}) => {
  return [
    {
      header: 'Transaction Name',
      accessorKey: 'transaction_name',
      cell: cellContext => {
        const name = cellContext.row.original.transaction_name
        return (
          <Tooltip title={name.length > 25 ? name : ''}>
            <div>
              <Typography variant={Variant.Callout} type="semibold">
                {name.slice(0, 25)}
                {name.length > 25 ? '...' : ''}
              </Typography>
            </div>
          </Tooltip>
        )
      },
      size: 15,
    },
    {
      header: 'Transaction Subname',
      accessorKey: 'transaction_subname',
      cell: cellContext => {
        const name = cellContext.row.original.transaction_subname || ''
        return (
          <Tooltip title={name.length > 25 ? name : ''}>
            <div>
              <Typography variant={Variant.Callout} type="semibold" className={classNames(!name && 'text-neutral-400')}>
                {name.slice(0, 25)}
                {name.length > 25 ? '...' : ''}
                {!!!name && 'NA'}
              </Typography>
            </div>
          </Tooltip>
        )
      },
      size: 15,
    },
    {
      header: entity1Label,
      accessorKey: 'entity1',
      cell: cellContext => {
        const entity = cellContext.row.original.entity1
        return (
          <Tooltip title={entity.length > 25 ? entity : ''}>
            <div>
              <Typography variant={Variant.Callout} type="semibold">
                {entity.slice(0, 25)}
                {entity.length > 25 ? '...' : ''}
              </Typography>
            </div>
          </Tooltip>
        )
      },
      size: 15,
    },
    {
      header: entity2Label,
      accessorKey: 'entity2',
      cell: cellContext => {
        const entity = cellContext.row.original.entity2
        return (
          <Tooltip title={entity.length > 25 ? entity : ''}>
            <div>
              <Typography variant={Variant.Callout} type="semibold">
                {entity.slice(0, 25)}
                {entity.length > 25 ? '...' : ''}
              </Typography>
            </div>
          </Tooltip>
        )
      },
      size: 15,
    },
    {
      header: 'Comparability Factors',
      cell: cellContext => {
        const complete = cellContext.row.original.is_description_added_for_all_factors !== false
        return complete ? (
          <Typography variant={Variant.Callout} className="gap-1 flex items-center" type="semibold">
            <GreenTick className="w-[14px] h-[14px]" />
            Added
          </Typography>
        ) : (
          <Typography variant={Variant.Callout} className="gap-1 flex items-center" type="semibold">
            <WarningAmber className="w-[14px] text-orange-400 h-[14px]" />
            Pending
          </Typography>
        )
      },
      size: 10,
    },
    ...(showActionsColumn
      ? [
          {
            header: () => <div className="flex justify-end">Actions</div>,
            id: 'actions',
            cell: (context: CellContext<UncontrolledTransactionDataTableData, unknown>) => {
              return (
                <div className="flex items-center gap-3 justify-end px-2">
                  <Button
                    onClick={() => onClickEdit && onClickEdit(context.row.original)}
                    variant={ButtonVariant.Tertiary}
                    className="!h-auto text-blue-800">
                    Edit
                  </Button>
                  <Button
                    isDefaultSize={false}
                    onClick={() => deleteFileHandler && deleteFileHandler(context.row.original)}
                    icon={IconsType.delete}
                    iconCLass="!w-[1.125rem] !h-[1.125rem]"
                    iconPathClassName="stroke-orange-600 "
                    variant={ButtonVariant.Tertiary}></Button>
                </div>
              )
            },
            size: 5,
          },
        ]
      : []),
  ]
}
