import { TransactionPliMethod, TransactionPliMethodField } from 'api/transactions/getTransactionPliMethod/types'

import { filterDataByYear } from './helpers/financialData.helpers'
import { FinancialData } from './types/financialData.types'

export const financialStructurePayload = ({ usGaapData, year: latestYear, pliMethod }: FinancialStructureProps) => {
  if (!pliMethod) return []
  const getLast3Years = (y: number) => {
    return [y - 2, y - 1, y]
  }

  const addFinancialStructurePayload = getLast3Years(latestYear).map(year => {
    const yearFinancialData = filterDataByYear(usGaapData, year)

    const data: {
      [key: string]: string | null | number
    } = {
      year: year.toString(),
    }
    ;([...pliMethod.required_fields, ...pliMethod.optional_fields] as TransactionPliMethodField[])
      .filter(field => field.type != 'Balance Sheet')
      .forEach(field => {
        if (!field.is_calculated) {
          data[field.name] = yearFinancialData[field.name]
        }
      })

    return data
  })

  return addFinancialStructurePayload.filter(val => !!val) as {
    [k: string]: string | null
  }[]
}

export const balanceSheetPayload = ({
  balanceSheetData,
  year: latestYear,
  pliMethod,
  tested_party,
}: BalanceSheetProps) => {
  if (!pliMethod) return []
  const getLast4Years = (y: number) => {
    return [y - 3, y - 2, y - 1, y]
  }

  const addFinancialStructurePayload = getLast4Years(latestYear).map(year => {
    const yearFinancialData = filterDataByYear(balanceSheetData, year)

    const data: {
      [key: string]: string | null | number
    } & {
      tested_party: number
    } = {
      year: year.toString(),
      tested_party,
    }

    ;([...pliMethod.required_fields, ...pliMethod.optional_fields] as TransactionPliMethodField[])
      .filter(field => field.type == 'Balance Sheet')
      .forEach(field => {
        if (!field.is_calculated) {
          data[field.name] = yearFinancialData[field.name]
        }
      })
    return data
  })

  return addFinancialStructurePayload.filter(val => !!val) as {
    [k: string]: string | null
  }[]
}

interface FinancialStructureProps {
  usGaapData: FinancialData[]
  year: number
  pliMethod: TransactionPliMethod | undefined
}

interface BalanceSheetProps {
  balanceSheetData: FinancialData[]
  year: number
  pliMethod: TransactionPliMethod | undefined
  tested_party: number
}
