import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import { Field } from 'organisms/formWithSubmission/types/form.types'
import GroupedFunctions from 'views/transactions/createTransaction/components/groupedFunctions'

import TestedParty from '../components/testedParty'
import { FIELDS_IDS } from './basicInfo.constants'

export const FIELDS: (Field | Field[])[] = [
  {
    id: FIELDS_IDS.TRANSACTION_VIEW,
    fieldToRenderer: GroupedFunctions,
  },
  [
    {
      id: FIELDS_IDS.TP_METHOD,
      fieldToRenderer: SELECT_DROPDOWN,
    },
    {
      id: FIELDS_IDS.TESTED_PARTY,
      fieldToRenderer: TestedParty,
    },
  ],
  [
    {
      id: FIELDS_IDS.PLI_METHODS,
      fieldToRenderer: SELECT_DROPDOWN,
    },
    {
      id: FIELDS_IDS.TESTING_METHODOLOGY,
      fieldToRenderer: SELECT_DROPDOWN,
    },
  ],
]
