import { ColumnDef } from '@tanstack/react-table'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'

function NoEntryFoundContainer<T>({
  columns,
  classes,
}: {
  columns: ColumnDef<T, unknown>[]
  classes: Partial<{ body: string }>
}) {
  return (
    <tbody className={classes.body || ''}>
      <tr className="text-center">
        <td colSpan={columns.length}>
          <Typography variant={Variant.Heading2} className="p-10 text-neutral600">
            No entries found
          </Typography>
        </td>
      </tr>
    </tbody>
  )
}

export default NoEntryFoundContainer
