import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { capitalizeFirstLetter } from 'utils/utils'

const IconCell = ({ action, icon, className, textClassName, iconClassName }: IconCellProps): JSX.Element => {
  return (
    <div className={cx('flex items-center justify-center rounded-[2.5rem] w-fit px-4 py-1', className)}>
      <div className="flex items-center gap-2">
        {icon && getIcons(icon, { className: 'w-[1.5rem] h-[1.5rem]', pathClassName: cx(iconClassName) })}
        <Typography variant={Variant.Callout} type="semibold" className={cx(textClassName)}>
          {capitalizeFirstLetter(action)}
        </Typography>
      </div>
    </div>
  )
}

interface IconCellProps {
  action: string
  icon?: IconsType
  className?: string
  textClassName: string
  iconClassName?: string
}

export default IconCell
