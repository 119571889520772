import { ColumnDef } from '@tanstack/react-table'
import { TabData } from 'components/tabs/Tabs'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import { SetStateAction } from 'react'

import { FinancialData } from '../../../types/financialData.types'
import BalanceSheet from '../components/BalanceSheet'

export const getTabData = ({
  usGaapData,
  setUsGaap,
  financialColumns,
  setUSGaapIsDirty,
  showBalanceSheet,
  isEdit,
  setIsEdit,
  balanceSheetData,
  setBalanceSheetData,
  balanceSheetColumns,
  setBalanceSheetDirty,
  balanceSheetAvgColumns,
  balanceSheetAvgData,
  readonly,
}: {
  usGaapData: FinancialData[]
  setUsGaap: React.Dispatch<React.SetStateAction<FinancialData[]>>
  financialColumns: ColumnDef<FinancialData>[]
  setUSGaapIsDirty: (value: SetStateAction<boolean>) => void
  showBalanceSheet?: boolean
  isEdit: boolean
  setIsEdit: (value: SetStateAction<boolean>) => void
  balanceSheetData: FinancialData[]
  setBalanceSheetData: React.Dispatch<React.SetStateAction<FinancialData[]>>
  balanceSheetColumns: ColumnDef<FinancialData>[]
  setBalanceSheetDirty: (value: SetStateAction<boolean>) => void
  balanceSheetAvgData: FinancialData[]
  balanceSheetAvgColumns: ColumnDef<FinancialData>[]
  readonly: boolean
}): TabData[] => {
  return [
    {
      label: 'Tested Party Financials ',
      content: (
        <NewEditableTable
          data={usGaapData}
          setData={setUsGaap}
          className="mt-4"
          isCommaFormatted
          columns={financialColumns}
          setTableAsDirty={setUSGaapIsDirty}
        />
      ),
    },
    {
      label: 'Balance Sheet',
      content: (
        <BalanceSheet
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          balanceSheetData={balanceSheetData}
          setBalanceSheetData={setBalanceSheetData}
          balanceSheetColumns={balanceSheetColumns}
          setBalanceSheetDirty={setBalanceSheetDirty}
          balanceSheetAvgColumns={balanceSheetAvgColumns}
          balanceSheetAvgData={balanceSheetAvgData}
          readonly={readonly}
        />
      ),
      hide: !showBalanceSheet,
    },
  ]
}
