import { IconsType } from 'assets/types'
import cx from 'classnames'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import Button, { ButtonVariant } from 'components/button'
import Drawer from 'components/drawer/Drawer'
import ItemRemovalWarning from 'components/itemRemovalWarning'
import TinyMceEditor from 'components/tinyMceEditor'
import { DEFAULT_LEFT_GROUP_BUTTONS, defaultFontFamilyScripts, integralCss } from 'components/tinyMceEditor/constants'
import { EditorRef } from 'components/tinyMceEditor/types'
import { BaseUrl } from 'constants/apis'
import { useAuth } from 'hooks/useAuth'
import useConfirmationModal from 'hooks/useConfirmationModal'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form'
import { toast } from 'react-toastify'
import { cleanupHTML } from 'utils/stringUtils'

type HTMLViewEditProps<T> = {
  value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: () => Promise<any> | void
  className?: string
  label?: string | React.ReactNode
  isLoading?: boolean
  saveButtonText?: string
  localFileCss?: string
  id: Path<T & FieldValues>
  control: Control<T & FieldValues>
  handleDelete?: (item: Path<T & FieldValues>) => void
  leftGroupButtons?: string[]
  editableLabel?: React.ReactNode
  editableLabelIsDirty?: boolean
}

function HTMLViewEditWithDrawer<T>({
  value,
  className = 'ml-auto',
  editableLabel,
  editableLabelIsDirty,
  label,
  isLoading,
  localFileCss,
  id,
  control,
  handleDelete,
  leftGroupButtons = DEFAULT_LEFT_GROUP_BUTTONS,
  saveButtonText = 'Save',
  onSave,
}: HTMLViewEditProps<T>) {
  const { user } = useAuth()
  const editorRef = useRef<EditorRef>(null)
  const { field } = useController({ name: id, control })
  const css = useMemo(() => {
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
  }, [localFileCss])

  const [showModal, setShowModal] = useState(false)
  const [enableEditModal, setEnableEditModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [htmlIsDirty, setHtmlIsDirty] = useState(false)
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleConfirmationToggle = useCallback(() => {
    setShowConfirmationModal(o => !o)
  }, [])

  const handleModalClose = useCallback(async () => {
    if (
      htmlIsDirty &&
      !(await getConfirmation('You may have unsaved changes, are you sure you want to exit?', 'Attention!'))
    ) {
      return
    }
    setHtmlIsDirty(false)
    setEnableEditModal(false)
    setShowModal(false)
  }, [getConfirmation, htmlIsDirty])

  const handleEditButton = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setShowModal(true)
    setEnableEditModal(true)
  }, [])

  const handleViewButton = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setShowModal(true)
    setEnableEditModal(false)
  }, [])

  const handleSaveOrEdit = useCallback(async () => {
    if (!enableEditModal) {
      setEnableEditModal(true)
    } else {
      if (editorRef.current?.editor?.getContent() || editableLabelIsDirty) {
        try {
          if (editorRef.current?.editor?.getContent()) {
            await editorRef.current.editor.uploadImages()
            const editorHtml = editorRef.current.editor?.getContent()
            const processedHTML = cleanupHTML(editorHtml) as PathValue<T, Path<T>>
            field.onChange(processedHTML)
          }
          if (onSave) {
            const result = await onSave()
            if (result instanceof Error) {
              throw result
            }
          }

          setHtmlIsDirty(false)
          setEnableEditModal(false)
          setShowModal(false)
        } catch (error) {
          toast.error(String(error))
        }
      } else {
        handleConfirmationToggle()
      }
    }
  }, [editableLabelIsDirty, enableEditModal, field, handleConfirmationToggle, onSave])

  const handleDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      handleConfirmationToggle()
    },
    [handleConfirmationToggle]
  )

  const html = field.value || value

  return (
    <div className={cx('flex gap-4', className)}>
      {html && (
        <Button variant={ButtonVariant.Tertiary} onClick={handleViewButton} className="!p-0 h-auto">
          Preview
        </Button>
      )}
      <Button
        icon={IconsType.edit}
        variant={ButtonVariant.Tertiary}
        onClick={handleEditButton}
        className="!p-0 h-auto"></Button>
      {html && typeof handleDelete == 'function' && (
        <Button
          variant={ButtonVariant.Tertiary}
          className="!p-0 h-auto"
          isDefaultSize={false}
          onClick={handleDeleteClick}
          iconPathClassName="stroke-red600"
          icon={IconsType.delete}></Button>
      )}
      <ItemRemovalWarning
        showModal={showConfirmationModal}
        setShowModal={handleConfirmationToggle}
        confirmationMessage={`Are you sure you want to delete ${label}?`}
        doConfirmationAction={() => {
          handleConfirmationToggle()
          handleDelete && handleDelete(id)
        }}
      />
      <Drawer
        containerClassName="w-[97vw]"
        className="w-full h-full p-6 overflow-y-auto"
        title={enableEditModal ? editableLabel || label : label}
        isOpen={showModal}
        onClose={handleModalClose}>
        <div className="flex flex-col items-start w-full h-full">
          <div className="doc-editor doc-editor-modal overflow-auto w-full mb-2">
            {!enableEditModal || isLoading ? (
              <BorderBoxWrapper className="bg-gray-100 h-[calc(100vh-18.25rem)] py-[1rem]">
                <iframe
                  title={`Preview`}
                  srcDoc={`
                      <!DOCTYPE html><html><head>
                      ${defaultFontFamilyScripts}
                      <style>
                    ${css}
                    </style></head><body>${html || ''}</body></html>`}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  className="w-full h-full border-none"
                />
              </BorderBoxWrapper>
            ) : (
              <TinyMceEditor
                author={{
                  id: user?.id,
                  username: user?.username || 'unknown',
                  fullName: user
                    ? user.first_name || user.last_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.username
                    : 'Unknown',
                  avatarUrl: user?.profile_photo || undefined,
                }}
                ref={editorRef}
                editorData={html}
                documentBaseUrl={BaseUrl}
                contentStyles={css}
                leftGroupButtons={leftGroupButtons}
                setIsDirty={setHtmlIsDirty}
              />
            )}
          </div>
          <div className="flex justify-end items-center w-full">
            <Button
              onClick={handleSaveOrEdit}
              disabled={isLoading || (!htmlIsDirty && !editableLabelIsDirty && enableEditModal)}
              icon={enableEditModal ? IconsType.save : IconsType.edit}>
              {enableEditModal ? saveButtonText : 'Edit'}
            </Button>
          </div>
        </div>
      </Drawer>
      <ConfirmationModal />
    </div>
  )
}

export default HTMLViewEditWithDrawer
