import { DoNotDisturbOnOutlined, Info } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  deleteControlledOrUncontrolledTransactions,
  updateUncontrolledTransaction,
} from 'api/transactions/comparableResults'
import { ComparableResultConclusion, TermsAndCharacteristics } from 'api/transactions/comparableResults/types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import NewAttributeTable from 'components/newAttributeTable'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE, integralCss } from 'components/tinyMceEditor/constants'
import Typography, { Variant } from 'components/typography'
import UploadDocuments from 'components/uploadDocuments'
import { currencyOptions } from 'constants/currencies'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import { CurrencyRequiredForValueTypes } from 'organisms/armsLengthRangeConclusion/constants/armsLengthRangeConclusion.constants'
import EditCreateArmsLengthRangeConclusion, {
  ConclusionTableItem,
} from 'organisms/armsLengthRangeConclusion/EditCreateArmsLengthRangeConclusion'
import {
  getConclusionTableColumns,
  getConclusionTableData,
} from 'organisms/armsLengthRangeConclusion/helpers/armsLengthRangeConclusion.helper'
import HTMLInputCardField from 'organisms/fieldRenderers/fields/accordionInputCard/components/fields/HTMLInputCardField'
import FileOrTableRadio from 'organisms/fieldRenderers/fields/FileOrTableRadio/FileOrTableRadio'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import AddComparableUncontrolledTransaction from './Components/AddComparableControlledTransaction/AddComparableUncontrolledTransaction'
import ReorderUncontrolledTransactions from './Components/reorderUncontrolledTransactions/ReorderUncontrolledTransactions'
import {
  getComparabilityTableColumns,
  // getComparableConclusionResultEntries,
} from './helpers/uncontrolledTransactionsData.helper'
import { InternalOrExternalOptions, ValueOptions } from './uncontrolledTransactionsData.constants'

type Props = {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionRefetch: () => void
  transactionByIdRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
}

const UncontrolledTransactionsData = ({
  transactionResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
}: Props) => {
  const Router = useRouter()

  const [showArmsLengthModal, setShowArmsLengthModal] = useState(false)
  const [selectedComparableResultForEdit, setSelectedComparableResultForEdit] = useState<
    ComparableResultConclusion | undefined
  >(undefined)
  const [selectedRow, setSelectedRow] = useState<UncontrolledTransactionDataTableData>()
  const [addTransactionModalOpen, setaddTransactionModalOpen] = useState(false)
  const { control, watch, setValue, handleSubmit, getValues } = useForm<FIELD_VALUES>({
    defaultValues: {
      uncontrolled: {
        type: 'File',
        value: ValueOptions[0],
      },
      conclusionTable: [],
      uncontrolled_transaction_type: {
        label: 'external',
        value: 'external',
      },
    },
  })
  const { getConfirmation, ConfirmationModal } = useConfirmationModal()
  const [reorderModal, setReorderModal] = useState(false)
  const [uncontrolled, conclusionTable, selectConclusion] = watch([
    'uncontrolled',
    'conclusionTable',
    'selectConclusion',
  ])
  const adjustments = watch('adjustments')
  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })
  const css = useMemo(() => {
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
  }, [localFileCss])
  const toggleReorderModal = useCallback(() => {
    setReorderModal(open => {
      return !open
    })
  }, [])

  useEffect(() => {
    setValue(
      'uncontrolled.document',
      economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file || undefined
    )
    setValue('adjustments', economicAnalysisTransactionResponse?.adjustments || '')
    setValue('uncontrolled_transaction_type', {
      label: economicAnalysisTransactionResponse?.uncontrolled_transaction_type || 'external',
      value: economicAnalysisTransactionResponse?.uncontrolled_transaction_type || 'external',
    })
    economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file
      ? setValue('uncontrolled.type', 'File')
      : setValue('uncontrolled.type', 'Table')
    economicAnalysisTransactionResponse?.comparison_metric &&
      setValue('uncontrolled.value', {
        label: economicAnalysisTransactionResponse?.comparison_metric,
        value: economicAnalysisTransactionResponse?.comparison_metric,
      })
    economicAnalysisTransactionResponse?.comparison_currency &&
      setValue('uncontrolled.currency', {
        label: economicAnalysisTransactionResponse?.comparison_currency,
        value: economicAnalysisTransactionResponse?.comparison_currency,
      })
  }, [
    economicAnalysisTransactionResponse?.adjustments,
    economicAnalysisTransactionResponse?.comparison_currency,
    economicAnalysisTransactionResponse?.comparison_metric,
    economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file,
    economicAnalysisTransactionResponse?.uncontrolled_transaction_type,
    setValue,
  ])

  const { data: comparableResultConclusionsList } = useQuery(
    [QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.key, Router.query.year],
    {
      queryFn: () => QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.function(Number(Router.query.year)),
      enabled: !isNaN(Number(Router.query.year)) && Router.isReady,
    }
  )

  const { data: selectedComparabilityFactors, isFetching: fetchingselectedComparabilityFactors } = useQuery(
    [QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.key, transactionResponse?.economic_analysis?.id],
    {
      enabled: !!transactionResponse?.economic_analysis?.id,
      queryFn: () =>
        QUERIES.GET_SELECTED_COMPARABILITY_FACTORS.function(transactionResponse?.economic_analysis?.id || 0),
    }
  )
  const orderedselectedComparabilityFactors = selectedComparabilityFactors
    ? selectedComparabilityFactors.sort((a, b) => a.order - b.order)
    : []

  const {
    data: termsAndConditions,
    isFetching: fetchingTermsAndConditions,
    refetch: refetchTermsAndConditions,
  } = useQuery([QUERIES.GET_TERMS_AND_CHARACTERISTICS.key, transactionResponse?.economic_analysis?.id], {
    enabled: !!transactionResponse?.economic_analysis?.id,
    queryFn: () => QUERIES.GET_TERMS_AND_CHARACTERISTICS.function(transactionResponse?.economic_analysis?.id || 0),
  })

  const tableData: UncontrolledTransactionDataTableData[] = useMemo(() => {
    if (!termsAndConditions?.length) return []

    return termsAndConditions
      .sort((a, b) => a.order - b.order)
      .filter(term => !term.is_controlled_transaction) as UncontrolledTransactionDataTableData[]
  }, [termsAndConditions])

  const { data: armsLengthRangeTypes } = useQuery([QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.key], {
    queryFn: () => QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.function(),
  })

  const comparableResultConclusionsOptions: SelectOptions[] = useMemo(() => {
    if (!comparableResultConclusionsList?.length) return []
    return comparableResultConclusionsList.map(opt => {
      return {
        value: opt.id,
        label: opt.name,
      }
    })
  }, [comparableResultConclusionsList])

  const { refetchChecklist } = useReportWizardContext()

  const deleteTransactionMutation = useMutation(deleteControlledOrUncontrolledTransactions)

  const deleteFileHandler = useCallback(
    async (row: UncontrolledTransactionDataTableData) => {
      const getDeleteConfirmation = getConfirmation(
        <>
          Are you sure you want to remove the transaction - <br />
          <span className="text-neutral800 font-semibold">{row.transaction_name || ''}</span>?
        </>
      )

      const canDeleteFile = await getDeleteConfirmation
      if (canDeleteFile) {
        deleteTransactionMutation.mutate(row.id, {
          onSuccess: () => {
            toast.success(`${row.transaction_name} transaction deleted successfully`, { autoClose: 1000 })
            refetchTermsAndConditions()
            refetchChecklist()
          },
          onError: error => {
            getToastErrorMessage(error as AxiosError)
          },
        })
      }
    },
    [getConfirmation, deleteTransactionMutation, refetchTermsAndConditions, refetchChecklist]
  )
  useEffect(() => {
    if (economicAnalysisTransactionResponse?.comparable_result_conclusion) {
      setValue('selectConclusion', {
        label: economicAnalysisTransactionResponse?.comparable_result_conclusion,
        value: economicAnalysisTransactionResponse?.comparable_result_conclusion,
      })
    }
  }, [economicAnalysisTransactionResponse?.comparable_result_conclusion, setValue])

  const selectedConclusionResultData: ComparableResultConclusion | undefined = useMemo(() => {
    if (!selectConclusion?.value || !comparableResultConclusionsList?.length) return undefined
    return comparableResultConclusionsList?.find(item => item.id == selectConclusion?.value)
  }, [comparableResultConclusionsList, selectConclusion?.value])

  const selectedRangeData = useMemo(() => {
    if (!armsLengthRangeTypes?.length || !selectedConclusionResultData?.arms_length_range) {
      return undefined
    }
    return armsLengthRangeTypes.find(opt => opt.value == selectedConclusionResultData.arms_length_range)
  }, [selectedConclusionResultData?.arms_length_range, armsLengthRangeTypes])

  useEffect(() => {
    setValue(
      'conclusionTable',
      getConclusionTableData({
        type: selectedRangeData,
        editable: false,
        comparableResultConclusion: selectedConclusionResultData,
      })
    )
  }, [selectedConclusionResultData, selectedRangeData, setValue])

  const setFile = useCallback(
    (file: File) => {
      setValue(`uncontrolled.document`, file)
    },
    [setValue]
  )

  const toggleAddModal = useCallback(() => {
    setaddTransactionModalOpen(open => {
      open && setSelectedRow(undefined)
      return !open
    })
  }, [])

  const onClickEdit = useCallback((row: UncontrolledTransactionDataTableData) => {
    setSelectedRow(row)
    setaddTransactionModalOpen(true)
  }, [])

  const uncontrolledComparabilityTableColumns = useMemo(() => {
    return getComparabilityTableColumns({
      entity1Label: transactionResponse?.functional_profile?.source_party_identification || 'Source',
      entity2Label: transactionResponse?.functional_profile?.target_party_identification || 'Target',
      onClickEdit,
      deleteFileHandler,
    })
  }, [
    onClickEdit,
    transactionResponse?.functional_profile?.source_party_identification,
    transactionResponse?.functional_profile?.target_party_identification,
    deleteFileHandler,
  ])

  const conclusionTableColumns = useMemo(() => {
    return getConclusionTableColumns()
  }, [])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const updateUncontrolledTransactionMutation = useMutation(updateUncontrolledTransaction)
  const handleRemoveConclusion = useCallback(async () => {
    if (!economicAnalysisTransactionResponse?.comparable_result_conclusion) {
      setValue('selectConclusion', null)
      return
    }
    const getRemoveConfirmation = getConfirmation(<>Are you sure you want to remove the selected conclusion ?</>)

    const canRemoveConclusion = await getRemoveConfirmation
    if (canRemoveConclusion) {
      updateUncontrolledTransactionMutation.mutate(
        {
          id: transactionResponse?.economic_analysis?.id || 0,

          comparable_result_conclusion: null,
        },
        {
          onSuccess() {
            toast.success('Conclusion removed successfully!!')
            setValue('selectConclusion', null)
            economicAnalysisTransactionRefetch()
            refetchChecklist()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    }
  }, [
    economicAnalysisTransactionResponse?.comparable_result_conclusion,
    getConfirmation,
    setValue,
    updateUncontrolledTransactionMutation,
    transactionResponse?.economic_analysis?.id,
    economicAnalysisTransactionRefetch,
    refetchChecklist,
  ])

  const onSave: SubmitHandler<FIELD_VALUES> = useCallback(
    async data => {
      if (data.uncontrolled.type == 'File') {
        const file = data.uncontrolled.document
        updateUncontrolledTransactionMutation.mutate(
          {
            id: transactionResponse?.economic_analysis?.id || 0,
            uncontrolled_transaction_terms_file: file instanceof File ? file : file === null ? null : undefined,
            uncontrolled_transaction_terms_file_name:
              file instanceof File ? file.name : file === null ? null : undefined,
            comparable_result_conclusion: data.selectConclusion?.value
              ? Number(data.selectConclusion?.value)
              : undefined,
          },
          {
            onSuccess() {
              toast.success('Successfully updated!!')
              economicAnalysisTransactionRefetch()
              refetchChecklist()
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      } else {
        updateUncontrolledTransactionMutation.mutate(
          {
            id: transactionResponse?.economic_analysis?.id || 0,
            comparison_currency: String(data.uncontrolled.currency?.value || '') || undefined,
            comparison_metric: String(data.uncontrolled.value?.value || '') || undefined,
            comparable_result_conclusion: data.selectConclusion?.value
              ? Number(data.selectConclusion?.value)
              : undefined,
            uncontrolled_transaction_type:
              data.uncontrolled_transaction_type?.value == 'internal' ? 'internal' : 'external',
          },
          {
            onSuccess() {
              toast.success('Successfully updated!!')
              economicAnalysisTransactionRefetch()
              refetchChecklist()
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    },
    [
      economicAnalysisTransactionRefetch,
      refetchChecklist,
      transactionResponse?.economic_analysis?.id,
      updateUncontrolledTransactionMutation,
    ]
  )
  const onAdjustmentsSaveAndDelete = useCallback(
    (isSave: boolean) => {
      updateUncontrolledTransactionMutation.mutate(
        { id: transactionResponse?.economic_analysis?.id || 0, adjustments: isSave ? getValues('adjustments') : '' },
        {
          onSuccess() {
            toast.success(`Adjustments ${isSave ? 'updated' : 'deleted'} Successfully`)
            economicAnalysisTransactionRefetch()
            refetchChecklist()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    },
    [
      updateUncontrolledTransactionMutation,
      transactionResponse?.economic_analysis?.id,
      getValues,
      economicAnalysisTransactionRefetch,
      refetchChecklist,
    ]
  )

  const onSaveClick = handleSubmit(onSave)

  const handleDelete = useCallback(async () => {
    const confirmation = await getConfirmation(`Are you sure you want to delete the file?`)
    confirmation && setValue('uncontrolled.document', null)
  }, [getConfirmation, setValue])

  const handleAddConclusion = useCallback(() => {
    setShowArmsLengthModal(true)
  }, [])

  const handleEditConclusion = useCallback(
    (data: ComparableResultConclusion) => () => {
      setShowArmsLengthModal(true)
      setSelectedComparableResultForEdit(data)
    },
    []
  )

  const toggleConclusionsModal = useCallback(() => {
    setShowArmsLengthModal(open => {
      open && setSelectedComparableResultForEdit(undefined)
      return !open
    })
  }, [])

  const onConclusionCreation = useCallback(
    (response: ComparableResultConclusion) => {
      setValue('selectConclusion', {
        label: response.name,
        value: response.id,
      })

      updateUncontrolledTransactionMutation.mutate({
        id: transactionResponse?.economic_analysis?.id || 0,
        comparable_result_conclusion: response.id ? Number(response.id) : undefined,
      })
    },
    [setValue, transactionResponse?.economic_analysis?.id, updateUncontrolledTransactionMutation]
  )

  useEffect(() => {
    return () => {
      economicAnalysisTransactionRefetch()
    }
  }, [economicAnalysisTransactionRefetch])

  const loading =
    updateUncontrolledTransactionMutation.isLoading ||
    fetchingselectedComparabilityFactors ||
    fetchingTermsAndConditions

  const disableSave = updateUncontrolledTransactionMutation.isLoading

  return (
    <div className="w-full relative">
      <div className="flex flex-col h-full w-full gap-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 22.5rem)' }}>
        {/* {transactionResponse && <GroupedFunctions currentTransaction={transactionResponse} />} */}
        {loading && <Loading className="absolute inset-0 flex items-center justify-center z-20 bg-white" />}
        <BorderlessBox
          bgClass="bg-yellow-50"
          label={
            <Typography variant={Variant.Callout} type="semibold" className="!text-yellow-500 mr-auto">
              The analysis of the following transactions were selected as comparable uncontrolled transactions for the
              purposes of applying [CUP/CUT/CUSP]
            </Typography>
          }>
          {null}
        </BorderlessBox>
        <BorderlessBoxAccordion
          defaultOpen
          className="gap-4 relative"
          topRightComponents={<FileOrTableRadio control={control} id="uncontrolled.type"></FileOrTableRadio>}
          label="Terms & Characteristics of the Uncontrolled Transaction">
          {loading && (
            <BorderlessBox className="absolute inset-4 z-10" variant="white">
              <Loading className="flex items-center justify-center bg-white w-full h-full" />
            </BorderlessBox>
          )}
          {uncontrolled.type == 'File' && (
            <BorderlessBox label="Upload File" variant="white" className="w-full gap-3 items-stretch">
              <UploadDocuments
                accept=".pdf"
                setFile={setFile}
                file={uncontrolled?.document instanceof File ? uncontrolled?.document : null}
                handleDelete={handleDelete}
                showDelete
                fileUrl={
                  economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file &&
                  economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file ===
                    uncontrolled?.document
                    ? uncontrolled?.document
                    : undefined
                }
                fileName={
                  economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file &&
                  economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file ===
                    uncontrolled?.document
                    ? economicAnalysisTransactionResponse?.uncontrolled_transaction_terms_appendix?.file_name ||
                      'Document'
                    : undefined
                }
              />
            </BorderlessBox>
          )}
          {uncontrolled.type == 'Table' && (
            <>
              <div className="flex items-center justify-between gap-4 w-full">
                <SelectDropDown
                  options={InternalOrExternalOptions}
                  control={control}
                  label="Transactions Type"
                  required
                  id={'uncontrolled_transaction_type'}
                  className="w-1/3"
                />
                <SelectDropDown
                  options={ValueOptions}
                  control={control}
                  label="Value"
                  required
                  id={'uncontrolled.value'}
                  className="w-1/3"
                />
                {uncontrolled.value?.value == ValueOptions[0].value ||
                uncontrolled.value?.value == ValueOptions[2].value ? (
                  <SelectDropDown
                    options={currencyOptions}
                    control={control}
                    label="Currency"
                    required
                    id={'uncontrolled.currency'}
                    className="w-1/3"
                  />
                ) : (
                  <div className="w-1/3" />
                )}
              </div>

              <BorderlessBox
                variant="white"
                label="List of Uncontrolled Transactions"
                className="w-full gap-2 bg-white items-stretch"
                topRightComponents={
                  tableData?.length > 1 && (
                    <Button onClick={toggleReorderModal} icon={IconsType.sixDotDrag} variant={ButtonVariant.Secondary}>
                      Reorder
                    </Button>
                  )
                }>
                <>
                  <NewAttributeTable
                    data={tableData}
                    headerClassName="whitespace-normal"
                    columns={uncontrolledComparabilityTableColumns}
                  />
                  <div className="w-full flex items-center justify-between">
                    <div className="text-xs text-gray-600 flex items-center">
                      <Info className="text-[#fb9800] w-4 h-4 mr-1" /> You can add more rows to this chart
                    </div>
                    <Button
                      variant={ButtonVariant.Tertiary}
                      onClick={toggleAddModal}
                      icon={IconsType.plus}
                      className="!gap-1">
                      Add
                    </Button>
                  </div>
                </>
              </BorderlessBox>
            </>
          )}
        </BorderlessBoxAccordion>
        <HTMLInputCardField
          id="adjustments"
          control={control}
          leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
          label="Adjustments"
          value={adjustments || ''}
          localFileCss={css}
          saveButtonText="Save"
          classname="border-r-0 border-t-0 border-b-0 border-l border-solid border-l-gray-300 ml-auto pl-4 py-1"
          containerClassname="!bg-gray-50 !py-5 !rounded-[0.625rem] border-solid !border !border-gray-100"
          handleSave={() => onAdjustmentsSaveAndDelete(true)}
          handleDelete={() => onAdjustmentsSaveAndDelete(false)}
        />
        <BorderlessBox className="gap-4">
          <SelectDropDown
            options={comparableResultConclusionsOptions}
            control={control}
            label="Conclusion"
            required
            menuPlacement="top"
            showSelectMenuButton
            selectMenuButtonProps={{
              buttonLabel: 'Add new',
              variant: ButtonVariant.Primary,
              buttonAction: handleAddConclusion,
            }}
            id={'selectConclusion'}
          />
          {!!selectedConclusionResultData && (
            <BorderlessBox
              label={
                selectedConclusionResultData
                  ? `${selectedConclusionResultData.name} (${
                      selectedConclusionResultData.metric
                        ?.replace(/_/g, ' ')
                        .replace(/\b\w/, char => char.toUpperCase()) || ''
                    }${
                      selectedConclusionResultData.metric &&
                      selectedConclusionResultData.currency &&
                      CurrencyRequiredForValueTypes.includes(selectedConclusionResultData.metric)
                        ? ` - ${selectedConclusionResultData.currency}`
                        : ''
                    })`
                  : 'Conclusion'
              }
              topRightComponents={
                <div className="flex items-center gap-3">
                  <Button
                    icon={IconsType.edit}
                    variant={ButtonVariant.Secondary}
                    onClick={handleEditConclusion(selectedConclusionResultData)}
                    className="!rounded-full !w-[2rem] !h-[2rem] !p-[0.5rem]"
                  />
                </div>
              }
              variant="white"
              className="w-full gap-2 items-stretch">
              <NewEditableTable
                isCommaFormatted
                showHeader={false}
                data={conclusionTable}
                columns={conclusionTableColumns}
              />
              <div className="flex justify-end">
                <Button
                  variant={ButtonVariant.Tertiary}
                  onClick={() => handleRemoveConclusion()}
                  className=" text-blue-900 flex items-center gap-1 !flex-wrap-reverse">
                  Remove conclusion
                  <DoNotDisturbOnOutlined className="w-4 text-blue-900"></DoNotDisturbOnOutlined>
                </Button>
              </div>
            </BorderlessBox>
          )}
          <EditCreateArmsLengthRangeConclusion
            data={selectedComparableResultForEdit}
            isOpen={showArmsLengthModal}
            onClose={toggleConclusionsModal}
            creationCallBack={onConclusionCreation}
            key={showArmsLengthModal ? 0 : 1}
          />
        </BorderlessBox>

        <ConfirmationModal />
        <AddComparableUncontrolledTransaction
          selectedComparabilityFactors={orderedselectedComparabilityFactors}
          key={`${addTransactionModalOpen ? 0 : 1}-${selectedRow?.id || 0}`}
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse}
          selectedTerm={selectedRow}
          isOpen={addTransactionModalOpen}
          onClose={toggleAddModal}
          entity1Label={transactionResponse?.functional_profile?.source_party_identification || 'Source Entity'}
          entity2Label={transactionResponse?.functional_profile?.target_party_identification || 'Target Entity'}
        />
        {economicAnalysisTransactionResponse && (
          <ReorderUncontrolledTransactions
            isOpen={reorderModal}
            refetchTransactions={refetchTermsAndConditions}
            economicAnalysisId={economicAnalysisTransactionResponse?.id}
            onClose={toggleReorderModal}
            transactionsList={tableData}
            transactionResponse={transactionResponse}
          />
        )}
      </div>
      <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
        <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
          <Button
            isDefaultSize={false}
            onClick={handleCancel}
            iconCLass="w-5 h-5 transform rotate-180"
            iconPathClassName="stroke-blue900"
            className="!gap-1"
            icon={IconsType.arrowRight}
            variant={ButtonVariant.Secondary}>
            Back
          </Button>
          <Button
            isDefaultSize={false}
            onClick={onSaveClick}
            icon={IconsType.save}
            disabled={disableSave}
            variant={ButtonVariant.Primary}>
            Save
          </Button>
        </div>
      </Portal>
    </div>
  )
}

type FIELD_VALUES = {
  uncontrolled: {
    type: 'File' | 'Table'
    value?: SelectOptions
    currency?: SelectOptions
    document?: File | string | null
  }
  conclusionTable: ConclusionTableItem[]
  adjustments?: string
  selectConclusion?: SelectOptions | null
  uncontrolled_transaction_type?: SelectOptions
}

export type UncontrolledTransactionDataTableData = TermsAndCharacteristics<false>

export type ComparableResultsFieldValues = FIELD_VALUES

export default UncontrolledTransactionsData
