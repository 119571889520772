export const TINYMCE_API_KEY = process.env.NEXT_PUBLIC_TINYMCE_API_KEY || ''

export const LOCAL_FILE_EDITOR_LEFT_GROUP_BUTTONS = [
  'undo',
  'redo',
  'blocks',
  'fontfamily',
  'fontsize',
  'lineheight',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'backcolor',
  'forecolor',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
  'alignnone',
  'table',
  'bullist',
  'numlist',
  'indent',
  'outdent',
  'pagebreak',
  'removeformat',
  'link',
  'image',
  'addcomment',
  'showcomments',
  'footnoteButton',
  // 'aidialog',
  'aishortcuts',
  'spellchecker',
]

export const DEFAULT_LEFT_GROUP_BUTTONS = [
  'undo',
  'redo',
  'blocks',
  'lineheight',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'backcolor',
  'forecolor',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
  'alignnone',
  'table',
  'bullist',
  'numlist',
  'indent',
  'outdent',
  'pagebreak',
  'removeformat',
  'link',
  'image',
  'footnoteButton',
  // 'aidialog',
  'aishortcuts',
  'exhibitButton',
  'spellchecker',
]

export const DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE = DEFAULT_LEFT_GROUP_BUTTONS.filter(button => button !== 'image')

// TODO: Please not that the export, powerpaste and tinycomments plugin can be enabled after the purchase of premium
export const defaultEditorPlugins = [
  'advlist',
  'anchor',
  'autolink',
  'charmap',
  'code',
  'code',
  'export',
  'fullscreen',
  'image',
  'insertdatetime',
  'link',
  'lists',
  'media',
  'pagebreak',
  'powerpaste',
  'preview',
  'searchreplace',
  'table',
  'tinycomments',
  'tinymcespellchecker',
  'visualblocks',
  'wordcount',
  'ai',
]

/*
  Fonts added in stylesheet can be used in the font family formats avaialble in the EditorInstance
*/
const defaultFamilies = ['Oswald', 'Roboto']

export const defaultFontFamilyClasses = `
  /* Custom Fonts added for Font Selection */
  ${defaultFamilies
    .map(className => `@import url('https://fonts.googleapis.com/css2?family=${className}&display=swap');`)
    .join('\n')}
`
export const defaultFontFamilyScripts = `
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  ${defaultFamilies
    .map(
      className => `<link href="https://fonts.googleapis.com/css2?family=${className}&display=swap" rel="stylesheet">`
    )
    .join('\n')}
`

export const customFootNoteStyles = `
  /* Custom footnote plugin class changes */
  .footnote {
    background: yellow;
    border-radius: 5px;
    color: black;
    float: footnote;
    font-family: 'Georgia';
    font-size: 12px;
    font-weight: normal;
    footnote-style-position: inside;
    padding-inline: 5px;
    text-align: left;
    vertical-align: super;
  }
`
export const customExhibitStyles = `
  /* Custom footnote plugin class changes */
  .exhibit {
    background: #00ffff88;
    border-radius: 5px;
  }
  .exhibit::before {
    content: "Exhibit:  "
  ;
}

`
/*
  Fonts from default stylesheet can be leveraged in the font family format list
*/
export const fontFamilyFormats = `
  Andale Mono=andale mono,times;
  Arial Black=arial black,avant garde;
  Arial=arial,helvetica,sans-serif;
  Book Antiqua=book antiqua,palatino;
  Comic Sans MS=comic sans ms,sans-serif;
  Courier New=courier new,courier;
  Georgia=georgia,palatino;
  Helvetica=helvetica;
  Impact=impact,chicago;
  Oswald=oswald;
  Roboto=roboto;
  Sans Serif=sans-serif;
  Symbol=symbol;
  Tahoma=tahoma,arial,helvetica,sans-serif;
  Terminal=terminal,monaco;
  Times New Roman=times new roman,times;
  Trebuchet MS=trebuchet ms,geneva;
  Verdana=verdana,geneva;
  Webdings=webdings;
  Wingdings=wingdings,zapf dingbats
`
export const fontSizeFormats = '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'

export const integralCss = `@page {
  margin: 0.75in;
  size: Letter;
  border-top: 1px solid #1E3A8A; /* Change color for the top line */
  border-bottom: 1px solid #1E3A8A; /* Change color for the bottom line */
  padding-top: 10px;
  padding-bottom: 10px;
  
  @top-left {
      content: "{{ legal_entity.name|safe }}\A FY{{financial_year}} Local File Report";
      font-size: 10pt;
      font-family: 'Roboto';
      font-weight: 500;
      white-space: pre;
      margin-top: 0.25in;
  }
  
  @bottom-right {
      content: counter(page);
      font-family: 'Roboto';
      font-style: italic;
  }
  
  @footnote {
      border-top: 1px solid #1E3A8A;
      padding-top: 10px;
  }
}

/* Selects the first page when printing */
@page :first {
  margin: None;
  border: None;
  
  @top-left {
      content: None;
  }
  
  @bottom-right {
      content: None;
  }
}

div.page-break {
  page-break-before: always;
}

body {
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
}

/* Common styles */
h1 {
  font-size: 32pt;
  font-weight: 700;
  font-style: italic;
}

h2 {
  font-size: 28pt;
  font-weight: 700;
  font-style: italic;
}

h3 {
  font-size: 16pt;
  font-weight: 700;
  font-style: italic;
  color: #1E3A8A;
}

h4, h5, h6 {
  color: #1E3A8A;
  font-weight: 700;
  font-style: italic;
}

h4 {
  font-size: 14pt;
}

h5 {
  font-size: 12pt;
}

h6 {
  font-size: 10pt;
}

.exhibit {
  font-size: 10pt;
  font-weight: 700;
  color: #1E3A8A;
  text-align: left;
  font-style: italic;
  margin-bottom: 10px;
}

p {
  font-size: 12pt;
  text-align: justify;
}

/* Style unordered list */
ul {
  font-size: 12pt;
}

/* Style ordered list */
ol {
  font-size: 12pt;
}

table {
  border-collapse: collapse;
  text-align: center;
  font-size: 11pt;
  width: 100%; /* Make the table full width */
  border: 1px solid #1E3A8A;
}

thead {
  display: table-header-group;
}

th, td {
  text-align: center;
  padding: 5px;
  border: 1px solid #1E3A8A;
}

th {
  color: #1E3A8A;
}

td {
  font-size: 10pt;
}

td p {
  font-size: 10pt;
}

.attribute-description-table th, .attribute-description-table td {
  font-size: 10pt;
}

hr {
  color: #1E3A8A;
}

a {
  color: inherit; /* inherit color */
  text-decoration: inherit; /* inherit text-decoration */
}

img {
  max-width: 100%;
  max-height: 850px;
  display: block;
}

.quote {
  position: relative;
  margin: 1rem;
  font-style: italic;
}

/* information_requested_under_oecd_guidelines.html */
.index-table {
  border-collapse: collapse;
  font-size: 10pt;
  font-style: italic;
  border: 1px solid #1E3A8A;
  width: 100%;
  page-break-inside: auto;
}

.index-table th {
  text-align: center;
  font-size: 13pt;
  color: #1E3A8A;
  font-style: italic;
  padding: 8px;
}

.index-table td {
  text-align: left;
  font-size: 11pt;
  color: #1E3A8A;
  font-style: italic;
  padding: 8px;
}

.index-table .td-special {
  text-align: center;
  color: #FFFFFF;
  background-color: #1E3A8A;
  border: 1px solid #1E3A8A;
}

/* table_of_contents.html */
.table-of-contents-list {
  padding-left: 15px;
  counter-reset: item;
}

.table-of-contents-list li {
  display: block;
  font-size: 11pt;
  font-style: italic;
  color: #1E3A8A;
}

.table-of-contents-list li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.table-of-contents-list a::after {
  content: ' ' leader('.') ' ' target-counter(attr(href), page);
}

.appendix-list {
  padding-left: 15px;
}

.appendix-list li {
  display: block;
  font-size: 11pt;
  font-style: italic;
  color: #1E3A8A;
}

.appendix-list a::after {
  content: ' ' leader('.') ' ' target-counter(attr(href), page);
}

/* local_entity.html */
.container {
  border: 4px solid #1E3A8A;
  padding: 10px;
}

.box {
  display: inline-block;
  text-align: center;
}

.box p {
  border: 1px solid #1E3A8A;
  padding: 5px;
  margin: 0;
  display: inline-block;
}

.arrow {
  display: inline-block; /* Allows us to set width and height */
  color: solid #1E3A8A; /* Adjust color as desired */
  font-size: 45px; /* Adjust the size of the arrow */
  line-height: 40px; /* Helps with vertical centering */
  text-align: center; /* Centers the arrow in the given width */
}

/* functional_analysis.html */
.transaction-attribute ul {
  font-size: 10pt;
  padding-left: 15px;
}

.transaction-attribute h6 {
  font-size: 12pt;
  font-style: italic;
  color: #1E3A8A;
  margin-bottom: 0.5em;
}

.footnote {
  float: footnote;
  footnote-style-position: inside; /* forces marker inside footnote area */
  font-weight: normal;
  font-style: italic;
  color: black;
  text-align: left;
  vertical-align: super;
  font-size: 8pt;
}

::footnote-call {
  font-size: 0.5em; /* Make the footnote call smaller */
  vertical-align: super; /* Align vertically as superscript */
  position: relative;
  top: -0.5em; /* Lift it up a bit more */
  line-height: 0; /* Prevent it from affecting line height */
}

/* code tag generated by markdown package */
code {
  font-size: 10pt;
  white-space: pre-wrap; /* Preserve white-space, allow wrapping */
  word-wrap: break-word; /* Break long words */
}

.significance-table {
  width: 50%;
}

.text-align-justify {
  text-align: justify;
  padding: 10px;
}

.text-align-left {
  text-align: left;
  padding: 5px;
}

.text-align-right {
  text-align: right;
  padding: 5px;
}

.text-align-center {
  text-align: center;
  padding: 5px;
}

.semi-bold {
  font-weight: 500;
}
`
