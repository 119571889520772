import { Tooltip } from '@mui/material'
import { Benchmark } from 'api/benchmarks/types'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

const BenchmarkNameCell = ({ data }: BenchmarkNameCellProps): JSX.Element => {
  const Router = useRouter()

  const handleNameClick = useCallback(() => {
    Router.push(`${ROUTES.BENCHMARK_MANAGEMENT}/${data.id}?name=${data.name}`)
  }, [Router, data.id, data.name])

  return (
    <div className="flex flex-col items-start">
      <Typography
        variant={Variant.Callout}
        type="semibold"
        onClick={handleNameClick}
        className="whitespace-nowrap max-w-[12rem] min-[1400px]:max-w-[22rem] min-[1700px]:max-w-[26rem] truncate cursor-pointer text-blue-800">
        <Tooltip title={data.name} placement="top">
          <span>{capitalizeFirstLetter(data.name)}</span>
        </Tooltip>
      </Typography>
    </div>
  )
}

interface BenchmarkNameCellProps {
  data: Benchmark
}

export default BenchmarkNameCell
