import { Editor } from '@tinymce/tinymce-react'
import Loading from 'components/loading'
import { forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { Editor as RawEditor } from 'tinymce'

import {
  customExhibitStyles,
  customFootNoteStyles,
  DEFAULT_LEFT_GROUP_BUTTONS,
  defaultEditorPlugins,
  defaultFontFamilyClasses,
  fontFamilyFormats,
  fontSizeFormats,
  TINYMCE_API_KEY,
} from './constants'
import { ai_requests } from './helpers/ai.helpers'
import { setupExhibitButton } from './helpers/exhibit.helpers'
import { setupFootnotesButton } from './helpers/footnote.helpers'
import { resizeImage } from './helpers/imageResize.helpers'
import { useComments, useSpellIgnoreList } from './hooks'
import { EditorAuthor, EditorConversation, EditorRef } from './types'

const TinyMceEditor = forwardRef<EditorRef, TinyMceEditorProps>(
  (
    {
      author,
      disabled = false,
      height = '100%',
      minHeight,
      maxHeight,
      documentBaseUrl,
      contentStyles,
      contentCSS,
      onSetup,
      actionToolbar,
      editorData,
      documentAuthors: prevAuthors,
      documentConversations,
      leftGroupButtons = DEFAULT_LEFT_GROUP_BUTTONS,
      setIsDirty,
    },
    editorRef
  ) => {
    const [version, setVersion] = useState(1)
    const [isEditorLoading, setIsEditorLoading] = useState(true)
    const [initialData, setInitialData] = useState<string>()
    const {
      createCommentFn,
      replyToCommentFn,
      editCommentFn,
      deleteCommentFn,
      deleteCommentThreadFn,
      deleteAllCommentThreadsFn,
      lookupACommentFn,
      fetchCommentsFn,
    } = useComments({ editorRef: editorRef as MutableRefObject<EditorRef>, author, prevAuthors: prevAuthors || [] })

    const [toolbarItems, setToolbarItems] = useState([
      {
        name: '',
        items: leftGroupButtons,
      },
    ])

    const ignoreList = useSpellIgnoreList()

    const isFootnoteEnabled = useMemo(() => {
      return toolbarItems.some(group => {
        return group.items.includes('footnoteButton')
      })
    }, [toolbarItems])

    const isExhibitEnabled = useMemo(() => {
      return toolbarItems.some(group => {
        return group.items.includes('exhibitButton')
      })
    }, [toolbarItems])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const nativeSetupHandler = async (editor: RawEditor) => {
      // any native setup for editor can be done here
      if (isFootnoteEnabled) {
        await new Promise(resolve => {
          setupFootnotesButton(editor, () => {
            resolve(null)
          })
        })
      }
      if (isExhibitEnabled) {
        await new Promise(resolve => {
          setupExhibitButton(editor, () => {
            resolve(null)
          })
        })
      }
      // To set max width on images
      editor.on('NodeChange', function (e) {
        // Check if the changed node is an image
        if (e.element.nodeName === 'IMG') {
          // Restrict the width to 672 pixels
          const currentWidth = e.element.getAttribute('width')
          if (currentWidth && !!Number(currentWidth) && Number(currentWidth) > 672) {
            setTimeout(() => {
              editor.dom.setAttribs(e.element, { width: 672, height: 'auto' })
            }, 0)
          }
        }
      })
    }

    const defaultContentStyles = `${defaultFontFamilyClasses} ${isFootnoteEnabled ? customFootNoteStyles : ''} ${
      isExhibitEnabled ? customExhibitStyles : ''
    } `

    useEffect(() => {
      // prettier-ignore
      if (editorRef && documentConversations) {
        // @ts-expect-error The editorRef is being sent from parent and needs more understanding of TypeScript to solve
        editorRef.current = { editor: editorRef?.current?.editor, comments: documentConversations || [], commentsLog: editorRef?.current?.commentsLog || [] }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentConversations])

    useEffect(() => {
      if (actionToolbar && actionToolbar.length) {
        setToolbarItems(toolbarItems => {
          if (toolbarItems.length === 1) {
            return [...toolbarItems, { name: 'right-group-buttons', items: actionToolbar }]
          }
          return toolbarItems
        })
      }
    }, [actionToolbar])

    useEffect(() => {
      setVersion(version => version + 1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolbarItems, contentStyles, contentCSS, documentBaseUrl])

    const handleEditorChange = useCallback(
      (editor: RawEditor) => {
        if (typeof setIsDirty != 'function' || isEditorLoading) return
        // Compare the current content with the initial content
        if (editor.getContent() !== initialData) {
          setIsDirty(true)
        } else {
          setIsDirty(false)
        }
      },
      [initialData, setIsDirty, isEditorLoading]
    )

    return (
      <>
        <div className={`h-full ${isEditorLoading ? 'visible' : 'hidden'}`}>
          <Loading className={`flex w-full justify-center items-center h-[${height}]`} />
        </div>
        <div className={`h-full ${!isEditorLoading ? 'visible' : 'hidden'}`}>
          <Editor
            key={`${version}`}
            apiKey={TINYMCE_API_KEY}
            onInit={(evt, editor) => {
              // prettier-ignore
              if (editorRef) {
                // @ts-expect-error The editorRef is being sent from parent and needs more understanding of TypeScript to solve
                editorRef.current = { editor, comments: editorRef?.current?.comments || [], commentsLog: editorRef?.current?.commentsLog || [] }
              }
            }}
            onEditorChange={(_, editor) => handleEditorChange(editor)}
            initialValue={editorData}
            disabled={disabled}
            init={{
              highlight_on_focus: false,
              branding: false,
              promotion: false,
              height: height,
              min_height: minHeight,
              max_height: maxHeight,
              menubar: false,
              plugins: defaultEditorPlugins,
              setup: async editor => {
                await nativeSetupHandler(editor)

                if (onSetup && typeof onSetup === 'function') {
                  onSetup(editor)
                }
              },
              pagebreak_separator: '<div class="page-break"></div>',
              pagebreak_split_block: true,
              toolbar: toolbarItems,
              block_formats:
                'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
              content_style: `${defaultContentStyles} ${contentStyles}`,
              document_base_url: documentBaseUrl,
              content_css: contentCSS,
              content_css_cors: false,
              font_family_formats: fontFamilyFormats,
              font_size_formats: fontSizeFormats,

              // Image related options
              automatic_uploads: false,
              images_upload_handler: async blobInfo => {
                const base64Url = 'data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64()

                try {
                  const resizedImage: string = await resizeImage(base64Url, 672 * 1.5, 912 * 1.5)
                  return base64Url.length > resizedImage.length ? resizedImage : base64Url
                } catch (error) {
                  return base64Url
                }
              },

              // power paste related options
              paste_data_images: true,
              paste_merge_formats: true,
              powerpaste_allow_local_images: true,

              // Comments plugin related work
              tinycomments_mode: 'callback',
              tinycomments_author: author.username,
              tinycomments_author_name: author.fullName || author.username,
              tinycomments_author_avatar: author.avatarUrl,
              tinycomments_create: createCommentFn,
              tinycomments_reply: replyToCommentFn,
              tinycomments_edit_comment: editCommentFn,
              tinycomments_delete: deleteCommentThreadFn,
              tinycomments_delete_all: deleteAllCommentThreadsFn,
              tinycomments_delete_comment: deleteCommentFn,
              tinycomments_lookup: lookupACommentFn,
              tinycomments_fetch: fetchCommentsFn,

              sidebar_show: documentConversations && documentConversations.length ? 'showcomments' : '',

              init_instance_callback: editor => {
                setTimeout(() => {
                  setInitialData(editor.getContent())
                }, 100)
                setTimeout(() => {
                  setIsEditorLoading(false)
                }, 800) // intentional loader to hide initial re-renders for the editor
                editor.on('CommentChange', evt => {
                  const eventLog = evt.getEventLog()
                  // prettier-ignore
                  if (eventLog?.events && eventLog.events.length) {
                    // @ts-expect-error The editorRef is being sent from parent and needs more understanding of TypeScript to solve
                    editorRef.current = { editor: editorRef?.current?.editor, comments: editorRef?.current?.comments, commentsLog: eventLog.events }
                  }
                })
              },
              paste_preprocess(editor, args) {
                args.content = args.content.replace(/font-family\s*:\s*[^;]+;/gi, '')
                // replace empty anchor tags being pasted from word documents
                args.content = args.content.replace(/<a name="[^"]+"><\/a>/g, '')
                //replace non breaking spaces with regular space
                args.content = args.content
                  .replace(/\u00A0/g, ' ') //unicode
                  .replace(/&nbsp;/g, ' ') //html
                  .replace(/&NonBreakingSpace;/g, ' ') //html
              },
              ai_request: ai_requests,

              link_target_list: false,
              link_default_target: '_blank',
              extended_valid_elements: 'a[href|target=_blank]',

              spellchecker_languages: 'English (United States)=en_US,English (United Kingdom)=en_GB',
              spellchecker_ignore_list: ignoreList,
            }}
          />
        </div>
      </>
    )
  }
)

TinyMceEditor.displayName = 'TinyMCEEditor'
interface TinyMceEditorProps {
  author: EditorAuthor
  height?: string | number
  minHeight?: number
  maxHeight?: number
  disabled?: boolean
  editorData: string
  documentBaseUrl?: string
  contentStyles?: string
  contentCSS?: string | boolean | string[]
  actionToolbar?: string[]
  documentConversations?: EditorConversation[]
  documentAuthors?: EditorAuthor[]
  onSetup?: (editor: RawEditor) => void
  setEditorData?: React.Dispatch<React.SetStateAction<string>>
  leftGroupButtons?: string[]
  setIsDirty?: (dirty: boolean) => void
}

export default TinyMceEditor
