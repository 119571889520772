import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import HalfArrow from 'assets/icons/halfArrow'
import IconWithText from 'components/iconWithText'
import Typography, { Variant } from 'components/typography'
import { SelectOptions } from 'types/common.types'
import TransactionPartyCell from 'views/transactions/components/transactionPartyCell'
export const getTransactionOptions = (transactionResponse?: TransactionByIdResponse): SelectOptions[] => {
  if (!transactionResponse) return []
  return [
    {
      label: (
        <div className="flex justify-between px-1">
          <div className="flex flex-2 w-1/2 gap-2 items-center">
            <Typography variant={Variant.Callout} type="semibold">
              {transactionResponse.name}
            </Typography>
          </div>
          <span className="flex-1 flex items-center gap-1 w-1/2">
            <IconWithText showIcon={false} type={transactionResponse.transaction_type} />
            {!!transactionResponse?.economic_analysis?.transfer_pricing_method_abbreviation && (
              <>
                <Typography as="span" variant={Variant.Callout} type="semibold">
                  ({transactionResponse?.economic_analysis?.transfer_pricing_method_abbreviation || ''})
                </Typography>
              </>
            )}
          </span>
          <div className="flex-2 w-1/2">
            <TransactionPartyCell
              disableClick
              partyIdentification={
                transactionResponse.is_primary_le_source
                  ? transactionResponse.functional_profile.source_party_identification
                  : transactionResponse.functional_profile.target_party_identification
              }
              entities={[transactionResponse.primary_entity]}
              transactionNature={transactionResponse.functional_profile?.transaction_nature}
            />
          </div>
          <div className="flex-2 w-1/2">
            <TransactionPartyCell
              disableClick
              partyIdentification={
                !transactionResponse.is_primary_le_source
                  ? transactionResponse.functional_profile.source_party_identification
                  : transactionResponse.functional_profile.target_party_identification
              }
              entities={transactionResponse.individual_transactions.map(txn => txn.associated_entity)}
              transactionNature={transactionResponse.functional_profile?.transaction_nature}
            />
          </div>
        </div>
      ),
      value: transactionResponse.id,
    },
  ]
}

export const getIndividualTransactionOptions = (transactionResponse?: TransactionByIdResponse): SelectOptions[] => {
  if (!transactionResponse || !transactionResponse.individual_transactions.length) return []

  return transactionResponse?.individual_transactions.map(trans => {
    return {
      label: (
        <div className="flex justify-between px-1">
          <div className="flex-2 w-[45%]">
            <TransactionPartyCell
              maxLength={40}
              disableClick
              partyIdentification={
                transactionResponse.is_primary_le_source
                  ? transactionResponse.functional_profile.source_party_identification
                  : transactionResponse.functional_profile.target_party_identification
              }
              entities={[transactionResponse.primary_entity]}
              transactionNature={transactionResponse.functional_profile?.transaction_nature}
            />
          </div>
          <div className="flex items-center w-[10%]">
            <div className="flex flex-col   gap-[0.1875rem] p-2 rounded-full bg-orange-50">
              <HalfArrow pathClassName="fill-[#ea580c]" className="w-[0.86191rem] h-[0.3175rem]" />
              <HalfArrow className="rotate-180 w-[0.86191rem] h-[0.3175rem]" pathClassName="fill-[#ea580c]" />
            </div>
          </div>
          <div className="flex-2 w-[45%]">
            <TransactionPartyCell
              maxLength={40}
              disableClick
              partyIdentification={
                !transactionResponse.is_primary_le_source
                  ? transactionResponse.functional_profile.source_party_identification
                  : transactionResponse.functional_profile.target_party_identification
              }
              entities={[trans.associated_entity]}
              transactionNature={transactionResponse.functional_profile?.transaction_nature}
            />
          </div>
        </div>
      ),
      value: trans.id,
      filtertext: ` ${transactionResponse.primary_entity.name} ${trans.associated_entity.name} ${transactionResponse.functional_profile.target_party_identification}`,
    }
  })
}
