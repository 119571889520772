import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

export type BorderlessBoxProps = {
  children: React.ReactNode
  className?: string
  label?: React.ReactNode
  topRightComponents?: React.ReactNode
  footer?: React.ReactNode
  variant?: 'white' | 'grey'
  bgClass?: `bg-${string}`
  divRef?: React.RefObject<HTMLDivElement>
} & React.HTMLAttributes<HTMLDivElement>

function BorderlessBox({
  variant = 'grey',
  bgClass,
  className,
  label,
  topRightComponents,
  children,
  footer,
  divRef,
  ...rest
}: BorderlessBoxProps) {
  return (
    <div
      ref={divRef}
      className={cx('p-4 rounded-lg items-start flex flex-col w-full', bgClass, className, {
        'bg-gray-50': !bgClass && variant == 'grey',
        'bg-white': !bgClass && variant == 'white',
      })}
      {...rest}>
      {(!!label || !!topRightComponents) && (
        <div className="flex items-center w-full">
          {!!label && typeof label == 'string' ? (
            <Typography variant={Variant.Callout} type="semibold" className="text-gray-700 mr-auto">
              {label}
            </Typography>
          ) : (
            label
          )}
          {topRightComponents}
        </div>
      )}
      {children}
      {footer}
    </div>
  )
}

export default BorderlessBox
