import { CheckCircle } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getRejectionMatrixDefaultDescription } from 'api/transactions/createTransaction'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import cx from 'classnames'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import BorderlessBoxAccordion from 'components/BorderlessBoxAccordion/BorderlessBoxAccordion'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import TinyMceEditor from 'components/tinyMceEditor'
import { DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE, integralCss } from 'components/tinyMceEditor/constants'
import { EditorRef } from 'components/tinyMceEditor/types'
import Typography, { Variant } from 'components/typography'
import { BaseUrl } from 'constants/apis'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import Lottie from 'lottie-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form'
import { Editor as RawEditor } from 'tinymce'
import { cleanupHTML, getToastErrorMessage, sleep } from 'utils/utils'

const DEFAULT_PLACEHOLDER = 'Tap here to enter a valid description...'

interface RejectionMatrixFieldProps<T extends FieldValues> {
  id: string
  label: string
  props: {
    selected: boolean
    value: string
    setValue: UseFormSetValue<T>
    descriptionField: Path<T & FieldValues>
    transactionId?: number
    selectedTemplate: string | undefined
    showEricaButton: boolean
    dirtyHtml: (dirty: boolean) => void
  }
}

export type RejectionMatrixEditModalHandle = {
  getTemplateContent: () => Promise<{ html: string; commentsLog: unknown[] }>
  savingTemplate: (isSaving: boolean) => void
  resettingTemplate: (isSaving: boolean) => void
}

function RejectionMatrixField<T>({ id, label, props }: RejectionMatrixFieldProps<T & FieldValues>) {
  const { user } = useAuth()
  const [iFrame, setIFrame] = useState<HTMLIFrameElement | null>(null)
  const editorRef = useRef<EditorRef>(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editorData, setEditorData] = useState('')
  const [aiButtonClicked, setAiButtonClicked] = useState(false)
  const [isDefaultValueSet, setIsDefaultValueSet] = useState(false)

  const isEditorDataEmpty = useMemo(() => {
    const divEle = document.createElement('div')
    divEle.innerHTML = editorData
    const textContent = divEle.textContent || divEle.innerText
    return !!textContent
  }, [editorData])

  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })

  const getAutomatedDescriptionMutation = useMutation(getRejectionMatrixDefaultDescription, {
    onSuccess: data => {
      setEditorData(data.description)
      props.setValue(props.descriptionField, data.description as PathValue<T & FieldValues, Path<T & FieldValues>>)
      setAiButtonClicked(true)
    },
    onError: (erorr: AxiosError) => {
      getToastErrorMessage(erorr)
    },
  })

  const getAutomatedDescription = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) event.stopPropagation()
    props.selectedTemplate &&
      getAutomatedDescriptionMutation.mutate({
        tpMethodId: id,
        transactionId: props.transactionId || 0,
        template_name: props.selectedTemplate,
      })
  }

  const updateDescriptionValue = (shouldUpdateEditor: boolean) => {
    if (editorRef.current?.editor?.getContent()) {
      const editorHtml = editorRef.current.editor?.getContent()
      const processedHTML = cleanupHTML(editorHtml)
      if (shouldUpdateEditor) setEditorData(processedHTML)
      props.setValue(props.descriptionField, processedHTML as PathValue<T & FieldValues, Path<T & FieldValues>>)
    }
  }

  const handleIframeClick = async () => {
    setIsEditMode(true)
    let editorIsActive = false
    let retryCount = 0

    // Logic to wait for editor to come up for 1.5 seconds and then focus on the editor
    while (!editorIsActive && retryCount < 5) {
      await sleep(300)
      retryCount++
      if (editorRef.current?.editor?.focus) {
        editorRef.current.editor.focus()
        editorIsActive = true
      }
    }
    editorRef.current?.editor?.focus()
  }

  const handleEditorSetup = (editor: RawEditor) => {
    const handleEdit = () => {
      updateDescriptionValue(false)
    }
    const handleUpdate = () => {
      updateDescriptionValue(true)
      setIsEditMode(false)
    }

    editor.on('input', handleEdit)
    editor.on('blur', handleUpdate)
    editor.on('remove', handleUpdate)
  }

  useEffect(() => {
    if (!isDefaultValueSet) {
      setTimeout(() => {
        if (!isDefaultValueSet) {
          setEditorData(props?.value || '')
        }
        setIsDefaultValueSet(true)
      }, 800) // 0 also works, just to run code on nextTick
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.value])

  useEffect(() => {
    const frame = iFrame
    if (frame) {
      const loadHandler = () => {
        frame.contentDocument?.addEventListener('click', handleIframeClick)
      }
      frame.addEventListener('load', loadHandler)
      return () => frame.removeEventListener('load', loadHandler)
    }
  }, [iFrame])

  return (
    <BorderlessBoxAccordion
      label={
        <Typography
          type="semibold"
          variant={Variant.Callout}
          className={cx('flex items-center gap-2 -mr-2', props.selected ? 'text-gray-700' : 'text-red-400')}>
          {label}
          {props?.selected ? (
            <span className="text-teal-600 inline-flex	text-footnote font-semibold items-center gap-1">
              <CheckCircle className="text-teal-600 w-[14px] h-[14px]" /> Selected
            </span>
          ) : null}
        </Typography>
      }
      className={cx('rounded-lg border border-solid !py-1.5', props.selected ? 'border-green-300' : 'border-gray-200')}
      variant="white">
      <div className="w-full py-3">
        <div className="z-10">
          <BorderBoxWrapper
            className={`bg-gray-100 h-[22rem] px-[3rem] py-[1rem] ${
              !isEditMode && isDefaultValueSet ? 'visible' : 'hidden'
            }`}>
            <iframe
              ref={node => setIFrame(node)}
              title={`Preview`}
              srcDoc={`<!DOCTYPE html>
                        <html><head><style>
                        ${localFileCss || integralCss}
                        </style></head><body>${!isEditorDataEmpty ? DEFAULT_PLACEHOLDER : editorData}</body></html>`}
              style={{ width: '100%', height: '98%', border: 'none' }}
              className="w-full h-full border-none"
            />
          </BorderBoxWrapper>
          <div className={`doc-editor min-h-[22rem] ${isEditMode && isDefaultValueSet ? 'visible' : 'hidden'}`}>
            <TinyMceEditor
              author={{
                id: user?.id,
                username: user?.username || 'unknown',
                fullName: user
                  ? user.first_name || user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.username
                  : 'Unknown',
                avatarUrl: user?.profile_photo || undefined,
              }}
              ref={editorRef}
              editorData={editorData}
              onSetup={handleEditorSetup}
              documentBaseUrl={BaseUrl}
              height="22rem"
              contentStyles={localFileCss || integralCss}
              leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS_WITHOUT_IMAGE}
              setIsDirty={dirty => props.dirtyHtml(dirty)}
            />
          </div>
          <Loading
            className={`flex justify-center items-center h-[22rem] ${!isDefaultValueSet ? 'visible' : 'hidden'}`}
          />
          {aiButtonClicked && <AITextDisclaimer />}
        </div>
        {getAutomatedDescriptionMutation.isLoading && (
          <div className="z-20 absolute inset-0 flex justify-center items-center opacity-90 bg-white">
            <Lottie animationData={LoadingStars} className="w-full" />
          </div>
        )}
        <div className="mt-4 flex gap-4 justify-end">
          {!!props.selectedTemplate && !!props.showEricaButton && (
            <Tooltip title={'Load AI generated language'} placement="top">
              <div className="flex justify-center items-center">
                <Button
                  size={ButtonSize.ExtraSmall}
                  onClick={getAutomatedDescription}
                  variant={ButtonVariant.AIGradient}
                  className="whitespace-nowrap ms-auto"
                  disabled={getAutomatedDescriptionMutation.isLoading}
                  icon={IconsType.sparkle}>
                  ERICA AI
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </BorderlessBoxAccordion>
  )
}

export default RejectionMatrixField
