import classNames from 'classnames'
import { getOverviewConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React from 'react'

import ChecklistAccordion from '../ChecklistAccordion'
import ChecklistItem from '../ChecklistItem'
import TaskCompetionStatus from '../TaskCompetionStatus'

type props = {
  overviewConfig: ReturnType<typeof getOverviewConfig>
  active: boolean
}

const CompanyOverview = ({ overviewConfig, active }: props) => {
  return (
    <div
      className={classNames('mt-6 space-y-3', {
        hidden: !active,
      })}>
      <TaskCompetionStatus
        completedTasks={overviewConfig.tabConfig.completedTasks}
        totalTasks={overviewConfig.tabConfig.totalTasks}
      />
      <div className="space-y-2">
        {overviewConfig.listConfig.map(item => {
          if (item.type == 'single') {
            return <ChecklistItem key={item.props.label} {...item.props} />
          }
          if (item.type == 'expandable') {
            return <ChecklistAccordion key={item.props.label} {...item.props} />
          }
        })}
      </div>
    </div>
  )
}

export default CompanyOverview
