import Button from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal/Modal'
import { useOrganizationDetails } from 'hooks/useOrganizationDetails'
import FileUploadWithName from 'organisms/fieldRenderers/fields/fileUploadWithName/FIleUploadWithName'
import selectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import FormWithSubmission from 'organisms/formWithSubmission/FormWithSubmission'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'
import { SelectOptions } from 'types/common.types'

import { FILE_IDS, LABEL_MAP } from '../../constants/constants'

function AddBenchMarkFileModal({
  isOpen,
  onClose,
  fileTypeOptions,
  benchmarkingFile,
  onAddNewFile,
  isLoading,
}: AddBenchMarkFileModalProps) {
  const { year } = useSelector((state: RootState) => state.app)

  const { isFetching, isLoading: orgLoading, organizationDetails } = useOrganizationDetails(isOpen && !!year, year)
  const excelAllowed = !!organizationDetails?.is_benchmarking_excel_upload_enabled
  const { control, handleSubmit, reset, setValue } = useForm<AddBenchMarkFileModalFields>()

  const { file, name, type } = useWatch({ control })
  const setfile = useCallback(
    (file: File) => {
      !name && setValue('name', file.name)
      setValue('file', file)
    },
    [name, setValue]
  )

  useEffect(() => {
    if (benchmarkingFile) {
      reset({
        file: benchmarkingFile.file,
        name: benchmarkingFile.name,
        type: {
          value: benchmarkingFile.type,
          label: LABEL_MAP[benchmarkingFile.type],
        },
      })
    } else {
      reset({
        file: undefined,
        name: undefined,
        type: fileTypeOptions[0],
      })
    }
  }, [benchmarkingFile, fileTypeOptions, reset])

  const fields = useMemo(() => {
    const fieldsArray = [
      {
        id: 'type',
        fieldToRenderer: selectDropDown,
        options: fileTypeOptions,
        label: 'Select file type',
        disabled: !!benchmarkingFile?.type,
      },
      {
        id: 'file',
        fieldToRenderer: FileUploadWithName,
        nameId: 'name',
        className: '!border-none !p-0 !rounded-none',
        nameLabel: 'Document Name',
        preview: excelAllowed ? false : true,
        file: file instanceof File ? file : undefined,
        setFile: setfile,
        fileName: typeof file == 'string' ? name : null,
        accept: excelAllowed ? '.csv,.xlsx,.xlsm,.pdf' : '.pdf',
        fileUrl: typeof file == 'string' ? file : undefined,
      },
    ]

    return fieldsArray
  }, [benchmarkingFile?.type, excelAllowed, file, fileTypeOptions, name, setfile])

  const onSubmitHandler = useCallback(
    (data: AddBenchMarkFileModalFields) => {
      onAddNewFile({ file: data.file, name: data.name, type: data.type.value as FILE_IDS })
    },
    [onAddNewFile]
  )

  const buttonDisabled = !file || !name || !type?.value

  const onSave = handleSubmit(onSubmitHandler)
  if (isLoading || orgLoading || isFetching) {
    return (
      <Modal
        className="p-6"
        containerClassName="w-[48rem]"
        isOpen={isOpen}
        onClose={onClose}
        title={benchmarkingFile ? 'Edit Benchmarking File' : 'Add Benchmarking File'}>
        <Loading className="flex items-center justify-center w-full h-[20rem]" />
      </Modal>
    )
  }

  return (
    <Modal
      className="p-6"
      containerClassName="w-[48rem]"
      isOpen={isOpen}
      onClose={onClose}
      title={benchmarkingFile ? 'Edit Benchmarking File' : 'Add Benchmarking File'}>
      <FormWithSubmission
        onSubmitHandler={onSubmitHandler}
        control={control}
        reset={reset}
        fields={fields}
        className="w-full"
        handleSubmit={handleSubmit}>
        <div className="flex justify-end">
          <Button onClick={onSave} disabled={buttonDisabled}>
            {benchmarkingFile ? 'Update File' : 'Add File'}
          </Button>
        </div>
      </FormWithSubmission>
    </Modal>
  )
}

export default AddBenchMarkFileModal

interface AddBenchMarkFileModalProps {
  isOpen: boolean
  onClose: VoidFunction
  fileTypeOptions: SelectOptions[]
  benchmarkingFile?: {
    file?: File | string | null
    name?: string | null
    type: FILE_IDS
  }
  onAddNewFile: (data: { file?: File | string | null; name?: string | null; type: FILE_IDS }) => void
  isLoading?: boolean
}

interface AddBenchMarkFileModalFields {
  type: SelectOptions
  file?: File | string | null
  name?: string | null
}
