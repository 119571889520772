import classNames from 'classnames'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import TxnSelectAndReorder from 'organisms/fieldRenderers/fields/TxnSelectAndReorder/TxnSelectAndReorder'
import { getTransactionConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React from 'react'

import ChecklistAccordion from '../ChecklistAccordion'
import ChecklistItem from '../ChecklistItem'
import ChecklistParentAccordion from '../ChecklistParentAccordion'
import TaskCompetionStatus from '../TaskCompetionStatus'

type props = {
  txnConfig: ReturnType<typeof getTransactionConfig>
  active?: boolean
}

const TransactionsChecklist = ({ txnConfig, active }: props) => {
  const { control, transactionOptions } = useReportWizardContext()

  return (
    <div
      className={classNames('mt-6 space-y-3', {
        hidden: !active,
      })}>
      <TxnSelectAndReorder
        label="Select Transactions"
        control={control}
        options={transactionOptions}
        id="transactions"
      />
      <TaskCompetionStatus
        completedTasks={txnConfig.tabConfig.completedTasks}
        totalTasks={txnConfig.tabConfig.totalTasks}
      />
      {txnConfig.listConfig.map(item => {
        return (
          <ChecklistParentAccordion
            added={item.added}
            label={item.label}
            key={item.href + item.label}
            href={item.href}
            isOpen={item.isOpen}>
            {item.subSections.map(item => {
              if (item.hidden) return null
              if (item.type == 'single') {
                return <ChecklistItem key={item.props.label} {...item.props} bgClass="bg-[#1A193B]" />
              }
              if (item.type == 'expandable') {
                return <ChecklistAccordion key={item.props.label} {...item.props} bgClass="bg-[#1A193B]" />
              }
            })}
          </ChecklistParentAccordion>
        )
      })}
    </div>
  )
}

export default TransactionsChecklist
