import Typography, { Variant } from 'components/typography'
import React from 'react'
import { components as Component, GroupBase, OptionProps } from 'react-select'
import { SelectOptions } from 'types/common.types'
export const SelectCheckboxOptions = (props: OptionProps<SelectOptions, true, GroupBase<SelectOptions>>) => {
  return (
    <Component.Option {...props}>
      <div className="flex items-center text-start">
        <input
          className="w-[1.2rem] h-[1.2rem] flex-shrink-0 accent-blue800 border-2 rounded border-solid border-neutral300 mr-4"
          type="checkbox"
          checked={props.isSelected}
          readOnly
        />
        <Typography className="whitespace-nowrap overflow-hidden w-auto truncate" variant={Variant.Body}>
          {props.children}
        </Typography>
      </div>
    </Component.Option>
  )
}
