import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { SelectedComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import GreenTick from 'assets/icons/greenTick'
import Typography, { Variant } from 'components/typography'

export const getSelectedMetricsColumns: ({
  onEditSelectedCriteria,
  onDeleteJustification,
}: {
  onEditSelectedCriteria: (data: SelectedComparabilityFactor) => void
  onDeleteJustification: (data: SelectedComparabilityFactor) => Promise<void>
}) => ColumnDef<SelectedComparabilityFactor>[] = ({ onEditSelectedCriteria, onDeleteJustification }) => {
  return [
    {
      accessorKey: 'comparability_factor_full_name',
      size: 300,
      cell(props) {
        const name = props.row.original.comparability_factor_full_name
        return (
          <Tooltip title={name}>
            <div>
              <Typography variant={Variant.Callout} className="flex items-center gap-1 my-0.5" type="semibold">
                {name.slice(0, 30)}
                {name.length > 30 ? '...' : ''}
                {!!props.row.original.justification && <GreenTick className="w-[14px] h-[14px]" />}
              </Typography>
            </div>
          </Tooltip>
        )
      },
    },
    {
      id: 'action',
      size: 60,
      cell: cell => {
        return (
          <div className="flex justify-end gap-4">
            <Typography
              onClick={() => onEditSelectedCriteria(cell.row.original)}
              type="semibold"
              className="text-right text-blue-800 cursor-pointer"
              variant={Variant.Callout}>
              Edit
            </Typography>
            <Typography
              onClick={() => onDeleteJustification(cell.row.original)}
              type="semibold"
              className="text-right text-blue-800 cursor-pointer"
              variant={Variant.Callout}>
              Delete
            </Typography>
          </div>
        )
      },
    },
  ]
}
