import 'styles/index.css'
import 'react-toastify/dist/ReactToastify.css'

import { UserProvider } from '@auth0/nextjs-auth0/client'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'hooks/useAuth'
import { Inter } from 'next/font/google'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import store from 'store'
import ThemeProvider from 'styles/theme'

const inter = Inter({
  subsets: ['latin'],
  preload: true,
  adjustFontFallback: true,
  variable: '--inter-font',
})

import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'
import { FTUTourContextProvider } from 'hooks/useFTUTour/useFTUTour'
import { ReportWizardContextProvider } from 'hooks/useReportWizard/useReportWizard'
import RoutingEventsHandlerProvider from 'hooks/useRoutingHandler/useRoutingHandler'
import type { AppProps } from 'next/app'
import Layout from 'organisms/layout'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { initializeAnalytics } from 'utils/analytics'

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  useEffect(() => {
    initializeAnalytics()
  }, [])

  return (
    <>
      <style jsx global>
        {`
          :root {
            --inter-font: ${inter.style.fontFamily};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider>
            <DndProvider backend={HTML5Backend}>
              <main className={inter.className}>
                <ErrorBoundary>
                  <UserProvider>
                    <AuthProvider>
                      <RoutingEventsHandlerProvider>
                        <FTUTourContextProvider>
                          <ReportWizardContextProvider>
                            <Layout>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ToastContainer autoClose={5000} />
                                <Component {...pageProps} />
                              </LocalizationProvider>
                            </Layout>
                            <ReactQueryDevtools initialIsOpen={false} />
                          </ReportWizardContextProvider>
                        </FTUTourContextProvider>
                      </RoutingEventsHandlerProvider>
                    </AuthProvider>
                  </UserProvider>
                </ErrorBoundary>
              </main>
            </DndProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
      <div id="tour-portal-root">
        <div></div>
      </div>
    </>
  )
}

export default MyApp
