import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import CheckboxActive from 'assets/icons/checkboxActive'
import CheckboxInactive from 'assets/icons/checkboxInactive'
import SixDotDrag from 'assets/icons/sixDotDrag'
import classNames from 'classnames'
import Button, { ButtonSize } from 'components/button'
import Typography, { Variant } from 'components/typography'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { components as Component, GroupBase, MenuProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

import { TxnSelectAndReorderContext } from '../TxnSelectAndReorder'

const TxnSelectAndReorderMenu = (props: MenuProps<SelectOptions, true, GroupBase<SelectOptions>>) => {
  const context = useContext(TxnSelectAndReorderContext)
  const { getValue, setValue, options, children } = props
  const [isReorder, setIsReorder] = useState(false)
  const allSelected = getValue().length == options.length
  const handleAllClick = useCallback(() => {
    if (allSelected) {
      setValue([], 'select-option')
    } else {
      const prevValues = getValue()
      const sortedOptions = [
        ...prevValues,
        ...(options as SelectOptions[]).filter((opt: SelectOptions) => !prevValues.find(val => val.value == opt.value)),
      ]
      setValue(sortedOptions, 'select-option')
    }
  }, [allSelected, getValue, options, setValue])

  const noneSelected = !getValue().length

  const handleNextClick = useCallback(() => {
    setIsReorder(o => !o)
  }, [])

  const reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void = useCallback(
    (draggedRowIndex, targetRowIndex) => {
      const swappedAttributes = [...getValue()]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setValue([...swappedAttributes], 'select-option')
      return
    },
    [getValue, setValue]
  )

  const handleCloseMenu = useCallback(() => {
    context?.setMenuOpen && context.setMenuOpen(false)
  }, [context])

  return (
    <Component.Menu
      {...props}
      className="!p-3 !border !border-solid !rounded-xl !border-gray-800 !bg-[#0A0929] shadow-containerShadow">
      {isReorder ? (
        <div
          onMouseDown={e => {
            e.stopPropagation()
          }}
          onTouchStart={e => e.stopPropagation()}
          className="flex flex-col gap-3 w-full">
          <Typography variant={Variant.Callout} type="semibold" className="text-white">
            Reorder Selected Transactions
          </Typography>
          <div className="w-full max-h-[250px] overflow-y-auto">
            {getValue().map((opt, index) => {
              return <DragabbleCard reorderRow={reorderRow} key={opt.value} opt={opt} index={index} />
            })}
          </div>
          <div className="flex items-center justify-between">
            <Button
              disabled={noneSelected}
              onClick={handleNextClick}
              size={ButtonSize.Small}
              className="flex !flex-row mt-2 items-center !gap-0.5 !py-0  bg-indigo-400 hover:!bg-indigo-500 !text-indigo-950">
              <ChevronLeft className="w-5 h-5" /> Back
            </Button>
            <Button
              disabled={noneSelected}
              onClick={handleCloseMenu}
              size={ButtonSize.Small}
              className="flex !flex-row mt-2 items-center !gap-1 !py-0  bg-indigo-400 hover:!bg-indigo-500 !text-indigo-950">
              Done
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <div className={classNames('!border-none !mb-2 w-full !px-4 !py-2 !bg-[rgb(16,14,46)]')}>
            <div className="flex items-center text-start w-full gap-2">
              <div className="flex items-center shrink-0" onClick={handleAllClick}>
                {allSelected ? (
                  <CheckboxActive className="w-[18px] h-[18px]" />
                ) : (
                  <CheckboxInactive className="w-[18px] h-[18px]" />
                )}
              </div>
              <div className="flex items-center w-full gap-2">
                <Typography variant={Variant.Callout} type="semibold" className="text-gray-300 w-full max-w-[42%]">
                  Txn Name
                </Typography>
                <Typography variant={Variant.Callout} type="semibold" className="text-gray-300 w-full max-w-[24%]">
                  Reporting Party
                </Typography>
                <Typography variant={Variant.Callout} type="semibold" className="text-gray-300 w-full max-w-[24%]">
                  Related Party
                </Typography>
                <Typography
                  variant={Variant.Callout}
                  type="semibold"
                  className="text-gray-300 w-full max-w-[10%] text-end">
                  TP Method
                </Typography>
              </div>
            </div>
          </div>
          {children}
          <Button
            disabled={noneSelected}
            onClick={
              (Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0) > 1
                ? handleNextClick
                : handleCloseMenu
            }
            size={ButtonSize.Small}
            className="flex !flex-row mt-2 items-center !gap-1 !py-0 ms-auto bg-indigo-400 hover:!bg-indigo-500 !text-indigo-950">
            {(Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0) > 1 ? (
              <>
                Next <ChevronRight className="w-5 h-5" />
              </>
            ) : (
              'Done'
            )}
          </Button>
        </div>
      )}
    </Component.Menu>
  )
}

const DragabbleCard = ({
  opt,
  index,
  reorderRow,
}: {
  opt: SelectOptions
  index: number
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const reorderRowFn = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => reorderRow(draggedRowIndex, targetRowIndex),
    [reorderRow]
  )

  const [, drop] = useDrop<{
    opt: typeof opt
    index: number
  }>({
    accept: 'txnRow',
    drop: draggedRow => {
      reorderRowFn(draggedRow.index, index)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
    item: () => {
      return {
        opt,
        index,
      }
    },
    type: 'txnRow',
  })

  preview(drag(drop(ref)))

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 999 : 'auto' }}
      className="w-full flex items-center px-2.5 py-2 cursor-pointer">
      <div className="flex items-center">
        <SixDotDrag className="w-6 h-6" pathClassName="fill-indigo-500" />
      </div>
      <div className="max-w-[calc(100%-50px)]">
        <Typography variant={Variant.Callout} type="semibold" className="text-gray-300 w-full truncate">
          {(opt.additionalData as unknown as TransactionResponse).name}
        </Typography>
      </div>
    </div>
  )
}

export default TxnSelectAndReorderMenu
