import React from 'react'
import { components as Component, GroupBase, MultiValueGenericProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

const TxnSelectAndReorderMultiValueContainer = (
  props: MultiValueGenericProps<SelectOptions, true, GroupBase<SelectOptions>>
) => {
  const index = (Array.isArray(props.selectProps.value) ? props.selectProps.value : []).findIndex(
    value => value.value == props.data.value
  )
  return (
    <Component.MultiValueContainer
      {...props}
      innerProps={{
        className: '!bg-none max-w-[calc(100%-50px)]',
      }}>
      <div
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onTouchStart={e => e.stopPropagation()}
        className="text-gray-300 mr-1 max-w-full truncate whitespace-nowrap text-callout">
        {props.data.label}
        {index < (Array.isArray(props.selectProps.value) ? props.selectProps.value.length - 1 : 0) && ', '}
      </div>
    </Component.MultiValueContainer>
  )
}

export default TxnSelectAndReorderMultiValueContainer
