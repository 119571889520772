import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { associated_entity, primary_entity, TransactionResponse } from 'api/transactions/getTransaction/types'
import ArrowDivider from 'components/arrowDivider'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import IconCell from 'components/iconCell'
import IconWithText from 'components/iconWithText'
import NewTable from 'components/newTable'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { formatNumberWithSuffix } from 'utils/numberUtils'
import { createQueryParams } from 'utils/utils'

import { getPrimaryEntityColumns, groupFunctionColumns } from './transactionGroupInfo.columns'

const TransactionGroupInfo = ({
  shouldHideCosts = false,
  isPrimarySource,
  secondaryLegalEntity,
  primaryLegalEntity,
  totalCostInUsd,
  secondaryEntityCosts,
  transactionResponse,
  boxVariant = 'white',
}: Props): JSX.Element => {
  const router = useRouter()

  const onClickSecondaryEntity = useCallback(
    (entity: associated_entity) => () => {
      if (!entity?.id) return
      router.push(
        `${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${entity?.id}/?${createQueryParams({
          name: entity?.name,
        })}`,
        undefined,
        { shallow: false }
      )
    },
    [router]
  )
  const columns = useMemo(() => {
    return groupFunctionColumns({
      isPrimarySource,
      shouldHideCosts,
      entityCosts: secondaryEntityCosts,
      transactionResponse,
      onClickSecondaryEntity,
    })
  }, [isPrimarySource, onClickSecondaryEntity, shouldHideCosts, secondaryEntityCosts, transactionResponse])

  const onClickPrimaryEntity = useCallback(() => {
    if (!primaryLegalEntity?.id) return
    router.push(
      `${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${primaryLegalEntity?.id}/?${createQueryParams({
        name: primaryLegalEntity?.name,
      })}`,
      undefined,
      { shallow: false }
    )
  }, [primaryLegalEntity?.id, primaryLegalEntity?.name, router])

  const primaryEntityData = useMemo(() => {
    return primaryLegalEntity ? [primaryLegalEntity] : []
  }, [primaryLegalEntity])

  const primaryEntityColumns = useMemo(() => {
    return getPrimaryEntityColumns({
      isPrimarySource,
      transactionResponse,
      onClickPrimaryEntity,
    })
  }, [isPrimarySource, transactionResponse, onClickPrimaryEntity])

  const primaryEntityElement = (
    <NewTable
      columns={primaryEntityColumns}
      data={primaryEntityData}
      height="170px"
      classes={{
        root: 'flex-1',
      }}
    />
  )

  const associatedEntitiesElement = (
    <NewTable
      columns={columns}
      height="170px"
      data={secondaryLegalEntity || []}
      classes={{
        root: 'flex-2',
      }}
    />
  )

  return (
    <BorderlessBox variant={boxVariant}>
      <div className="flex items-center w-full gap-8">
        {!isPrimarySource ? primaryEntityElement : associatedEntitiesElement}
        <div className="flex flex-col shrink-0">
          <ArrowDivider>
            <IconCell
              action={`$${formatNumberWithSuffix(totalCostInUsd, 2)}`}
              textClassName="text-green-600 !font-bold"
              className="py-2 px-4 bg-lime-50"
            />
          </ArrowDivider>
          <ArrowDivider chidrenPosition="bottom" rtl>
            <IconWithText
              className="rounded-[2.5rem] px-4 py-2 bg-yellow-50"
              type={transactionResponse.transaction_type}
              renameTangibles={true}
              textClassName="!text-yellow-500 !font-bold"
              showIcon={false}
            />
          </ArrowDivider>
        </div>
        {isPrimarySource ? primaryEntityElement : associatedEntitiesElement}
      </div>
    </BorderlessBox>
  )
}

interface Props {
  shouldHideCosts?: boolean
  isPrimarySource: boolean
  secondaryLegalEntity?: associated_entity[]
  primaryLegalEntity?: primary_entity
  totalCostInUsd: number
  transactionResponse: TransactionByIdResponse | TransactionResponse
  secondaryEntityCosts: Record<string, { cost: string; costInUsd: number }>
  boxVariant?: 'white' | 'grey'
}

export default TransactionGroupInfo
