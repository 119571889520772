import { SelectOptions } from 'types/common.types'

export function generateYearOptions(forYears = 2): SelectOptions[] {
  const currentYear = new Date().getFullYear()
  const yearOptions: SelectOptions[] = []

  for (let year = currentYear; year >= currentYear - forYears; year--) {
    yearOptions.push({
      label: year.toString(),
      value: year,
    })
  }

  return yearOptions
}

export function generate10YearOptions(startYear = 2010): SelectOptions[] {
  const currentYear = new Date().getFullYear()
  const yearOptions: SelectOptions[] = []

  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push({
      label: year.toString(),
      value: year,
    })
  }

  return yearOptions
}
