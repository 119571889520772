import { useMutation } from '@tanstack/react-query'
import { deleteAttribute } from 'api/transactions/createTransaction'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import useMirrorConfirmation from 'views/transactions/createTransaction/components/MirroredTransactionEditConfirmation/useMirrorConfirmation'

import { GroupedEntitiesByAttributes } from '../../types/types'

const DeleteFunctionModal = ({
  isDeleteModal,
  setDeleteModal,
  currentAttributeData,
  functionProfileRefetch,
  transactionRefetch,
  currentTransaction,
}: DeleteFunctionModalProps) => {
  const handleOnClose = useCallback(() => {
    setDeleteModal(false)
  }, [setDeleteModal])

  const { refetchChecklist } = useReportWizardContext()

  const deleteQueryMutation = useMutation(deleteAttribute, {
    onSuccess: () => {
      functionProfileRefetch()
      transactionRefetch()
      refetchChecklist()
      setDeleteModal(false)
      toast.success('Attribute deleted successfully')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })
  const isMirrored = !!currentTransaction.mirrored_with_transaction?.id
  const { getMirrorConfirmation, MirrorConfirmationModal } = useMirrorConfirmation()

  const handleDeleteAttribute = useCallback(async () => {
    if (isMirrored) {
      const status = await getMirrorConfirmation(
        `This is a mirrored transaction, deleting this ${currentAttributeData?.attribute_type} will remove it from all the mirrored transactions. Do you want to proceed?`,
        false
      )
      if (status == 'close') return
    }
    deleteQueryMutation.mutate({ id: currentAttributeData?.id || 0 })
  }, [
    currentAttributeData?.attribute_type,
    currentAttributeData?.id,
    deleteQueryMutation,
    getMirrorConfirmation,
    isMirrored,
  ])

  return (
    <Modal
      containerClassName="w-[31rem]"
      className="p-10"
      icon={IconsType.alertTriangle}
      title="Warning!"
      isOpen={isDeleteModal}
      onClose={handleOnClose}>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex items-start gap-2 justify-center w-full">
          <Typography variant={Variant.Body}>
            Are you sure you want to delete
            <Typography variant={Variant.Body} type="semibold">
              {currentAttributeData?.attribute_name}
            </Typography>
          </Typography>
        </div>
        <div className="flex items-center w-full mt-10">
          <Button onClick={handleOnClose} className="mr-4" isFullWidth variant={ButtonVariant.Secondary}>
            No, Go Back
          </Button>
          <Button isFullWidth onClick={handleDeleteAttribute}>
            Yes, I am Sure
          </Button>
        </div>
      </div>
      <MirrorConfirmationModal />
    </Modal>
  )
}

interface DeleteFunctionModalProps {
  isDeleteModal: boolean
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  currentAttributeData?: GroupedEntitiesByAttributes[number]
  transactionRefetch: () => void
  functionProfileRefetch: () => void
  currentTransaction: TransactionByIdResponse
}

export default DeleteFunctionModal
