import { addStringAsABlob } from 'utils/stringUtils'

import axios from '../index'
import {
  createOrgDetailsPayload,
  deleteOrganizationDetailsFilesPayload,
  deletePOSectionDescriptionPayload,
  DepartmentListItem,
  HomepageDataItem,
  ImportOrgDetailsPayload,
  OrganizationDetails,
  TitlesResponse,
  updateMarketAnalysisDetailsPayload,
  updateMasterFileDetailsPayload,
  updateOrganiationDetailsPayload,
} from './types'

export const getOrganizationDetails = (year?: number, id?: number) => {
  return axios
    .get<OrganizationDetails[]>(`/api/v1/org/${id ?? '{organization}'}/organization/`, { params: { year } })
    .then(res => res.data)
}

export const deleteOrganizationDataForYear = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/organization/${id}`).then(res => res.data)
}

export const createOrgDetails = (variables: createOrgDetailsPayload) => {
  return axios.post<{ id: number }>(`/api/v1/org/{organization}/organization/`, variables)
}

export async function updateOrganizationDetails(variables: updateOrganiationDetailsPayload) {
  const {
    abbr,
    ceo,
    company_logo,
    description,
    head_quarters,
    currency,
    incorporation_year,
    id,
    name,
    total_head_count,
    total_revenue,
    se_traded,
    ticker_symbol,
    incorporation_place,
    ipo_year,
  } = variables

  const formData = new FormData()

  formData.append('ceo', ceo || '')
  formData.append('description', description || '')
  formData.append('total_head_count', String(total_head_count || ''))
  formData.append('total_revenue', String(total_revenue || ''))
  formData.append('currency', String(currency || ''))
  formData.append('se_traded', se_traded || '')
  formData.append('ticker_symbol', ticker_symbol || '')
  formData.append('incorporation_year', String(incorporation_year || ''))
  formData.append('ipo_year', String(ipo_year || ''))
  formData.append('incorporation_place', incorporation_place || '')
  formData.append('abbr', abbr || '')
  formData.append('head_quarters', head_quarters || '')
  if (company_logo) formData.append('company_logo', company_logo, company_logo?.name)
  if (name) formData.append('name', name || '')

  if (id) formData.append('id', id.toString())

  const axiosResponse = await axios.patch<OrganizationDetails>(
    `/api/v1/org/{organization}/organization/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return axiosResponse
}

export async function updateMarketAnalysisDetails(variables: updateMarketAnalysisDetailsPayload) {
  const {
    id,
    group_business_overview,
    descr_of_products_services,
    industry_name,
    industry_description,
    industry_attributes_desc,
    attributes_implication_desc,
    business_strategy_and_group_competitor_factors,
    org_far_overview,
  } = variables

  const formData = new FormData()

  if (id) formData.append('id', id.toString())
  if (group_business_overview) formData.append('group_business_overview', addStringAsABlob(group_business_overview))
  if (org_far_overview) formData.append('org_far_overview', addStringAsABlob(org_far_overview))
  if (descr_of_products_services)
    formData.append('descr_of_products_services', addStringAsABlob(descr_of_products_services))
  if (industry_attributes_desc) formData.append('industry_attributes_desc', addStringAsABlob(industry_attributes_desc))
  if (industry_description) formData.append('industry_description', addStringAsABlob(industry_description))
  if (attributes_implication_desc)
    formData.append('attributes_implication_desc', addStringAsABlob(attributes_implication_desc))
  if (industry_name) formData.append('industry_name', industry_name)
  if (business_strategy_and_group_competitor_factors)
    formData.append(
      'business_strategy_and_group_competitor_factors',
      addStringAsABlob(business_strategy_and_group_competitor_factors)
    )

  const axiosResponse = await axios.patch<OrganizationDetails>(
    `/api/v1/org/{organization}/organization/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return axiosResponse
}
export async function updateMasterFileDetails(variables: updateMasterFileDetailsPayload) {
  const { master_file_org_abbr, master_file_org_name, master_file_po_description, master_file_po_section_name, id } =
    variables

  const formData = new FormData()

  if (id) formData.append('id', id.toString())
  if (master_file_org_name || master_file_org_name === '') {
    formData.append('master_file_org_name', master_file_org_name || '')
  }
  if (master_file_org_abbr || master_file_org_abbr === '') {
    formData.append('master_file_org_abbr', master_file_org_abbr || '')
  }
  if (master_file_po_section_name) {
    formData.append('master_file_po_section_name', master_file_po_section_name || '')
  }
  if (master_file_po_description)
    formData.append('master_file_po_description', addStringAsABlob(master_file_po_description))

  const axiosResponse = await axios.patch<OrganizationDetails>(
    `/api/v1/org/{organization}/organization/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return axiosResponse
}
export async function deletePOSectionDescription(variables: deletePOSectionDescriptionPayload) {
  const { id, master_file_po_description } = variables
  const axiosResponse = await axios.patch<OrganizationDetails>(`/api/v1/org/{organization}/organization/${id}/`, {
    id,
    master_file_po_description,
  })
  return axiosResponse
}

export async function deleteOrganizationDetailsFiles(variables: deleteOrganizationDetailsFilesPayload) {
  const {
    id,
    group_business_overview,
    descr_of_products_services,
    industry_description,
    industry_attributes_desc,
    attributes_implication_desc,
    business_strategy_and_group_competitor_factors,
    org_far_overview,
  } = variables

  const axiosResponse = await axios.patch<OrganizationDetails>(`/api/v1/org/{organization}/organization/${id}/`, {
    id,
    group_business_overview,
    descr_of_products_services,
    industry_description,
    industry_attributes_desc,
    attributes_implication_desc,
    business_strategy_and_group_competitor_factors,
    org_far_overview,
  })
  return axiosResponse
}

export const getDepartmentsList = () => {
  return axios
    .get<DepartmentListItem[]>(`/api/v1/org/{organization}/organization/department_name`)
    .then(res => res.data)
}
export const deleteDepartment = ({ id }: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/organization/department_name/${id}`).then(res => res.data)
}
export const createDepartment = ({ name, id }: { name: string; id?: number }) => {
  if (typeof id == 'number') {
    return axios
      .patch<DepartmentListItem>(`/api/v1/org/{organization}/organization/department_name/${id}/`, { name })
      .then(res => res.data)
  } else {
    return axios
      .post<DepartmentListItem>(`/api/v1/org/{organization}/organization/department_name/`, { name })
      .then(res => res.data)
  }
}

export const getTitlesList = () => {
  return axios.get<TitlesResponse[]>(`/api/v1/org/{organization}/organization/job_title`).then(res => res.data)
}

export const createTitle = ({ name, id }: { name: string; id?: number; organization?: number }) => {
  if (typeof id == 'number') {
    return axios
      .patch<TitlesResponse>(`/api/v1/org/{organization}/organization/job_title/${id}/`, { name })
      .then(res => res.data)
  } else {
    return axios
      .post<TitlesResponse>(`/api/v1/org/{organization}/organization/job_title/`, { name })
      .then(res => res.data)
  }
}
export const deleteTitle = ({ id }: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/organization/job_title/${id}`).then(res => res.data)
}
export const getHomePageData = ({ orgId }: { orgId?: string | null }) => {
  return axios
    .get<HomepageDataItem[]>(`/api/v1/org/${orgId || '{organization}'}/organization/homepage/`)
    .then(res => res.data)
}

export const importOrgDetails = ({ data }: { data: ImportOrgDetailsPayload }) => {
  return axios.post(`/api/v1/org/{organization}/organization/import_organization_details/`, data)
}

export async function updateOrgTemplate(variables: { id: number; template_language: string }) {
  const { id, template_language } = variables

  const axiosResponse = await axios.patch<OrganizationDetails>(`/api/v1/org/{organization}/organization/${id}/`, {
    id,
    template_language,
  })
  return axiosResponse
}
