import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Loading from 'components/loading'
import NewTable from 'components/newTable'
import { ROUTES } from 'constants/routes'
import { usePliMethods } from 'hooks/usePliMethods'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import AddFinancialDataModal from './components/AddFinancialDataModal/AddFinancialDataModal'
import { getFinancialEntityTableColumns } from './helpers/financialData.helpers'
import type { FinancialDataEntityTableData } from './types/financialData.types'

const FinancialDataComponent = ({
  transactionResponse = {} as TransactionByIdResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionFetching,
  transactionByIdLoading,
  onTabChange,
}: FinancialDataProps) => {
  const [selectedEntity, setSelectedEntity] = useState<FinancialDataEntityTableData>()
  const {
    pli_method,
    testing_methodology: testingMethodology,
    is_primary_entity_tested_party,
    transfer_pricing_method,
  } = economicAnalysisTransactionResponse?.[0]
  const router = useRouter()

  const { year } = router.query

  const { pliMethods } = usePliMethods({
    transactionResponse,
    tp_methods: transfer_pricing_method,
    enabled: !!transfer_pricing_method,
  })
  const pliMethod = pliMethods?.find(pliMethod => pliMethod.id === pli_method)

  useEffect(() => {
    if (economicAnalysisTransactionResponse?.[0]) {
      const pliPresent = !!economicAnalysisTransactionResponse[0].pli_method
      const testingMethodologyPresent = !!economicAnalysisTransactionResponse[0].testing_methodology
      const testedPartyPresent =
        typeof economicAnalysisTransactionResponse[0].is_primary_entity_tested_party == 'boolean'

      const Labels = ['PLI', 'Testing Methodology', 'Tested Party']

      if (!testedPartyPresent || !testingMethodology || !pliPresent) {
        const message = `Please select and save ${[pliPresent, testingMethodologyPresent, testedPartyPresent]
          .map((val, index) => (val ? Labels[index] : null))
          .filter(val => !!val)
          .join(', ')
          .replace(/, ([^,]*)$/, ' and $1')} before adding financial data`

        toast.warning(message)
        onTabChange(0)
      }
    }
  }, [economicAnalysisTransactionResponse, onTabChange, testingMethodology])

  useEffect(() => {
    economicAnalysisTransactionRefetch()
  }, [economicAnalysisTransactionRefetch])

  const handleLEClick = useCallback(
    (id: number) => {
      router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${id}`)
    },
    [router]
  )

  const financialEntityColumns = useMemo(() => {
    return getFinancialEntityTableColumns({
      testingMethodology: testingMethodology || undefined,
      setSelectedEntity,
      year: Number(year || 0),
      handleLEClick,
      isReadOnly: false,
    })
  }, [handleLEClick, testingMethodology, year])

  const tabledata: FinancialDataEntityTableData[] = useMemo(() => {
    if (!testingMethodology || !pli_method) return []
    if (is_primary_entity_tested_party) {
      return [
        {
          entity: transactionResponse.primary_entity,
          financial_data_appendix: economicAnalysisTransactionResponse?.[0]?.financial_data_appendices.find(
            val => val.tested_party == transactionResponse.primary_entity.id
          )?.tested_party_financial_data_appendix,
          tested_party_currency:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id]?.[0]
              .tested_party_currency || '',
          gaap_type:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id]?.[0]
              .gaap_type || '',

          policy_rate: economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
            return struc.tested_party == transactionResponse.primary_entity.id || ''
          })?.policy_rate,
          testing_policy_description:
            economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
              return struc.tested_party == transactionResponse.primary_entity.id
            })?.testing_policy_description || '',
          financial_structure:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[transactionResponse.primary_entity.id],
          balance_sheets:
            economicAnalysisTransactionResponse?.[0]?.balance_sheets?.[transactionResponse.primary_entity.id],
          balance_sheet_averages:
            economicAnalysisTransactionResponse?.[0]?.balance_sheet_averages?.[transactionResponse.primary_entity.id] ||
            [],
          averagePli: economicAnalysisTransactionResponse?.[0]?.pli_percentage?.[transactionResponse.primary_entity.id],
        },
      ]
    } else {
      return transactionResponse.individual_transactions.map(txn => {
        return {
          entity: txn.associated_entity,
          financial_data_appendix: economicAnalysisTransactionResponse?.[0]?.financial_data_appendices.find(
            val => val.tested_party == txn.associated_entity.id
          )?.tested_party_financial_data_appendix,
          tested_party_currency:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id]?.[0]
              .tested_party_currency || '',
          gaap_type:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id]?.[0].gaap_type ||
            '',
          policy_rate: economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
            return struc.tested_party == txn.associated_entity.id
          })?.policy_rate,
          testing_policy_description:
            economicAnalysisTransactionResponse?.[0]?.policy_testing?.find(struc => {
              return struc.tested_party == txn.associated_entity.id
            })?.testing_policy_description || '',
          financial_structure:
            economicAnalysisTransactionResponse?.[0]?.financial_structure?.[txn.associated_entity.id],
          balance_sheets: economicAnalysisTransactionResponse?.[0]?.balance_sheets?.[txn.associated_entity.id],
          balance_sheet_averages:
            economicAnalysisTransactionResponse?.[0]?.balance_sheet_averages?.[txn.associated_entity.id] || [],
          averagePli: economicAnalysisTransactionResponse?.[0]?.pli_percentage?.[txn.associated_entity.id],
        }
      })
    }
  }, [
    economicAnalysisTransactionResponse,
    is_primary_entity_tested_party,
    pli_method,
    testingMethodology,
    transactionResponse.individual_transactions,
    transactionResponse.primary_entity,
  ])

  const isLoading = economicAnalysisTransactionFetching || transactionByIdLoading
  return (
    <div className="flex flex-col h-full w-full gap-4">
      {isLoading ? (
        <Loading className="w-full h-[800px] shrink-0 flex items-center justify-center" />
      ) : (
        <>
          <BorderlessBox variant="grey">
            <NewTable
              data={tabledata}
              noEntryText={'Please select PLI and Testing Methodology'}
              columns={financialEntityColumns}
              tableHeadClassName="whitespace-nowrap"
            />
          </BorderlessBox>
          {!!selectedEntity && (
            <AddFinancialDataModal
              key={selectedEntity.entity.id}
              testing_methodology={testingMethodology == 'testing_a_policy' ? 'testing_a_policy' : 'testing_p_and_l'}
              economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
              pliMethod={pliMethod}
              selectedEntityData={selectedEntity}
              isOpen={!!selectedEntity}
              onClose={() => setSelectedEntity(undefined)}
              economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
            />
          )}
        </>
      )}
    </div>
  )
}

interface FinancialDataProps {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse: economic_analysis[]
  economicAnalysisTransactionRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
  onTabChange: (idx: number) => void
}

export default FinancialDataComponent
