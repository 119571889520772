import { requiredDropdownOptionSchema } from 'schemas/schemas'
import { getCharacterLimitArgs } from 'schemas/schemas.helpers'
import * as yup from 'yup'

import {
  ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS,
  CHARACTER_LIMITS,
} from './AddComparableControlledTransaction.constants'

export const schema = yup.object({
  [ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.TRANSACTIONS]: requiredDropdownOptionSchema,
  [ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.VALUE_VOLUME_TABLE]: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .matches(/^\d*\.?\d*$/, 'Please enter a valid number')
          .max(
            ...getCharacterLimitArgs(CHARACTER_LIMITS[ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.VALUE], 'Value')
          )
          .nullable()
          .transform((currentValue, originalValue) => (originalValue === '' ? null : currentValue))
          .test('value-or-volume', 'Value is required if Volume is provided', function (value) {
            const { volume } = this.parent
            return !volume || (value !== null && value !== undefined)
          }),
        volume: yup
          .string()
          .nullable()
          .max(
            ...getCharacterLimitArgs(CHARACTER_LIMITS[ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.VOLUME], 'Volume')
          )
          .test('volume-or-value', 'Volume is required if Value is provided', function (volume) {
            const { value } = this.parent
            return !value || (volume !== null && volume !== '')
          }),
      })
    )
    .test('at-least-one-value', 'At least one value and one volume must be provided', function (values) {
      const hasValue = values && values.some(item => item.value !== null && item.value !== undefined)
      const hasVolume = values && values.some(item => item.volume !== null && item.volume !== '')
      return hasValue && hasVolume
    }),
  [ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.COMPARABILITY_FACTORS]: yup.array().of(
    yup.object().shape({
      value: yup.string().required('This is required'),
    })
  ),
})
