import { postAiQuery } from 'api/tinyMceAssistant'

export const ai_requests = (request: AI_REQUEST, respondWith: AI_RESPONDWITH) => {
  respondWith.stream(async (signal, streamMessage) => {
    const response = await postAiQuery(request, signal)
    const stream = response.body
    if (stream) {
      const reader = stream.getReader()
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          return
        }

        const chunk = new TextDecoder('utf-8').decode(value)
        streamMessage(chunk)
      }
    } else {
      throw Error('Invalid response')
    }
  })
}

export type AI_REQUEST = {
  query: string
  context: string
  prompt: string
  system: string[]
}

export type AI_RESPONDWITH = {
  string: (callback: (signal: AbortSignal) => Promise<string>) => void
  stream: (callback: (signal: AbortSignal, streamMessage: (message: string) => void) => Promise<void>) => void
}
