import { User } from 'api/login/login.types'
import { ROUTES } from 'constants/routes'
import { ParsedUrlQueryInput } from 'querystring'
import { ECONOMIC_ANALYSIS_TAB_TYPES } from 'views/transactions/createTransaction/organisms/economicAnalysis/EconomicAnalysis'
import { FAR_TAB_TYPES } from 'views/transactions/createTransaction/organisms/functionalAnalysis/FunctionalAnalysis'

import { StepIds, StepTypes, TourHighlightIds } from './types'

export const getTourSteps = (
  product_tour_details?: User['product_tour_details']
): ({ id: StepIds; pathName: (typeof ROUTES)[keyof typeof ROUTES]; queries?: ParsedUrlQueryInput } & (
  | {
      type: StepTypes.Modal
    }
  | {
      type: StepTypes.Tooltip

      highlightBox: TourHighlightIds
      tooltipData: {
        offset?: {
          top: number | `${number}%`
          left: number | `${number}%`
        }
        text: string
      }
    }
))[] => {
  const {
    organization = '14',
    year = '2023',
    legal_entity = '109',
    transaction = '1011',
    benchmarking = '273',
    local_file_document = '293',
  } = JSON.parse(product_tour_details || '{}')
  return [
    {
      id: StepIds.Start,
      pathName: ROUTES.HOME,
      type: StepTypes.Modal,
    },
    {
      id: StepIds.Organization,
      pathName: ROUTES.ORGANIZATION,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.Organization,
      tooltipData: {
        text: 'Organization Section provides information about the group, its structure, industry analysis, and related elements at the group level, including the Master File.',
      },
    },
    {
      id: StepIds.MarketAnalysis,
      pathName: ROUTES.ORGANIZATION,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.MarketAnalysis,
      tooltipData: {
        text: 'Market Analysis section provides details like business overview, product and service information, industry analysis, global strategies, and functional analysis.',
      },
    },
    {
      id: StepIds.MasterFile,
      pathName: ROUTES.ORGANIZATION,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.MasterFile,
      tooltipData: {
        text: 'You can structure the Master File with section headers as per client specifications, ensuring version control and backup for reviews.',
      },
    },
    {
      id: StepIds.LEActions,
      pathName: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.LEActions,
      tooltipData: {
        text: 'Add legal entities and its management structure, core activities, and Annual Reports under one header, with easy import of entities from specific years.',
      },
    },
    {
      id: StepIds.LECreate,
      pathName: ROUTES.CREATE_LEGAL_ENTITY,
      queries: {
        orgId: organization,
        year,
        entityId: legal_entity,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.LECreate,
      tooltipData: {
        text: 'Upload local entity specific data into relevant sections for Local Report and Forms publication.',
      },
    },
    {
      id: StepIds.LEList,
      pathName: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.LEList,
      tooltipData: {
        text: 'View all legal entities, their statutory due dates, local report progress, and if any, dormant entities within the Group.',
      },
    },
    {
      id: StepIds.Txns,
      pathName: ROUTES.TRANSACTION_MANAGEMENT,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNS,
      tooltipData: {
        text: 'Add/upload transactions with volume and copy/mirror existing transactions for easy export/mirror of relevant information.',
      },
    },
    {
      id: StepIds.TxnsBasicInfo,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'basicInfo',
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Create/update transaction details under the Basic Info section. Assign the template for relevant transaction sections and indicate Y/N for FAR inclusion in reports.',
      },
    },
    {
      id: StepIds.TxnsList,
      pathName: ROUTES.TRANSACTION_MANAGEMENT,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNSList,
      tooltipData: {
        text: 'View the transaction list, aligned entities and counterparties, total volume (Local Currency/USD), and TP method for each transaction in the overview section.',
      },
    },
    {
      id: StepIds.ICFlow,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'intercompanyAgreement',
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Upload the client-specific transaction chart (if any) and relevant agreements, with an overview in the agreement analysis section.',
      },
    },
    {
      id: StepIds.FAR,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'functionalAnalysis',
        farTab: FAR_TAB_TYPES.function,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Add transactional functional analysis in relevant FAR sections by selecting default suggested options or creating new headers with relevant descriptions.',
      },
    },
    {
      id: StepIds.FARConclusion,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'functionalAnalysis',
        farTab: FAR_TAB_TYPES.conclusion,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Provide a conclusion on the analysis of the functions performed, assets employed, and risks assumed by identifying the least complex entity and risk bearer for the selected transaction.',
      },
    },
    {
      id: StepIds.EconomicBasicInfo,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'economicAnalysis',
        economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.basicInfo,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Align the transaction to the Economic Analysis section by selecting the TP method, tested entity(ies), PLI, and testing methodology (P&L/Policy).',
      },
    },
    {
      id: StepIds.MethodSelection,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'economicAnalysis',
        economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.methodSelection,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Provide reasoning for unselected TP methods and rationale for choosing the specific method based on the TP method selection.',
      },
    },
    {
      id: StepIds.FinancialData,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'economicAnalysis',
        economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.financialData,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Align the tested entity based on the PLI and testing methodology (P&L/Policy) selection, and add relevant financial metrics for transaction testing.',
      },
    },
    {
      id: StepIds.BenchmarkingData,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'economicAnalysis',
        economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.benchmarkingAnalysis,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: 'Align the uploaded Benchmark(s) and assign the tested entity(ies) for the transaction being tested.',
      },
    },
    {
      id: StepIds.EconomicConclusion,
      pathName: ROUTES.CREATE_TRANSACTION,
      queries: {
        orgId: organization,
        year,
        transactionId: transaction,
        activeTab: 'economicAnalysis',
        economicAnalysisTab: ECONOMIC_ANALYSIS_TAB_TYPES.conclusion,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.TXNBasicInfo,
      tooltipData: {
        text: "The conclusion summarizes the TP method, comparability analysis, financial metrics, and arm's length compliance, with justification and regulatory confirmation.",
      },
    },
    {
      id: StepIds.Reports,
      pathName: ROUTES.LEGAL_ENTITY_DETAILS,
      queries: {
        orgId: organization,
        year,
        id: legal_entity,
        local_file_document,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.Layout,
      tooltipData: {
        text: 'Generate the local report by selecting the template, reviewing the checklist, and ensuring version control and backup for five review versions.',
      },
    },
    {
      id: StepIds.ReportsReview,
      pathName: ROUTES.LEGAL_ENTITY_DETAILS,
      queries: {
        orgId: organization,
        year,
        id: legal_entity,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.Reports,
      tooltipData: {
        text: 'User can resume and review the report content before exporting the local file as a PDF and can compare the final report version with previous backups to review system changes.',
      },
    },
    {
      id: StepIds.Benchmarks,
      pathName: ROUTES.BENCHMARK_MANAGEMENT,
      queries: {
        orgId: organization,
        year,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.Benchmarks,
      tooltipData: {
        text: 'View the Benchmark list, aligned regions, and relevant interquartile range and full range as a dashboard.',
      },
    },
    {
      id: StepIds.AddBenchmarks,
      pathName: ROUTES.BENCHMARK_DETAILS,
      queries: {
        orgId: organization,
        year,
        id: benchmarking,
      },
      type: StepTypes.Tooltip,
      highlightBox: TourHighlightIds.AddBenchmarks,
      tooltipData: {
        text: 'Create new Benchmark by aligning with the region and years, and upload the final Benchmark appendices to include in the report.',
      },
    },
    {
      id: StepIds.Done,
      pathName: ROUTES.HOME,
      type: StepTypes.Modal,
    },
  ]
  // .filter(step => {
  //   if (!product_tour_details && step.type == StepTypes.Tooltip) {
  //     return false
  //   }
  //   return true
  // }) as ({ id: StepIds; pathName: (typeof ROUTES)[keyof typeof ROUTES]; queries?: ParsedUrlQueryInput } & (
  //   | {
  //       type: StepTypes.Modal
  //     }
  //   | {
  //       type: StepTypes.Tooltip

  //       highlightBox: TourHighlightIds
  //       tooltipData: {
  //         offset?: {
  //           top: number | `${number}%`
  //           left: number | `${number}%`
  //         }
  //         text: string
  //       }
  //     }
  // ))[]
}
