import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'

import { getDataByYear, mappedYearsFinancialStructureData } from '../helpers/financialData.helpers'
import { FinancialDataTableData } from '../types/financialData.types'

export const balanceSheetConfig = ({
  balanceSheet,
  year,
  pliMethod,
  show4Years,
}: balanceSheetConfigProps): FinancialDataTableData[] => {
  const dataByYear = getDataByYear(balanceSheet || [])

  if (!pliMethod) return []

  const getLast4Years = (year: number) => {
    return [year - 3, year - 2, year - 1, year]
  }

  const [yearCurrentMinus3, yearCurrentMinus2, yearCurrentMinus1, yearCurrent] = getLast4Years(year).map(year =>
    mappedYearsFinancialStructureData(dataByYear, year)
  )

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    required: boolean,
    formula?: string,
    ...values: (number | null | string | undefined)[]
  ): FinancialDataTableData => {
    const data = {
      name,
      [getLast4Years(year)[1]?.toString()]: String(values?.[1] ?? ''),
      [getLast4Years(year)[2]?.toString()]: String(values?.[2] ?? ''),
      [getLast4Years(year)[3]?.toString()]: String(values?.[3] ?? ''),
      isEditable: !is_calculated,
      formula,
      isEditAllowed: true,
      key,
      required: required && !is_calculated,
    }
    if (show4Years) {
      data[getLast4Years(year)[0]?.toString()] = String(values?.[0] ?? '')
    }
    return data
  }

  const tableData: FinancialDataTableData[] = [
    ...pliMethod.required_fields.map(field => {
      return {
        ...field,
        _requiredField: true,
      }
    }),
    ...pliMethod.optional_fields.map(field => {
      return {
        ...field,
        _requiredField: false,
      }
    }),
  ]
    .filter(field => field.type == 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        field._requiredField,
        pliMethod.formulae[field.name],
        yearCurrentMinus3?.[field.name],
        yearCurrentMinus2?.[field.name],
        yearCurrentMinus1?.[field.name],
        yearCurrent?.[field.name]
      )
    })

  return tableData
}

export const balanceSheetAvgConfig = ({
  balanceSheetAverages,
  year,
  pliMethod,
}: balanceSheetAvgConfigProps): FinancialDataTableData[] => {
  if (!balanceSheetAverages?.length) return []
  const dataByYear = getDataByYear(balanceSheetAverages)
  if (!pliMethod) return []
  const getLast3Years = (year: number) => {
    return [year - 2, year - 1, year]
  }
  const [yearCurrentMinus3, yearCurrentMinus2, yearCurrentMinus1, yearCurrent] = getLast3Years(year).map(year =>
    mappedYearsFinancialStructureData(dataByYear, year)
  )

  const createFinancialDataEntry = (
    name: string,
    key: string,
    is_calculated: boolean,
    formula?: string,
    ...values: (number | null | string | undefined)[]
  ): FinancialDataTableData => {
    const data = {
      name,
      [getLast3Years(year)[0]?.toString()]: String(values?.[0] ?? ''),
      [getLast3Years(year)[1]?.toString()]: String(values?.[1] ?? ''),
      [getLast3Years(year)[2]?.toString()]: String(values?.[2] ?? ''),
      isEditable: !is_calculated,
      formula,
      isEditAllowed: false,
      key,
    }
    return data
  }

  const tableData: FinancialDataTableData[] = [...pliMethod.required_fields, ...pliMethod.optional_fields]
    .filter(field => field.type == 'Balance Sheet')
    .sort((a, b) => a.order - b.order)
    .map(field => {
      return createFinancialDataEntry(
        field.display_name,
        field.name,
        field.is_calculated,
        pliMethod.formulae[field.name],
        yearCurrentMinus3?.[field.name],
        yearCurrentMinus2?.[field.name],
        yearCurrentMinus1?.[field.name],
        yearCurrent?.[field.name]
      )
    })

  return tableData
}

interface balanceSheetConfigProps {
  balanceSheet?: {
    [key: string]: number | null | string
  }[]
  year: number
  pliMethod: TransactionPliMethod | undefined
  show4Years: boolean
}

interface balanceSheetAvgConfigProps {
  balanceSheetAverages?: { [key: string]: number | null | string }[]
  year: number
  pliMethod: TransactionPliMethod | undefined
}
