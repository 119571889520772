import cx from 'classnames'
import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import _isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import Select from 'react-select'
import { SelectOptions } from 'types/common.types'

import TxnSelectAndReorderMenu from './Components/TxnSelectAndReorderMenu'
import TxnSelectAndReorderMultiValueContainer from './Components/TxnSelectAndReorderMultiValueContainer'
import TxnSelectAndReorderOption from './Components/TxnSelectAndReorderOption'
import TxnSelectAndReorderValueContainer from './Components/TxnSelectAndReorderValueContainer'
import { selectStyle } from './styles'

type TxnSelectAndReorderContextType = {
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  menuOpen?: boolean
}

export const TxnSelectAndReorderContext = React.createContext<TxnSelectAndReorderContextType>({})

function TxnSelectAndReorder<T extends FieldValues>({
  control,
  options,
  setSelectedOptions,
  id,
  label,
  defaultValue,
  disabled,
  required,
  className,
  height,
  placeholder = 'Select the options',
  isCustomFilter = false,
  menuPortalTarget,
  menuPlacement = 'bottom',
}: TxnSelectAndReorderProps<T>) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { formSelectedTransactions, selectedTransactions, setSelectedTransactions, checklistData } =
    useReportWizardContext()

  useEffect(() => {
    const isSame =
      formSelectedTransactions?.map(opt => opt.value).join('-') == checklistData?.transactions_order?.join('-')
    if (!menuOpen && !isSame) {
      setSelectedTransactions(formSelectedTransactions?.map(opt => Number(opt.value)))
    }
  }, [
    checklistData?.transactions_order,
    formSelectedTransactions,
    menuOpen,
    selectedTransactions,
    setSelectedTransactions,
  ])

  const customFilter = useCallback(({ data }: { data: SelectOptions }, input: string) => {
    if (input) {
      if (data.filtertext) {
        if (data.filtertext.toLowerCase().includes(input.toLowerCase())) return true
      }
      return false
    }
    return true // if not search, then all match
  }, [])

  const filter = useMemo(() => {
    return isCustomFilter ? customFilter : undefined
  }, [customFilter, isCustomFilter])

  const menuRef = useRef<HTMLDivElement>(null)

  //create a function in your component to handleOutsideClicks
  const handleOutsideClicks = useCallback(
    (event: MouseEvent) => {
      if (event.target && menuOpen && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false)
      }
    },
    [menuOpen]
  )

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleOutsideClicks)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClicks)
    }
  }, [handleOutsideClicks])

  return (
    <Controller
      name={id}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { name, onBlur, onChange, ref, value } = field

        const newValue = value?.map((opt: SelectOptions) => options?.find(option => option.value == opt.value) || opt)
        return (
          <div className={cx('w-full', className)}>
            {label && (
              <Typography
                variant={Variant.Callout}
                type="semibold"
                className="mb-[0.125rem] text-gray-300 flex items-center">
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <TxnSelectAndReorderContext.Provider value={{ setMenuOpen, menuOpen }}>
              <div className="w-full" ref={menuRef}>
                <Select
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  filterOption={filter}
                  placeholder={placeholder}
                  name={name}
                  ref={ref}
                  onBlur={onBlur}
                  isDisabled={disabled}
                  defaultValue={defaultValue}
                  menuShouldScrollIntoView
                  isMulti
                  menuIsOpen={menuOpen}
                  menuPlacement={menuPlacement}
                  menuPortalTarget={menuPortalTarget}
                  value={newValue}
                  styles={selectStyle({ height, isError: !!(error && !_isEmpty(error)), disableDropdown: !!disabled })}
                  onChange={options => {
                    if (Array.isArray(options) && setSelectedOptions) {
                      setSelectedOptions(options)
                    }
                    onChange([...options])
                  }}
                  options={options}
                  components={{
                    Option: TxnSelectAndReorderOption,
                    Menu: TxnSelectAndReorderMenu,
                    ValueContainer: TxnSelectAndReorderValueContainer,
                    MultiValueContainer: TxnSelectAndReorderMultiValueContainer,
                    IndicatorsContainer: () => null,
                  }}
                  onMenuOpen={() => setMenuOpen(true)}
                  classNames={{
                    menuList: () => 'w-full',
                    control() {
                      return classNames('!bg-[#100F2E] border-gray-800 rounded-lg')
                    },
                    input() {
                      return 'text-white text-callout'
                    },
                  }}
                />
                {error && !_isEmpty(error) && (
                  <Typography variant={Variant.Callout} className="pt-[0.125rem] text-red500 ">
                    {error.message}
                  </Typography>
                )}
              </div>
            </TxnSelectAndReorderContext.Provider>
          </div>
        )
      }}
    />
  )
}

interface TxnSelectAndReorderProps<T extends FieldValues> {
  control: Control<T>
  label?: string
  id: Path<T>
  placeholder?: string
  options?: SelectOptions[]
  className?: string
  required?: boolean
  disabled?: boolean
  setSelectedOptions?: React.Dispatch<React.SetStateAction<SelectOptions[]>>
  defaultValue?: SelectOptions[]
  height?: string
  isCustomFilter?: boolean
  menuPortalTarget?: HTMLElement | null | undefined
  menuPlacement?: 'auto' | 'bottom' | 'top'
}

export default TxnSelectAndReorder
