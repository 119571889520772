import { IconsType } from 'assets/types'
import { HTMLInputTypeAttribute } from 'react'

export enum InputVariant {
  Default = 'Default',
  Success = 'Success',
  Error = 'Error',
  Disabled = 'Disabled',
}

export interface InputVariantConfig {
  background: string
  border: string
}

export enum IconPlacement {
  Left = 'Left',
  Right = 'Right',
}

export interface InputProps {
  iconPlacement?: IconPlacement
  newInput?: boolean
  variant?: InputVariant
  icon?: IconsType
  descriptiveText?: string
  labelClassName?: string
  label: string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  name?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onIconClickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void
  error?: boolean
  isAnimatedLabel?: boolean
  required?: boolean
  defaultValue?: string
  iconClassName?: string
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  value?: string
  characterLimit?: number
  autoComplete?: 'off'
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  inputClassName?: string
  inputBoxClassName?: string
}
