import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'

export const attributeColumns = ({
  handleModalOpen,
  isSourceAttribute,
  setIsSource,
}: attributeColumnsProps): ColumnDef<FunctionalAttributes>[] => {
  return [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      size: '100%' as unknown as number,
      cell: props => {
        return (
          <Tooltip
            title={props.row.original.name}
            followCursor
            TransitionProps={{
              timeout: 50,
            }}
            placement="top">
            <span className="block">
              <Typography
                className="overflow-hidden text-ellipsis text-start"
                variant={Variant.Callout}
                type="semibold">
                {props.row.original.name}
              </Typography>
            </span>
          </Tooltip>
        )
      },
    },
    {
      header: '',
      id: 'actions',
      accessorKey: 'id',
      cell: props => {
        const handleClick = () => {
          handleModalOpen(props.row.original)
          setIsSource && setIsSource(isSourceAttribute || false)
        }
        return (
          <div className="flex justify-end w-full">
            <Button onClick={handleClick} variant={ButtonVariant.Tertiary} className="text-blue-900 ">
              Add
            </Button>
          </div>
        )
      },
    },
  ]
}

interface attributeColumnsProps {
  handleModalOpen: (attribute: FunctionalAttributes) => void
  isSourceAttribute?: boolean
  setIsSource?: React.Dispatch<React.SetStateAction<boolean>>
}
