import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type PortalProps = {
  children: React.ReactNode
  portalId: string
}

function Portal({ children, portalId }: PortalProps) {
  const [portalNode, setPortalNode] = useState(document.getElementById(portalId))

  useEffect(() => {
    let domNode = document.getElementById(portalId)
    let interval: NodeJS.Timer
    if (domNode) {
      setPortalNode(domNode)
    } else {
      interval = setInterval(() => {
        domNode = document.getElementById(portalId)
        if (domNode) {
          setPortalNode(domNode)
          clearInterval(interval)
        }
      }, 100)
    }
    return () => {
      interval && clearInterval(interval)
    }
  }, [portalId])

  return portalNode ? createPortal(children, portalNode) : null
}

export default Portal
