import { UseQueryResult } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { TabData } from 'components/tabs/Tabs'

import BasicInfo from '../components/basicInfo'
// import BasicInfo from '../components/basicInfo'
import BenchmarkingAnalysis from '../components/benchMarkAnalysis'
import ComparabilityFactors from '../components/ComparabilityFactors/ComparabilityFactors'
import Conclusion from '../components/conclusion'
import ControlledTransactionsData from '../components/controlledTransactionsData/ControlledTransactionsData'
import FinancialData from '../components/financialData'
import RejectionMatrix from '../components/rejectionMatrix/RejectionMatrix'
import UncontrolledTransactionsData from '../components/uncontrolledTransactionsData/UncontrolledTransactionsData'

export const getTabsData = ({
  transactionResponse,
  transactionByIdRefetch,
  economicAnalysisTransactionFetching,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionResponse,
  transactionByIdLoading,
  onTabChange,
}: FunctionalAnalysisProps): TabData[] => {
  return [
    {
      label: 'Basic Information',
      content: (
        <BasicInfo
          transactionResponse={transactionResponse}
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          transactionByIdRefetch={transactionByIdRefetch}
        />
      ),
    },
    {
      label: 'Method Selection',
      content: (
        <RejectionMatrix
          transactionResponse={transactionResponse}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
        />
      ),
      hide: !economicAnalysisTransactionResponse?.[0]?.transfer_pricing_method,
    },
    {
      label: 'Financial Data', //tnmm
      content: (
        <FinancialData
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          transactionByIdLoading={transactionByIdLoading}
          onTabChange={onTabChange}
        />
      ),
      disabled: economicAnalysisTransactionResponse?.length <= 0,
      hide: !economicAnalysisTransactionResponse?.[0]?.required_sections.includes('financial_data'),
    },
    {
      label: 'Comparability Factors',
      content: (
        <ComparabilityFactors
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          transactionByIdRefetch={transactionByIdRefetch}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          transactionByIdLoading={transactionByIdLoading}
        />
      ),
      disabled: economicAnalysisTransactionResponse?.length <= 0,
      hide: !economicAnalysisTransactionResponse?.[0]?.required_sections.includes('comparability_factors'),
    },
    {
      label: 'Benchmarking Analysis',
      content: (
        <BenchmarkingAnalysis
          transactionByIdLoading={transactionByIdLoading}
          transactionByIdRefetch={transactionByIdRefetch}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          onTabChange={onTabChange}
        />
      ),
      disabled: economicAnalysisTransactionResponse?.length <= 0,
      hide: !economicAnalysisTransactionResponse?.[0]?.required_sections.includes('benchmarking'),
    },
    {
      label: 'Controlled Transaction Data',
      content: (
        <ControlledTransactionsData
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          transactionByIdRefetch={transactionByIdRefetch}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          transactionByIdLoading={transactionByIdLoading}
        />
      ),
      disabled: economicAnalysisTransactionResponse?.length <= 0,
      hide: !economicAnalysisTransactionResponse?.[0]?.required_sections.includes('comparability_factors'),
    },
    {
      label: 'Uncontrolled Transaction Data',
      content: (
        <UncontrolledTransactionsData
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse?.[0]}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
          transactionByIdRefetch={transactionByIdRefetch}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          transactionByIdLoading={transactionByIdLoading}
        />
      ),
      disabled: economicAnalysisTransactionResponse?.length <= 0,
      hide: !economicAnalysisTransactionResponse?.[0]?.required_sections.includes('comparability_factors'),
    },
    {
      label: 'Conclusion',
      content: (
        <Conclusion
          economicAnalysisTransactionResponse={economicAnalysisTransactionResponse}
          transactionResponse={transactionResponse}
          economicAnalysisTransactionFetching={economicAnalysisTransactionFetching}
          economicAnalysisTransactionRefetch={economicAnalysisTransactionRefetch}
        />
      ),
      hide: economicAnalysisTransactionResponse?.length <= 0,
    },
  ]
}

interface FunctionalAnalysisProps {
  transactionResponse?: TransactionByIdResponse
  transactionByIdRefetch: () => void
  economicAnalysisTransactionFetching: boolean
  transactionByIdLoading: boolean
  economicAnalysisTransactionRefetch: UseQueryResult['refetch']
  economicAnalysisTransactionResponse: economic_analysis[]
  onTabChange: (idx: number) => void
}
