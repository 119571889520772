import axios from 'api'
import { AxiosRequestConfig } from 'axios'

import { VariablesType } from './bulkDocument.type'
import {
  AllowedTemplatesResponse,
  ChecklistDiffType,
  ChecklistResponse,
  DeleteSelectedDocumentResponse,
  DMSDocumentResponse,
  DocumentFileResponse,
  DocumentResponse,
  UpdateSelectedDocumentPayloadType,
} from './types'

// Local file model functions exposed here
export { deleteLocalFile, updateLocalFile } from './localFiles'

export const getAllDocumentsList = (isArchieved: boolean, year?: number) =>
  axios
    .get<DMSDocumentResponse[]>(`/api/v1/org/{organization}/dms/documents/?is_archived=${isArchieved}`, {
      params: { year },
    })
    .then(res => res.data)

export const getAllowedTemplates = () =>
  axios
    .get<AllowedTemplatesResponse>(
      `/api/v1/org/{organization}/reports/local_file_template_config/get_allowed_templates/`
    )
    .then(res => res.data)

export const generateLocalFile = ({
  entityId,
  localFileIndex,
  transactions,
  template,
}: {
  entityId: number | null
  localFileIndex?: number
  transactions?: number[]
  template?: string
}) => {
  const urlParams = new URLSearchParams()
  if (entityId) urlParams.append('legal_entity', entityId.toString())
  if (localFileIndex) urlParams.append('local_file', localFileIndex.toString())
  if (template) urlParams.append('template_name', template)
  if (transactions) {
    transactions.forEach(transactionId => {
      urlParams.append('transactions', transactionId.toString())
    })
  }
  return axios
    .get<DocumentResponse>('/api/v1/org/{organization}/reports/local_file_document/download_local_file/', {
      params: urlParams,
      timeout: 10 * 60 * 1000,
    })
    .then(res => res.data)
}

export const download_local_file = ({ reportId }: { reportId: number }) => {
  return axios
    .get<DocumentResponse>(`/api/v1/org/{organization}/reports/local_file_document/${reportId}/download_local_file/`, {
      timeout: 10 * 60 * 1000,
    })
    .then(res => res.data)
}

export const getChecklist = ({
  entityId,
  localFileIndex,
  transactions,
  template,
  skip_fields,
}: {
  entityId?: number
  localFileIndex?: number
  transactions?: number[]
  template?: string
  skip_fields?: string
}) => {
  const urlParams = new URLSearchParams()
  if (entityId) urlParams.append('legal_entity', entityId.toString())
  if (localFileIndex) urlParams.append('local_file', localFileIndex.toString())
  if (template) urlParams.append('template_name', template)
  if (skip_fields) urlParams.append('skip_fields', skip_fields)
  if (transactions) {
    transactions.forEach(transactionId => {
      urlParams.append('transactions', transactionId.toString())
    })
  }
  return axios
    .get<ChecklistResponse>('/api/v1/org/{organization}/reports/local_file_document/checklist/', {
      params: urlParams,
    })
    .then(res => res.data)
}

export const getChecklistDiff = ({ reportId }: { reportId: number }) => {
  return axios
    .get<ChecklistDiffType>(`/api/v1/org/{organization}/reports/local_file_document/${reportId}/checklist_diff/`)
    .then(res => res.data)
}

export const updateSelectedDocumentHTML = async (variables: {
  legal_entity: number
  html_file: File
  local_file: number
  export_to_pdf: boolean
  conversation_event_log: unknown[]
}) => {
  const { html_file, ...data } = variables
  const formData = new FormData()

  if (html_file) {
    formData.append('html_file', html_file)
  }
  formData.append('conversation_event_log', JSON.stringify(data.conversation_event_log))
  formData.append('export_to_pdf', String(data.export_to_pdf))
  formData.append('legal_entity', data.legal_entity.toString())

  const axiosResponse = (
    await axios.patch<DocumentResponse>(
      `/api/v1/org/{organization}/reports/local_file_document/${data.local_file}/upload_html_local_file/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  ).data
  return axiosResponse
}

export const getSelectedDocumentFile = ({ id }: { id?: number }) => {
  return axios
    .get<DocumentFileResponse>(`/api/v1/org/{organization}/dms/documents/${id}/get_document/`)
    .then(res => res.data)
}

export async function uploadBulkDocument(variables: VariablesType) {
  const { files, data } = variables
  const formData = new FormData()

  if (files) {
    files.forEach((file: File) => {
      formData.append('files', file)
    })
  }
  formData.append('data', JSON.stringify(data))

  const axiosResponse = await axios.post('/api/v1/org/{organization}/dms/documents/bulk_create/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return axiosResponse
}

export const getSelectedDocumentDeleted = ({ documentId }: { documentId: number }) => {
  return axios
    .delete<DeleteSelectedDocumentResponse>(`/api/v1/org/{organization}/dms/documents/${documentId}/`)
    .then(res => res.data)
}

export const updateSelectedDocument = ({ data }: UpdateSelectedDocumentPayloadType) => {
  return axios
    .patch(`/api/v1/org/{organization}/dms/documents/${data.document_id}/`, {
      document_owner: data.document_owner,
      integral_generated: data.integral_generated,
      is_archived: data.isArchived,
      name: data.name,
      status: data.status,
      type: data.type,
      year: data.year,
      legal_entity_id: data.legal_entity_id,
    })
    .then(res => res.data)
}

export const setTaxRulingAppendices = async ({
  leId,
  payload,
}: {
  leId: number
  payload: Record<string, File | string | null | undefined>
}) => {
  const apiOptions: AxiosRequestConfig = { headers: {} }

  const hasFiles = Object.values(payload).some(value => value instanceof File)
  if (hasFiles) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  const res = await axios.patch(
    `/api/v1/org/{organization}/legal_entity/legal_entity/${leId}/create_tax_ruling/`,
    payload,
    apiOptions
  )
  return res.data
}
