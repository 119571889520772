import { IconsType } from 'assets/types'
import { ReactNode } from 'react'

import DefaultTab from './DefaultTab'
import PillTab from './PillTab'

function Tabs({ tabType = 'default', ...props }: TabsProps) {
  return tabType == 'default' ? <DefaultTab {...props} /> : <PillTab {...props} />
}

export interface TabData {
  label: string
  content: NonNullable<ReactNode>
  disabled?: boolean
  inactiveClassname?: string
  activeClassname?: string
  activeBGColor?: string
  icon?: IconsType
  iconName?: string
  hide?: boolean
}

interface TabsProps {
  className?: string
  containerClassName?: string
  tabsData: TabData[]
  initialActiveTabIndex?: number
  handleActiveIndex?: boolean
  forcedActiveIndex?: number
  onTabChange?: (idx: number, label: string) => void
  rightTabHeaderComponent?: React.ReactNode
  tabType?: 'pill' | 'default'
}

export default Tabs
