import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useEffect, useMemo, useState } from 'react'

import { accordianSummary, useStyles } from './styles'

const AccordianTableWrapper = ({
  children,
  title,
  className,
  icon,
  actionsContent,
  leftTitleContent,
  isActive = false,
}: Props): JSX.Element => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (isActive) {
      setExpanded(newExpanded ? panel : false)
    } else {
      setExpanded(false)
    }
  }

  useEffect(() => {
    setExpanded(isActive ? 'panel1' : false)
  }, [isActive])

  const toggleIcon = useMemo(() => {
    return expanded === 'panel1' ? (
      <IconButton>
        <KeyboardArrowUpIcon />
      </IconButton>
    ) : (
      <IconButton>
        <KeyboardArrowDownIcon />
      </IconButton>
    )
  }, [expanded])

  return (
    <div className={cx(className)}>
      <Accordion
        className={classes.root}
        TransitionProps={{
          unmountOnExit: true,
        }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary
          sx={accordianSummary}
          classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
          aria-controls="panel1d-content"
          id="panel1d-header">
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center gap-4">
              {!leftTitleContent && getIcons(icon as IconsType)}
              {leftTitleContent && leftTitleContent}
              <Typography
                variant={Variant.Body}
                type="semibold"
                className={isActive ? 'text-neutral800' : 'text-neutral400'}>
                {title}
              </Typography>
            </div>

            {actionsContent && <div className="ml-auto">{actionsContent}</div>}
            {toggleIcon}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

interface Props {
  title: string | React.ReactNode
  children: React.ReactNode
  className?: string
  icon?: IconsType
  actionsContent?: React.ReactNode
  leftTitleContent?: React.ReactNode
  isActive?: boolean
}

export default AccordianTableWrapper
