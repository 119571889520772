import { SvgIconProps } from '@mui/material'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Typography, { Variant } from 'components/typography'
import { capitalizeFirstLetter } from 'utils/utils'

const TransactionCardInfo = ({ transactionResponse, variant = 'white' }: Props): JSX.Element => {
  return (
    <BorderlessBox variant={variant} className="items-start !flex-row gap-16">
      <Card
        title="Transaction Type"
        subtitle={capitalizeFirstLetter(transactionResponse?.transaction_type || '')}
        icon={IconsType.productCategory}
        className="w-[18px] h-[18px]"
        pathClassName="stroke-blue900"
      />
      <Card
        title="Transaction Nature"
        subtitle={capitalizeFirstLetter(transactionResponse?.functional_profile?.transaction_nature || '')}
        icon={IconsType.services}
        className="w-[18px] h-[18px]"
        pathClassName="fill-blue-900"
      />
    </BorderlessBox>
  )
}

export const Card = ({
  title,
  subtitle,
  icon,
  className,
  pathClassName,
  MuiIcon,
}: {
  title: string
  subtitle: string
  icon?: IconsType
  className?: string
  pathClassName?: string
  MuiIcon?: React.ComponentType<SvgIconProps>
}) => {
  return (
    <div className="flex justify-between items-center gap-4">
      <div className="w-9 h-9 bg-blue50 border-solid border border-blue-100 rounded-full flex justify-center items-center shrink-0">
        {MuiIcon ? (
          <MuiIcon className="text-blue-800 w-[1.125rem]" />
        ) : (
          icon && getIcons(icon, { className: className, pathClassName: pathClassName })
        )}
      </div>

      <div className="space-y-1.5">
        <Typography className="text-neutral-800" variant={Variant.Callout} type="regular">
          {title}
        </Typography>
        <Typography className="text-gray-800" variant={Variant.Callout} type="semibold">
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

interface Props {
  transactionResponse?: TransactionByIdResponse | TransactionResponse
  variant?: 'white' | 'grey' | undefined
}

export default TransactionCardInfo
