import axios from 'api'

import { TransactionDataResponse } from './types'
export const getTransactionsBetweenCountries = (
  year?: number,
  legal_entity?: string,
  transaction_type?: string,
  transfer_pricing_method?: string
) => {
  const params: Record<string, string | number | []> = {}

  if (year) {
    params.year = year
  }
  if (legal_entity) {
    params.legal_entity = legal_entity
  }
  if (transaction_type) {
    params.transaction_type = transaction_type
  }
  if (transfer_pricing_method) {
    params.transfer_pricing_method = transfer_pricing_method
  }
  return axios
    .get<Record<string, TransactionDataResponse[]>>(
      `/api/v1/org/{organization}/transaction/transactions/get_transactions_between_countries/`,
      {
        params,
      }
    )
    .then(res => res.data)
}
