import { SelectOptions } from 'types/common.types'

export const GAAP_OPTIONS: SelectOptions[] = [
  {
    label: 'US GAAP',
    value: 'us_gaap',
  },
  {
    label: 'IFRS GAP',
    value: 'ifrs_gaap',
  },
  {
    label: 'Local statutory GAAP',
    value: 'local_statutory_gaap',
  },
]

export const FIELD_IDS = {
  FINANCIAL_DATA_FILE_NAME: 'financial_data_file_name',
  GAAP_TYPE: 'gaap_type',
  TESTED_PARTY_CURRENCY: 'tested_party_currency',
  POLICY_RATE: 'policy_rate',
}
