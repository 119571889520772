import axios from '../index'
import { GetAIAttributeDescription, RecommendationsResponse } from './types'

export const getAIAttributeDescription = (variables?: GetAIAttributeDescription) => {
  return axios
    .get<RecommendationsResponse>(`/api/v1/org/{organization}/recommendations/generate_attribute_description/`, {
      params: { ...variables },
    })
    .then(res => res.data.recommendations)
}
