import { Field, Props } from '../types/form.types'

export const addPropsToRenderer = (field: (Field | Field[])[], props: Props[]) => {
  return field.map(field => {
    if (Array.isArray(field)) {
      return field.map(field => {
        const prop = props.find(prop => prop.id === field.id)
        return prop ? { ...field, ...prop.props } : field
      })
    }
    const prop = props.find(prop => prop.id === field.id)
    return prop ? { ...field, ...prop.props } : field
  })
}
