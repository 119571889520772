import { Tooltip } from '@mui/material'
import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import NewTable from 'components/newTable'
import NoContentContainer from 'components/noContentContainer/NoContentContainer'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

import { GroupedEntitiesByAttributes } from '../../types/types'
import AddFunctionsModal from '../addFunctionsModal'
import DeleteFunctionModal from '../deleteFunctionModal'
import ReorderSelectedEntitiesModal from '../reorderSelectedEntitiesModal/ReorderSelectedEntitiesModal'
import { selectedEntitiesColumns } from './selectedEntities.column'

const SelectEntities = ({
  attributeId,
  selectedTransactionId,
  setAttributeId,
  functionProfileRefetch,
  transactionRefetch,
  attributesOnTabKey,
  setSelectedAttributeData,
  groupedEntitiesByAttributes,
  tabKey,
  currentTransaction,
}: SelectEntitiesProps): JSX.Element => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [reOrderModal, setReOrderModal] = useState(false)

  const handleDeleteModalOpen = useCallback(
    (id: number) => {
      setDeleteModal(true)
      setAttributeId(id)
    },
    [setAttributeId]
  )

  const handleEditModalOpen = useCallback(
    (id: number) => {
      setEditModal(true)
      setAttributeId(id)
    },
    [setAttributeId]
  )
  const toggleReorderModal = useCallback(() => {
    setReOrderModal(o => !o)
  }, [])

  const columns = useMemo(
    () =>
      selectedEntitiesColumns({
        handleDeleteModalOpen,
        handleEditModalOpen,
        groupedEntitiesByAttributes,
      }),
    [handleDeleteModalOpen, handleEditModalOpen, groupedEntitiesByAttributes]
  )

  const columnsSansAction = useMemo(() => columns.filter(column => column.id !== 'actions'), [columns])

  // on modal close cleanup
  useEffect(() => {
    if (!editModal) {
      setAttributeId(0)
      setSelectedAttributeData(undefined)
    }
  }, [editModal, setAttributeId, setSelectedAttributeData])

  const renderNoItemContent = useMemo(() => {
    return (
      <BorderlessBox variant="white" className="w-full h-full" label={`Selected ${capitalizeFirstLetter(tabKey)}s`}>
        <NoContentContainer
          className="mt-2 bg-gray-50 text-gray-400 !rounded-[0.625rem] w-full"
          title={`Add Suggested ${capitalizeFirstLetter(tabKey)} from left panel to see selling and 
          purchasing entity. All your added ${tabKey}s will appear here.`}
        />
      </BorderlessBox>
    )
  }, [tabKey])

  if (attributesOnTabKey.length === 0) {
    return <>{renderNoItemContent}</>
  }

  return (
    <>
      <BorderlessBox
        variant="white"
        className="w-full h-full"
        topRightComponents={
          <Tooltip title={`Reorder ${tabKey}`} placement="top" arrow followCursor>
            <div>
              <Button
                disabled={attributesOnTabKey.length <= 1}
                icon={IconsType.sixDotDrag}
                variant={ButtonVariant.Secondary}
                onClick={toggleReorderModal}
                className="!py-0 !px-2 !bg-blue">
                Reorder
              </Button>
            </div>
          </Tooltip>
        }
        label={`Selected ${capitalizeFirstLetter(tabKey)}s`}>
        <div className="flex w-full mt-1">
          {attributesOnTabKey.length !== 0 && (
            <NewTable showHeader={false} height="calc(100vh - 31rem)" columns={columns} data={attributesOnTabKey} />
          )}
        </div>
      </BorderlessBox>

      <DeleteFunctionModal
        setDeleteModal={setDeleteModal}
        isDeleteModal={deleteModal}
        currentAttributeData={groupedEntitiesByAttributes[attributeId]}
        functionProfileRefetch={functionProfileRefetch}
        transactionRefetch={transactionRefetch}
        currentTransaction={currentTransaction}
      />
      <ReorderSelectedEntitiesModal
        isOpen={reOrderModal}
        onClose={toggleReorderModal}
        data={attributesOnTabKey}
        columns={columnsSansAction}
        tabKey={tabKey}
        key={reOrderModal ? 0 : 1}
        transactionId={currentTransaction.id}
        functionProfileRefetch={functionProfileRefetch}
        transactionRefetch={transactionRefetch}
      />

      {editModal && (
        <AddFunctionsModal
          isFunctionModalOpen={editModal}
          setIsFunctionModalOpen={setEditModal}
          attributeId={attributeId}
          transactionRefetch={transactionRefetch}
          functionProfileRefetch={functionProfileRefetch}
          tabKey={tabKey}
          currentAttributeData={groupedEntitiesByAttributes[attributeId]}
          currentTransaction={currentTransaction}
          isOtherModal={false}
          key={`${attributeId}-${selectedTransactionId}`}
        />
      )}
    </>
  )
}

interface SelectEntitiesProps {
  selectedTransactionId: number
  attributeId: number
  setAttributeId: (id: number) => void
  setSelectedAttributeData: Dispatch<SetStateAction<FunctionalAttributes | undefined>>
  transactionRefetch: () => void
  functionProfileRefetch: () => void
  groupedEntitiesByAttributes: GroupedEntitiesByAttributes
  attributesOnTabKey: FunctionalAttributes[]
  tabKey: string
  currentTransaction: TransactionByIdResponse
}

export default SelectEntities
