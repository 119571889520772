import { Tooltip } from '@mui/material'
import React from 'react'
import { components as Component, GroupBase, MultiValueGenericProps } from 'react-select'
import { SelectOptions } from 'types/common.types'

export const SelectCheckboxMultiValueContainer = (
  props: MultiValueGenericProps<SelectOptions, true, GroupBase<SelectOptions>>
) => {
  return (
    <Component.MultiValueContainer
      {...props}
      innerProps={{
        className: '!bg-none max-w-[200px]',
      }}>
      <Tooltip title={props.data.label}>
        <div
          onMouseDown={e => {
            e.stopPropagation()
          }}
          onTouchStart={e => e.stopPropagation()}
          className="text-orange-600 mr-1 max-w-full truncate whitespace-nowrap text-callout bg-orange-100 px-[0.625rem] py-[0.125rem] rounded-full">
          {props.data.label}
        </div>
      </Tooltip>
    </Component.MultiValueContainer>
  )
}
