import { ColumnDef } from '@tanstack/react-table'
import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { FinancialDataTableData } from '../types/financialData.types'

export const balanceSheetColumns = (year: number, showFourthYear: boolean): ColumnDef<FinancialDataTableData>[] => {
  const columns: ColumnDef<FinancialDataTableData>[] = [
    {
      header: `Balance Sheet Items`,
      accessorKey: 'name',
      accessorFn: row => row.name,
      size: 240,
      cell: EditableTableLabelCell,
      meta: {
        isReadOnly: true,
      },
    },
    {
      header: 'Formulae',
      accessorFn: row => row.formula || '--',
      cell: data => {
        return (
          <Typography
            className={classNames(
              'flex items-center text-start justify-start border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 mr-2 whitespace-nowrap'
            )}
            variant={Variant.Callout}
            type="semibold">
            {data.row.original.formula?.replace(/\s+/g, '') || '--'}
          </Typography>
        )
      },
      meta: {
        isReadOnly: true,
      },
      size: 1,
    },
    {
      accessorKey: year.toString(),
      accessorFn: row => row[year.toString()],
      header: `${year.toString()} *`,
    },
    {
      accessorKey: (year - 1).toString(),
      accessorFn: row => row[(year - 1).toString()],
    },
    {
      accessorKey: (year - 2).toString(),
      accessorFn: row => row[(year - 2).toString()],
    },
  ]

  if (showFourthYear) {
    columns.push({
      accessorKey: (year - 3).toString(),
      accessorFn: row => row[(year - 3).toString()],
    })
  }

  return columns
}

export const balanceSheetAvgColumns = (year: number): ColumnDef<FinancialDataTableData>[] => {
  const columns: ColumnDef<FinancialDataTableData>[] = [
    {
      header: `Balance Sheet Items`,
      accessorKey: 'name',
      accessorFn: row => row.name,
      size: 240,
      cell: EditableTableLabelCell,
      meta: {
        isReadOnly: true,
      },
    },
    {
      header: 'Formulae',
      accessorFn: row => row.formula || '--',
      cell: data => {
        return (
          <Typography
            className={classNames(
              'flex items-center text-start justify-start border rounded border-solid text-gray-600 border-gray-200 bg-blue-50 h-[39px] mb-1 px-4 mr-2 whitespace-nowrap'
            )}
            variant={Variant.Callout}
            type="semibold">
            {data.row.original.formula?.replace(/\s+/g, '') || '--'}
          </Typography>
        )
      },
      size: 150,
      meta: {
        isReadOnly: true,
      },
    },
    {
      accessorKey: year.toString(),
      accessorFn: row => row[year.toString()],
      header: `${year.toString()} Avg`,
      size: 200,
    },
    {
      accessorKey: (year - 1).toString(),
      header: `${(year - 1).toString()} Avg`,
      accessorFn: row => row[(year - 1).toString()],
      size: 200,
    },
    {
      accessorKey: (year - 2).toString(),
      accessorFn: row => row[(year - 2).toString()],
      header: `${(year - 2).toString()} Avg`,
      size: 200,
    },
  ]

  return columns
}
