import { Benchmark } from 'api/benchmarks/types'
import { addPropsToRenderer } from 'organisms/formWithSubmission'
import { UseFormResetField } from 'react-hook-form'
import { FORM_FIELDS } from 'views/benchmarks/overview/organisms/updateBenchmarkModal/types'

import { CHARACTER_LIMITS, complianceRegions, FIELDS_IDS } from '../constants/constants'
import { FIELDS } from '../constants/fields'

export const getFields = ({
  benchmarkId,
  quarterlyAnalysis,
  updateQuarterlyAnalysis,
  disableYear,
  resetField,
  setFromYear,
  setToYear,
  from_year,
  to_year,
}: getFieldsProps) => {
  return addPropsToRenderer(FIELDS, [
    {
      id: FIELDS_IDS.NAME,
      props: {
        isAnimatedLabel: false,
        fieldClassName: 'mr-6',
        placeholder: 'Benchmark Name',
        required: true,
        characterLimit: CHARACTER_LIMITS[FIELDS_IDS.NAME],
      },
    },
    {
      id: FIELDS_IDS.REGION,
      props: {
        placeholder: 'Do you have the region of the comparable search?',
        options: complianceRegions.map(region => ({ value: region.code, label: region.label })),
        required: true,
      },
    },
    {
      id: FIELDS_IDS.QUARTERLY_ANALYSIS,
      props: {
        benchmarkId,
        quarterlyAnalysis,
        updateQuarterlyAnalysis,
        disableYear,
        resetField,
        setFromYear,
        setToYear,
        from_year,
        to_year,
      },
    },
  ])
}

interface getFieldsProps {
  benchmarkId?: number
  quarterlyAnalysis?: Benchmark['quarterly_analysis']
  updateQuarterlyAnalysis: (data: Benchmark['quarterly_analysis']) => void
  disableYear?: boolean
  resetField?: UseFormResetField<FORM_FIELDS>
  setFromYear: (year: string) => void
  setToYear: (year: string) => void
  from_year: number
  to_year: number
}
