import { Benchmark } from 'api/benchmarks/types'
import _every from 'lodash/every'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import { BenchMarkAnalysisTableData } from 'views/transactions/createTransaction/organisms/economicAnalysis/components/benchMarkAnalysis/BenchMarkAnalysis'

const keysToScan: (keyof Benchmark['quarterly_analysis'][number])[] = [
  'lowest_observed_value',
  'lower_quartile',
  'median',
  'upper_quartile',
  'highest_observed_value',
]

export const convertTableDataToBenchmarkData = (
  from_year: number,
  to_year: number,
  tableData: BenchMarkAnalysisTableData[]
) => {
  const columnNames: string[] = []
  let year = 0
  while (year <= to_year - from_year) {
    columnNames.push((from_year + year).toString())
    year++
  }
  columnNames.push('WAVG')

  const benchmarkData: Record<string, number | undefined>[] = []
  _forEach(tableData, rowData => {
    columnNames.forEach(name => {
      const foundInBenchmarkData = benchmarkData.find(obj => {
        if (name == 'WAVG') {
          return obj.year == -1
        } else {
          return obj.year == Number(name)
        }
      })

      if (foundInBenchmarkData) {
        foundInBenchmarkData[rowData.key] = rowData[name]
      } else {
        benchmarkData.push({
          [rowData.key]: rowData[name],
          year: name == 'WAVG' ? -1 : Number(name),
        })
      }
    })
  })

  // filter out empty year columns if all keys are undefined
  const filteredBenchmarkData = _filter(
    benchmarkData,
    benchmark => !_every(keysToScan, key => benchmark[key] == undefined || String(benchmark[key]) == '')
  )

  return filteredBenchmarkData as unknown as Benchmark['quarterly_analysis']
}
