import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import React from 'react'

import { FILE_IDS } from '../constants/constants'
const getColumns = ({
  handleDelete,
  handlePreview,
  handleEdit,
}: {
  handleDelete: (id: FILE_IDS) => () => void
  handlePreview: (id: FILE_IDS) => () => void
  handleEdit: (id: FILE_IDS) => () => void
}) => {
  const column: ColumnDef<{
    id: FILE_IDS
    label: string
    name: string
  }>[] = [
    {
      header: () => <div className="flex justify-start">File Type</div>,
      accessorKey: 'label',
      accessorFn: row => row.label,
      size: 350,
      id: 'label',
      cell: props => {
        return (
          <Typography variant={Variant.Callout} className="flex justify-start" type="semibold">
            {props.row.original.label}
          </Typography>
        )
      },
    },
    {
      header: () => <div className="flex justify-start">File Name</div>,
      accessorKey: 'name',
      id: 'name',
      size: 400,
      cell: props => {
        return (
          <Tooltip title={props.row.original.name} placement="top">
            <div>
              <Typography variant={Variant.Callout} className="truncate text-start max-w-[20rem]" type="semibold">
                {props.row.original.name}
              </Typography>
            </div>
          </Tooltip>
        )
      },
    },
    {
      header: () => <div className="flex justify-end">Actions</div>,
      id: 'action',
      size: 1,
      cell: ({ row }) => {
        return (
          <span className="flex gap-6 justify-end items-center">
            <Typography
              onClick={handlePreview(row.original.id)}
              className="cursor-pointer text-blue-900"
              type="semibold"
              variant={Variant.Callout}>
              Preview
            </Typography>
            <Typography
              onClick={handleEdit(row.original.id)}
              className="cursor-pointer text-blue-900"
              type="semibold"
              variant={Variant.Callout}>
              Edit
            </Typography>
            <Button
              className="!h-4"
              onClick={handleDelete(row.original.id)}
              isDefaultSize={false}
              variant={ButtonVariant.Tertiary}
              iconCLass="w-4 h-4"
              iconPathClassName="stroke-orange-600 "
              icon={IconsType.delete}></Button>
          </span>
        )
      },
    },
  ]
  return column
}
export default getColumns
