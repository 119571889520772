import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Portal } from '@mui/material'
import { StepTypes } from 'hooks/useFTUTour/types'
import useFTUTour from 'hooks/useFTUTour/useFTUTour'
import React, { useMemo } from 'react'

type Props = {
  overlayDOMRect: DOMRect
}

const TourHighLightTooltip = ({ overlayDOMRect }: Props) => {
  const { step, totalSteps, currentStepNumber, handleNextStepChange, handlePrevStepChange } = useFTUTour()

  const stepData = useMemo(() => {
    if (step?.type == StepTypes.Tooltip) {
      return step.tooltipData
    }
  }, [step])

  return (
    <Portal>
      <div className="fixed inset-0 z-[10000]">
        <div
          style={{
            position: 'absolute',
            top: overlayDOMRect.top,
            left: overlayDOMRect.left,
            width: overlayDOMRect.width,
            height: overlayDOMRect.height,
            boxShadow: 'rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px',
            zIndex: 10000,
            borderRadius: 8,
          }}></div>
        <div
          style={{
            position: 'absolute',
            bottom: 36,
            width: overlayDOMRect.width,
            left: overlayDOMRect.left,
            zIndex: 10000,
          }}
          className="rounded-lg bg-[#0A0929] p-6 z-[10000] space-y-3">
          <div className="text-body text-white">{stepData?.text}</div>
          <div className="flex w-full justify-between items-center">
            <div className="text-gray-400 text-footnote">
              {currentStepNumber}/{totalSteps}
            </div>
            <div className="flex items-center text-callout font-semibold gap-6">
              {currentStepNumber > 1 && (
                <div
                  onClick={handlePrevStepChange}
                  className="flex items-center gap-0.5 text-indigo-400 cursor-pointer">
                  <KeyboardArrowLeft className="w-3.5 h-3.5 scale-150" />
                  Previous
                </div>
              )}
              <div onClick={handleNextStepChange} className="flex items-center gap-0.5 text-indigo-400 cursor-pointer">
                {currentStepNumber != totalSteps ? (
                  <>
                    Next
                    <KeyboardArrowRight className="w-3.5 h-3.5 scale-150" />
                  </>
                ) : (
                  'Finish'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default TourHighLightTooltip
