import { SelectOptions } from 'types/common.types'

export const ValueOptions: SelectOptions[] = [
  {
    label: 'Amount',
    value: 'amount',
  },
  {
    label: 'Percent',
    value: 'percentage',
  },
  {
    label: 'Price/Unit',
    value: 'price_per_unit',
  },
]

export const CurrencyRequiredForValueTypes = ['amount', 'price_per_unit']

export const FIELD_IDS = {
  CONCLUSION_TABLE: 'conclusionTable',
  ARMS_LENGTH_RANGE: 'armsLengthRange',
  NAME: 'name',
  VALUE: 'value',
  CURRENCY: 'currency',
}
