import { default as MuiDrawer } from '@mui/material/Drawer'
import cx from 'classnames'

import DrawerHeader from './components/drawerHeader/DrawerHeader'

const Drawer = ({
  isOpen,
  onClose,
  children,
  className,
  title,
  containerClassName,
  closeIconClassName,
  closeIconPathClassName = 'fill-gray-300',
}: DrawerProps) => {
  return (
    <MuiDrawer
      open={isOpen}
      onClose={onClose}
      style={{ zIndex: 50 }}
      anchor="right"
      transitionDuration={500}
      PaperProps={{
        className: cx(
          'transition-transform transform ease-in-out duration-500 absolute right-0 bg-[#0A0929] top-0',
          {
            'translate-x-0': isOpen,
            'translate-x-full': !isOpen,
          },
          containerClassName
        ),
      }}>
      <div
        className={cx('flex flex-col items-start p-4', className, {
          'gap-4': title,
        })}>
        <DrawerHeader
          closeIconClassName={closeIconClassName}
          closeIconPathClassName={closeIconPathClassName}
          handleClose={onClose}
          title={title}
        />
        {children}
      </div>
    </MuiDrawer>
  )
}

interface DrawerProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: VoidFunction
  title: string | React.ReactNode
  containerClassName?: string
  className?: string
  closeIconClassName?: string
  closeIconPathClassName?: string
}

export default Drawer
