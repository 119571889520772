import { Tooltip } from '@mui/material'
import { flexRender, Row } from '@tanstack/react-table'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'

function AttributeTableRow<T>({ row, isWidthSet = true, reorderRow }: AttributeTableRowProps<T>) {
  const reorderRowFn = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) =>
      reorderRow ? reorderRow(draggedRowIndex, targetRowIndex) : () => null,
    [reorderRow]
  )

  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<T>) => reorderRowFn(draggedRow.index, row.index),
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  })

  return (
    <tr
      ref={previewRef}
      style={{ opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 999 : 'auto' }}
      className="bg-white hover:bg-gray-50"
      key={row.id}>
      {reorderRow ? (
        <td style={{ width: '0px' }} className="p-4" ref={dropRef}>
          <div className="flex items-center">
            <Tooltip title="Drag to reorder" placement="top">
              <button ref={dragRef} className="w-4 bg-transparent border-0 cursor-move opacity-70">
                {getIcons(IconsType.sixDotDrag, { pathClassName: 'stroke-transparent fill-blue900' })}
              </button>
            </Tooltip>
          </div>
        </td>
      ) : (
        <></>
      )}
      {row.getVisibleCells().map(cell => {
        const size = cell.column.columnDef.size

        const isPercentage = String(size)?.includes('%')

        return (
          <td
            style={{
              width: isPercentage ? size : `${size}px`,
            }}
            className={cx('text-center', {
              'w-[11.5rem] p-4': isWidthSet,
            })}
            key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

interface AttributeTableRowProps<T> {
  row: Row<T>
  isWidthSet?: boolean
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
}

export default AttributeTableRow
