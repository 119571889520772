import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import { DEFAULT_LEFT_GROUP_BUTTONS } from 'components/tinyMceEditor/constants'
import Typography, { Variant } from 'components/typography'
import React, { useMemo } from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'

import HTMLViewEdit from '../HTMLViewEdit'
import HTMLViewEditWithDrawer from '../HTMLViewEditWithDrawer'

type HTMLInputCardFieldProps<T> = {
  label: string
  editableLabel?: React.ReactNode
  editableLabelIsDirty?: boolean
  value: string
  icon?: IconsType
  iconClassName?: string
  iconPathClassName?: string
  id: Path<T & FieldValues>
  control: Control<T & FieldValues>
  saveButtonText?: string
  handleDelete?: (item: Path<T & FieldValues>) => void
  localFileCss?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSave?: () => Promise<any> | void
  isLoading?: boolean
  containerClassname?: string
  classname?: string
  leftGroupButtons?: string[]
  isDrawerComponent?: boolean
}

function HTMLInputCardField<T>({
  label,
  value,
  icon,
  editableLabel,
  editableLabelIsDirty,
  iconClassName,
  iconPathClassName,
  id,
  control,
  handleDelete,
  localFileCss,
  handleSave,
  saveButtonText = 'Save',
  isLoading,
  containerClassname,
  isDrawerComponent = false,
  classname,
  leftGroupButtons = DEFAULT_LEFT_GROUP_BUTTONS,
}: HTMLInputCardFieldProps<T>) {
  const children = useMemo(
    () =>
      isDrawerComponent ? (
        <HTMLViewEditWithDrawer
          className={classname}
          label={label}
          editableLabel={editableLabel}
          editableLabelIsDirty={editableLabelIsDirty}
          value={value}
          id={id}
          control={control}
          saveButtonText={saveButtonText}
          handleDelete={handleDelete}
          localFileCss={localFileCss}
          leftGroupButtons={leftGroupButtons}
          onSave={handleSave}
          isLoading={isLoading}
        />
      ) : (
        <HTMLViewEdit
          icon={icon}
          className={classname}
          iconClassName={iconClassName}
          iconPathClassName={iconPathClassName}
          label={label}
          editableLabel={editableLabel}
          editableLabelIsDirty={editableLabelIsDirty}
          value={value}
          id={id}
          control={control}
          saveButtonText={saveButtonText}
          handleDelete={handleDelete}
          localFileCss={localFileCss}
          leftGroupButtons={leftGroupButtons}
          onSave={handleSave}
          isLoading={isLoading}
        />
      ),
    [
      classname,
      control,
      editableLabel,
      editableLabelIsDirty,
      handleDelete,
      handleSave,
      icon,
      iconClassName,
      iconPathClassName,
      id,
      isDrawerComponent,
      isLoading,
      label,
      leftGroupButtons,
      localFileCss,
      saveButtonText,
      value,
    ]
  )
  return (
    <BorderBoxWrapper className={cx('py-3 px-4 flex items-center bg-white flex-shrink-0', containerClassname)}>
      <Typography variant={Variant.Callout} className="leading-4" type="semibold">
        {label}
      </Typography>
      {!!value &&
        getIcons(IconsType.greenTick, { className: 'ml-1 w-4 h-4 flex-shrink-0', pathClassName: 'fill-[#16a34a]' })}
      {children}
    </BorderBoxWrapper>
  )
}

export default HTMLInputCardField
