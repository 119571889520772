import { ColumnDef, FilterFn, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table'
import cx from 'classnames'
import NoEntryFoundContainer from 'components/newTable/components/noEntryFoundContainer/NoEntryFoundContainer'
import Fuse from 'fuse.js'
import { useCallback } from 'react'

import AttributeHeadRow from './components/attributeHeadRow'
import AttributeTableRow from './components/attributeTableRow'

function NewAttributeTable<T>({
  columns,
  data = [],
  className,
  tableClassName,
  bodyClassName,
  isTextCenter,
  headerClassName = 'whitespace-nowrap',
  reorderRow,
  globalFilter,
  hideHeader = false,
}: NewAttributeTableProps<T>) {
  const fuzzyFilter: FilterFn<T> = useCallback((row, columnId, value) => {
    let columnIds = []
    if (columnId.includes('|')) {
      columnIds = [...columnId.split('|')]
    } else {
      columnIds.push(columnId)
    }
    const fuse = new Fuse([row.original], {
      keys: [...columnIds],
      threshold: 0.3,
      includeMatches: true,
    })

    const matches = fuse.search(value)

    if (matches.length === 0) {
      return false
    }

    return true
  }, [])

  const { getHeaderGroups, getRowModel } = useReactTable<T>({
    state: {
      globalFilter,
    },
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false,
    pageCount: 1000,
    globalFilterFn: fuzzyFilter,
  })

  return (
    <div className="relative w-full">
      <div className={cx(className)}>
        <div className={cx('bg-white, w-full flex flex-col justify-end items-end ')}>
          <table
            rules="none"
            className={cx('w-full border-separate border-spacing-x-0 border-spacing-y-1.5', tableClassName)}>
            {!hideHeader && (
              <thead>
                {getHeaderGroups().map(headerGroup => (
                  <AttributeHeadRow
                    headerClassName={headerClassName}
                    isTextCenter={isTextCenter}
                    key={headerGroup.id}
                    headerGroup={headerGroup}
                    reorderRow={reorderRow}
                  />
                ))}
              </thead>
            )}

            {getRowModel()?.rows?.length ? (
              <tbody className={bodyClassName}>
                {getRowModel()?.rows?.map(row => {
                  return <AttributeTableRow key={row.id} row={row} reorderRow={reorderRow} />
                })}
              </tbody>
            ) : (
              <>
                <NoEntryFoundContainer columns={columns} classes={{ body: cx(bodyClassName, 'w-full') }} />
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  )
}

interface NewAttributeTableProps<T> {
  columns: ColumnDef<T, unknown>[]
  data: T[]
  className?: string
  tableClassName?: string
  isTextCenter?: boolean
  headerClassName?: string
  bodyClassName?: string
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
  globalFilter?: string
  setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>
  hideHeader?: boolean
}

export default NewAttributeTable
