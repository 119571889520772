import { InfoOutlined } from '@mui/icons-material'
import Typography, { Variant } from 'components/typography'
import { AI_TEXT_DISCLAIMER } from 'constants/app.constants'
import React from 'react'

function AITextDisclaimer() {
  return (
    <Typography className=" mt-1 flex items-center text-orange-500" variant={Variant.Footnote}>
      <InfoOutlined className="ms-auto h-3 w-3 me-1" />
      {AI_TEXT_DISCLAIMER}
    </Typography>
  )
}

export default AITextDisclaimer
