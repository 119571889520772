import { primary_entity } from 'api/transactions/getTransaction/types'
import { useEffect } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { ENTITY_FIELDS_VALUE } from './AddFunctionsModal'

const useCommonLanguageChange = ({ entityFormWatch, entityFormSetValue, associatedEntities }: Props) => {
  const groupLanguageWeight = entityFormWatch('_common.weight')
  const isGroupLanguageActive = entityFormWatch('_common.isActive')

  useEffect(() => {
    if (isGroupLanguageActive) {
      associatedEntities.forEach(entity => {
        entityFormSetValue(`${entity.id}.isActive`, isGroupLanguageActive)
      })
    }
  }, [associatedEntities, entityFormSetValue, isGroupLanguageActive])

  useEffect(() => {
    if (groupLanguageWeight && isGroupLanguageActive) {
      associatedEntities.forEach(entity => {
        entityFormSetValue(`${entity.id}.weight`, groupLanguageWeight)
      })
    }
  }, [associatedEntities, entityFormSetValue, groupLanguageWeight, isGroupLanguageActive])

  return { isGroupLanguageActive }
}

interface Props {
  entityFormWatch: UseFormWatch<ENTITY_FIELDS_VALUE>
  entityFormSetValue: UseFormSetValue<ENTITY_FIELDS_VALUE>
  associatedEntities: primary_entity[]
}

export default useCommonLanguageChange
