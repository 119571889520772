/* eslint-disable react/jsx-key */
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'

function AttributeHeadRow<T>({ headerGroup, isTextCenter, headerClassName, reorderRow }: AttributeHeadRowProps<T>) {
  return (
    <tr>
      {reorderRow && headerGroup.headers.find(header => header.column.columnDef.header) ? (
        <td style={{ width: '0px' }} className="p-4">
          <div className="flex items-center"> </div>
        </td>
      ) : (
        <></>
      )}
      {headerGroup.headers.map(column => {
        const size = column.column.columnDef.size
        if (column.column.columnDef.header) {
          return (
            <th
              style={{
                minWidth: `${size}px`,
                width: `${size}px`,
              }}
              key={column.id}
              className={cx(
                'text-2xs text-neutral800 font-bold whitespace-nowrap p-4',
                {
                  'text-center ': isTextCenter,
                  'text-start': !isTextCenter,
                },
                headerClassName
              )}>
              <Typography variant={Variant.Callout} type="semibold" key={column.index}>
                {flexRender(column.column.columnDef.header, column.getContext())}
              </Typography>
            </th>
          )
        }
        return null
      })}
    </tr>
  )
}

interface AttributeHeadRowProps<T> {
  headerGroup: HeaderGroup<T>
  isTextCenter?: boolean
  headerClassName?: string
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
}

export default AttributeHeadRow
