export const ROUTES = {
  ACCESS_FORBIDDEN: '/403',
  ACTIVITY_LOG: '/org/[orgId]/activity-log',
  BENCHMARK_MANAGEMENT: '/org/[orgId]/benchmarks',
  BENCHMARK_DETAILS: '/org/[orgId]/benchmarks/[id]',
  CHAT_BOT: '/org/[orgId]/chatbot',
  CREATE_LEGAL_ENTITY: '/org/[orgId]/legal-entity-management/create',
  CREATE_TRANSACTION: '/org/[orgId]/transaction-management/create-transaction',
  DASHBOARD: '/org/[orgId]/dashboard',
  DOCUMENT_ARCHIVAL: '/org/[orgId]/document-management/document-archival',
  DOCUMENT_CHAT: '/org/[orgId]/document-chat',
  DOCUMENT_MANAGEMENT: '/org/[orgId]/document-management',
  FORGOT_PASSWORD_VERIFICATION: '/forgot-password/forgot-password-verification',
  FORGOT_PASSWORD: '/forgot-password',
  HELP_CENTER: '/org/[orgId]/help-center',
  HOME: '/home',
  IC_AGREEMENTS_MANAGEMENT: '/org/[orgId]/ic-agreements-management',
  LEGAL_ENTITY_MANAGEMENT: '/org/[orgId]/legal-entity-management',
  LEGAL_ENTITY_DETAILS: '/org/[orgId]/legal-entity-management/[id]',
  NEW_YEAR_TRANSACTIONS: '/org/[orgId]/transaction-management/new-year-transactions',
  ORGANIZATION: '/org/[orgId]/organization',
  REGISTRATION_SUCCESSFUL: '/signup/registration-successful',
  REGISTRATION_VERIFICATION: '/signup/registration-verification',
  RESOURCE_NOT_FOUND: '/404',
  SETTINGS: '/org/[orgId]/settings-support',
  SIGN_UP: '/signup',
  TEMPLATE_MANAGEMENT: '/org/[orgId]/template-management',
  TRANSACTION_MANAGEMENT: '/org/[orgId]/transaction-management',
  USER_ACCESS_MANAGEMENT: '/org/[orgId]/user-access-management',
  USER_LOGIN: '/user-login',
  USER_PROFILE: '/org/[orgId]/user-profile',
} as const
