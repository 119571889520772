import { Dialog, Transition } from '@headlessui/react'
import Loading from 'assets/icons/loading'
import cx from 'classnames'
import React, { Fragment } from 'react'

const TourLoadingState = ({ show }: { show: boolean }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50 commonIntegralModalClass" onClose={() => console.log('Dialog closed')}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className={cx('transform  rounded-xl text-white transition-all')}>
                <div className={cx('flex flex-col items-center rounded-lg bg-[#0A0929] p-6 w-[528px] gap-4')}>
                  <Loading className="w-6 h-6 !fill-[#0A0929] text-indigo-400" />
                  <div className="flex flex-col items-center self-stretch gap-1">
                    <div className="text-body font-medium	">Please Wait</div>
                    <div className="text-footnote">We are loading the next set of information</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default TourLoadingState
