import { SelectOptions } from 'types/common.types'

export const ValueOptions: SelectOptions[] = [
  {
    label: 'Amount',
    value: 'amount',
  },
  {
    label: 'Percent',
    value: 'percentage',
  },
  {
    label: 'Price/Unit',
    value: 'price_per_unit',
  },
]

export const TransactionTypeOptions: SelectOptions[] = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
]
