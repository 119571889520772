import { WarningAmber } from '@mui/icons-material'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import GreenTick from 'assets/icons/greenTick'
import { IconsType } from 'assets/types'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import TransactionPartyCell from 'views/transactions/components/transactionPartyCell'

import { ControlledTransactionDataTableData } from '../ControlledTransactionsData'

export const getComparabilityTableColumns: ({
  onClickEdit,
  deleteFileHandler,
  showActionsColumn,
}: {
  onClickEdit?: (row: ControlledTransactionDataTableData) => void
  deleteFileHandler?: (row: ControlledTransactionDataTableData) => void
  showActionsColumn?: boolean
}) => ColumnDef<ControlledTransactionDataTableData>[] = ({
  onClickEdit,
  deleteFileHandler,
  showActionsColumn = true,
}) => {
  return [
    {
      header: 'Reporting Party',
      size: 150,
      cell: cellContext => {
        return cellContext.row.original.transactionData ? (
          <TransactionPartyCell
            disableClick
            maxLength={40}
            partyIdentification={
              cellContext.row.original.transactionData.is_primary_le_source
                ? cellContext.row.original.transactionData.functional_profile.source_party_identification
                : cellContext.row.original.transactionData.functional_profile.target_party_identification
            }
            entities={[cellContext.row.original.transactionData?.primary_entity]}
            transactionNature={cellContext.row.original.transactionData.functional_profile?.transaction_nature}
          />
        ) : (
          ''
        )
      },
    },
    {
      header: 'Related Party',
      size: 150,
      cell: cellContext => {
        const entity = cellContext.row.original.include_all_individual_transactions
          ? cellContext.row.original.transactionData?.individual_transactions.map(txn => txn.associated_entity) || []
          : [
              cellContext.row.original.transactionData?.individual_transactions.find(
                trans => trans.id === cellContext.row.original.individual_transaction.id
              )?.associated_entity || [],
            ].flat()

        if (cellContext.row.original.transactionData && entity.length) {
          return (
            <TransactionPartyCell
              disableClick
              maxLength={40}
              partyIdentification={
                !cellContext.row.original.transactionData.is_primary_le_source
                  ? cellContext.row.original.transactionData.functional_profile.source_party_identification
                  : cellContext.row.original.transactionData.functional_profile.target_party_identification
              }
              entities={entity}
              transactionNature={cellContext.row.original.transactionData.functional_profile?.transaction_nature}
            />
          )
        }
        return ''
      },
    },
    {
      header: 'Comparability Factors',
      cell: cellContext => {
        const complete = cellContext.row.original.is_description_added_for_all_factors !== false
        return complete ? (
          <Typography variant={Variant.Callout} className="gap-1 flex items-center" type="semibold">
            <GreenTick className="w-[14px] h-[14px]" />
            Added
          </Typography>
        ) : (
          <Typography variant={Variant.Callout} className="gap-1 flex items-center" type="semibold">
            <WarningAmber className="w-[14px] text-orange-400 h-[14px]" />
            Pending
          </Typography>
        )
      },

      size: 100,
    },
    ...(showActionsColumn
      ? [
          {
            header: () => <div className="flex justify-end">Actions</div>,
            id: 'actions',
            cell: (context: CellContext<ControlledTransactionDataTableData, unknown>) => {
              return (
                <div className="flex items-center gap-3 justify-end px-2">
                  <Button
                    onClick={() => onClickEdit && onClickEdit(context.row.original)}
                    variant={ButtonVariant.Tertiary}
                    className="!h-auto text-blue-800">
                    Edit
                  </Button>
                  <Button
                    isDefaultSize={false}
                    onClick={() => deleteFileHandler && deleteFileHandler(context.row.original)}
                    icon={IconsType.delete}
                    iconCLass="!w-[1.125rem] !h-[1.125rem]"
                    iconPathClassName="stroke-orange-600 "
                    variant={ButtonVariant.Tertiary}></Button>
                </div>
              )
            },
            size: 50,
          },
        ]
      : []),
  ]
}

// export const getComparableConclusionResultEntries: (param: {
//   data: comparabilityTableItem[]
//   entity1Label: string
//   entity2Label: string
// }) => CreateComparableResultsPayload['comparable_conclusion_result_entries'] = ({
//   data,
//   entity1Label,
//   entity2Label,
// }) => {
//   return data
//     .filter(item => !(!item.entity1 && !item.entity2 && !item.description && !item.value))
//     .map(item => {
//       if (!item.entity1) throw new Error(`${entity1Label} for entry number ${item.index} is missing`)
//       if (!item.entity2) throw new Error(`${entity2Label} for entry number ${item.index} is missing`)
//       if (!item.value) throw new Error(`Value for entry number ${item.index} is missing`)
//       return {
//         value: item.value,
//         description: item.description,
//         entity1_name: item.entity1,
//         entity2_name: item.entity2,
//       }
//     })
// }
