import { CellContext } from '@tanstack/react-table'
import { TPFormResponse } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import React, { useEffect, useState } from 'react'

export const EditableNameCell = ({
  data,
  onEdit,
}: {
  data: CellContext<TPFormResponse, unknown>
  onEdit: (tPform: TPFormResponse, name: string) => void
}) => {
  const [editable, setEditable] = useState(false)
  const [name, setName] = useState<string>(data.row.original.name)

  const onToggleEdit = () => {
    if (editable) {
      onEdit(data.row.original, name)
    }
    setEditable(!editable)
  }

  return (
    <div className="flex items-center gap-4">
      {editable ? (
        <input
          onChange={e => setName(e.target.value)}
          value={name}
          type="text"
          className="w-[20rem] border-b border-b-neutral-300 border-t-0 border-r-0 border-l-0 border-solid focus:outline-none focus:border-b-neutral-300 focus:bg-gray-50 p-2"
        />
      ) : (
        <Typography variant={Variant.Callout} type="semibold" className="text-blue-900 whitespace-nowrap">
          {data.row.original.name}
        </Typography>
      )}
      <Button
        icon={editable ? IconsType.save : IconsType.edit}
        variant={editable ? ButtonVariant.Primary : ButtonVariant.Tertiary}
        className="h-[38px]"
        onClick={onToggleEdit}
      />
    </div>
  )
}

export const CommonEditableNameCell = ({
  data,
  onEdit,
  resetData = false,
}: {
  data: string
  onEdit: (name: string) => void
  resetData?: boolean
}) => {
  const [editable, setEditable] = useState(false)
  const [name, setName] = useState<string>(data)

  const onToggleEdit = () => {
    if (editable) {
      onEdit(name)
    }
    setEditable(!editable)
  }
  useEffect(() => {
    if (!editable && resetData) {
      setName(data)
    }
  }, [data, editable, resetData])

  return (
    <div className="flex items-center gap-4">
      {editable ? (
        <input
          onChange={e => setName(e.target.value)}
          value={name}
          type="text"
          className="w-[20rem] border-b border-b-neutral-300 border-t-0 border-r-0 border-l-0 border-solid focus:outline-none focus:border-b-neutral-300 focus:bg-gray-50 p-2"
        />
      ) : (
        <Typography variant={Variant.Callout} type="semibold" className="text-gray-700 whitespace-nowrap">
          {name || data}
        </Typography>
      )}
      <Button
        icon={editable ? IconsType.save : IconsType.edit}
        variant={editable ? ButtonVariant.Primary : ButtonVariant.Tertiary}
        className="h-[38px]"
        onClick={onToggleEdit}
      />
    </div>
  )
}
