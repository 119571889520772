import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import NewTable from 'components/newTable'
import DebouncedInput from 'components/table/components/debouncedInput/DebouncedInput'
import { useMemo, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

import { attributeColumns } from '../../functionSelector.columns'

const OtherTabKeyTable = ({ tableData, handleModalOpen, tabKey }: Props): JSX.Element => {
  const columns = useMemo(() => attributeColumns({ handleModalOpen }), [handleModalOpen])
  const [globalFilter, setGlobalFilter] = useState('')

  return (
    <BorderlessBox
      variant="white"
      className="h-full"
      label={`Suggested ${capitalizeFirstLetter(tabKey)}s`}
      topRightComponents={
        <DebouncedInput
          value={globalFilter ?? ''}
          className="h-[2rem] !rounded-2xl "
          onChange={value => setGlobalFilter(String(value))}
          placeholder="Search"
        />
      }>
      <div className="w-full mt-1">
        <NewTable
          showHeader={false}
          tableCellClassname=" !px-4 !py-[6px]"
          height="calc(100vh - 31rem)"
          columns={columns}
          data={tableData}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </BorderlessBox>
  )
}

interface Props {
  tableData: FunctionalAttributes[]
  attributesOnTabKey: FunctionalAttributes[]
  handleModalOpen: (data: FunctionalAttributes) => void
  tabKey: string
}

export default OtherTabKeyTable
