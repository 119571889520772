import { useMutation } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { updateAttributeOrder } from 'api/transactions/createTransaction'
import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import { IconsType } from 'assets/types'
import AttributeTable from 'components/atrributeTable/AttributeTable'
import Button from 'components/button/Button'
import Loading from 'components/loading'
import Modal from 'components/modal/Modal'
import TableWrapper from 'components/tableWrapper/TableWrapper'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter } from 'utils/utils'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  data: FunctionalAttributes[]
  tabKey: string
  columns: ColumnDef<FunctionalAttributes>[]
  transactionId: number
  transactionRefetch: () => void
  functionProfileRefetch: () => void
}

function ReorderSelectedEntitiesModal({
  isOpen,
  onClose,
  data,
  tabKey,
  columns,
  transactionId,
  transactionRefetch,
  functionProfileRefetch,
}: Props) {
  const [reorderedAttributes, setReorderedAttributes] = useState(data)

  const reorderRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedAttributes = [...reorderedAttributes]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setReorderedAttributes([...swappedAttributes])
    },
    [reorderedAttributes]
  )

  const { refetchChecklist } = useReportWizardContext()

  const updateAttributeOrderMutation = useMutation(updateAttributeOrder, {
    onSuccess: () => {
      toast.success(`${capitalizeFirstLetter(tabKey)} order updated successfully!!`)
      transactionRefetch()
      refetchChecklist()
      functionProfileRefetch()
      onClose()
    },
    onError: () => {
      toast.error(`Failed to update ${tabKey} order`)
    },
  })

  const handleReorder = useCallback(() => {
    const attributeOrderArray = reorderedAttributes.map(attr => attr.id)
    updateAttributeOrderMutation.mutate({
      attributes: attributeOrderArray,
      attribute_type: tabKey,
      transaction_id: transactionId,
    })
  }, [reorderedAttributes, tabKey, transactionId, updateAttributeOrderMutation])

  return (
    <Modal
      className="px-10 py-11 overflow-y-auto"
      containerClassName="w-[65rem]"
      isOpen={isOpen}
      onClose={onClose}
      icon={IconsType.functionalAnalysis}
      title={`Reorder ${tabKey}s`}>
      <TableWrapper
        textColor="text-teal-600"
        iconPathClassName="fill-[#0D9488]"
        isTableDefaultHeight={false}
        style={{
          backgroundColor: '#EFF6FF',
        }}
        icon={IconsType.checkWithCircle}
        className="w-full max-h-full"
        title={`Selected ${capitalizeFirstLetter(tabKey)}`}>
        <div className="flex relative">
          <AttributeTable
            isTextCenter={false}
            isRoundedCorner={false}
            isWidthSet={false}
            columns={columns}
            data={reorderedAttributes}
            reorderRow={reorderRow}
            className="overflow-y-auto max-h-[70vh]"
          />
          {updateAttributeOrderMutation.isLoading && (
            <div className="h-full w-full absolute inset-0 bg-white flex justify-center items-center">
              <Loading />
            </div>
          )}
        </div>
      </TableWrapper>
      <Button
        onClick={handleReorder}
        disabled={reorderedAttributes == data || updateAttributeOrderMutation.isLoading}
        className="self-center">
        Update
      </Button>
    </Modal>
  )
}

export default ReorderSelectedEntitiesModal
