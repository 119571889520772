import DefaultTabDark from 'components/tabs/DefaultTabDark'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useMemo } from 'react'

import { getAppendixChecklistTabs } from './AppendixChecklist.helpers'

type props = {
  appendicesConfig: ReturnType<typeof getAppendicesConfig>
}

const AppendixChecklist = ({ appendicesConfig }: props) => {
  const tabdata = useMemo(() => {
    return getAppendixChecklistTabs(appendicesConfig).filter(tab => !tab.hide)
  }, [appendicesConfig])
  return (
    <div className="mt-6 space-y-3">
      <DefaultTabDark tabsData={tabdata} tabSpacingClassname="w-full gap-14 px-4" />
    </div>
  )
}

export default AppendixChecklist
