export function resizeImage(base64Url: string, maxWidth: number, maxHeight: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = function () {
      let width = img.width
      let height = img.height

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height *= maxWidth / width
          width = maxWidth
        } else {
          width *= maxHeight / height
          height = maxHeight
        }

        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL('image/webp', 0.9))
        } else {
          reject(new Error('Canvas context is null'))
        }
      } else {
        // If the image doesn't need resizing, resolve with the original base64 URL
        resolve(base64Url)
      }
    }

    img.onerror = function (error) {
      reject(error)
    }

    // Set the source after attaching the onload and onerror event handlers
    img.src = base64Url
  })
}
