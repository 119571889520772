import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAIAttributeDescription } from 'api/recommendations'
import { updateAttribute } from 'api/transactions'
import {
  addFunctionalAttributes,
  getAttributeTransactionDescription,
  updateAttributeName,
} from 'api/transactions/createTransaction'
import { AddFunctionalAttributesProps } from 'api/transactions/createTransaction/types/addFunctionalAttributes.types'
import { UpdateAttributeProps } from 'api/transactions/createTransaction/types/updateAttribute.type'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import AccordianTableWrapper from 'components/accordianTableWrapper'
import Button, { ButtonVariant } from 'components/button'
import FlagReader from 'components/flagReader'
import ItemRemovalWarning from 'components/itemRemovalWarning/ItemRemovalWarning'
import Loading from 'components/loading'
import Modal from 'components/modal'
import TableWrapper from 'components/tableWrapper'
import Typography, { Variant } from 'components/typography'
import { QUERIES } from 'constants/query'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { INPUT_FIELDS as CustomInput, TEXTAREA_FIELD as TextAreaInput } from 'organisms/fieldRenderers'
import CreatableSelectDropDown from 'organisms/fieldRenderers/fields/creatableSelectDropDown'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { requiredSchema } from 'schemas'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter, getToastErrorMessage, truncateString } from 'utils/utils'
import useMirrorConfirmation from 'views/transactions/createTransaction/components/MirroredTransactionEditConfirmation/useMirrorConfirmation'
import * as yup from 'yup'

import { GroupedEntitiesByAttributes, PerEntityAttributeFomFields } from '../../types/types'
import EntityAddAttribute from './components/entityAddAttribute'
import { segregateEntitiesFromTransactions } from './helpers'
import useCommonLanguageChange from './useCommonLanguageChange.hook'

const AddFunctionModal = ({
  setIsFunctionModalOpen,
  isFunctionModalOpen,
  attributeId,
  hideIntegralLanguageButton,
  transactionRefetch,
  functionProfileRefetch,
  tabKey,
  currentAttributeData,
  currentTransaction,
  isOtherModal = false,
}: AddFunctionModalProps) => {
  const { refetchChecklist } = useReportWizardContext()

  const attributeDescriptionMutation = useMutation(getAttributeTransactionDescription)
  const [selectedAIDescription, setSelectedAIDescription] = useState<{ [key: string]: number }>({})
  const [aIDescriptions, setAIDescriptions] = useState<Record<string, string[]>>({})

  const [isNameEditable, setIsNameEditable] = useState(false)
  const [showEditNameWarning, setShowEditNameWarning] = useState(false)

  const isEditMode = !!currentAttributeData?.data

  const showDescription = !!currentTransaction.functional_analysis_description_toggle

  const { data: foreignAttributes } = useQuery(
    [QUERIES.FOREIGN_ATTRIBUTES.key, currentTransaction.functional_profile.id, tabKey],
    {
      queryFn: () =>
        QUERIES.FOREIGN_ATTRIBUTES.function({
          attributeType: tabKey,
          id: currentTransaction?.functional_profile?.id,
          exclude: true,
        }).then(res => res.data),
      enabled: isOtherModal,
    }
  )

  const clearAIDescriptions = useCallback((fieldId: string | number) => {
    setAIDescriptions(prev => {
      if (prev?.[fieldId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fieldId]: toDelete, ...rest } = prev
        return rest
      }
      return prev
    })

    setSelectedAIDescription(prev => {
      if (prev?.[fieldId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fieldId]: toDelete, ...rest } = prev
        return rest
      }
      return prev
    })
  }, [])

  const foreignAttributesOptions: SelectOptions[] = useMemo(() => {
    if (!foreignAttributes || !foreignAttributes.length) return []
    return foreignAttributes.map(attr => {
      return {
        value: attr.id,
        label: attr.name,
      }
    })
  }, [foreignAttributes])

  const { control, handleSubmit, reset, setValue, setFocus, watch, formState } = useForm<FORM_FIELDS_VALUE>({
    mode: 'all',
    resolver: !isOtherModal
      ? yupResolver(yup.object().shape({ functionName: requiredSchema }))
      : yupResolver(
          yup.object().shape({
            functionSelect: yup
              .object()
              .shape({
                label: yup.string().required('This field is required'),
                value: yup.string().required('This field is required'),
              })
              .required('This field is required'),
          })
        ),
  })

  const {
    control: entityFormControl,
    watch: entityFormWatch,
    setValue: entityFormSetValue,
    getValues: entityFormGetValues,
    setFocus: entityFormSetFocus,
    reset: entityFormReset,
    formState: entityFormState,
  } = useForm<ENTITY_FIELDS_VALUE>({})

  const isFormDirty = formState.isDirty || entityFormState.isDirty

  const basicInfoValue = watch('basicInfo')

  const { primaryEntity, associatedEntities, sourceEntities, targetEntities } = useMemo(
    () => segregateEntitiesFromTransactions(currentTransaction),
    [currentTransaction]
  )
  const isMirrored = !!currentTransaction.mirrored_with_transaction?.id
  const { getMirrorConfirmation, MirrorConfirmationModal } = useMirrorConfirmation()

  const updateAttributeMutation = useMutation(updateAttribute, {
    onSuccess() {
      setIsFunctionModalOpen(false)
      transactionRefetch()
      functionProfileRefetch()
      toast.success('Attribute updated successfully')
      reset()
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const updateAttributeNameMutation = useMutation(updateAttributeName, {
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const addOtherFunctionAttributeMutation = useMutation(addFunctionalAttributes, {
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleModalClose = useCallback(async () => {
    if (
      isFormDirty &&
      !(await getConfirmation('You may have unsaved changes, are you sure you want to exit?', 'Attention!'))
    ) {
      return
    }
    setIsFunctionModalOpen(false)
    reset()
    entityFormReset()
  }, [isFormDirty, getConfirmation, setIsFunctionModalOpen, reset, entityFormReset])

  const getAIAttributeMutation = useMutation(getAIAttributeDescription)

  const handleResetLanguage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    attributeDescriptionMutation.mutate(attributeId, {
      onSuccess(data) {
        setValue('basicInfo', data.default_description || '')
        setFocus('basicInfo')
        refetchChecklist()
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          getToastErrorMessage(error)
        }
      },
    })
  }

  const updateAIDescriptionFormValues = useCallback(
    async (fieldId: string | number, subField: string, subFieldValue: string, newIndex: number) => {
      setSelectedAIDescription(prevValue => ({ ...prevValue, [fieldId]: newIndex }))
      if (subField === 'description') {
        entityFormSetValue(`${fieldId}.${subField}`, subFieldValue)
        entityFormSetFocus(`${fieldId}.${subField}`)
      }
    },
    [entityFormSetFocus, entityFormSetValue]
  )

  const aiButtonDisabled = getAIAttributeMutation.isLoading

  const showLoadIntegralLanguage = currentAttributeData ? currentAttributeData.added_by_integral : !isOtherModal

  const handleLoadAILanguage = useCallback(
    (subField: string, fieldId?: string | number) => () => {
      const aiDescriptionsForField = aIDescriptions[fieldId || 0]
      if (fieldId) {
        if (!aiDescriptionsForField || !aiDescriptionsForField?.length) {
          getAIAttributeMutation
            .mutateAsync({
              attribute: attributeId,
              transaction: currentTransaction.id,
              legal_entity: !isNaN(parseInt(fieldId.toString())) ? parseInt(fieldId.toString()) : undefined,
              weight: entityFormGetValues(`${fieldId}.weight`) || '',
            })
            .then(aiRecommendations => {
              setAIDescriptions(prevValue => ({ ...prevValue, [fieldId]: aiRecommendations }))
              updateAIDescriptionFormValues(fieldId, subField, aiRecommendations[0], 0)
            })
            .catch((error: unknown) => {
              if (error instanceof AxiosError) {
                getToastErrorMessage(error)
              }
            })
        } else {
          const newIndex = (selectedAIDescription[fieldId] + 1) % 3
          const newDescription = aiDescriptionsForField[newIndex]
          updateAIDescriptionFormValues(fieldId, subField, newDescription, newIndex)
        }
      }
    },
    [
      aIDescriptions,
      attributeId,
      currentTransaction.id,
      entityFormGetValues,
      getAIAttributeMutation,
      selectedAIDescription,
      updateAIDescriptionFormValues,
    ]
  )

  const handleSaveAttribute = useCallback(
    async (data: FORM_FIELDS_VALUE) => {
      let newAttributeId: number | null = null
      let mirror_functional_analysis_language = false
      if (isMirrored && !(!showDescription && !isOtherModal)) {
        const status = await getMirrorConfirmation(
          isOtherModal
            ? `This is a mirrored transaction, adding ${capitalizeFirstLetter(
                tabKey
              )} to this transaction will add this  ${capitalizeFirstLetter(tabKey)} to all the mirrored transactions

${
  showDescription
    ? `Do you want to copy the language for this ${capitalizeFirstLetter(tabKey)} to mirrored transactions as well?`
    : ''
}`
            : `Do you want to copy the language for this ${capitalizeFirstLetter(tabKey)} to mirrored transactions?`,
          true
        )

        if (status == 'close') return
        if (status == 'mirrorConfirm') mirror_functional_analysis_language = true
      }

      if (isOtherModal) {
        let newAttributePayload: AddFunctionalAttributesProps
        if (typeof data.functionSelect?.value == 'number') {
          newAttributePayload = {
            functional_profile: currentTransaction.functional_profile.id,
            attribute_id: data.functionSelect.value,
          }
        } else {
          newAttributePayload = {
            attribute_type: tabKey.toLocaleLowerCase(),
            functional_profile: currentTransaction.functional_profile.id,
            attribute_name: (data.functionSelect?.label || '') as string,
          }
        }
        const newAttributeResponse = await addOtherFunctionAttributeMutation.mutateAsync(newAttributePayload)

        if (newAttributeResponse?.data?.id) {
          newAttributeId = newAttributeResponse.data.id
        } else {
          setIsFunctionModalOpen(false)
          transactionRefetch()
          refetchChecklist()
          functionProfileRefetch()
          reset()
          return
        }
      } else {
        if (
          data.functionName.trim() !== currentAttributeData?.attribute_name.trim() &&
          data.functionName.trim() &&
          !currentAttributeData?.added_by_integral
        ) {
          await updateAttributeNameMutation.mutateAsync({
            id: attributeId || 0,
            attributeName: data.functionName.trim(),
          })
        }
      }

      const attributeData: UpdateAttributeProps = {
        id: currentAttributeData?.id || null,
        primary_entity_attribute: null,
        group_attribute: null,
        individual_transaction_attributes: [],
        basic_info: showDescription ? data.basicInfo || null : undefined,
        attribute: newAttributeId || attributeId,
        transaction: currentTransaction.id,
        mirror_functional_analysis_language: mirror_functional_analysis_language || undefined,
      }

      const entityFormValues = entityFormGetValues()

      const primaryEntityId = currentTransaction.primary_entity.id
      if (entityFormValues[primaryEntityId] && entityFormValues[primaryEntityId].isActive) {
        attributeData.primary_entity_attribute = {
          individual_transaction: null,
          weight: entityFormValues[primaryEntityId].weight,
          description: showDescription ? entityFormValues[primaryEntityId].description || '' : undefined,
        }
      }

      if (entityFormValues['_common'] && entityFormValues['_common'].isActive) {
        attributeData.group_attribute = {
          individual_transaction: null,
          weight: entityFormValues['_common'].weight,
          description: showDescription ? entityFormValues['_common'].description || '' : undefined,
        }
      }

      const associatedEntityAttributes: UpdateAttributeProps['individual_transaction_attributes'] = []
      currentTransaction.individual_transactions.forEach(transaction => {
        const associatedEntityId = transaction.associated_entity.id
        if (entityFormValues[associatedEntityId] && entityFormValues[associatedEntityId].isActive) {
          associatedEntityAttributes.push({
            individual_transaction: transaction.id,
            weight: entityFormValues[associatedEntityId].weight,
            description: showDescription ? entityFormValues[associatedEntityId].description || '' : undefined,
          })
        }
      })
      attributeData.individual_transaction_attributes = associatedEntityAttributes

      updateAttributeMutation.mutate(attributeData)
    },
    [
      isMirrored,
      showDescription,
      isOtherModal,
      currentAttributeData?.id,
      currentAttributeData?.attribute_name,
      currentAttributeData?.added_by_integral,
      attributeId,
      currentTransaction.id,
      currentTransaction.primary_entity.id,
      currentTransaction.individual_transactions,
      currentTransaction.functional_profile.id,
      entityFormGetValues,
      updateAttributeMutation,
      getMirrorConfirmation,
      tabKey,
      addOtherFunctionAttributeMutation,
      setIsFunctionModalOpen,
      transactionRefetch,
      refetchChecklist,
      functionProfileRefetch,
      reset,
      updateAttributeNameMutation,
    ]
  )

  const handleSave = useCallback(
    async (data: FORM_FIELDS_VALUE) => {
      await handleSaveAttribute(data)
    },
    [handleSaveAttribute]
  )

  const getResponsesUsed = useCallback(
    (subField: string, fieldId?: string | number) => {
      const aiDescriptionsForField = aIDescriptions[fieldId || 0]
      if (
        fieldId &&
        typeof selectedAIDescription[fieldId] == 'number' &&
        aiDescriptionsForField &&
        aiDescriptionsForField.length
      ) {
        return ` (${(selectedAIDescription[fieldId] % 3) + 1}/${aiDescriptionsForField.length})`
      }
      return ''
    },
    [selectedAIDescription, aIDescriptions]
  )

  const { isGroupLanguageActive } = useCommonLanguageChange({ entityFormWatch, entityFormSetValue, associatedEntities })

  // setting default values on mount
  useEffect(() => {
    // per entity changes
    if (currentTransaction && !isOtherModal) {
      const allEntities = [...(sourceEntities || []), ...(targetEntities || [])]

      // for individual entities
      const associatedEntities = !currentTransaction.is_primary_le_source ? sourceEntities : targetEntities

      const resetValue: DefaultValues<ENTITY_FIELDS_VALUE> = {}

      allEntities.forEach(entity => {
        const entityAttrData = currentAttributeData?.data?.find(attr => entity.id === attr.legal_entity?.id)

        const isPrimary = primaryEntity.id == entity.id

        resetValue[`${entity.id}`] = {
          description: entityAttrData?.description || '',
          isActive: entityAttrData?.isActive || false,
          weight: entityAttrData?.weight || (isPrimary ? 'majority' : 'limited'),
        }
      })
      // for common language
      const commonAttrData = currentAttributeData?.data?.find(attr => !!attr.is_common_attribute)
      resetValue[`_common`] = {
        description: commonAttrData?.description || '',
        isActive: associatedEntities.length > 1 ? commonAttrData?.isActive || false : false,
        weight: commonAttrData?.weight || 'limited',
      }

      entityFormReset(resetValue)
      reset({
        basicInfo: currentAttributeData?.basic_info || '',
        functionName: currentAttributeData?.attribute_name || '',
      })
    }
  }, [
    currentAttributeData,
    currentAttributeData?.data,
    currentTransaction,
    entityFormReset,
    isOtherModal,
    primaryEntity.id,
    reset,
    sourceEntities,
    targetEntities,
  ])
  const loading =
    updateAttributeMutation.isLoading ||
    addOtherFunctionAttributeMutation.isLoading ||
    updateAttributeNameMutation.isLoading

  if (loading)
    return (
      <Modal
        className="p-6"
        title={`${isEditMode ? 'Edit' : 'Add'} ${capitalizeFirstLetter(tabKey)}`}
        isOpen={isFunctionModalOpen}
        onClose={handleModalClose}>
        <Loading className="w-[65rem] h-[40vh] flex items-center justify-center" />
      </Modal>
    )
  return (
    <Modal
      className="p-6"
      containerClassName="w-[65rem]"
      isOpen={isFunctionModalOpen}
      onClose={handleModalClose}
      title={`${isEditMode ? 'Edit' : 'Add'} ${capitalizeFirstLetter(tabKey)}`}>
      <div className="w-full space-y-4 overflow-auto" style={{ maxHeight: 'calc(100vh - 12.5rem)' }}>
        {isOtherModal && (
          <CreatableSelectDropDown
            options={foreignAttributesOptions}
            className="w-full"
            label={`${capitalizeFirstLetter(tabKey)} Name`}
            placeholder="Select existing or create new name"
            id="functionSelect"
            control={control}
            required
          />
        )}
        {!isOtherModal && !currentAttributeData?.added_by_integral ? (
          <div className="flex gap-4 items-end">
            <CustomInput
              className="w-full min-w-[300px]"
              label={`${capitalizeFirstLetter(tabKey)} Name`}
              id="functionName"
              disabled={!isNameEditable}
              control={control}
              required={isNameEditable}
            />

            <Button
              className="h-[2.375rem]"
              icon={!isNameEditable ? IconsType.edit : IconsType.cross}
              isDefaultSize={false}
              iconPathClassName={!isNameEditable ? 'stroke-white' : 'fill-white'}
              iconCLass="w-5 h-5"
              onClick={() => {
                if (!isNameEditable) {
                  setShowEditNameWarning(true)
                } else {
                  setValue('functionName', currentAttributeData?.attribute_name || '')
                  setIsNameEditable(false)
                }
              }}></Button>
          </div>
        ) : (
          <Tooltip
            title={
              (currentAttributeData?.attribute_name || '').length > 100 ? currentAttributeData?.attribute_name : ''
            }
            placement="top">
            <div>
              <Typography variant={Variant.Heading2} type="semibold" className="self-start">
                {truncateString(currentAttributeData?.attribute_name || '', 100)}
              </Typography>
            </div>
          </Tooltip>
        )}

        <form onSubmit={handleSubmit(handleSave)} className="w-full flex flex-col justify-center items-center">
          {showDescription && (
            <AccordianTableWrapper
              isActive
              className="w-full mb-4"
              title={
                <Typography variant={Variant.Callout} type="semibold" className="self-start">
                  Basic {tabKey} Information
                </Typography>
              }
              actionsContent={
                <>
                  {showLoadIntegralLanguage && !hideIntegralLanguageButton && (
                    <Tooltip title="Load Integral Language" placement="top">
                      <div>
                        <Button
                          onClick={handleResetLanguage}
                          variant={ButtonVariant.Prominent}
                          className="whitespace-nowrap"
                          icon={IconsType.reset}>
                          Integral Language
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              }>
              <TextAreaInput
                id={`basicInfo`}
                label=""
                disabled={attributeDescriptionMutation.isLoading}
                control={control}
                inputClassName="bg-slate-100 focus:bg-blue-50 text-callout text-gray-800 font-semibold"
                rows={basicInfoValue?.length > 280 ? 12 : 4}
                placeholder={`Brief description about the ${tabKey}`}
              />
            </AccordianTableWrapper>
          )}
          {associatedEntities.length > 1 ? (
            <div className="flex flex-col gap-4 w-full">
              <TableWrapper
                title={`${capitalizeFirstLetter(tabKey)} description for ${(currentTransaction.is_primary_le_source
                  ? currentTransaction?.functional_profile.source_party_identification
                  : currentTransaction?.functional_profile.target_party_identification
                ).toLowerCase()}(s)`}
                childClassName="flex flex-col gap-4 p-4 w-full">
                <EntityAddAttribute
                  key={`${primaryEntity.id}-primaryEntity`}
                  watch={entityFormWatch}
                  control={entityFormControl}
                  setValue={entityFormSetValue}
                  entityData={primaryEntity}
                  tabKey={tabKey}
                  currentTransaction={currentTransaction}
                  aiButtonDisabled={aiButtonDisabled}
                  isOtherModal={isOtherModal}
                  getResponsesUsed={getResponsesUsed}
                  handleLoadAILanguage={handleLoadAILanguage}
                  clearAIDescriptions={clearAIDescriptions}
                  showDescription={showDescription}
                />
              </TableWrapper>
              <TableWrapper
                title={`${capitalizeFirstLetter(tabKey)} description for ${(currentTransaction.is_primary_le_source
                  ? currentTransaction?.functional_profile.target_party_identification
                  : currentTransaction?.functional_profile.source_party_identification
                ).toLowerCase()}(s)`}
                childClassName="flex flex-col gap-4 p-4 w-full">
                <Typography variant={Variant.Callout} type="semibold" className="self-start">{`${capitalizeFirstLetter(
                  tabKey
                )} description for all ${(currentTransaction.is_primary_le_source
                  ? currentTransaction?.functional_profile.target_party_identification
                  : currentTransaction?.functional_profile.source_party_identification
                ).toLowerCase()}(s)`}</Typography>
                <EntityAddAttribute
                  key="_common-associatedEntity"
                  watch={entityFormWatch}
                  control={entityFormControl}
                  setValue={entityFormSetValue}
                  tabKey={tabKey}
                  clearAIDescriptions={clearAIDescriptions}
                  fallbackLabel={
                    <Typography
                      key="_common-label-associatedEntity"
                      variant={Variant.Callout}
                      type="semibold"
                      className="text-start max-w-[700px] overflow-auto">
                      {currentTransaction.is_primary_le_source
                        ? currentTransaction?.functional_profile.target_party_identification
                        : currentTransaction?.functional_profile.source_party_identification}
                      (s) &#40;{' '}
                      {associatedEntities.map((entityData, idx) => {
                        if (idx == 3 && associatedEntities.length > 3) {
                          return (
                            <span className="ms-1" key={`flag-e-${idx}`}>
                              and +{associatedEntities.length - 3} more
                            </span>
                          )
                        }
                        if (idx > 3) {
                          return null
                        }
                        return (
                          <>
                            {idx !== 0 && <span className="mr-2">,</span>}
                            <FlagReader
                              key={`flag-e-${idx}`}
                              country={entityData.country}
                              otherText={`${entityData.name_abbreviation || entityData.name}`}
                              className="!inline-flex"
                            />
                          </>
                        )
                      })}{' '}
                      &#41;
                    </Typography>
                  }
                  currentTransaction={currentTransaction}
                  aiButtonDisabled={aiButtonDisabled}
                  isOtherModal={isOtherModal}
                  getResponsesUsed={getResponsesUsed}
                  handleLoadAILanguage={handleLoadAILanguage}
                  showDescription={showDescription}
                />
                <Typography variant={Variant.Callout} type="semibold" className="self-start">{`${capitalizeFirstLetter(
                  tabKey
                )} description for individual ${(currentTransaction.is_primary_le_source
                  ? currentTransaction?.functional_profile.target_party_identification
                  : currentTransaction?.functional_profile.source_party_identification
                ).toLowerCase()}(s)`}</Typography>
                {associatedEntities.map((entityData, idx) => (
                  <EntityAddAttribute
                    key={`${entityData.id}-associatedEntity-${idx}`}
                    watch={entityFormWatch}
                    control={entityFormControl}
                    setValue={entityFormSetValue}
                    entityData={entityData}
                    tabKey={tabKey}
                    disableFeatures={isGroupLanguageActive}
                    currentTransaction={currentTransaction}
                    aiButtonDisabled={aiButtonDisabled}
                    isOtherModal={isOtherModal}
                    getResponsesUsed={getResponsesUsed}
                    handleLoadAILanguage={handleLoadAILanguage}
                    clearAIDescriptions={clearAIDescriptions}
                    showDescription={showDescription}
                  />
                ))}
              </TableWrapper>
            </div>
          ) : (
            <div className="flex flex-col gap-4 w-full">
              <Typography variant={Variant.Callout} type="semibold" className="self-start">
                {currentTransaction?.functional_profile.source_party_identification}
              </Typography>
              {sourceEntities.map((entityData, idx) => (
                <EntityAddAttribute
                  key={`${entityData.id}-entity-${idx}`}
                  watch={entityFormWatch}
                  control={entityFormControl}
                  setValue={entityFormSetValue}
                  entityData={entityData}
                  tabKey={tabKey}
                  currentTransaction={currentTransaction}
                  aiButtonDisabled={aiButtonDisabled}
                  isOtherModal={isOtherModal}
                  getResponsesUsed={getResponsesUsed}
                  handleLoadAILanguage={handleLoadAILanguage}
                  clearAIDescriptions={clearAIDescriptions}
                  showDescription={showDescription}
                />
              ))}
              <Typography variant={Variant.Callout} type="semibold" className="self-start">
                {currentTransaction?.functional_profile.target_party_identification}
              </Typography>
              {targetEntities.map((entityData, idx) => (
                <EntityAddAttribute
                  key={`${entityData.id}-entity-${idx}`}
                  watch={entityFormWatch}
                  control={entityFormControl}
                  setValue={entityFormSetValue}
                  entityData={entityData}
                  tabKey={tabKey}
                  currentTransaction={currentTransaction}
                  aiButtonDisabled={aiButtonDisabled}
                  isOtherModal={isOtherModal}
                  getResponsesUsed={getResponsesUsed}
                  handleLoadAILanguage={handleLoadAILanguage}
                  clearAIDescriptions={clearAIDescriptions}
                  showDescription={showDescription}
                />
              ))}
            </div>
          )}
        </form>
      </div>
      <Button
        onClick={handleSubmit(handleSave)}
        className="ml-auto mt-4"
        icon={isEditMode ? IconsType.save : IconsType.plus}>
        {isEditMode ? 'Save' : 'Add'}
      </Button>
      <ItemRemovalWarning
        confirmationMessage={
          <>
            Are you sure that you want to rename{' '}
            <span className="font-semibold">{currentAttributeData?.attribute_name}</span>
            <br />
            Renaming this {tabKey} will rename this across all transactions it is used.
            <br />
          </>
        }
        doConfirmationAction={() => {
          setShowEditNameWarning(false)
          setIsNameEditable(true)

          setTimeout(() => {
            setFocus('functionName')
          }, 0)
        }}
        showModal={showEditNameWarning}
        setShowModal={setShowEditNameWarning}></ItemRemovalWarning>
      {/* <DevTool control={control} /> */}
      <MirrorConfirmationModal />
      <ConfirmationModal />
    </Modal>
  )
}

interface AddFunctionModalProps {
  hideIntegralLanguageButton?: boolean
  setIsFunctionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isFunctionModalOpen: boolean
  attributeId: number
  transactionRefetch: () => void
  functionProfileRefetch: () => void
  tabKey: string
  currentAttributeData?: GroupedEntitiesByAttributes[number]
  currentTransaction: TransactionByIdResponse
  isOtherModal?: boolean
}

export interface FORM_FIELDS_VALUE {
  functionSelect?: SelectOptions
  basicInfo: string
  functionName: string
}

export interface ENTITY_FIELDS_VALUE {
  [entity: string]: PerEntityAttributeFomFields
}

export default AddFunctionModal
