import { Dialog, Transition } from '@headlessui/react'
import Rocket from 'assets/icons/rocket'
import cx from 'classnames'
import Button from 'components/button'
import { useAuth } from 'hooks/useAuth'
import { StepIds } from 'hooks/useFTUTour/types'
import useFTUTour from 'hooks/useFTUTour/useFTUTour'
import React, { Fragment, useEffect, useMemo } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

const StartTourModal = () => {
  const { user } = useAuth()
  const { step, stepLoading, setStepLoading, isTourActive, handleNextStepChange } = useFTUTour()

  const isCurrentStep = useMemo(() => {
    return step?.id === StepIds.Start
  }, [step?.id])

  useEffect(() => {
    if (isCurrentStep && stepLoading) {
      setStepLoading(false)
    }
  }, [isCurrentStep, setStepLoading, stepLoading])

  const modalOpen = isCurrentStep && !stepLoading && isTourActive

  return (
    <Transition appear show={!!modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 commonIntegralModalClass" onClose={() => console.log('Dialog closed')}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className={cx('transform  rounded-xl bg-white transition-all')}>
                <div className={cx('flex flex-col items-center rounded-lg bg-[#0A0929] p-6 w-[528px] gap-[22px]')}>
                  <div className="w-full flex flex-col items-center gap-4">
                    <div className="bg-[#32316080] p-6 rounded-full w-[76px] h-[76px]">
                      <Rocket className="w-7 h-7" pathClassName="fill-orange-500" />
                    </div>
                    <div className="flex flex-col text-white items-center self-stretch gap-3">
                      <div className="h-[25px] text-center text-white text-xs font-semibold leading-[19px]">
                        WELCOME{' '}
                        {capitalizeFirstLetter(
                          `${(user?.first_name ? `${user?.first_name} ` : '') + (user?.last_name || '')}` ||
                            user?.username ||
                            user?.email ||
                            'User'
                        )}
                        !
                      </div>
                      <div className="flex flex-col items-center self-stretch gap-1">
                        <div className="text-body font-medium	">Let’s start with a quick product tour</div>
                        <div className="text-footnote">We’ll have you up and running in no time</div>
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={handleNextStepChange}
                    className="flex !flex-row items-center !gap-1 !py-0  bg-indigo-400 hover:!bg-indigo-500 focus:!bg-indigo-500 !text-indigo-950">
                    Get started
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default StartTourModal
