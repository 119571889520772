import { Tooltip } from '@mui/material'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import LoadingStars from 'assets/lottie/loadingStars.json'
import { IconsType } from 'assets/types'
import AITextDisclaimer from 'components/aiTextDisclaimer/AITextDisclaimer'
import Button, { ButtonVariant } from 'components/button'
import Lottie from 'lottie-react'
import { MuiTextField } from 'organisms/fieldRenderers'
import { RADIO_BUTTON_GROUP as RadioButtonGroup } from 'organisms/fieldRenderers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import { capitalizeFirstLetter } from 'utils/utils'

import { ENTITY_FIELDS_VALUE } from '../../AddFunctionsModal'
import { weightSelectionOptions } from '../../config'

const AddAttribute = ({
  tabKey,
  control,
  isSource,
  fieldId,
  aiButtonDisabled,
  currentTransaction,
  handleLoadAILanguage,
  getResponsesUsed,
  disableFeatures = false,
  showAutoLoadButtons = false,
  clearAIDescriptions,
  showDescription,
}: AddAttributeProps): JSX.Element => {
  const { field } = useController({ control, name: `${fieldId}.weight`, defaultValue: 'limited' })
  const [aiButtonClicked, setAiButtonClicked] = useState(false)

  useEffect(() => {
    field.value && clearAIDescriptions(fieldId)
  }, [clearAIDescriptions, field.value, fieldId])

  const handleJarvisAI = useCallback(() => {
    handleLoadAILanguage('description', fieldId)()
    setAiButtonClicked(true)
  }, [fieldId, handleLoadAILanguage])

  const rightComponent = useMemo(() => {
    return (
      <div className="flex items-center  gap-4">
        {showAutoLoadButtons && (
          <>
            <Tooltip title={'Load AI generated language'} placement="top">
              <div>
                <Button
                  onClick={handleJarvisAI}
                  variant={ButtonVariant.AIGradient}
                  className="whitespace-nowrap ms-auto"
                  disabled={aiButtonDisabled}
                  icon={IconsType.sparkle}>
                  ERICA AI{getResponsesUsed('description', fieldId)}
                </Button>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    )
  }, [showAutoLoadButtons, fieldId, handleJarvisAI, aiButtonDisabled, getResponsesUsed])

  return (
    <div className="w-full flex flex-col">
      <RadioButtonGroup
        row
        disabled={disableFeatures}
        id={`${fieldId}.weight`}
        control={control}
        label={
          !isSource
            ? currentTransaction?.functional_profile.target_party_identification + ' significance'
            : currentTransaction?.functional_profile.source_party_identification + ' significance'
        }
        classes={{ root: 'gap-24' }}
        radioOptions={weightSelectionOptions}
      />
      {showDescription && (
        <MuiTextField
          title={`${capitalizeFirstLetter(tabKey)} Description`}
          control={control}
          rightComponent={rightComponent}
          className="relative"
          minRows={10}
          id={`${fieldId}.description`}>
          {aiButtonDisabled && (
            <div className="absolute inset-0 flex justify-center items-center opacity-90 bg-white">
              <Lottie animationData={LoadingStars} className="w-full" />
            </div>
          )}
        </MuiTextField>
      )}
      {aiButtonClicked && <AITextDisclaimer />}
    </div>
  )
}

interface AddAttributeProps {
  tabKey: string
  control: Control<ENTITY_FIELDS_VALUE>
  isSource: boolean
  fieldId: number | string
  aiButtonDisabled: boolean
  disableFeatures?: boolean
  currentTransaction: TransactionByIdResponse
  handleLoadAILanguage: (fieldName: string, fieldId: string | number) => () => void
  getResponsesUsed: (fieldName: string, fieldId: string | number) => string
  showAutoLoadButtons: boolean
  clearAIDescriptions: (fieldId: string | number) => void
  showDescription: boolean
}

export default AddAttribute
