import { ColumnDef } from '@tanstack/react-table'
import { EditableTableLabelCell } from 'organisms/NewEditableTable/NewEditableTable'

import { BenchMarkAnalysisTableData } from '../BenchMarkAnalysis'

export const benchMarkAnalysisColumn = ({
  from_year,
  to_year,
  showPercentage = false,
}: {
  from_year?: number
  to_year?: number
  showPercentage?: boolean
}) => {
  if (!from_year || !to_year) return []

  const name_column: ColumnDef<BenchMarkAnalysisTableData> = {
    header: 'Statistical Measure',
    accessorKey: 'name',
    accessorFn: row => row.name,
    cell: EditableTableLabelCell,
    meta: {
      isReadOnly: true,
    },
  }

  const year_columns: ColumnDef<BenchMarkAnalysisTableData>[] = []

  let year = to_year - from_year

  while (year >= 0) {
    const key = (from_year + year).toString()
    year_columns.push({
      accessorKey: key,
      accessorFn: row => (row[key] ? `${row[key]}${showPercentage ? '%' : ''}` : ''),
    })
    year--
  }
  const WAVG_Column: ColumnDef<BenchMarkAnalysisTableData> = {
    header: `${to_year - from_year + 1}-Yr WAVG`,
    accessorKey: 'WAVG',
    accessorFn: row => row.WAVG,
  }

  return [name_column, WAVG_Column, ...year_columns]
}
