import { useMutation, useQuery } from '@tanstack/react-query'
import { updateRejectionMatrix } from 'api/transactions/createTransaction'
import { updateRejectionMatrixPayload } from 'api/transactions/createTransaction/types/economicAnalysis.types'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonSize, ButtonVariant } from 'components/button'
import Loading from 'components/loading/Loading'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRoutingHandlerState } from 'hooks/useRoutingHandler/useRoutingHandler'
import useTemplatesMap from 'hooks/useTemplatesMap/useTemplatesMap'
import { useRouter } from 'next/router'
import FormWithSubmission from 'organisms/formWithSubmission'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage, sleep } from 'utils/utils'

import { getFields } from './helpers/rejectionMatrix.fields.helper'

function RejectionMatrix({
  transactionResponse,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionFetching,
}: RejectionMatrixProps) {
  const Router = useRouter()
  const [defaultFieldDescriptions, setDefaultFieldDescriptions] = useState<Record<number, string>>({})
  const [htmlIsDirty, setHtmlIsDirty] = useState(false)
  const { isDirty, setIsDirty } = useRoutingHandlerState()
  const dirtyHtml = useCallback(
    (dirty: boolean) => {
      if (!htmlIsDirty) {
        setHtmlIsDirty(dirty)
      }
    },
    [htmlIsDirty]
  )

  const { getRejectionMatrixTemplateOptions, data } = useTemplatesMap({ transaction: transactionResponse?.id || 0 })

  const { data: complianceRegionData, isLoading: complianceRegionLoading } = useQuery(
    [QUERIES.GET_COMPLIANCE_REGION.key, transactionResponse?.compliance_region, transactionResponse?.transaction_type],
    {
      queryFn: () =>
        QUERIES.GET_COMPLIANCE_REGION.function({
          complianceRegion: transactionResponse?.compliance_region as string,
          transactionType:
            transactionResponse?.compliance_region === 'us' ? transactionResponse?.transaction_type ?? 'services' : '',
        }),
      enabled: !!transactionResponse?.compliance_region,
    }
  )
  useEffect(() => {
    setIsDirty(htmlIsDirty)

    return () => {
      setIsDirty(false)
    }
  }, [htmlIsDirty, setIsDirty])

  const { refetchChecklist } = useReportWizardContext()

  const updateRejectionMatrixMutation = useMutation(updateRejectionMatrix, {
    onSuccess: () => {
      toast.success('Method Selection Updated', { autoClose: 3000 })
      economicAnalysisTransactionRefetch()
      setHtmlIsDirty(false)
      refetchChecklist()
    },
    onError: (erorr: AxiosError) => {
      getToastErrorMessage(erorr)
    },
  })

  const { control, handleSubmit, reset, watch, setValue, getValues } = useForm<REJECTION_MATRIX_FIELDS>()

  const [selectedTemplate] = watch(['template'])

  const rejectionMatrixTemplateOptions = useMemo(() => {
    return getRejectionMatrixTemplateOptions(String(selectedTemplate?.value || ''))
  }, [getRejectionMatrixTemplateOptions, selectedTemplate?.value])

  useEffect(() => {
    if (rejectionMatrixTemplateOptions.length && !selectedTemplate?.value) {
      setValue('template', rejectionMatrixTemplateOptions[0])
    } else if (rejectionMatrixTemplateOptions.length && selectedTemplate?.value) {
      const templateInOptions = rejectionMatrixTemplateOptions.find(opt => opt.value == selectedTemplate?.value)
      if (!templateInOptions) {
        setValue('template', rejectionMatrixTemplateOptions[0])
      }
    }
  }, [rejectionMatrixTemplateOptions, selectedTemplate?.value, setValue])

  const onSubmitHandler = useCallback(async () => {
    await sleep(0)
    const data = getValues()
    const payload: updateRejectionMatrixPayload['data'] = []
    Object.keys(data.methods).forEach(key => {
      const field = data.methods[key]
      const foundTPM = complianceRegionData?.data?.find(val => String(val.id) == String(key))
      foundTPM &&
        payload.push({
          transfer_pricing_method: Number(key),
          description: field.description || '',
        })
    })
    transactionResponse?.economic_analysis?.id &&
      updateRejectionMatrixMutation.mutate({ id: transactionResponse?.economic_analysis?.id, data: payload })
  }, [complianceRegionData?.data, getValues, transactionResponse?.economic_analysis?.id, updateRejectionMatrixMutation])

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const fields = useMemo(() => {
    return getFields({
      setValue,
      transactionId: transactionResponse?.id,
      tPMethods: complianceRegionData?.data,
      fieldDescriptions: defaultFieldDescriptions,
      selectedTPM: economicAnalysisTransactionResponse?.transfer_pricing_method
        ? Number(economicAnalysisTransactionResponse?.transfer_pricing_method)
        : undefined,
      selectedTemplate: String(selectedTemplate?.value || '') || undefined,
      rejectionMatrixTemplateOptions: rejectionMatrixTemplateOptions,
      dirtyHtml,
      rejectionMatrixTemplates: data?.rejection_matrix,
    })
  }, [
    setValue,
    transactionResponse?.id,
    complianceRegionData?.data,
    defaultFieldDescriptions,
    economicAnalysisTransactionResponse?.transfer_pricing_method,
    selectedTemplate?.value,
    rejectionMatrixTemplateOptions,
    dirtyHtml,
    data?.rejection_matrix,
  ])

  useEffect(() => {
    if (economicAnalysisTransactionResponse?.rejection_matrix?.length) {
      const descriptions: Record<number, string> = {}
      economicAnalysisTransactionResponse?.rejection_matrix.forEach(obj => {
        descriptions[obj.transfer_pricing_method] = obj.description || ''
        setValue(`methods.${obj.transfer_pricing_method}`, {
          description: obj.description,
        })
      })
      setDefaultFieldDescriptions(descriptions)
    }
  }, [economicAnalysisTransactionResponse, setValue])

  const loading =
    complianceRegionLoading ||
    !transactionResponse ||
    updateRejectionMatrixMutation.isLoading ||
    economicAnalysisTransactionFetching

  return (
    <BorderlessBox className="relative">
      <FormWithSubmission
        onSubmitHandler={onSubmitHandler}
        reset={reset}
        style={{ maxHeight: 'calc(100vh - 23.7rem)' }}
        className="w-full overflow-y-auto"
        control={control}
        fields={fields}
        handleSubmit={handleSubmit}>
        {loading && <Loading className="absolute inset-0 bg-white flex items-center justify-center z-10" />}
        <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
          <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
            <Button
              isDefaultSize={false}
              onClick={handleCancel}
              iconCLass="w-5 h-5 transform rotate-180"
              iconPathClassName="stroke-blue900"
              icon={IconsType.arrowRight}
              variant={ButtonVariant.Secondary}>
              Back
            </Button>
            <Button
              size={ButtonSize.ExtraSmall}
              onClick={onSubmitHandler}
              variant={ButtonVariant.Primary}
              disabled={!isDirty}
              icon={IconsType.save}>
              Save
            </Button>
          </div>
        </Portal>
      </FormWithSubmission>
    </BorderlessBox>
  )
}

export default RejectionMatrix

export type REJECTION_MATRIX_FIELDS = {
  methods: {
    [key: string]: {
      description: string
    }
  }
  template?: SelectOptions
}

type RejectionMatrixProps = {
  transactionResponse?: TransactionByIdResponse
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionFetching: boolean
  economicAnalysisTransactionRefetch: () => void
}
