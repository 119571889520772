import { TransferPricingMethods } from 'api/transactions/createTransaction/types/complianceRegion.type'
import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'

export const getOptions = (TransferPricingMethods: TransferPricingMethods[]) => {
  return TransferPricingMethods?.filter(method => method.selectable).map(complianceRegion => ({
    value: String(complianceRegion.id),
    label: `${complianceRegion.abbreviation} (${complianceRegion.name})`,
  }))
}

export const getPliMethodsOptions = (pliMethods: TransactionPliMethod[]) => {
  return pliMethods?.map(pliMethod => ({
    value: pliMethod.id,
    label: pliMethod.name,
  }))
}
