import { SelectOptions } from 'types/common.types'

export const FIELDS_IDS = {
  TRANSACTION_VIEW: 'transactionView',
  TP_METHOD: 'transferPricingMethod',
  PLI_METHODS: 'pliMethods',
  TESTED_PARTY: 'is_primary_entity_tested_party',
  TESTING_METHODOLOGY: 'testing_methodology',
}

export const TESTING_METHODOLOGY_OPTIONS: SelectOptions[] = [
  {
    label: 'Testing P&L',
    value: 'testing_p_and_l',
  },
  {
    label: 'Testing a Policy',
    value: 'testing_a_policy',
  },
  {
    label: 'Testing Transaction Margin',
    value: 'testingTransactionMargin',
    disabled: true,
  },
]
