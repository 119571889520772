import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

const InfoLabel = ({ newInput = true, label, required = false, labelClassName }: InfoLabelProps) => {
  if (!label && !required) return null
  return newInput ? (
    <Typography
      variant={Variant.ContainerText}
      type="semibold"
      className={classNames('mb-[0.125rem] flex items-center text-gray-600', labelClassName)}>
      <span className="my-1">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  ) : (
    <Typography
      variant={Variant.Callout}
      type="semibold"
      className={classNames('mb-[0.125rem] text-neutral-800 flex items-center', labelClassName)}>
      <span className="my-1">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  )
}

interface InfoLabelProps {
  label: string
  required?: boolean
  newInput?: boolean
  labelClassName?: string
}

export default InfoLabel
