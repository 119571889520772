import { useQuery } from '@tanstack/react-query'
import { QUERIES } from 'constants/query'
import { useCallback, useMemo } from 'react'
import { SelectOptions } from 'types/common.types'

function useTemplatesMap(variables: { transaction: number }) {
  const { data } = useQuery([QUERIES.GET_TEMPLATES_MAP.key], {
    queryFn: () => QUERIES.GET_TEMPLATES_MAP.function(variables),
    enabled: !!variables.transaction,
  })

  const economicConclusionTemplateOptions: SelectOptions[] = useMemo(() => {
    if (!data?.conclusion?.economic_analysis?.length) return []
    return data.conclusion.economic_analysis.map(val => {
      return {
        value: val.name,
        label: val.label,
      }
    })
  }, [data?.conclusion?.economic_analysis])

  const functionalConclusionTemplateOptions: SelectOptions[] = useMemo(() => {
    if (!data?.conclusion?.functional_analysis?.length) return []
    return data.conclusion.functional_analysis.map(val => {
      return {
        value: val.name,
        label: val.label,
      }
    })
  }, [data?.conclusion?.functional_analysis])

  const getRejectionMatrixTemplateOptions = useCallback(
    (method?: string): SelectOptions[] => {
      const uniqueMap = new Map<string, SelectOptions>()
      if (data?.rejection_matrix) {
        Object.keys(data?.rejection_matrix).forEach(key => {
          const templates = data?.rejection_matrix?.[key]
          if (method) {
            const keyToUse = key === method ? 'selected' : 'not_selected'
            if (templates) {
              templates?.[keyToUse]?.forEach(item => {
                if (!uniqueMap.has(item.name)) {
                  uniqueMap.set(item.name, {
                    label: item.label,
                    value: item.name,
                  })
                }
              })
            }
          } else {
            if (templates?.['selected']?.length) {
              templates['selected'].forEach(item => {
                if (!uniqueMap.has(item.name)) {
                  uniqueMap.set(item.name, {
                    label: item.label,
                    value: item.name,
                  })
                }
              })
            }
            if (templates?.['not_selected']?.length) {
              templates['not_selected'].forEach(item => {
                if (!uniqueMap.has(item.name)) {
                  uniqueMap.set(item.name, {
                    label: item.label,
                    value: item.name,
                  })
                }
              })
            }
          }
        })
      }
      return Array.from(uniqueMap.values())
    },
    [data?.rejection_matrix]
  )

  return {
    data,
    economicConclusionTemplateOptions,
    functionalConclusionTemplateOptions,
    getRejectionMatrixTemplateOptions,
  }
}

export default useTemplatesMap
