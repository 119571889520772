import AppColors from 'constants/colors'
import { StylesConfig } from 'react-select'

export const selectStyle = (errors: boolean): StylesConfig => {
  return {
    control: base => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: '#1E293B',
        border: `1px solid ${errors ? '#F87171' : '#CBD5E1'}`,
        borderRadius: '0.75rem',
        textAlign: 'left',
        backgroundColor: errors ? AppColors.red50 : AppColors.white,
      }
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#EFF6FF' : 'white',
        color: '#1E293B',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        zIndex: 20,
      }
    },
  }
}
