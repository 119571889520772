import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { SelectOptions } from 'types/common.types'

import DropDown from './dropDown'

const CustomDropDown = ({ value, disabled = false, options, icon, onChange, className }: CustomDropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const handleDropDownTrigger: MouseEventHandler<HTMLButtonElement> = event => {
    if (disabled) {
      event.stopPropagation()
    }
    setIsOpen(prevState => !prevState)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const handleSelectedValue = useCallback(
    (value: string) => () => {
      onChange && onChange(value)
      setIsOpen(false)
    },
    [onChange]
  )

  return (
    <div className="relative w-fit" ref={wrapperRef}>
      <div
        className={cx(
          'flex items-center w-fit p-3 rounded-lg h-8',
          disabled && 'bg-gray-200 hover:cursor-not-allowed',
          className
        )}>
        <div className="flex items-center gap-3 mr-5">
          {icon && getIcons(icon, { className: 'w-[1.125rem] h-[1.125rem]' })}
          <Typography variant={Variant.Callout} type="semibold" className="text-neutral800">
            {value}
          </Typography>
        </div>
        <IconButton
          disabled={disabled}
          placeholder="Please select"
          className="w-[1.125rem] h-[1.125rem]"
          onClick={handleDropDownTrigger}
          sx={{
            padding: '0',
          }}>
          <ExpandMoreIcon />
        </IconButton>
      </div>

      {isOpen && <DropDown handleSelectedValue={handleSelectedValue} options={options} />}
    </div>
  )
}

interface CustomDropDownProps {
  value: string
  options: SelectOptions[]
  icon?: IconsType
  disabled?: boolean
  onChange?: (value: string) => void
  className?: string
}

export default CustomDropDown
