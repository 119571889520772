import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createComparableResults, updateComparableResults } from 'api/transactions/comparableResults'
import { ComparableResultConclusion } from 'api/transactions/comparableResults/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button/Button'
import Loading from 'components/loading/Loading'
import Modal from 'components/modal'
import { currencyOptions } from 'constants/currencies'
import { QUERIES } from 'constants/query'
import { useRouter } from 'next/router'
import { INPUT_FIELDS } from 'organisms/fieldRenderers'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { CurrencyRequiredForValueTypes, ValueOptions } from './constants/armsLengthRangeConclusion.constants'
import {
  getConclusionsPayload,
  getConclusionTableColumns,
  getConclusionTableData,
} from './helpers/armsLengthRangeConclusion.helper'
import { armsLengthRangeConclusionSchema } from './schema/armsLengthRangeConclusion.schema'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  data?: ComparableResultConclusion
  creationCallBack?: (data: ComparableResultConclusion) => void
}

const EditCreateArmsLengthRangeConclusion = ({ isOpen, onClose, data, creationCallBack }: Props) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { control, watch, setValue, getValues, trigger } = useForm<FIELD_VALUES>({
    defaultValues: {
      conclusionTable: [],
    },
    resolver: yupResolver(armsLengthRangeConclusionSchema),
  })

  const [conclusionTable, armsLengthRange, name, valueType] = watch([
    'conclusionTable',
    'armsLengthRange',
    'name',
    'value',
  ])

  const { data: ComparableResultConclusionsList, isFetching: ComparableResultConclusionsListFetching } = useQuery(
    [QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.key],
    {
      queryFn: () => QUERIES.GET_ARMS_LENGTH_RANGE_TYPES.function(),
    }
  )

  const setConclusionTable: (action: (data: ConclusionTableItem[]) => ConclusionTableItem[]) => void = useCallback(
    action => {
      const tableValue = getValues('conclusionTable')
      setValue('conclusionTable', action(tableValue))
    },
    [getValues, setValue]
  )

  const conclusionTableColumns = useMemo(() => {
    return getConclusionTableColumns()
  }, [])

  const selectedRangeData = useMemo(() => {
    if (!ComparableResultConclusionsList?.length || !armsLengthRange?.value) {
      return undefined
    }
    return ComparableResultConclusionsList.find(opt => opt.value == armsLengthRange.value)
  }, [armsLengthRange?.value, ComparableResultConclusionsList])

  const ArmsLengthRangeOptions: SelectOptions[] = useMemo(() => {
    if (!ComparableResultConclusionsList?.length) return []
    return ComparableResultConclusionsList.map(opt => {
      return {
        value: opt.value,
        label: opt.label,
      }
    })
  }, [ComparableResultConclusionsList])

  useEffect(() => {
    setValue(
      'conclusionTable',
      getConclusionTableData({ type: selectedRangeData, editable: true, comparableResultConclusion: data })
    )
  }, [data, selectedRangeData, setValue])

  useEffect(() => {
    if (data) {
      setValue('name', data?.name)
      setValue(
        'armsLengthRange',
        ArmsLengthRangeOptions.find(opt => opt.value == data?.arms_length_range)
      )
      setValue(
        'currency',
        data?.currency
          ? currencyOptions.find(opt => opt.value == data?.currency) || {
              value: data?.currency,
              label: data?.currency,
            }
          : undefined
      )
    }
    setValue('value', ValueOptions.find(opt => opt.value == data?.metric) || ValueOptions[0])
  }, [ArmsLengthRangeOptions, data, data?.arms_length_range, data?.name, setValue])

  useEffect(() => {
    if (!armsLengthRange?.value && ArmsLengthRangeOptions.length && !data) {
      setValue('armsLengthRange', ArmsLengthRangeOptions[0])
    }
  }, [ArmsLengthRangeOptions, armsLengthRange?.value, data, setValue])

  const createComparableResultsMutation = useMutation(createComparableResults)
  const updateComparableResultsMutation = useMutation(updateComparableResults)

  const onSave = useCallback(async () => {
    const valid = await trigger()
    if (!valid) return
    try {
      const formData = getValues()
      const conclusionsData = getConclusionsPayload(formData.conclusionTable)
      if (data?.id) {
        updateComparableResultsMutation.mutate(
          {
            arms_length_range: String(armsLengthRange?.value || ''),
            name: formData.name || '',
            data: conclusionsData,
            id: data.id,
            metric: String(formData.value?.value || ''),
            currency:
              formData.value?.value && CurrencyRequiredForValueTypes.includes(String(formData.value?.value))
                ? String(formData.currency?.value || '') || null
                : null,
          },
          {
            onSuccess(response) {
              queryClient.refetchQueries([QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.key])
              onClose()
              toast.success('Comparable result conclusion updated!!')
              typeof creationCallBack == 'function' && creationCallBack(response)
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      } else {
        createComparableResultsMutation.mutate(
          {
            arms_length_range: String(armsLengthRange?.value || ''),
            year: Number(router.query.year),
            name: formData.name || '',
            data: conclusionsData,
            metric: String(formData.value?.value || ''),
            currency:
              formData.value?.value && CurrencyRequiredForValueTypes.includes(String(formData.value?.value))
                ? String(formData.currency?.value || '') || null
                : null,
          },
          {
            onSuccess(response) {
              queryClient.refetchQueries([QUERIES.GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST.key])
              onClose()
              toast.success('Comparable result conclusion created!!')
              typeof creationCallBack == 'function' && creationCallBack(response)
            },
            onError(error) {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }, [
    armsLengthRange?.value,
    createComparableResultsMutation,
    creationCallBack,
    data?.id,
    getValues,
    onClose,
    queryClient,
    router.query.year,
    trigger,
    updateComparableResultsMutation,
  ])

  const loading =
    updateComparableResultsMutation.isLoading ||
    createComparableResultsMutation.isLoading ||
    ComparableResultConclusionsListFetching

  return (
    <Modal
      title={`${data?.id ? 'Edit' : 'Add New'} Conclusion`}
      isOpen={isOpen}
      onClose={onClose}
      containerClassName="p-6 max-w-full w-[1000px]">
      <div className="flex flex-col items-stretch gap-4 w-full relative">
        {loading && <Loading className="absolute inset-0 flex items-center justify-center z-20 bg-white" />}
        <div className="w-full gap-6 flex items-center justify-between">
          <INPUT_FIELDS control={control} characterLimit={150} label="Name" id="name" className="w-full" required />
          <SelectDropDown
            options={ArmsLengthRangeOptions}
            control={control}
            label="Arm's Length Range"
            required
            id={'armsLengthRange'}
          />
        </div>
        <div className="w-full gap-6 flex items-center justify-between">
          <SelectDropDown options={ValueOptions} control={control} label="Value" required id={'value'} />
          {valueType?.value && CurrencyRequiredForValueTypes.includes(String(valueType?.value)) ? (
            <SelectDropDown options={currencyOptions} control={control} label="Currency" required id={'currency'} />
          ) : (
            <div className="w-full" />
          )}
        </div>
        {!!armsLengthRange?.value && (
          <BorderlessBox label="Conclusion" variant="white" className="w-full gap-3 items-stretch !p-0">
            <NewEditableTable
              showHeader={false}
              setData={setConclusionTable}
              data={conclusionTable}
              columns={conclusionTableColumns}
              isCommaFormatted
            />
          </BorderlessBox>
        )}
        <div className="flex items-center justify-end">
          <Button
            onClick={onSave}
            disabled={!name || !armsLengthRange?.value || loading}
            className="!gap-1"
            icon={IconsType.save}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EditCreateArmsLengthRangeConclusion

export type ConclusionTableItem = {
  key: string
  label: string
  value: string
  isEditable: boolean
  isEditAllowed: boolean
}

type FIELD_VALUES = {
  conclusionTable: ConclusionTableItem[]
  armsLengthRange?: SelectOptions
  name?: string
  value?: SelectOptions
  currency?: SelectOptions
}
