import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import EditSuccessFullModal from 'components/editSuccesfullModal/EditSuccesfullModal'
import { PORTAL_IDS } from 'components/Portal/constants'
import Portal from 'components/Portal/Portal'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

const CreateTransactionFooter = ({ handleSave, disabled, txnName, id, showEditModal = true }: FinancialDataProps) => {
  const Router = useRouter()
  const isEdit = Router.query.isEdit === 'true'
  const [isEditModal, setIsEditModal] = useState<boolean>(false)

  const handleCancel = useCallback(() => {
    Router.push(ROUTES.TRANSACTION_MANAGEMENT)
  }, [Router])

  const handleSaveClick = useCallback(() => {
    if (isEdit && !isEditModal) {
      setIsEditModal(true)
    } else {
      handleSave && handleSave()
    }
  }, [handleSave, isEdit, isEditModal])

  const handleModalClose = () => {
    setIsEditModal(false)
  }

  useEffect(() => {
    if (isEdit && isEditModal && txnName && id) {
      handleSave && handleSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router, id, isEdit, isEditModal, txnName])

  return (
    <Portal portalId={PORTAL_IDS.FOOTER_COMPONENT_ID}>
      <div className="flex items-center justify-between px-6 py-4 footer-box-shadow">
        <Button
          isDefaultSize={false}
          onClick={handleCancel}
          iconCLass="w-5 h-5 transform rotate-180"
          iconPathClassName="stroke-blue900"
          icon={IconsType.arrowRight}
          className="w-28"
          variant={ButtonVariant.Secondary}>
          Back
        </Button>
        <Button
          disabled={disabled}
          onClick={handleSaveClick}
          buttonType="submit"
          icon={IconsType.save}
          className="w-28">
          Save
        </Button>
        <EditSuccessFullModal txnName={txnName} isOpen={isEditModal && showEditModal} handleClose={handleModalClose} />
      </div>
    </Portal>
  )
}

interface FinancialDataProps {
  handleSave?: VoidFunction
  disabled?: boolean
  txnName: string
  id: number
  showEditModal?: boolean
}

export default CreateTransactionFooter
