import { INPUT_FIELDS, SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import { Field } from 'organisms/formWithSubmission/types/form.types'

import BenchmarkResults from '../components/benchmarkResults'
import { FIELDS_IDS } from './constants'

export const FIELDS: (Field | Field[])[] = [
  [
    {
      id: FIELDS_IDS.NAME,
      fieldToRenderer: INPUT_FIELDS,
      label: 'Name',
    },
    {
      id: FIELDS_IDS.REGION,
      fieldToRenderer: SELECT_DROPDOWN,
      label: 'Region',
    },
  ],
  {
    id: FIELDS_IDS.QUARTERLY_ANALYSIS,
    fieldToRenderer: BenchmarkResults,
  },
]
