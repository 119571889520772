import { useQuery } from '@tanstack/react-query'
import { QUERIES } from 'constants/query'
export const useAllBenchmarkList = ({ year }: PayloadProps) => {
  const {
    data: benchmarksList,
    isFetching,
    isLoading,
    refetch: refetchBenchmarks,
  } = useQuery([QUERIES.GET_ALL_BENCHMARKS.key, year], { queryFn: () => QUERIES.GET_ALL_BENCHMARKS.function(year) })

  return {
    benchmarksList,
    isFetching,
    isLoading,
    refetchBenchmarks,
  }
}

interface PayloadProps {
  year?: number
}
