import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { FunctionalAttributes } from 'api/transactions/createTransaction/types/functionalProfileAttributes.types'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import FlagReader from 'components/flagReader'
import Typography, { Variant } from 'components/typography'
import { capitalizeFirstLetter } from 'utils/utils'

import { GroupedEntitiesByAttributes } from '../../types/types'

export const selectedEntitiesColumns = ({
  handleDeleteModalOpen,
  handleEditModalOpen,
  groupedEntitiesByAttributes,
}: selectedEntitiesColumnsProps): ColumnDef<FunctionalAttributes>[] => {
  return [
    {
      header: '',
      id: 'name',
      accessorKey: 'attribute_name',
      size: '100%' as unknown as number,
      cell: props => {
        const groupedData = groupedEntitiesByAttributes[props.row.original.id]?.data
        const entitiesInSelectedAttribute = groupedData
          .map(attribute => attribute.legal_entity)
          .filter(entity => !!entity)

        return (
          <div className="flex flex-col items-start px-2 overflow-hidden">
            <Typography className="text-start overflow-hidden text-ellipsis" variant={Variant.Callout} type="semibold">
              {props.row.original.name}
            </Typography>
            <div className={`flex items-center gap-2 ${entitiesInSelectedAttribute.length > 0 ? 'mt-2' : ''}`}>
              <div className="text-start overflow-hidden text-ellipsis flex flex-wrap">
                {!!entitiesInSelectedAttribute.length && entitiesInSelectedAttribute.length <= 3 ? (
                  entitiesInSelectedAttribute.map(
                    (entity, entityIdx) =>
                      entity && (
                        <Typography
                          key={`selectedAttribute-${entity.id}`}
                          variant={Variant.Callout}
                          className="text-neutral800 flex whitespace-nowrap">
                          {!!entityIdx && <span className="mr-2">,</span>}
                          <FlagReader country={entity.country} otherText={capitalizeFirstLetter(entity.name)} />
                        </Typography>
                      )
                  )
                ) : (
                  <>
                    {entitiesInSelectedAttribute.map(
                      (entity, entityIdx) =>
                        entity &&
                        entityIdx < 3 && (
                          <Typography
                            key={`selectedAttribute-${entity.id}`}
                            variant={Variant.Callout}
                            className="text-neutral800 flex whitespace-nowrap">
                            {!!entityIdx && <span className="mr-2">,</span>}
                            <FlagReader country={entity.country} otherText={capitalizeFirstLetter(entity.name)} />
                          </Typography>
                        )
                    )}
                    {
                      <Tooltip
                        classes={{
                          tooltip:
                            'p-0 bg-[#1A193B] w-[200px] max-h-[258px] overflow-y-auto rounded-lg border border-solid border-neutral-500',
                        }}
                        title={
                          <>
                            {entitiesInSelectedAttribute.map(
                              (entity, entityIdx) =>
                                entity &&
                                entityIdx >= 3 && (
                                  <>
                                    <div className={cx('flex flex-col py-2 self-stretch px-3 gap-1')}>
                                      <Typography
                                        key={`relatedParty-${entity.id}`}
                                        variant={Variant.Callout}
                                        type="semibold"
                                        className={cx('text-gray-300 max-w-[100%] truncate gap-1 flex')}>
                                        <FlagReader country={entity.country} />
                                        {capitalizeFirstLetter(entity.name)}
                                      </Typography>
                                    </div>
                                  </>
                                )
                            )}
                          </>
                        }
                        placement="top">
                        <span className="flex items-center ml-2">
                          {entitiesInSelectedAttribute.length ? (
                            <span className="bg-orange-100 rounded-[0.25rem] p-[0.25rem] text-orange-700 text-footnote">
                              +{entitiesInSelectedAttribute.length - 3}
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </Tooltip>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      header: '',
      id: 'actions',
      accessorKey: 'id',
      cell: props => {
        const onEditClick = () => {
          handleEditModalOpen(props.row.original.id)
        }
        return (
          <div className="flex items-center gap-3 justify-end px-2">
            <div className="cursor-pointer" onClick={onEditClick}>
              {getIcons(IconsType.edit)}
            </div>
            <div className="cursor-pointer" onClick={() => handleDeleteModalOpen(props.row.original.id)}>
              {getIcons(IconsType.delete)}
            </div>
          </div>
        )
      },
    },
  ]
}

interface selectedEntitiesColumnsProps {
  handleDeleteModalOpen: (id: number) => void
  handleEditModalOpen: (id: number) => void
  groupedEntitiesByAttributes: GroupedEntitiesByAttributes
}
