import { ColumnDef, FilterFn, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import NoEntryFoundContainer from 'components/table/components/noEntryFoundContainer/NoEntryFoundContainer'
import Typography, { Variant } from 'components/typography'
import Fuse from 'fuse.js'
import { useCallback } from 'react'

import AttributeHeadRow from './components/attributeHeadRow'
import AttributeTableRow from './components/attributeTableRow'

function AttributeTable<T>({
  columns,
  data = [],
  className,
  title,
  tableClassName,
  tableWrapperClassName,
  bodyClassName,
  isWidthSet,
  isRoundedCorner = true,
  rules = 'all',
  isTextCenter,
  bottomButtonProps,
  headerClassName,
  style,
  reorderRow,
  globalFilter,
}: AttributeTableProps<T>) {
  const fuzzyFilter: FilterFn<T> = useCallback((row, columnId, value) => {
    let columnIds = []
    if (columnId.includes('|')) {
      columnIds = [...columnId.split('|')]
    } else {
      columnIds.push(columnId)
    }
    const fuse = new Fuse([row.original], {
      keys: [...columnIds],
      threshold: 0.3,
      includeMatches: true,
    })

    const matches = fuse.search(value)

    if (matches.length === 0) {
      return false
    }

    return true
  }, [])

  const { getHeaderGroups, getRowModel } = useReactTable<T>({
    state: {
      globalFilter,
    },
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false,
    pageCount: 1000,
    globalFilterFn: fuzzyFilter,
  })

  return (
    <div className="relative w-full">
      <div style={style} className={cx(className)}>
        {title && (
          <div
            className={cx(
              'bg-blue50 h-11 flex flex-col items-center justify-center border border-solid border-neutral300 border-b-0 sticky top-0',
              isRoundedCorner && 'rounded-t-2xl',
              {
                'text-start': !isTextCenter,
                'text-center': isTextCenter,
              },
              tableWrapperClassName
            )}>
            <Typography variant={Variant.Callout} type="semibold">
              {title}
            </Typography>
          </div>
        )}
        <div
          className={cx(
            isRoundedCorner && 'rounded-b-2xl overflow-hidden',
            'bg-blue-50 border border-solid border-neutral300 w-full flex flex-col justify-end items-end ',
            {
              'rounded-xl': isRoundedCorner && !title,
            }
          )}>
          <table
            cellPadding={0}
            rules={rules || 'all'}
            className={cx('w-full border-collapse custom-border-color', tableClassName)}
            style={{ boxShadow: '0 0 0 1px #E5E7EB' }}>
            <thead>
              {getHeaderGroups().map(headerGroup => (
                <AttributeHeadRow
                  headerClassName={headerClassName}
                  isTextCenter={isTextCenter}
                  key={headerGroup.id}
                  headerGroup={headerGroup}
                  reorderRow={reorderRow}
                />
              ))}
            </thead>

            {getRowModel()?.rows?.length ? (
              <tbody className={bodyClassName}>
                {getRowModel()?.rows?.map(row => {
                  return <AttributeTableRow isWidthSet={isWidthSet} key={row.id} row={row} reorderRow={reorderRow} />
                })}
              </tbody>
            ) : (
              <>
                <NoEntryFoundContainer columns={columns} classes={{ body: cx(bodyClassName, 'w-full') }} />
              </>
            )}
          </table>
        </div>
      </div>
      {bottomButtonProps?.title && (
        <div className="sticky bottom-0 border border-solid border-l-0  border-b-0 border-neutral300 flex flex-col justify-end items-end pr-4">
          <Button
            variant={ButtonVariant.Secondary}
            onClick={bottomButtonProps.onClick}
            isDefaultSize={false}
            iconPathClassName="stroke-[#0D9488]"
            className="flex items-end justify-end mt-2 mb-2 !bg-[#F0FDF5] !border-[#F0FDF5] !text-[#0D9488] hover:!bg-teal-50 active:bg-teal-50"
            icon={IconsType.plus}>
            {bottomButtonProps.title}
          </Button>
        </div>
      )}
    </div>
  )
}

interface BottomButtonProps {
  title: string
  onClick: VoidFunction
}

interface AttributeTableProps<T> {
  columns: ColumnDef<T, unknown>[]
  data: T[]
  className?: string
  title?: string | JSX.Element
  tableClassName?: string
  tableWrapperClassName?: string
  isWidthSet?: boolean
  isRoundedCorner?: boolean
  rules?: 'columns' | 'all' | 'none' | 'groups' | 'rows'
  isTextCenter?: boolean
  bottomButtonProps?: BottomButtonProps
  headerClassName?: string
  bodyClassName?: string
  style?: React.CSSProperties
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
  globalFilter?: string
  setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>
}

export default AttributeTable
