import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  summaryRoot: {
    backgroundColor: 'white',
    borderbottom: '1px solid #1E3A8A',
  },
  summaryContent: {
    marginTop: '0',
    marginBottom: '0',
  },
  root: {
    background: 'transparent',
    border: '1px solid #1E3A8A',
    borderRadius: '1rem !important',
    overflow: 'hidden',
    boxShadow: 'none',
  },
})

export const accordianSummary = {
  '.css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
    margin: '0 !important',
    borderBottom: '1px solid #CBD5E1',
  },
}
