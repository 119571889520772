import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import React, { InputHTMLAttributes, useEffect, useState } from 'react'
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  className,
  inputClassName,
  isSearchExpanded = true,
  iconClassName = 'w-5 h-5',
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
  inputClassName?: string
  iconClassName?: string
  isSearchExpanded?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, debounce, onChange])

  // const Router = useRouter()
  // const dispatch = useDispatch()

  // const rightComponent = useMemo(
  //   () => getRightComponent({ activeLink, Router, dispatch }),
  //   [activeLink, Router, dispatch]

  return (
    <label
      onClick={() => setIsFocused(true)}
      className={cx(
        'border border-solid border-neutral300 rounded-xl px-3 py-[0.438rem] flex justify-center items-center gap-2 bg-white cursor-pointer',
        isFocused ? 'shadow-lg border-blue-500' : 'shadow-sm',
        className
      )}>
      <div className="flex">{getIcons(IconsType.search, { className: iconClassName })}</div>

      {(isFocused || value || isSearchExpanded) && (
        <input
          className={cx(
            'border-none bg-b outline-none focus:outline-none placeholder:text-neutral-500 w-full h-full text-body',
            inputClassName
          )}
          {...props}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={() => setIsFocused(false)}
        />
      )}

      {value && (
        <div
          onClick={() => setValue('')}
          className="w-[1.125rem] h-[1.125rem] flex items-center justify-center cursor-pointer">
          {getIcons(IconsType.cross)}
        </div>
      )}
    </label>
  )
}

export default DebouncedInput
