import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { useCallback, useEffect, useRef } from 'react'

import SuccessCheck from '../../../public/lottie/successCheck.json'

const EditSuccessFullModal = ({ isOpen, handleClose, txnName }: EditSuccessFullModalProps) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  useEffect(() => {
    if (isOpen && lottieRef.current) {
      setTimeout(() => {
        if (lottieRef.current) {
          lottieRef.current.goToAndPlay(0)
          lottieRef.current.setSpeed(1.25)
        }
      }, 0)
    }
  }, [isOpen])

  const onAnimComplete = useCallback(() => {
    setTimeout(() => {
      if (isOpen) {
        handleClose()
      }
    }, 1000)
  }, [handleClose, isOpen])

  return (
    <Modal
      containerClassName="min-h-[376px] w-[526px]"
      className="flex gap-10 p-6 flex-col justify-center items-center "
      isOpen={isOpen}
      onClose={handleClose}>
      <Lottie
        className="object-contain h-40 mt-6"
        onComplete={onAnimComplete}
        animationData={SuccessCheck}
        lottieRef={lottieRef}
        loop={false}
      />
      <Typography variant={Variant.Body} className="mt-4">
        Transaction with the name <span className="text-sm font-semibold">{txnName}</span> has been successfully
        updated.
      </Typography>
    </Modal>
  )
}

interface EditSuccessFullModalProps {
  isOpen: boolean
  handleClose: () => void
  txnName: string
}

export default EditSuccessFullModal
