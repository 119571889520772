import AppColors from 'constants/colors'
import { StylesConfig } from 'react-select'
import { SelectOptions } from 'types/common.types'

export const selectStyle = (errors: boolean): StylesConfig<SelectOptions, true> => {
  return {
    control: base => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: '#1E293B',
        border: `1px solid ${errors ? '#F87171' : '#CBD5E1'}`,
        borderColor: errors ? '#F87171' : '#CBD5E1',
        borderRadius: '0.75rem',
        textAlign: 'left',
        backgroundColor: errors ? AppColors.red50 : AppColors.white,
      }
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#EFF6FF' : 'white',
        color: '#1E293B',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        zIndex: 20,
      }
    },
    placeholder: base => {
      return {
        ...base,
        color: AppColors.gray['400'],
      }
    },
    menu: base => {
      return {
        ...base,
        borderRadius: '0.75rem',
        marginTop: '0.25rem',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        border: `1px solid #CBD5E1`,
        padding: '1rem',
        zIndex: 10000,
      }
    },
    menuList: base => {
      return {
        ...base,
        maxHeight: 250,
      }
    },
    multiValue: base => {
      return {
        ...base,
        borderRadius: 30,
      }
    },
  }
}
