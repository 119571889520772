import { Tooltip } from '@mui/material'
import Typography, { Variant } from 'components/typography'
import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import React, { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'
import { SelectOptions } from 'types/common.types'
import LegalEntityNameCell from 'views/legalEntity/legalEntityOverview/components/legalEntityCustomCell/LegalEntityNameCell'

import { TestedPartyProps } from './testedParty.types'

const TestedParty = <T extends FieldValues>({
  control,
  transactionResponse,
  label,
  required,
  id,
}: TestedPartyProps<T>): JSX.Element => {
  const testedPartyOptions: SelectOptions[] = useMemo(() => {
    return [
      {
        value: 'true',
        disabled: !transactionResponse,
        label: (
          <div className="flex items-center gap-2">
            <Typography variant={Variant.Callout} type="semibold">
              {(transactionResponse?.is_primary_le_source
                ? transactionResponse?.functional_profile.source_party_identification
                : transactionResponse?.functional_profile.target_party_identification) || 'Primary entity'}
            </Typography>
            -
            <LegalEntityNameCell
              country={transactionResponse?.primary_entity.country}
              name={
                transactionResponse?.primary_entity?.name_abbreviation ||
                transactionResponse?.primary_entity?.name ||
                'Primary Entity'
              }
              textClassname={'!w-[150px] shrink-0 truncate'}
            />
          </div>
        ),
        filtertext: `${
          (transactionResponse?.is_primary_le_source
            ? transactionResponse?.functional_profile.source_party_identification
            : transactionResponse?.functional_profile.target_party_identification) || 'Primary entity'
        } ${transactionResponse?.primary_entity.country.name || ''} ${
          transactionResponse?.primary_entity?.name || 'Primary Entity'
        }`,
      },
      {
        label: (
          <div className="flex items-center gap-2">
            <Typography variant={Variant.Callout} type="semibold">
              {(!transactionResponse?.is_primary_le_source
                ? transactionResponse?.functional_profile.source_party_identification
                : transactionResponse?.functional_profile.target_party_identification) || 'Secondary entity'}
            </Typography>
            -
            {transactionResponse?.individual_transactions.length ? (
              transactionResponse?.individual_transactions.length != 1 ? (
                <Tooltip
                  title={transactionResponse?.associated_entities_group_name || 'Associated Entity/Entities'}
                  placement="top">
                  <span>
                    <Typography type="semibold" variant={Variant.Callout}>
                      {transactionResponse?.associated_entities_group_name || 'Associated Entity/Entities'}
                    </Typography>
                  </span>
                </Tooltip>
              ) : (
                <LegalEntityNameCell
                  textClassname={'!w-[150px] shrink-0 truncate'}
                  country={transactionResponse?.individual_transactions[0].associated_entity.country}
                  name={
                    transactionResponse?.individual_transactions[0].associated_entity.name ||
                    'Associated Entity/Entities'
                  }
                />
              )
            ) : null}
          </div>
        ),
        value: 'false',
        disabled: !transactionResponse?.individual_transactions.length,
      },
    ]
  }, [transactionResponse])

  return (
    <SELECT_DROPDOWN
      isCustomFilter
      label={label}
      required={required}
      options={testedPartyOptions}
      id={id}
      control={control}
    />
  )
}

export default TestedParty
