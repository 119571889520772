import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import { ROUTES } from 'constants/routes'
import ChecklistParentAccordion from 'organisms/reportWizard/components/ChecklistParentAccordion'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useMemo, useState } from 'react'

import { getTXNAppendixColumns } from './TransactionAppendixTab.helpers'

type Props = {
  data: ReturnType<typeof getAppendicesConfig>['transactions']['data']
}

const TransactionAppendixTab = ({ data }: Props) => {
  const [filePreviewUrl, setfilePreviewUrl] = useState<string>()
  const [isFullScreen, setIsFullScreen] = useState(false)

  const onClosePreview = useCallback(() => {
    setfilePreviewUrl(undefined)
  }, [])

  const handlePreview = useCallback((data: TransactionAppendixTabRow) => {
    if (data.file) setfilePreviewUrl(data.file)
  }, [])

  const tableData: {
    name: string
    id: number
    table: TransactionAppendixTabRow[]
  }[] = useMemo(() => {
    return (
      data?.map(txn => {
        const txnData: {
          name: string
          id: number
          table: TransactionAppendixTabRow[]
        } = {
          name: txn.name,
          id: txn.id,
          table: [],
        }

        if (txn.intercompany_agreement.file) {
          txnData.table.push({
            fileType: 'Intercompany Agreement Document',
            file: txn.intercompany_agreement.file,
            file_name: txn.intercompany_agreement.file_name || 'Intercompany Agreement Document',
            testedParties: [],
          })
        }
        if (txn.comparability_factors?.comparability_factors_appendices.length) {
          txn.comparability_factors?.comparability_factors_appendices.forEach(factor => {
            if (factor.file) {
              txnData.table.push({
                fileType: txn.comparability_factors?.combined_comparability_factors
                  ? 'Comparability factor'
                  : `Supporting document for Comparability Factor ${factor.comparability_factor_name}`,
                file: factor.file,
                file_name:
                  factor.file_name ||
                  (txn.comparability_factors?.combined_comparability_factors
                    ? 'Comparability factor'
                    : 'Comparability Factor Supporting Document'),
                testedParties: [],
              })
            }
          })
        }
        if (txn.controlled_transaction_terms?.file) {
          txnData.table.push({
            fileType: 'Controlled Transaction Terms',
            file: txn.controlled_transaction_terms?.file,
            file_name: txn.controlled_transaction_terms?.file_name || 'Controlled Transaction Terms',
            testedParties: [],
          })
        }
        if (txn.uncontrolled_transaction_terms?.file) {
          txnData.table.push({
            fileType: 'Uncontrolled Transaction Terms',
            file: txn.uncontrolled_transaction_terms?.file,
            file_name: txn.uncontrolled_transaction_terms?.file_name || 'Uncontrolled Transaction Terms',
            testedParties: [],
          })
        }

        if (txn.financial_data_files?.length) {
          txn.financial_data_files.forEach(doc => {
            if (doc.appendix.file) {
              txnData.table.push({
                fileType: 'Financial Data File',
                file: doc.appendix.file,
                file_name: doc.appendix.file_name || 'Financial Data File',
                testedParties: [doc.tested_party],
              })
            }
          })
        }

        if (txn.benchmarkings?.length) {
          txn.benchmarkings.forEach(bm => {
            if (bm.combined_benchmarking.file) {
              txnData.table.push({
                fileType: `${bm.name} - Combined Benchmarking`,
                file: bm.combined_benchmarking.file,
                file_name: bm.combined_benchmarking.file_name || `${bm.name} - Combined Benchmarking`,
                testedParties: bm.tested_parties,
              })
            }
            if (bm.benchmark_studies.file) {
              txnData.table.push({
                fileType: `${bm.name} - Benchmark Studies`,
                file: bm.benchmark_studies.file,
                file_name: bm.benchmark_studies.file_name || `${bm.name} - Benchmark Studies`,
                testedParties: bm.tested_parties,
              })
            }
            if (bm.company_info.file) {
              txnData.table.push({
                fileType: `${bm.name} - Company Info`,
                file: bm.company_info.file,
                file_name: bm.company_info.file_name || `${bm.name} - Company Info`,
                testedParties: bm.tested_parties,
              })
            }
            if (bm.accepted_rejection_matrix.file) {
              txnData.table.push({
                fileType: `${bm.name} - Accepted Rejection Matrix`,
                file: bm.accepted_rejection_matrix.file,
                file_name: bm.accepted_rejection_matrix.file_name || `${bm.name} - Accepted Rejection Matrix`,
                testedParties: bm.tested_parties,
              })
            }
          })
        }

        txnData.table = txnData.table.filter(row => !!row.file)

        return txnData
      }) || []
    ).filter(txn => !!txn.table.length)
  }, [data])

  const columns = useMemo(() => {
    return getTXNAppendixColumns({ handlePreview })
  }, [handlePreview])

  return (
    <div className="w-full space-y-2">
      {tableData.map((txn, idx) => {
        return (
          <ChecklistParentAccordion
            isOpen={!idx}
            added={false}
            key={txn.id}
            href={`${ROUTES.TRANSACTION_MANAGEMENT}/${txn.id}`}
            label={txn.name}>
            <NewTable
              tableRowClassName="!bg-[#141431] !border-none hover:!bg-[#141431]"
              tableCellClassname="!border-none"
              tableHeadClassName="!bg-[#212048] !text-white"
              data={txn.table}
              columns={columns}
            />
          </ChecklistParentAccordion>
        )
      })}
      <DocumentPreviewModal
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        documentPreviewModal={!!filePreviewUrl}
        setDocumentPreviewModal={onClosePreview}
        selectedFile={filePreviewUrl || undefined}
      />
    </div>
  )
}

export default TransactionAppendixTab

export type TransactionAppendixTabRow = {
  fileType: string
  file_name?: null | string
  file?: null | string
  testedParties?: {
    id: number
    name_abbreviation: string
    name: string
    country: {
      code: string
      name: string
    }
  }[]
}
