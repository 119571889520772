import { Tooltip } from '@mui/material'
import { CountryResponse } from 'api/common'
import { LegalEntityResponse } from 'api/legalEntity/types/legalEntity'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

const LegalEntityNameCell = ({
  id,
  name,
  country,
  shouldRedirect = false,
  textClassname,
  status,
}: LegalEntityNameCellProps): JSX.Element => {
  const Router = useRouter()

  const handleNameClick = useCallback(() => {
    if (shouldRedirect) Router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${id}?name=${name}`)
  }, [Router, id, name, shouldRedirect])

  return (
    <div className="flex flex-col items-start">
      <Typography
        variant={Variant.Callout}
        type="semibold"
        onClick={handleNameClick}
        className={cx(
          'break-words',
          shouldRedirect ? 'cursor-pointer text-blue-800' : 'text-neutral800',
          textClassname
        )}>
        {country?.code && (
          <Tooltip title={country.name} placement="top">
            <Image
              src={`https://flagcdn.com/w160/${country.code?.toLowerCase()}.png`}
              width={18}
              height={10}
              alt="contryFlag"
              className="mr-1"
            />
          </Tooltip>
        )}
        <Tooltip title={name} placement="top">
          <span>{capitalizeFirstLetter(name)}</span>
        </Tooltip>
      </Typography>
      {status == 'dormant' && (
        <div className="bg-red-100 mt-0.5 min-h-[20px] py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
          Inactive
        </div>
      )}
    </div>
  )
}

interface LegalEntityNameCellProps {
  id?: number
  name: string
  country?: CountryResponse
  shouldRedirect?: boolean
  textClassname?: string
  status?: LegalEntityResponse['active_status']
}

export default LegalEntityNameCell
