import { useQuery } from '@tanstack/react-query'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import { useMemo } from 'react'

import { getPliMethodsOptions } from './helpers'

export const usePliMethods = ({ transactionResponse, tp_methods, enabled = true }: usePliMethodsProps) => {
  const { user } = useAuth()
  const { data, refetch, isFetching } = useQuery([QUERIES.GET_TRANSACTION_PLI_METHOD.key, user?.id, tp_methods], {
    queryFn: () => QUERIES.GET_TRANSACTION_PLI_METHOD.function({ tp_methods }),
    enabled: !!transactionResponse && enabled,
  })

  const pliMethods = useMemo(() => {
    return data?.data
  }, [data])

  const pliMethodsOptions = useMemo(() => getPliMethodsOptions(pliMethods) || [], [pliMethods])

  return {
    pliMethodsOptions,
    refetch,
    pliMethods,
    fetchingPli: isFetching,
  }
}

interface usePliMethodsProps {
  transactionResponse?: TransactionByIdResponse
  tp_methods?: number
  enabled?: boolean
}
