import axios from 'api'

import { AppendicesResponse, UpdateAppendixFile } from '../types/appendices'

export const getLegalEntityAppendices = (entityId: number) =>
  axios
    .get<AppendicesResponse>(
      `/api/v1/org/{organization}/legal_entity/legal_entity/${entityId}/get_legal_entity_appendices/`
    )
    .then(res => res.data)

export const uploadBulkAppendices = ({ filesData, files }: { filesData: UpdateAppendixFile[]; files: File[] }) => {
  const formData = new FormData()
  formData.append('data', JSON.stringify(filesData))
  files.forEach(file => {
    formData.append('files', file)
  })
  return axios
    .put('/api/v1/org/{organization}/legal_entity/local_file_appendix/bulk_create_or_update/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data)
}
