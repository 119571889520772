import { Benchmark } from 'api/benchmarks/types'
import { getIcons } from 'assets/index'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import CustomDropDown from 'components/CustomDropDown'
import Typography, { Variant } from 'components/typography'
import { generateYearOptions } from 'lib/getYears'
import NewEditableTable from 'organisms/NewEditableTable/NewEditableTable'
import { useEffect, useMemo, useState } from 'react'
import { UseFormResetField } from 'react-hook-form'
import { FORM_FIELDS } from 'views/benchmarks/overview/organisms/updateBenchmarkModal/types'
import { BenchMarkAnalysisTableData } from 'views/transactions/createTransaction/organisms/economicAnalysis/components/benchMarkAnalysis/BenchMarkAnalysis'
import { benchMarkAnalysisColumn } from 'views/transactions/createTransaction/organisms/economicAnalysis/components/benchMarkAnalysis/config/benchMarkAnalysis.column'
import { benchMarkAnalysisTableConfig } from 'views/transactions/createTransaction/organisms/economicAnalysis/components/benchMarkAnalysis/config/benchMarkAnalysis.tableData'

import { convertTableDataToBenchmarkData } from './helpers'

const BenchmarkResults = ({
  quarterlyAnalysis,
  updateQuarterlyAnalysis,
  disableYear,
  setFromYear,
  setToYear,
  from_year,
  to_year,
}: BenchmarkResultsProps) => {
  const [benchMarkAnalysisData, setBenchMarkAnalysisData] = useState<BenchMarkAnalysisTableData[]>([])

  const benchmarkColumns = benchMarkAnalysisColumn({ from_year, to_year })

  const benchMarkAnalysisTableConfigs = useMemo(
    () =>
      benchMarkAnalysisTableConfig({
        from_year,
        to_year,
        isEditAllowed: true,
        quarterlyAnalysis,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [from_year, to_year]
  )

  useEffect(() => {
    const quarterlyAnalysis = convertTableDataToBenchmarkData(from_year, to_year, benchMarkAnalysisData)
    updateQuarterlyAnalysis(quarterlyAnalysis)
  }, [benchMarkAnalysisData, from_year, to_year, updateQuarterlyAnalysis])

  useEffect(() => {
    setBenchMarkAnalysisData(() => benchMarkAnalysisTableConfigs)
  }, [benchMarkAnalysisTableConfigs])

  const yearOptions = useMemo(() => generateYearOptions(10), [])

  const filteredToYearOptions = useMemo(() => {
    return yearOptions.filter(opt => Number(opt.value) - from_year < 5 && Number(opt.value) >= from_year)
  }, [from_year, yearOptions])

  return (
    <div className="rounded-lg p-3  space-y-3 bg-neutral-50">
      <div className={cx('flex items-center justify-between w-full')}>
        <Typography variant={Variant.Body} className="text-neutral-800 flex items-center gap-2 !font-medium">
          {getIcons(IconsType.benchmarking, {
            className: 'w-[1.125rem] h-[1.125rem]',
            pathClassName: 'fill-blue-800',
          })}
          <span>{`Benchmarking results including working capital adjustments if any`}</span>
        </Typography>
        <div className="flex items-center gap-6">
          <div className="flex items-center justify-end gap-2">
            <Typography variant={Variant.Callout} type="semibold">
              From
            </Typography>
            <CustomDropDown
              disabled={!!disableYear}
              className="bg-white border border-solid border-neutral300"
              icon={IconsType.calendar}
              options={yearOptions}
              value={String(from_year)}
              onChange={setFromYear}
            />
          </div>
          <div className="flex items-center justify-end gap-2">
            <Typography variant={Variant.Callout} type="semibold">
              To
            </Typography>
            <CustomDropDown
              disabled={!!disableYear}
              className="bg-white border border-solid border-neutral300"
              icon={IconsType.calendar}
              options={filteredToYearOptions}
              value={String(to_year)}
              onChange={setToYear}
            />
          </div>
        </div>
      </div>
      <NewEditableTable
        data={benchMarkAnalysisData}
        setData={setBenchMarkAnalysisData}
        className="mt-4"
        isCommaFormatted
        columns={benchmarkColumns}
      />
    </div>
  )
}

interface BenchmarkResultsProps {
  selectedYear: number
  updateSelectedYear: (year: number) => void
  quarterlyAnalysis: Benchmark['quarterly_analysis']
  updateQuarterlyAnalysis: (data: Benchmark['quarterly_analysis']) => void
  disableYear?: boolean
  resetField?: UseFormResetField<FORM_FIELDS>
  setFromYear: (year: string) => void
  setToYear: (year: string) => void
  from_year: number
  to_year: number
}

export default BenchmarkResults
