import { Benchmark } from 'api/benchmarks/types'
import { complianceRegions } from 'organisms/benchmark/basicDetailsForm/constants/constants'
import { SelectOptions } from 'types/common.types'

export const comparableSearchOptions = (benchmarksList?: Benchmark[]): SelectOptions[] => {
  return complianceRegions.map(({ code, label }) => {
    const benchmarkLength = benchmarksList?.filter(benchmark => benchmark?.region === code)?.length || 0

    return {
      label: (
        <span>
          {label}
          <span className="text-neutral-400 text-xs font-semibold ml-2">({benchmarkLength})</span>
        </span>
      ),
      value: code,
    }
  })
}
