import * as Sentry from '@sentry/nextjs'
import { BaseUrl } from 'constants/apis'
import { COOKIE } from 'constants/cookie'
import { logoutEvent } from 'hooks/useAuth/event'
import { checkAndUpdateAccessToken } from 'hooks/useAuth/useAuth'
import { getCookie } from 'hooks/useCookie/useCookie.helper'
import { jwtDecode } from 'jwt-decode'
import { getOrgIdFromUrl } from 'utils/utils'

async function fetchWithAuth(url: string, options: RequestInit = {}) {
  try {
    let authToken = getCookie(COOKIE.authToken)

    if (authToken) {
      const decodedToken = jwtDecode(authToken)
      const timeDifferenceInMin = ((decodedToken.exp || 0) - Date.now() / 1000) / 60

      if (timeDifferenceInMin < 1) {
        const newToken = await checkAndUpdateAccessToken()
        if (!newToken) {
          logoutEvent.emit('logout')
          throw new Error('Failed to fetch access token')
        }
        authToken = newToken
      }
      options.headers = {
        ...options.headers,
        Authorization: `Auth0Bearer ${authToken}`,
      }
    }

    const orgId = sessionStorage.getItem('orgId') ?? getOrgIdFromUrl() ?? localStorage.getItem('orgId')
    if (url.includes('{organization}')) {
      if (orgId && !!Number(orgId)) {
        url = url.replace('{organization}', orgId)
      }
    }

    const response = await fetch((BaseUrl || '').replace(/\/$/, '') + '/' + url.replace(/^\//, ''), options)

    if (!response.ok) {
      const errorData = await response.json()
      const authTokenError = [
        'token is expired',
        'User not found',
        'Authentication credentials were not provided.',
      ].includes(errorData.detail)

      if (response.status === 401 && authTokenError) {
        logoutEvent.emit('logout')
      }
      throw new Error(response.statusText || '')
    }

    return response
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export default fetchWithAuth
