import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { updateEconomicAnalysisTransaction } from 'api/transactions'
import { updateEconomicAnalysis } from 'api/transactions/createTransaction'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { AxiosError } from 'axios'
import CreateTransactionFooter from 'components/createTransactionFooter'
import Loading from 'components/loading'
import { QUERIES } from 'constants/query'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { usePliMethods } from 'hooks/usePliMethods'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import FormWithSubmission from 'organisms/formWithSubmission'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { getOptions } from './helpers/basicInfo.common.helpers'
import { getFields } from './helpers/basicInfo.fields.helpers'
import { basicInfoSchema } from './schema/basicInfo.schema'

const BasicInfo = ({
  transactionResponse,
  transactionByIdRefetch,
  economicAnalysisTransactionRefetch,
  economicAnalysisTransactionResponse,
}: BasicInfoProps) => {
  const { control, watch, setValue, handleSubmit, reset, setError, clearErrors } =
    useForm<TRANSFER_PRICING_FIELDS_VALUE>({
      resolver: yupResolver(basicInfoSchema),
      mode: 'onSubmit',
    })

  const transferPricingMethod = watch('transferPricingMethod')
  const pli_method = watch('pliMethods')
  const testing_methodology = watch('testing_methodology')
  const testedParty = watch('is_primary_entity_tested_party')

  const { data: complianceRegionData, isFetching: fetchingTPmethods } = useQuery(
    [QUERIES.GET_COMPLIANCE_REGION.key, transactionResponse?.compliance_region, transactionResponse?.transaction_type],
    {
      queryFn: () =>
        QUERIES.GET_COMPLIANCE_REGION.function({
          complianceRegion: transactionResponse?.compliance_region as string,
          transactionType:
            transactionResponse?.compliance_region === 'us' ? transactionResponse?.transaction_type ?? 'services' : '',
        }),
      enabled: !!transactionResponse?.compliance_region,
    }
  )

  const TransferPricingMethods = complianceRegionData?.data

  const pliRequired = useMemo(() => {
    const selectedPLIMethod = TransferPricingMethods?.find(opt => transferPricingMethod?.value == opt.id)
    return !!selectedPLIMethod?.required_economic_analysis_sections.includes('financial_data')
  }, [TransferPricingMethods, transferPricingMethod?.value])

  const { pliMethodsOptions, fetchingPli } = usePliMethods({
    transactionResponse,
    tp_methods: transferPricingMethod?.value ? Number(transferPricingMethod.value) : undefined,
    enabled: !!transferPricingMethod?.value && !!pliRequired,
  })

  useEffect(() => {
    if (pli_method?.value) {
      clearErrors('pliMethods')
    }
    if (testing_methodology?.value) {
      clearErrors('testing_methodology')
    }
  }, [clearErrors, pli_method?.value, testing_methodology?.value])

  useEffect(() => {
    economicAnalysisTransactionResponse?.transfer_pricing_method &&
      setValue('transferPricingMethod', {
        label: `${economicAnalysisTransactionResponse?.transfer_pricing_method_abbreviation} (${economicAnalysisTransactionResponse?.transfer_pricing_method_name})`,
        value: economicAnalysisTransactionResponse?.transfer_pricing_method,
      })
    typeof economicAnalysisTransactionResponse?.is_primary_entity_tested_party == 'boolean'
      ? setValue(
          'is_primary_entity_tested_party',
          economicAnalysisTransactionResponse?.is_primary_entity_tested_party
            ? {
                label: 'Primary',
                value: 'true',
              }
            : {
                label: 'Associated',
                value: 'false',
              }
        )
      : setValue('is_primary_entity_tested_party', {
          label: 'Primary',
          value: 'true',
        })
    if (economicAnalysisTransactionResponse?.testing_methodology) {
      setValue('testing_methodology', {
        label: economicAnalysisTransactionResponse?.testing_methodology,
        value: economicAnalysisTransactionResponse?.testing_methodology,
      })
    }
    if (economicAnalysisTransactionResponse?.pli_method) {
      setValue('pliMethods', {
        label: economicAnalysisTransactionResponse?.pli_method,
        value: economicAnalysisTransactionResponse?.pli_method,
      })
    }
  }, [economicAnalysisTransactionResponse, setValue])

  const { refetchChecklist } = useReportWizardContext()

  const updateEconomicAnalysisMutation = useMutation(updateEconomicAnalysis, {
    onSuccess: () => {
      toast.success('Economic analysis added successfully')
      transactionByIdRefetch()
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const updateMutation = useMutation(updateEconomicAnalysisTransaction, {
    onSuccess: () => {
      toast.success('Economic analysis updated successfully')
      transactionByIdRefetch()
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
  })

  const TP_OPTIONS = useMemo(() => getOptions(TransferPricingMethods || []), [TransferPricingMethods])

  const fields = useMemo(
    () =>
      getFields({
        TP_OPTIONS,
        control,
        setValue,
        transactionResponse,
        pliMethodsOptions,
        pliRequired,
      }),
    [TP_OPTIONS, control, setValue, transactionResponse, pliMethodsOptions, pliRequired]
  )

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const { tpMethodChanged, testedPartyChanged, pliChanged, testingMethodChanged } = useMemo(() => {
    const tpMethodChanged =
      !!economicAnalysisTransactionResponse?.transfer_pricing_method &&
      transferPricingMethod?.value != economicAnalysisTransactionResponse?.transfer_pricing_method
    const testedPartyChanged =
      typeof economicAnalysisTransactionResponse?.is_primary_entity_tested_party == 'boolean' &&
      (testedParty?.value == 'true') != economicAnalysisTransactionResponse?.is_primary_entity_tested_party
    const pliChanged =
      economicAnalysisTransactionResponse?.pli_method && pliRequired
        ? pli_method?.value != economicAnalysisTransactionResponse?.pli_method
        : false
    const testingMethodChanged =
      economicAnalysisTransactionResponse?.testing_methodology && pliRequired
        ? testing_methodology?.value != economicAnalysisTransactionResponse?.testing_methodology
        : false
    return {
      tpMethodChanged,
      testedPartyChanged,
      pliChanged,
      testingMethodChanged,
    }
  }, [
    economicAnalysisTransactionResponse?.is_primary_entity_tested_party,
    economicAnalysisTransactionResponse?.pli_method,
    economicAnalysisTransactionResponse?.testing_methodology,
    economicAnalysisTransactionResponse?.transfer_pricing_method,
    pliRequired,
    pli_method?.value,
    testedParty?.value,
    testing_methodology?.value,
    transferPricingMethod?.value,
  ])

  const handleSaveHandler = useCallback(
    async (data: TRANSFER_PRICING_FIELDS_VALUE) => {
      if (pliRequired) {
        if (!data.pliMethods || !data.testing_methodology) {
          if (!data.pliMethods?.value) {
            setError(
              'pliMethods',
              {
                type: 'custom',
                message: 'PLI is required',
              },
              {
                shouldFocus: true,
              }
            )
          } else {
            clearErrors('pliMethods')
          }
          if (!data.testing_methodology?.value) {
            setError(
              'testing_methodology',
              {
                type: 'custom',
                message: 'Testing methodology is required',
              },
              {
                shouldFocus: true,
              }
            )
          } else {
            clearErrors('testing_methodology')
          }
          return
        }
      }

      if (
        (tpMethodChanged || testedPartyChanged || pliChanged || testingMethodChanged) &&
        !!economicAnalysisTransactionResponse?.id
      ) {
        const confirmation = await getConfirmation(
          `Changes that you have made might reset some economic analysis sections. Are you sure you want to proceed?`,
          'Attention!!'
        )
        if (!confirmation) return
      }
      if (transactionResponse?.economic_analysis === null) {
        updateEconomicAnalysisMutation.mutate({
          // pli_method: Number(data?.pliMethods?.value),
          transfer_pricing_method: Number(data?.transferPricingMethod?.value || 0),
          transaction: transactionResponse?.id,
          is_primary_entity_tested_party: data.is_primary_entity_tested_party
            ? data.is_primary_entity_tested_party.value == 'true'
            : true,
          pli_method: pliRequired ? Number(data.pliMethods?.value || '0') : undefined,
          testing_methodology: pliRequired ? String(data.testing_methodology?.value || '') : undefined,
        })
      } else {
        updateMutation.mutate({
          id: transactionResponse?.economic_analysis?.id || 0,
          // pli_method: Number(data?.pliMethods?.value),
          transaction: transactionResponse?.id,
          transfer_pricing_method: Number(data?.transferPricingMethod?.value),
          is_primary_entity_tested_party: data.is_primary_entity_tested_party
            ? data.is_primary_entity_tested_party.value == 'true'
            : true,
          pli_method: pliRequired ? Number(data.pliMethods?.value || '0') : undefined,
          testing_methodology: pliRequired ? String(data.testing_methodology?.value || '') : undefined,
        })
      }
    },
    [
      clearErrors,
      economicAnalysisTransactionResponse?.id,
      getConfirmation,
      pliChanged,
      pliRequired,
      setError,
      testedPartyChanged,
      testingMethodChanged,
      tpMethodChanged,
      transactionResponse?.economic_analysis,
      transactionResponse?.id,
      updateEconomicAnalysisMutation,
      updateMutation,
    ]
  )

  const isLoading =
    updateMutation.isLoading ||
    updateEconomicAnalysisMutation.isLoading ||
    fetchingPli ||
    fetchingTPmethods ||
    !transactionResponse ||
    (!!transactionResponse.economic_analysis?.id && !economicAnalysisTransactionResponse)

  return (
    <FormWithSubmission
      control={control}
      handleSubmit={handleSubmit}
      onSubmitHandler={handleSaveHandler}
      reset={reset}
      style={{ maxHeight: 'calc(100vh - 22.5rem)' }}
      className="relative overflow-y-auto"
      fields={fields}>
      <CreateTransactionFooter
        id={transactionResponse?.id || 0}
        txnName={transactionResponse?.name || ''}
        showEditModal={false}
        handleSave={handleSubmit(handleSaveHandler)}
        disabled={
          !!economicAnalysisTransactionResponse?.id
            ? !(tpMethodChanged || testedPartyChanged || pliChanged || testingMethodChanged)
            : false
        }
      />
      <ConfirmationModal />
      {isLoading && <Loading className="absolute inset-0 bg-white flex items-center justify-center z-10" />}
    </FormWithSubmission>
  )
}

interface BasicInfoProps {
  transactionResponse?: TransactionByIdResponse
  transactionByIdRefetch: () => void
  economicAnalysisTransactionRefetch: () => void
  economicAnalysisTransactionResponse?: economic_analysis
}

export interface TRANSFER_PRICING_FIELDS_VALUE {
  transferPricingMethod?: SelectOptions
  pliMethods?: SelectOptions
  is_primary_entity_tested_party?: SelectOptions
  testing_methodology?: SelectOptions
}

export default BasicInfo
