type FormattedCurrencyResult = string | null

export function formatCurrency(value: number | string): FormattedCurrencyResult {
  // Ensure the input value is a number or can be converted to a number
  if (isNaN(parseFloat(value as string))) {
    return null
  }

  const floatValue = parseFloat(value as string)
  const fixedValue = Number.isInteger(floatValue) ? floatValue.toFixed(0) : floatValue.toFixed(2)

  // Insert commas as thousand separators
  const parts = fixedValue.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Prepend the $ sign and join the parts
  return `${parts.join('.')}`
}
