import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { updateBenchmarkTransactionEntities } from 'api/benchmarks'
import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { economic_analysis, quarterly_analysis, transaction_entity } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import FlagReader from 'components/flagReader/FlagReader'
import Loading from 'components/loading'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useAllBenchmarkList } from 'hooks/useAllBenchmarkLists'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter, getToastErrorMessage, truncateString } from 'utils/utils'

import BenchmarkAddEntitiesModal from './components/BenchmarkAddEntitiesModal/BenchmarkAddEntitiesModal'
import { getTestedPartBenchmarksColumn } from './helpers/benchMarkAnalysis.helpers'
import { TestedPartyBenchmarksTableData } from './types'

const BenchmarkingAnalysis = ({
  transactionResponse,
  economicAnalysisTransactionResponse,
  transactionByIdRefetch,
  economicAnalysisTransactionRefetch,
  onTabChange,
}: BenchmarkingAnalysisProps) => {
  const router = useRouter()
  const { year } = router.query
  const [showBenchmarkEntitiesModal, setShowBenchmarkEntitiesModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TestedPartyBenchmarksTableData>()

  const toggleBenchmarkEntitiesModal = useCallback(() => {
    setShowBenchmarkEntitiesModal(open => !open)
    setSelectedRow(undefined)
  }, [])

  const { benchmarksList, isLoading, isFetching, refetchBenchmarks } = useAllBenchmarkList({ year: Number(year) })

  useEffect(() => {
    if (
      economicAnalysisTransactionResponse &&
      typeof economicAnalysisTransactionResponse.is_primary_entity_tested_party !== 'boolean'
    ) {
      toast.warning('Select and save tested party first to add benchmarking data')
      onTabChange(0)
    }
  }, [economicAnalysisTransactionResponse, onTabChange])

  const testedParties = useMemo(() => {
    if (!transactionResponse || !economicAnalysisTransactionResponse) return []
    if (economicAnalysisTransactionResponse?.is_primary_entity_tested_party) {
      return [transactionResponse.primary_entity]
    } else {
      return transactionResponse.individual_transactions.map(txn => txn.associated_entity)
    }
  }, [economicAnalysisTransactionResponse, transactionResponse])

  const tabledata = useMemo(() => {
    if (!economicAnalysisTransactionResponse?.tested_party_benchmarkings?.length) return []
    const uniqueBenchmarkings = new Set(
      economicAnalysisTransactionResponse?.tested_party_benchmarkings.map(tpb => {
        return tpb.benchmarking
      })
    )
    const returnValue: TestedPartyBenchmarksTableData[] = []
    uniqueBenchmarkings.forEach(value => {
      const legalEntites: transaction_entity[] = []
      economicAnalysisTransactionResponse?.tested_party_benchmarkings
        ?.filter(tpb => {
          return tpb.benchmarking == value
        })
        .forEach(tpb => {
          const party = testedParties.find(entity => {
            return entity.id == tpb.tested_party
          })
          if (party) legalEntites.push(party)
        })
      const benchmarkingData = benchmarksList?.find(benchmark => benchmark.id == value)
      returnValue.push({
        benchmarkId: value,
        legalEntites,
        benchmarkingData,
      })
    })
    return returnValue
  }, [benchmarksList, economicAnalysisTransactionResponse?.tested_party_benchmarkings, testedParties])

  const editBenchmarkEntity = useCallback(
    (row: TestedPartyBenchmarksTableData) => () => {
      setSelectedRow(row)
      setShowBenchmarkEntitiesModal(true)
    },
    []
  )

  const { refetchChecklist } = useReportWizardContext()

  const updateBenchmarkTransactionEntitiesMutation = useMutation(updateBenchmarkTransactionEntities, {
    onSuccess: () => {
      toast.success('Removed Selected Benchmark')
      economicAnalysisTransactionRefetch()
      refetchChecklist()
    },
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
  })

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleDelete = useCallback(
    (row: TestedPartyBenchmarksTableData) => async () => {
      if (
        await getConfirmation(
          'Are you sure you want to delete benchmark selection for these legal entities?',
          'Attention!!'
        )
      ) {
        if (!economicAnalysisTransactionResponse?.id) return
        updateBenchmarkTransactionEntitiesMutation.mutate({
          economic_analysis: economicAnalysisTransactionResponse.id,
          benchmarking: row.benchmarkId,
          tested_parties: [],
        })
      }
    },
    [economicAnalysisTransactionResponse?.id, getConfirmation, updateBenchmarkTransactionEntitiesMutation]
  )

  const handleLEClick = useCallback(
    (id: number) => () => {
      router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${id}`)
    },
    [router]
  )

  const tableColumns = useMemo(() => {
    return getTestedPartBenchmarksColumn({ editBenchmarkEntity, handleDelete, handleLEClick })
  }, [editBenchmarkEntity, handleDelete, handleLEClick])

  const testedPartiesOptions: SelectOptions[] = useMemo(() => {
    if (!testedParties.length) return []
    return testedParties.map(party => {
      return {
        label: (
          <Tooltip title={capitalizeFirstLetter(party.name_abbreviation || party.name)} placement="top">
            <div className={classNames('text-neutral800 truncate flex')}>
              <FlagReader
                country={party.country}
                otherText={truncateString(capitalizeFirstLetter(party.name_abbreviation || party.name), 20)}
              />
            </div>
          </Tooltip>
        ),
        value: party.id,
      }
    })
  }, [testedParties])

  const saveCallback = useCallback(() => {
    refetchBenchmarks()
    transactionByIdRefetch()
    economicAnalysisTransactionRefetch()
  }, [economicAnalysisTransactionRefetch, refetchBenchmarks, transactionByIdRefetch])

  const excludedBenchmarks: number[] = useMemo(() => {
    if (!tabledata.length || selectedRow) return []
    return tabledata.map(data => data.benchmarkId)
  }, [selectedRow, tabledata])

  if (
    isLoading ||
    isFetching ||
    !transactionResponse ||
    !economicAnalysisTransactionResponse ||
    updateBenchmarkTransactionEntitiesMutation.isLoading
  ) {
    return <Loading className="flex flex-col h-[50vh] justify-center items-center" />
  }

  return (
    <div>
      <BorderlessBox className="gap-4">
        <BorderlessBox className="!py-3" variant="white">
          <div className="flex items-center justify-between w-full">
            <Typography variant={Variant.Callout} className=" text-gray-700 flex items-center">
              <Info className="text-[#fb9800] w-4 h-4 mr-1" /> Add legal entities to a benchmark
            </Typography>
            <Button
              onClick={toggleBenchmarkEntitiesModal}
              variant={ButtonVariant.Tertiary}
              icon={IconsType.plus}
              className="!gap-1 !py-0 !h-auto">
              Add
            </Button>
          </div>
        </BorderlessBox>
        {!!tabledata.length && (
          <BorderlessBox variant="white">
            <NewTable data={tabledata} columns={tableColumns} />
          </BorderlessBox>
        )}
      </BorderlessBox>
      <BenchmarkAddEntitiesModal
        year={Number(year)}
        isOpen={showBenchmarkEntitiesModal}
        onClose={toggleBenchmarkEntitiesModal}
        testedPartiesOptions={testedPartiesOptions}
        benchmarksList={benchmarksList}
        selectedRow={selectedRow}
        economicAnalysisTransactionResponse={economicAnalysisTransactionResponse}
        saveCallback={saveCallback}
        excludedBenchmarks={excludedBenchmarks}
      />
      <ConfirmationModal />
    </div>
  )
}

interface BenchmarkingAnalysisProps {
  transactionResponse?: TransactionByIdResponse
  transactionByIdRefetch: VoidFunction
  transactionByIdLoading: boolean
  economicAnalysisTransactionResponse?: economic_analysis
  economicAnalysisTransactionRefetch: VoidFunction
  onTabChange: (idx: number) => void
}

type BenchmarkYearlyData = Record<string, number | undefined>

export interface BenchMarkAnalysisTableDataWithoutYears {
  name: string
  isEditAllowed: boolean
  key: keyof quarterly_analysis
  isEditable?: boolean
  weightedAverage?: number
}

export type BenchMarkAnalysisTableData = BenchMarkAnalysisTableDataWithoutYears & BenchmarkYearlyData

export default memo(BenchmarkingAnalysis)
