import { EMPTY_ARRAY } from 'constants/app.constants'
import React, { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { FileUploaderHook, useFileUploaderProps } from './types'

const useFileUploader: FileUploaderHook = ({
  onFileSelected,
  onCancelUpload,
  accept,
  onProgress,
  isMultiple,
}: useFileUploaderProps) => {
  const [dragging, setDragging] = useState(false)
  const [progress, setProgress] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const handleDragEnter = useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setDragging(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const handleDragLeave = useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setDragging(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy'
      setDragging(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const handleFilesRead = useCallback(
    (files: FileList) => {
      const acceptedTypes = accept.split(',').map(type => type.trim())
      const validFiles: File[] = []
      const invalidFiles: File[] = []

      Array.from(files).forEach(file => {
        const fileExtension = file.name.split('.').pop()
        const isValid = acceptedTypes.some(type => {
          if (type.startsWith('.')) {
            return `.${fileExtension}`.toLowerCase() === type.toLowerCase()
          } else if (type.includes('/*')) {
            const [mainType] = type.split('/')
            return file.type.startsWith(mainType)
          } else {
            return file.type === type
          }
        })

        if (isValid) {
          validFiles.push(file)
        } else {
          invalidFiles.push(file)
        }
      })

      invalidFiles.forEach(file => {
        toast.error(`Invalid file: ${file.name}. Allowed types: ${accept}`)
      })
      validFiles.forEach(file => {
        const reader = new FileReader()
        reader.onloadstart = () => {
          setProgress(0)
          if (onProgress) {
            onProgress(0)
          }
        }

        reader.onprogress = data => {
          if (data.lengthComputable) {
            const percentage = parseInt(((data.loaded / data.total) * 100).toString(), 10)
            setProgress(percentage)
            if (onProgress) {
              onProgress(percentage)
            }
          }
        }

        reader.onloadend = () => {
          setProgress(100)
          if (onProgress) {
            onProgress(100)
          }
        }

        reader.readAsArrayBuffer(file)
        onFileSelected(file)
      })
    },
    [accept, onFileSelected, onProgress]
  )

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setDragging(false)
      if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        handleFilesRead(event.dataTransfer.files)
        event.dataTransfer.clearData()
      }
    },
    [handleFilesRead]
  )

  const handleClick = useCallback(() => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = accept
    fileInput.multiple = isMultiple || false
    fileInput.onchange = event => {
      const files = (event.target as HTMLInputElement).files
      if (files && files.length > 0) {
        handleFilesRead(files)
      } else {
        if (onCancelUpload) onCancelUpload()
      }
    }
    fileInput.click()
  }, [accept, handleFilesRead, isMultiple, onCancelUpload])

  React.useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener('dragenter', handleDragEnter)
      node.addEventListener('dragleave', handleDragLeave)
      node.addEventListener('dragover', handleDragOver)
      node.addEventListener('drop', handleDrop)
    }

    return () => {
      if (node) {
        node.removeEventListener('dragenter', handleDragEnter)
        node.removeEventListener('dragleave', handleDragLeave)
        node.removeEventListener('dragover', handleDragOver)
        node.removeEventListener('drop', handleDrop)
      }
    }
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop, ref])

  return { ref, dragging, handleClick, progress }
}

export default useFileUploader
