import { IconProps } from 'assets'
import React from 'react'

const CheckboxInactive = ({ className, pathClassName = 'stroke-neutral-300' }: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none">
      <rect className={pathClassName} x="3.90039" y="3.40039" width="17.2" height="17.2" rx="3" stroke-width="2" />
    </svg>
  )
}

export default CheckboxInactive
