import { useMutation, useQuery } from '@tanstack/react-query'
import { editFinancialStructure } from 'api/transactions'
import { editBalanceSheet, editTestedPartyPolicyTesting } from 'api/transactions/createTransaction'
import { economic_analysis } from 'api/transactions/getTransaction/types'
import { TransactionPliMethod } from 'api/transactions/getTransactionPliMethod/types'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button'
import { IconPlacement } from 'components/input'
import InfoLabel from 'components/input/components/infoLabel'
import Loading from 'components/loading'
import Modal from 'components/modal/Modal'
import TinyMceEditor from 'components/tinyMceEditor'
import { DEFAULT_LEFT_GROUP_BUTTONS, integralCss } from 'components/tinyMceEditor/constants'
import { EditorRef } from 'components/tinyMceEditor/types'
import { BaseUrl } from 'constants/apis'
import { currencyOptions } from 'constants/currencies'
import { QUERIES } from 'constants/query'
import { useAuth } from 'hooks/useAuth'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import { INPUT_FIELDS } from 'organisms/fieldRenderers'
import FileUploadWithName from 'organisms/fieldRenderers/fields/fileUploadWithName/FIleUploadWithName'
import SelectDropDown from 'organisms/fieldRenderers/fields/selectDropDown'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DefaultValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { fetchResourceFromUrl } from 'utils/apiUtils'
import { addStringAsABlob, capitalizeFirstLetter, cleanupHTML, getToastErrorMessage } from 'utils/utils'

import {
  balanceSheetAvgColumns as getBSAvgColumns,
  balanceSheetColumns as getBSColumns,
} from '../../config/balanceSheet.column'
import { balanceSheetAvgConfig, balanceSheetConfig } from '../../config/balanceSheet.config'
import { usGaapColumns } from '../../config/usGaap.column'
import { usGaapConfig } from '../../config/usGaap.config'
import { balanceSheetPayload, financialStructurePayload } from '../../financialData.factory'
import { FinancialDataEntityTableData, FinancialDataTableData } from '../../types/financialData.types'
import TestedPartyFinancialTable from '../testedPartyFinancialTable'
import { FIELD_IDS, GAAP_OPTIONS } from './AddFinancialDataModal.constants'

type Props = {
  selectedEntityData: FinancialDataEntityTableData
  onClose: VoidFunction
  isOpen: boolean
  testing_methodology: 'testing_p_and_l' | 'testing_a_policy'
  economicAnalysisTransactionResponse?: economic_analysis
  pliMethod: TransactionPliMethod | undefined
  economicAnalysisTransactionRefetch: () => void
}

function AddFinancialDataModal({
  isOpen,
  onClose,
  selectedEntityData,
  testing_methodology,
  pliMethod,
  economicAnalysisTransactionResponse,
  economicAnalysisTransactionRefetch,
}: Props) {
  const { user } = useAuth()
  const router = useRouter()
  const { year } = router.query

  const editorRef = useRef<EditorRef>(null)

  const [file, setFile] = useState<File | null | string>()

  const [html, setHtml] = useState('')
  const [usGaapIsDirty, setUSGaapIsDirty] = useState(false)
  const [balanceSheetDirty, setBalanceSheetDirty] = useState(false)
  const [htmlIsDirty, setHtmlIsDirty] = useState(false)

  const [initialTab, setInitialTab] = useState(0)

  const usGaapWithEditableConfig = useMemo(
    () => usGaapConfig({ financial_structure: selectedEntityData.financial_structure, year: Number(year), pliMethod }),
    [selectedEntityData.financial_structure, year, pliMethod]
  )

  const balanceSheetWithEditableConfig = useMemo(
    () =>
      balanceSheetConfig({
        balanceSheet: selectedEntityData.balance_sheets,
        year: Number(year),
        pliMethod,
        show4Years: true,
      }),
    [selectedEntityData.balance_sheets, year, pliMethod]
  )
  const balanceSheetAvgData = useMemo(
    () =>
      balanceSheetAvgConfig({
        balanceSheetAverages: selectedEntityData.balance_sheet_averages,
        year: Number(year),
        pliMethod,
      }),
    [selectedEntityData.balance_sheet_averages, year, pliMethod]
  )

  const [usGaapData, setUsGaap] = useState<FinancialDataTableData[]>(usGaapWithEditableConfig)
  const [balanceSheetData, setBalanceSheetData] = useState<FinancialDataTableData[]>(balanceSheetWithEditableConfig)

  const [balanceSheetTempData, setBalanceSheetTempData] =
    useState<FinancialDataTableData[]>(balanceSheetWithEditableConfig)

  useEffect(() => {
    setUsGaap(usGaapWithEditableConfig)
    setUSGaapIsDirty(false)
  }, [usGaapWithEditableConfig])

  useEffect(() => {
    setBalanceSheetTempData(balanceSheetData)
  }, [balanceSheetData])

  useEffect(() => {
    setBalanceSheetData(balanceSheetWithEditableConfig)
    setBalanceSheetDirty(false)
  }, [balanceSheetWithEditableConfig])

  const { control, reset, watch, formState, setValue, getValues } = useForm<FIELD_VALUES>()

  const [gaap] = watch(['gaap_type'])

  const { data: localFileCss } = useQuery([QUERIES.GET_LOCAL_FILE_CSS.key], {
    queryFn: QUERIES.GET_LOCAL_FILE_CSS.function,
  })

  const css = useMemo(() => {
    return localFileCss
      ? localFileCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
      : integralCss.concat(`body { margin-left: 3rem; margin-right: 3rem; }`)
  }, [localFileCss])

  useEffect(() => {
    if (selectedEntityData.testing_policy_description) {
      fetchResourceFromUrl<string>(selectedEntityData.testing_policy_description, () => {
        setHtml('')
        setHtmlIsDirty(false)
        toast.error('Error fetching testing policy description', { autoClose: 2000 })
      }).then(val => {
        setHtml(val)
        setHtmlIsDirty(false)
      })
    }
  }, [selectedEntityData.testing_policy_description])

  useEffect(() => {
    const resetValue: DefaultValues<FIELD_VALUES> = {}
    if (selectedEntityData.gaap_type) {
      resetValue['gaap_type'] = GAAP_OPTIONS.find(({ value }) => value == selectedEntityData.gaap_type) || {
        label: capitalizeFirstLetter(selectedEntityData.gaap_type?.replace('_', ' ') || ''),
        value: selectedEntityData.gaap_type,
      }
    }
    if (selectedEntityData.tested_party_currency) {
      resetValue['tested_party_currency'] = {
        label: selectedEntityData.tested_party_currency,
        value: selectedEntityData.tested_party_currency,
      }
    }
    if (selectedEntityData.policy_rate) {
      resetValue['policy_rate'] = selectedEntityData.policy_rate
    }
    if (selectedEntityData.financial_data_appendix?.file && selectedEntityData.financial_data_appendix?.file_name) {
      resetValue['financial_data_file_name'] = selectedEntityData.financial_data_appendix?.file_name
    }
    reset(resetValue)
  }, [
    reset,
    selectedEntityData.financial_data_appendix?.file,
    selectedEntityData.financial_data_appendix?.file_name,
    selectedEntityData.gaap_type,
    selectedEntityData.policy_rate,
    selectedEntityData.tested_party_currency,
  ])

  useEffect(() => {
    if (selectedEntityData.financial_data_appendix?.file) {
      setFile(selectedEntityData.financial_data_appendix?.file)
    }
  }, [selectedEntityData.financial_data_appendix?.file])

  const financialColumns = useMemo(() => {
    return usGaapColumns(Number(year), (gaap?.label || '')?.toString())
  }, [year, gaap?.label])

  const balanceSheetColumns = useMemo(() => {
    return getBSColumns(Number(year), true)
  }, [year])

  const balanceSheetAvgColumns = useMemo(() => {
    return getBSAvgColumns(Number(year))
  }, [year])

  const { refetchChecklist } = useReportWizardContext()

  const updateEconomicFinancialStructure = useMutation(editFinancialStructure, {
    onSuccess() {
      setUSGaapIsDirty(false)
      refetchChecklist()
    },
  })
  const updateBalanceSheetMutation = useMutation(editBalanceSheet, {
    onSuccess() {
      setBalanceSheetDirty(false)
      refetchChecklist()
    },
  })

  const handleSaveFinancialData = useCallback(async () => {
    let isMutation = false
    let abort = false
    await updateEconomicFinancialStructure
      .mutateAsync({
        id: economicAnalysisTransactionResponse?.id || 0,
        addFinancialStructurePayload: financialStructurePayload({
          usGaapData,
          year: Number(year),
          pliMethod,
        }),
        gaap_type: String(gaap?.value || ''),
        tested_party_currency: String(getValues('tested_party_currency')?.value || ''),
        tested_party_financial_data_file: typeof file == 'string' ? undefined : file,
        tested_party_financial_data_file_name:
          getValues('financial_data_file_name') || (file instanceof File ? file.name : ''),
        tested_party: selectedEntityData.entity.id,
      })
      .then(() => {
        isMutation = true
      })
      .catch((error: AxiosError) => {
        getToastErrorMessage(error)
        abort = true
        setInitialTab(0)
      })
    if (!!pliMethod?.is_balance_sheet_required && !abort) {
      await updateBalanceSheetMutation
        .mutateAsync({
          id: economicAnalysisTransactionResponse?.id || 0,
          addBalanceSheetPayload: balanceSheetPayload({
            balanceSheetData: balanceSheetTempData,
            year: Number(year),
            pliMethod,
            tested_party: selectedEntityData.entity.id,
          }),
        })
        .then(() => {
          isMutation = true
        })
        .catch((error: AxiosError) => {
          getToastErrorMessage(error)
          abort = true
          setInitialTab(1)
        })
    }
    if (isMutation && !abort) {
      toast.success('Saved Financial Data')
      economicAnalysisTransactionRefetch()
      onClose()
    }
  }, [
    balanceSheetTempData,
    economicAnalysisTransactionRefetch,
    economicAnalysisTransactionResponse?.id,
    file,
    gaap?.value,
    getValues,
    onClose,
    pliMethod,
    selectedEntityData.entity.id,
    updateBalanceSheetMutation,
    updateEconomicFinancialStructure,
    usGaapData,
    year,
  ])

  const editTestedPartyPolicyTestingMutation = useMutation(editTestedPartyPolicyTesting, {
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
    onSuccess() {
      toast.success('Saved Financial Data')
      economicAnalysisTransactionRefetch()
      onClose()
      refetchChecklist()
    },
  })

  const handlePolicyTestingSave = useCallback(async () => {
    let htmlString = ''
    if (editorRef.current?.editor?.getContent()) {
      await editorRef.current.editor.uploadImages()
      const editorHtml = editorRef.current.editor?.getContent()
      const processedHTML = cleanupHTML(editorHtml)
      setHtml(processedHTML)
      htmlString = processedHTML
    }
    editTestedPartyPolicyTestingMutation.mutate({
      id: economicAnalysisTransactionResponse?.id || 0,
      tested_party: selectedEntityData.entity.id,
      policy_rate: getValues('policy_rate') || '',
      testing_policy_description: htmlString
        ? addStringAsABlob(htmlString, 'testing_policy_description.html')
        : undefined,
    })
  }, [
    economicAnalysisTransactionResponse?.id,
    editTestedPartyPolicyTestingMutation,
    getValues,
    selectedEntityData.entity.id,
  ])

  const handleDeleteFile = useCallback(() => {
    setFile(null)
    setValue('financial_data_file_name', '')
  }, [setValue])

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    (testing_methodology == 'testing_a_policy' && htmlIsDirty) ||
    (testing_methodology == 'testing_p_and_l' &&
      (file instanceof File || file === null || usGaapIsDirty || balanceSheetDirty))

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const loading =
    editTestedPartyPolicyTestingMutation.isLoading ||
    updateEconomicFinancialStructure.isLoading ||
    updateBalanceSheetMutation.isLoading

  const handleModalClose = useCallback(async () => {
    if (isDirty) {
      const consent = await getConfirmation('Are you sure you want to quit with unsaved changes?', 'Attention!')
      if (!consent) return
    }
    onClose()
  }, [getConfirmation, isDirty, onClose])

  return (
    <Modal
      containerClassName="text-start p-8 max-w-[100vw] min-w-[1000px] w-full relative "
      title={`Financial Data for ${selectedEntityData.entity.name_abbreviation || selectedEntityData.entity.name}`}
      onClose={handleModalClose}
      isOpen={isOpen}>
      {loading && <Loading className="bg-white flex items-center justify-center absolute inset-0 z-20" />}
      {testing_methodology == 'testing_p_and_l' && (
        <>
          <div className="w-full overflow-y-auto space-y-4" style={{ maxHeight: 'calc(100vh - 12rem)' }}>
            <BorderlessBox>
              <div className="flex items-center justify-between w-full gap-6">
                <SelectDropDown
                  options={GAAP_OPTIONS}
                  control={control}
                  id={FIELD_IDS.GAAP_TYPE}
                  label="Select GAAP"
                  required
                />
                <SelectDropDown
                  options={currencyOptions}
                  control={control}
                  id={FIELD_IDS.TESTED_PARTY_CURRENCY}
                  label="Select Currency for Financial Data"
                  required
                />
              </div>
            </BorderlessBox>
            <TestedPartyFinancialTable
              setUsGaap={setUsGaap}
              usGaapData={usGaapData}
              year={Number(year)}
              financialColumns={financialColumns}
              showBalanceSheet={!!pliMethod?.is_balance_sheet_required}
              balanceSheetData={balanceSheetTempData}
              setBalanceSheetData={setBalanceSheetTempData}
              balanceSheetColumns={balanceSheetColumns}
              balanceSheetAvgData={balanceSheetAvgData}
              balanceSheetAvgColumns={balanceSheetAvgColumns}
              setUSGaapIsDirty={setUSGaapIsDirty}
              setBalanceSheetDirty={setBalanceSheetDirty}
              initialTab={initialTab}
              pliName={pliMethod?.name}
              averagePli={selectedEntityData.averagePli}
              usGaapIsDirty={usGaapIsDirty}
              balanceSheetDirty={balanceSheetDirty}
            />

            <FileUploadWithName
              fileName={
                typeof file == 'string'
                  ? selectedEntityData.financial_data_appendix?.file_name || 'Financial Data'
                  : undefined
              }
              label="Supporting Financial Data Document"
              file={file instanceof File ? file : null}
              setFile={setFile}
              fileUrl={typeof file == 'string' ? file : undefined}
              showDelete
              className="bg-white w-full"
              handleDelete={handleDeleteFile}
              control={control}
              nameId={'financial_data_file_name'}
              nameLabel={'Name Of Document'}
              accept=".pdf"
            />
          </div>
          <Button disabled={!isDirty} onClick={handleSaveFinancialData} icon={IconsType.save} className="w-28 ms-auto">
            Save
          </Button>
        </>
      )}
      {testing_methodology == 'testing_a_policy' && (
        <>
          <BorderlessBox>
            <INPUT_FIELDS
              icon={IconsType.percentage}
              iconPlacement={IconPlacement.Right}
              placeholder="25"
              control={control}
              id={FIELD_IDS.POLICY_RATE}
              label={`Policy Rate for ${pliMethod?.name || 'selected PLI'}`}
              className="w-full"
              required
            />
          </BorderlessBox>

          <BorderlessBox className="items-stretch">
            <InfoLabel label="Detailed Description" />
            <TinyMceEditor
              author={{
                id: user?.id,
                username: user?.username || 'unknown',
                fullName: user
                  ? user?.first_name || user?.last_name
                    ? `${user?.first_name} ${user?.last_name}`
                    : user?.username
                  : 'Unknown',
                avatarUrl: user?.profile_photo || undefined,
              }}
              ref={editorRef}
              height={'45vh'}
              editorData={html}
              documentBaseUrl={BaseUrl}
              contentStyles={css}
              leftGroupButtons={DEFAULT_LEFT_GROUP_BUTTONS}
              setIsDirty={setHtmlIsDirty}
            />
          </BorderlessBox>

          <Button onClick={handlePolicyTestingSave} disabled={!isDirty} icon={IconsType.save} className="w-28 ms-auto">
            Save
          </Button>
        </>
      )}
      <ConfirmationModal />
    </Modal>
  )
}

type FIELD_VALUES = {
  financial_data_file_name: string
  gaap_type?: SelectOptions
  tested_party_currency?: SelectOptions
  policy_rate?: string
}

export default AddFinancialDataModal
