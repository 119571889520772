export const ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS = {
  TRANSACTIONS: 'transaction',
  COMPARABILITY_FACTORS: 'comparabilityFactors',
  DESCRIPTION: 'description',
  VALUE_VOLUME_TABLE: 'valueVolumeTable',
  VALUE: 'value',
  VOLUME: 'volume',
}
export const CHARACTER_LIMITS = {
  [ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.VALUE]: 50,
  [ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS.VOLUME]: 150,
}
