import { attributes } from 'api/transactions/getTransaction/types'
import { useMemo } from 'react'

export enum AttributeType {
  Function = 'function',
  Asset = 'asset',
  Risk = 'risk',
}

export const useFilterAttributesByType = (attributes: attributes[]) => {
  const functionAttributes = useMemo(
    () => attributes?.filter(attribute => attribute?.attribute_type === AttributeType.Function),
    [attributes]
  )
  const assetAttributes = useMemo(
    () => attributes?.filter(attribute => attribute?.attribute_type === AttributeType.Asset),
    [attributes]
  )
  const riskAttributes = useMemo(
    () => attributes?.filter(attribute => attribute?.attribute_type === AttributeType.Risk),
    [attributes]
  )

  return {
    functionAttributes,
    assetAttributes,
    riskAttributes,
  }
}
