import { DevTool } from '@hookform/devtools'
import { Add, Info } from '@mui/icons-material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createComparabilityFactors } from 'api/transactions/comparabilityMetrics'
import { ComparabilityFactor } from 'api/transactions/comparabilityMetrics/types'
import { AxiosError } from 'axios'
import Button from 'components/button'
import Loading from 'components/loading/Loading'
import Modal from 'components/modal/Modal'
import { QUERIES } from 'constants/query'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import CreatableSelectDropDown from 'organisms/fieldRenderers/fields/creatableSelectDropDown'
import MultiSelectTextInput from 'organisms/fieldRenderers/fields/MultiSelectTextInput'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  metricsList: ComparabilityFactor[] | undefined
}

function CreateFactorsModal({ isOpen, onClose, metricsList }: Props) {
  const { control, watch } = useForm<CreateFactorsFieldValues>()
  const [inputValue, setInputValue] = useState('')

  const queryClient = useQueryClient()

  const { refetchChecklist } = useReportWizardContext()

  const createComparabilityFactorsMutation = useMutation(createComparabilityFactors, {
    onSuccess() {
      toast.success('Created new suggested factors!!')
      queryClient.removeQueries([QUERIES.GET_COMPARABILITY_METRICS_LIST.key])
      refetchChecklist()
      isOpen && onClose()
    },
    onError(error) {
      getToastErrorMessage(error as AxiosError)
    },
  })

  const values = watch()

  const factorsOptions = useMemo(() => {
    if (!metricsList?.length) return []
    return metricsList.map(factor => {
      return {
        label: factor.name,
        value: factor.id,
      }
    })
  }, [metricsList])

  const onSave = useCallback(() => {
    if (!values.factor) {
      toast.warning('Select or enter comparability factor')
      return
    }
    const subFactorsAdded = [...(values.subFactors || [])].map(subFactor => String(subFactor.value))
    if (inputValue.trim()) {
      subFactorsAdded.push(inputValue.trim())
    }
    createComparabilityFactorsMutation.mutate({
      comparability_factor_name: typeof values.factor.value == 'number' ? undefined : String(values.factor.label),
      comparability_factor_id: typeof values.factor.value == 'number' ? values.factor.value : undefined,
      sub_factor_names: subFactorsAdded.length ? subFactorsAdded : undefined,
    })
  }, [createComparabilityFactorsMutation, values.factor, inputValue, values.subFactors])
  const requiredSubfactor = useMemo(
    () => factorsOptions.find(factor => factor === values.factor),
    [factorsOptions, values.factor]
  )

  const disableAdd = useMemo(
    () => (requiredSubfactor ? !!values?.subFactors && values.subFactors.length : true),
    [requiredSubfactor, values?.subFactors]
  )

  return (
    <Modal
      containerClassName="p-6 w-[1024px] max-w-full"
      className="gap-6 w-full !items-stretch text-start"
      isOpen={isOpen}
      onClose={onClose}
      title="Add New Comparability Factor & Sub Factor">
      {!!createComparabilityFactorsMutation.isLoading ? (
        <Loading className="w-full h-[300px] flex items-center justify-center " />
      ) : (
        <>
          <CreatableSelectDropDown
            label="Comparability Factor"
            placeholder="Please type to create new or select from existing"
            required
            options={factorsOptions}
            control={control}
            id={'factor'}
          />
          <MultiSelectTextInput
            control={control}
            label={`Add Sub Factors${!!requiredSubfactor ? '' : '(Optional)'} `}
            id="subFactors"
            placeholder="Type something and press enter/tab to add multiple..."
            setInputValue={setInputValue}
            inputValue={inputValue}
            required={!!requiredSubfactor}
          />
          <div className="text-xs text-gray-600 flex -mt-4 items-center">
            <Info className="text-[#fb9800] w-4 h-4 mr-1" /> Sub factor(s) will be added to the selected comparability
            factor. Press Tab or Enter after typing a sub factor name to add multiple.
          </div>
          <Button
            disabled={!values.factor?.value || !disableAdd}
            onClick={onSave}
            className="ms-auto self-end !items-center !py-0 !flex !flex-row !gap-1 -mt-2">
            <Add className="w-[18px] h-[18px]" /> Add
          </Button>
        </>
      )}
      <DevTool control={control} />
    </Modal>
  )
}

type CreateFactorsFieldValues = {
  factor?: SelectOptions
  subFactors?: SelectOptions[]
}

export default CreateFactorsModal
