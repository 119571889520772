import { TransactionByIdResponse } from 'api/transactions/getTransaction/getTransactionByIdResponse'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import classNames from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import TransactionGroupInfo from 'organisms/transactions/transactionGroupInfo'
import { useCallback, useMemo } from 'react'
import { createQueryParams } from 'utils/utils'
import TransactionCardInfo from 'views/transactions/selectedTransactionDashboard/organisms/basicInfo/transactionCardInfo'

import { segregateEntitiesFromTransactions } from '../../organisms/functionalAnalysis/components/functionalFunctions/organisms/functionSelector/components/addFunctionsModal/helpers'

const GroupFunctions = ({ className = '', currentTransaction, showLink = false }: GroupFunctionsProps): JSX.Element => {
  const { sourceEntities, targetEntities, perEntityCosts } = segregateEntitiesFromTransactions(currentTransaction)

  const { push } = useRouter()

  const { isPrimarySource, primaryEntity, secondaryEntities } = useMemo(() => {
    const isPrimarySource = !!currentTransaction.is_primary_le_source
    const primaryEntity = isPrimarySource ? sourceEntities[0] : targetEntities[0]
    const secondaryEntities = !isPrimarySource ? sourceEntities : targetEntities
    return { primaryEntity, secondaryEntities, isPrimarySource }
  }, [currentTransaction.is_primary_le_source, sourceEntities, targetEntities])

  const onViewTransactionClick = useCallback(() => {
    push(
      `${ROUTES.TRANSACTION_MANAGEMENT}/${currentTransaction.id}/?${createQueryParams({
        txnName: currentTransaction.name,
      })}`
    )
  }, [currentTransaction.id, currentTransaction.name, push])

  return (
    <BorderlessBox
      className={classNames(className, 'gap-3')}
      label="Transaction Group Info"
      topRightComponents={
        showLink ? (
          <Button
            onClick={onViewTransactionClick}
            className="ms-auto"
            variant={ButtonVariant.Secondary}
            icon={IconsType.eyeVisible}>
            View
          </Button>
        ) : undefined
      }>
      <div className="space-y-4 w-full">
        <TransactionCardInfo transactionResponse={currentTransaction} />
        <TransactionGroupInfo
          isPrimarySource={isPrimarySource}
          totalCostInUsd={currentTransaction.total_cost_in_usd}
          secondaryEntityCosts={perEntityCosts}
          primaryLegalEntity={primaryEntity}
          secondaryLegalEntity={secondaryEntities}
          transactionResponse={currentTransaction}
        />
      </div>
    </BorderlessBox>
  )
}

interface GroupFunctionsProps {
  className?: string
  currentTransaction: TransactionByIdResponse | TransactionResponse
  showLink?: boolean
}

export default GroupFunctions
