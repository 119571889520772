export const ADD_COMPARABLE_CONTROLLED_TRANSACTION_FIELD_IDS = {
  ENTITY1: 'entity1',
  ENTITY2: 'entity2',
  COMPARABILITY_FACTORS: 'comparabilityFactors',
  DESCRIPTION: 'description',
  VALUE_VOLUME_TABLE: 'valueVolumeTable',
  TRANSACTION_NAME: 'transaction_name',
  TRANSACTION_SUBNAME: 'transaction_subname',
  VALUE: 'value',
  VOLUME: 'volume',
}
