import { InfoRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import Delete from 'assets/icons/delete'
import Edit from 'assets/icons/edit'
import File from 'assets/icons/file'
import SixDotDrag from 'assets/icons/sixDotDrag'
import classNames from 'classnames'
import DocumentPreviewModal from 'components/documentPreviewModal'
import Typography, { Variant } from 'components/typography'
import { INPUT_FIELDS } from 'organisms/fieldRenderers'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useFormContext } from 'react-hook-form'

import { OtherAppendicesTabFieldValues } from '../OtherAppendicesTabNew'

const OtherAppendicesTableRow = ({
  opt,
  index,
  reorderRow,
  handleDeleteFile,
}: {
  opt: OtherAppendicesTabFieldValues['files'][0]
  index: number
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void
  handleDeleteFile: (index: number) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const { setFocus, control } = useFormContext<OtherAppendicesTabFieldValues>()
  const [isFocused, setIsFocused] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(true)
  const [previewFile, setPreviewFile] = useState('')

  useEffect(() => {
    const inputElement = inputRef.current

    // Define focus and blur event handlers
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    // Attach event listeners
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus)
      inputElement.addEventListener('blur', handleBlur)
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus)
        inputElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [])

  const reorderRowFn = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => reorderRow(draggedRowIndex, targetRowIndex),
    [reorderRow]
  )

  const [, drop] = useDrop<{
    opt: typeof opt
    index: number
  }>({
    accept: 'txnRow',
    drop: draggedRow => {
      reorderRowFn(draggedRow.index, index)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
    item: () => {
      return {
        opt,
        index,
      }
    },
    type: 'txnRow',
  })

  preview(drop(ref))

  const fileName = opt.newData?.name || ''

  const onClickEdit = useCallback(() => {
    inputRef.current?.focus()
    setFocus(`files.${index}.newData.name`)
  }, [index, setFocus])

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
    if (event.key === 'Enter' && event.currentTarget.value.trim() && !event.shiftKey) {
      inputRef.current?.blur()
    }
  }, [])

  const isUnsaved = useMemo(() => {
    if (opt.oldData?.id) {
      return opt.oldData.file_name !== opt.newData?.name
    }
    return true
  }, [opt.newData?.name, opt.oldData?.file_name, opt.oldData?.id])

  const onClosePreview = useCallback(() => {
    setPreviewFile('')
  }, [])

  const setFileForPreview = useCallback(() => {
    if (opt.newData?.file) {
      const fileUrl = URL.createObjectURL(opt.newData?.file)
      setPreviewFile(fileUrl)
    } else {
      setPreviewFile(opt.oldData?.file || '')
    }
  }, [opt.newData?.file, opt.oldData?.file])

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 999 : 'auto' }}
      className="w-full flex  items-center">
      <div ref={drag} className="flex items-center cursor-move shrink-0 ">
        <SixDotDrag className="w-6 h-6" pathClassName="fill-indigo-500" />
      </div>
      <div className="w-full flex rounded-[10px] bg-[#161532] p-3 items-center ">
        <div className="w-8 h-8 shrink-0 flex items-center justify-center rounded-full bg-indigo-100">
          <File className="w-4 h-4" pathClassName="fill-indigo-900" />
        </div>
        <div className="w-full border-0 border-r border-solid  border-gray-800 flex items-center gap-1 min-h-[32px] px-3">
          {!isFocused ? (
            <>
              <Typography variant={Variant.ContainerText} type="semibold" className="text-indigo-400">
                {fileName}
              </Typography>
              <div onClick={onClickEdit} className="cursor-pointer flex items-center shrink-0">
                <Edit pathClassName="stroke-gray-400" className="w-[18px] h-[18px]" />
              </div>
              {isUnsaved && (
                <Tooltip title="Unsaved changes" placement="top">
                  <div className="flex items-center shrink-0">
                    <InfoRounded className="w-4 h-4 text-[#EF6C00]" />
                  </div>
                </Tooltip>
              )}
            </>
          ) : null}
          <INPUT_FIELDS
            className={classNames({
              'w-0 h-0 opacity-0': !isFocused,
              'w-full': isFocused,
            })}
            inputBoxClassName="!p-0 !bg-[unset] focus:!bg-[unset]  !h-[unset] !pb-[5px] !rounded-none !border-0 !border-b border-solid !border-gray-400"
            inputClassName="!text-container-text !text-indigo-400 !font-semibold"
            inputRef={inputRef}
            id={`files.${index}.newData.name`}
            label=""
            control={control}
            onKeyDown={onKeyDown}
          />
        </div>
        <Typography
          onClick={setFileForPreview}
          variant={Variant.Callout}
          type="semibold"
          className="text-indigo-400 mx-5 shrink-0 cursor-pointer">
          Preview
        </Typography>
        <div onClick={() => handleDeleteFile(index)} className="flex items-center cursor-pointer">
          <Delete className="w-4 h-4" pathClassName="stroke-orange-600" />
        </div>
      </div>
      <DocumentPreviewModal
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        documentPreviewModal={!!previewFile}
        setDocumentPreviewModal={onClosePreview}
        selectedFile={previewFile}
      />
    </div>
  )
}

export default OtherAppendicesTableRow
