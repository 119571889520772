import cx from 'classnames'
import { FieldValues } from 'react-hook-form'

import { FormProps } from './types/form.types'

function FormWithSubmission<T>({
  fields,
  control,
  style,
  handleSubmit,
  onSubmitHandler,
  className,
  children,
  reset,
}: FormProps<T & FieldValues>): JSX.Element {
  return (
    <>
      <form onReset={reset as never} onSubmit={handleSubmit(onSubmitHandler)} style={style} className={className}>
        {fields.map(field => {
          if (Array.isArray(field)) {
            return (
              <div key={field.map(item => item.id).join('-')} className="flex items-baseline mb-4 w-full">
                {field.map(({ id, fieldToRenderer: FieldToRender, label, fieldClassName, ...rest }) => (
                  <div className={cx('flex-1', fieldClassName)} key={id}>
                    <FieldToRender label={label || ''} control={control as never} id={id} {...rest} />
                  </div>
                ))}
              </div>
            )
          } else if (field.id.includes('__template_')) {
            const { fieldToRenderer: FieldToRender, id, ...rest } = field
            return (
              <div key={id} className="mb-4">
                <FieldToRender id={id} {...rest} />
              </div>
            )
          } else {
            const { fieldToRenderer: FieldToRender, id, label, fieldClassName, show = true, ...rest } = field
            return (
              <div key={id} className={cx('mb-4', fieldClassName)}>
                <FieldToRender show={show} label={label || ''} control={control as never} id={id} {...rest} />
              </div>
            )
          }
        })}
        {children}
      </form>
    </>
  )
}

export default FormWithSubmission
