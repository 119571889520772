import { IconProps } from 'assets'
import React from 'react'

const CheckboxActive = ({ className, pathClassName = 'fill-blue-800' }: IconProps): JSX.Element => {
  return (
    <svg
      width="25"
      className={className}
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="2.89844" y="2.39941" width="19.2" height="19.2" rx="4" className={pathClassName} />
      <rect
        x="8.73633"
        y="10.7188"
        width="5.12"
        height="1.92"
        rx="0.96"
        transform="rotate(45 8.73633 10.7188)"
        fill="#EFF6FF"
      />
      <rect
        x="9.75"
        y="14.1689"
        width="8.49987"
        height="1.92"
        rx="0.96"
        transform="rotate(-45 9.75 14.1689)"
        fill="#EFF6FF"
      />
    </svg>
  )
}

export default CheckboxActive
