import fetchWithAuth from 'api/fetch'
import { AI_REQUEST } from 'components/tinyMceEditor/helpers/ai.helpers'
import { APIS } from 'constants/apis'

export const postAiQuery = (request: AI_REQUEST, signal: AbortSignal) => {
  return fetchWithAuth(APIS.TINYMCE_AI_ASSISTANT, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
    },
    signal: signal,
  })
}
