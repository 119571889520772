/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from '@tanstack/react-query'
import { GlobalSearchResponseType } from 'api/globalSearch'
import { QUERIES } from 'constants/query'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'

import {
  createComment,
  deleteAllCommentThreads,
  deleteComment,
  deleteCommentThread,
  editComment,
  fetchComments,
  lookupAComment,
  replyToComment,
} from '../helpers/comments.helpers'
import { EditorAuthor, EditorRef } from '../types'

export const useComments = ({ editorRef, author, prevAuthors }: UseCommentsProps) => {
  const createCommentFn = useCallback(
    (ref: any, done: any, fail: any) => createComment({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const replyToCommentFn = useCallback(
    (ref: any, done: any, fail: any) => replyToComment({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const editCommentFn = useCallback(
    (ref: any, done: any, fail: any) => editComment({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const deleteCommentFn = useCallback(
    (ref: any, done: any, fail: any) => deleteComment({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const deleteCommentThreadFn = useCallback(
    (ref: any, done: any, fail: any) => deleteCommentThread({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const deleteAllCommentThreadsFn = useCallback(
    (ref: any, done: any, fail: any) => deleteAllCommentThreads({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const lookupACommentFn = useCallback(
    (ref: any, done: any, fail: any) => lookupAComment({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, prevAuthors, editorRef]
  )

  const fetchCommentsFn = useCallback(
    (ref: any, done: any, fail: any) => fetchComments({ ref, done, fail, editorRef, author, prevAuthors }),
    [author, editorRef, prevAuthors]
  )

  return {
    createCommentFn,
    replyToCommentFn,
    editCommentFn,
    deleteCommentFn,
    deleteCommentThreadFn,
    deleteAllCommentThreadsFn,
    lookupACommentFn,
    fetchCommentsFn,
  }
}

export function useSpellIgnoreList() {
  const queryClient = useQueryClient()
  const { year } = useSelector((state: RootState) => state.app)

  const searchdata = queryClient.getQueryData<GlobalSearchResponseType[]>([QUERIES.GET_GLOBAL_SEARCH_RESULTS.key, year])

  const ignoredWords = useMemo(() => {
    if (searchdata?.length) {
      return searchdata
        .map(item => item.name)
        .join(' ')
        .split(' ')
    }
    return []
  }, [searchdata])

  return ignoredWords
}

interface UseCommentsProps {
  author: EditorAuthor
  prevAuthors: EditorAuthor[]
  editorRef: MutableRefObject<EditorRef>
}
