import Typography, { Variant } from 'components/typography'
import { RADIO_BUTTON_GROUP } from 'organisms/fieldRenderers'
import React, { ReactElement } from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'

const options: { value: string; label: ReactElement | string; disabled?: boolean }[] = [
  {
    value: 'Table',
    label: (
      <Typography variant={Variant.Callout} className="text-gray-700">
        Enter Manually
      </Typography>
    ),
  },
  {
    value: 'File',
    label: (
      <Typography variant={Variant.Callout} className="text-gray-700">
        Upload File
      </Typography>
    ),
  },
]

type Props<T extends FieldValues> = {
  control: Control<T>
  id: Path<T>
  className?: string
}

const FileOrTableRadio = <T extends FieldValues>({ control, id, className = 'ms-auto' }: Props<T>) => {
  return (
    <div onClick={e => e.stopPropagation()} className={className}>
      <RADIO_BUTTON_GROUP control={control} row id={id} radioOptions={options} />
    </div>
  )
}

export default FileOrTableRadio
