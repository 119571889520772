import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'

function TableHeadRow<T>({
  headerGroup,
  tableHeadClassName,
  reorderRow,
  isSort = true,
  tableHeadBGColor,
  sortPathClassname,
}: TableHeadRowProps<T>) {
  return (
    <tr className="border border-solid border-neutral300 pl-2">
      {reorderRow ? (
        <th
          style={{ width: '0px', background: tableHeadBGColor }}
          className={cx(
            'newTableTh ',
            'text-callout text-gray-700 bg-blue-50 font-semibold text-start px-4 py-2.5',
            tableHeadClassName
          )}>
          <div className="flex items-center"></div>
        </th>
      ) : (
        <></>
      )}
      {headerGroup.headers.map(column => {
        const size = column.column.columnDef.size

        return (
          <th
            key={column.id}
            style={{
              minWidth: `${size}px`,
              width: `${size}px`,
              background: tableHeadBGColor,
            }}
            className={cx(
              'newTableTh ',
              'text-callout text-gray-700 bg-blue-50 font-semibold text-start px-4 py-2.5',
              tableHeadClassName
            )}>
            <div className={`${isSort ? 'flex items-center' : ''} whitespace-pre-line`}>
              {column.column.columnDef.header && column.column.columnDef.sortingFn !== 'auto' && (
                <div className="cursor-pointer" onClick={column.column.getToggleSortingHandler()}>
                  {getIcons(IconsType.sort, {
                    className: 'mr-1 -mb-1',
                    pathClassName: sortPathClassname,
                  })}
                </div>
              )}
              {flexRender(column.column.columnDef.header, column.getContext())}
            </div>
          </th>
        )
      })}
    </tr>
  )
}

interface TableHeadRowProps<T> {
  headerGroup: HeaderGroup<T>
  tableHeadClassName?: string
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
  isSort: boolean
  tableHeadBGColor?: string
  sortPathClassname?: string
}

export default TableHeadRow
