import dayjs, { Dayjs } from 'dayjs'
import * as yup from 'yup'

export const emailSchema = yup
  .string()
  .email()
  .required('Email is required field')
  .label('email')
  .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email must be a valid email')

export const passwordSchema = yup
  .string()
  .required('Password is required field')
  .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.{8,})/, 'Password must contain at least 8 characters and one Uppercase')
  .label('password')

export const repeatPasswordSchema = yup
  .string()
  .required('Password is required field')
  .oneOf([yup.ref('password')], 'Passwords must match')

export const requiredSchema = yup.string().required('This field is required').label('required')

export const requiredDropdownOptionSchema = yup
  .object()
  .shape({
    value: yup.string().required('This field is required'),
  })
  .required('This field is required')

export const amountSchema = yup //for optional numbers with atmost 2 decimal palaces
  .string()
  .nullable()
  .test({
    name: 'maxDecimals',
    message: 'This field should be a number with at most 2 decimal places',
    test: value => (value ? /^\d+(\.\d{1,2})?$/.test(value.toString()) : true),
  })
export const dateSchema = yup
  .mixed<Dayjs>()
  .test('isValidDate', 'Invalid Date', (value: Dayjs | undefined) => {
    return !value || dayjs(value).isValid()
  })
  .nullable()

export const requiredDateSchema = yup
  .mixed<Dayjs>()
  .test('isValidDate', 'Invalid Date', (value: Dayjs | undefined) => {
    return !value || dayjs(value).isValid()
  })
  .required('This field a required')
