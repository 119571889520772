import { QuarterlyAnalysis } from 'api/benchmarks/types'
import { quarterly_analysis } from 'api/transactions/getTransaction/types'

import { BenchMarkAnalysisTableData, BenchMarkAnalysisTableDataWithoutYears } from '../BenchMarkAnalysis'

export const benchMarkAnalysisTableConfig = ({
  from_year,
  to_year,
  isEditAllowed,
  quarterlyAnalysis = [],
  hideNumberOfObservations,
}: BenchMarkAnalysisTableConfigProps): BenchMarkAnalysisTableData[] => {
  if (!from_year || !to_year) return []
  const columnNames: string[] = []
  let year = 0
  while (year <= to_year - from_year) {
    columnNames.push((from_year + year).toString())
    year++
  }
  columnNames.push('WAVG')

  const quarterlyData = columnNames.map(name => {
    if (name !== 'WAVG') {
      const relatedQuarterlyAnalysis = quarterlyAnalysis.find(val => {
        return val.year == Number(name)
      })
      if (relatedQuarterlyAnalysis) return relatedQuarterlyAnalysis
      const emptyColumn: Partial<QuarterlyAnalysis> = {
        year: Number(name),
      }
      return emptyColumn
    } else {
      const relatedQuarterlyAnalysis = quarterlyAnalysis.find(val => {
        return val.year == -1
      })
      if (relatedQuarterlyAnalysis) return relatedQuarterlyAnalysis
      const emptyColumn: Partial<QuarterlyAnalysis> = {
        year: -1,
      }
      return emptyColumn
    }
  })

  const columns: BenchMarkAnalysisTableDataWithoutYears[] = [
    {
      name: 'Maximum Observed value',
      isEditAllowed: isEditAllowed,
      isEditable: true,
      key: 'highest_observed_value',
    },
    {
      name: 'Upper Quartile',
      isEditAllowed: isEditAllowed,
      isEditable: true,
      key: 'upper_quartile',
    },
    {
      name: 'Median',
      isEditAllowed: isEditAllowed,
      isEditable: true,
      key: 'median',
    },
    {
      name: 'Lower Quartile',
      isEditAllowed: isEditAllowed,
      isEditable: true,
      key: 'lower_quartile',
    },
    {
      name: 'Minimum Observed value',
      isEditAllowed: isEditAllowed,
      key: 'lowest_observed_value',
      isEditable: true,
    },
  ]

  if (!hideNumberOfObservations) {
    columns.push({
      name: 'Number Of Comparable Companies',
      isEditAllowed: isEditAllowed,
      key: 'number_of_observations',
      isEditable: true,
    })
  }

  const benchmarkColumns: BenchMarkAnalysisTableData[] = []

  columns.forEach(columnData => {
    const key = columnData.key

    const benchmarkRow = {
      ...columnData,
    } as BenchMarkAnalysisTableData

    columnNames.forEach(col => {
      const correspondingYearlyData = quarterlyData.find(val => {
        if (col == 'WAVG') {
          return val.year == -1
        } else {
          return Number(col) == val.year
        }
      })
      benchmarkRow[col] = correspondingYearlyData ? correspondingYearlyData[key] : undefined
    })

    benchmarkColumns.push(benchmarkRow)
  })

  return benchmarkColumns
}

interface BenchMarkAnalysisTableConfigProps {
  from_year?: number
  to_year?: number
  isEditAllowed: boolean
  hideNumberOfObservations?: boolean
  quarterlyAnalysis: quarterly_analysis[]
}
