import { Transition } from '@headlessui/react'
import { FormatListBulleted, Info, MapsHomeWorkOutlined, ThumbUpOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import Cross from 'assets/icons/cross'
import FileMaximize from 'assets/icons/fileMaximize'
import LoadingIcon from 'assets/icons/loading'
import Minimize from 'assets/icons/minimize'
import TemplatesSubType from 'assets/icons/templatesSubType'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Loading from 'components/loading'
import PercentageBar from 'components/percentageBar/PercentageBar'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import colors from 'tailwindcss/colors'

import AppendixChecklist from './components/AppendixChecklist/AppendixChecklist'
import CompanyOverview from './components/CompanyOverview/CompanyOverview'
import LocalEntityOverview from './components/LocalEntityOverview/LocalEntityOverview'
import ModuleCard from './components/ModuleCard'
import TransactionsChecklist from './components/TransactionsChecklist/TransactionsChecklist'
import {
  getAppendicesConfig,
  getLEOverviewConfig,
  getOverviewConfig,
  getReportWizardModules,
  getTransactionConfig,
} from './ReportWizard.helpers'

const ReportWizard = () => {
  const state = useReportWizardContext()
  const router = useRouter()

  const {
    isActive,
    isExpanded,
    setIsExpanded,
    setIsActive,
    control,
    templateOptions,
    activeModule,
    setActiveModule,
    checklistData,
    handleGenerateClick,
    stickyFooterHeight,
    isFetching,
    handleTemplateChange,
    selectedTemplate,
    loading,
    legalEntityName,
    generating,
  } = state

  const wizardContainerRef = useRef<HTMLDivElement>(null)

  const reportName = checklistData?.name || checklistData?.legal_entity?.name || legalEntityName

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const customIntegralModal = document.getElementsByClassName('commonIntegralModalClass')
      if (
        wizardContainerRef.current &&
        !wizardContainerRef.current.contains(event.target as Node) &&
        (customIntegralModal.length
          ? !Array.from(customIntegralModal).some(elem => elem.contains(event.target as Node))
          : true)
      ) {
        setIsExpanded(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsExpanded])

  const leId = checklistData?.legal_entity?.id || state.legalEntity

  const { overviewConfig, legalEntityConfig, txnConfig, appendicesConfig } = useMemo(() => {
    return {
      overviewConfig: getOverviewConfig(checklistData),
      legalEntityConfig: getLEOverviewConfig(checklistData, leId),
      txnConfig: getTransactionConfig(checklistData),
      appendicesConfig: getAppendicesConfig(checklistData),
    }
  }, [checklistData, leId])

  const moduleConfig = useMemo(() => {
    return getReportWizardModules({
      active: activeModule,
      complete: {
        company_overview: overviewConfig.tabConfig.isComplete,
        appendices: true,
        local_entity_overview: legalEntityConfig.tabConfig.isComplete,
        transactions: txnConfig.tabConfig.isComplete,
      },
      disabled: {
        appendices: !checklistData?.transactions_order.length,
        transactions: !selectedTemplate?.value,
      },
    })
  }, [
    activeModule,
    checklistData?.transactions_order.length,
    legalEntityConfig.tabConfig.isComplete,
    overviewConfig.tabConfig.isComplete,
    selectedTemplate?.value,
    txnConfig.tabConfig.isComplete,
  ])

  const checklistsComplete =
    legalEntityConfig.tabConfig.isComplete && overviewConfig.tabConfig.isComplete && txnConfig.tabConfig.isComplete

  const handleLegalEntityClick = useCallback(() => {
    router
      .push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${checklistData?.legal_entity?.id || state.legalEntity}`)
      .then(() => setIsExpanded(false))
  }, [checklistData?.legal_entity?.id, router, setIsExpanded, state.legalEntity])

  return (
    <>
      <Transition
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        style={{
          bottom: stickyFooterHeight,
        }}
        className={`transition-transform z-30 duration-500 ease-in-out absolute right-0 bg-[#0A0929] top-0 w-[888px] max-w-[85vw]`}
        show={isActive && isExpanded}>
        <div ref={wizardContainerRef} id="reportWizardId" className="w-full relative h-full ">
          <div className="p-4 w-full h-[56px] flex items-center cursor-pointer">
            <div className="flex items-center" onClick={() => setIsExpanded(false)}>
              <Minimize />
            </div>
            <Tooltip title={reportName}>
              <div onClick={() => setIsExpanded(false)} className="ms-2 cursor-pointer">
                <Typography variant={Variant.Heading2} type="semibold" className="text-gray-300 max-w-[550px] truncate">
                  {reportName}
                </Typography>
              </div>
            </Tooltip>
            <div className="ms-auto flex items-center cursor-pointer" onClick={() => setIsActive(false)}>
              <Cross className="w-[18px] h-[18px]" pathClassName="fill-gray-300" />
            </div>
          </div>
          <div className="overflow-scroll h-[calc(100%-56px)] w-full p-4 pt-0 space-y-4 relative">
            <div className="flex items-center justify-between gap-4 w-full">
              <BorderlessBox
                bgClass="bg-[rgb(16,14,46)]"
                className="!w-1/2 min-h-[82px] !flex-row items-center gap-4 flex-1">
                <div className="flex items-center justify-center h-9 w-9 shrink-0 rounded-full bg-[rgb(33,32,71)]">
                  <MapsHomeWorkOutlined className="text-indigo-500 w-[18px] h-[18px]" />
                </div>
                <div onClick={handleLegalEntityClick} className="flex flex-col gap-1.5 cursor-pointer">
                  <Typography variant={Variant.Callout} className="text-gray-300 max-w-[200px] truncate">
                    Legal entity
                  </Typography>
                  <Tooltip title={checklistData?.legal_entity?.name}>
                    <div>
                      <Typography
                        type="semibold"
                        variant={Variant.Callout}
                        className="text-gray-300 max-w-[200px] truncate">
                        {checklistData?.legal_entity?.name || legalEntityName}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
              </BorderlessBox>
              <BorderlessBox bgClass="bg-[rgb(16,14,46)]" className="!w-1/2 h-[82px] justify-center">
                <SELECT_DROPDOWN
                  newSelectDropDown={false}
                  label="Select Template"
                  options={templateOptions}
                  control={control}
                  isDark
                  disabled={isFetching}
                  id="template"
                  className="!w-[250px] text-gray-300 -mt-2"
                  handleChange={handleTemplateChange}
                />
              </BorderlessBox>
            </div>
            {!!selectedTemplate?.value && (
              <>
                <BorderlessBox bgClass="bg-[rgb(16,14,46)]" className="!flex-row items-center justify-between !py-3">
                  <Typography variant={Variant.Callout} type="semibold" className="text-gray-300">
                    Report Progress
                  </Typography>

                  <PercentageBar
                    className="ms-2"
                    secondaryColor={colors.gray[700]}
                    primaryColor={colors.indigo[500]}
                    value={checklistData?.progress_in_percentage || 0}
                  />
                </BorderlessBox>
                <BorderlessBox bgClass="bg-[rgb(16,14,46)]" className="!flex-row !gap-2 items-center">
                  {checklistsComplete ? (
                    <>
                      <ThumbUpOutlined className="text-orange-500 w-7 h-7" />
                      <div className="flex flex-col grow">
                        <Typography variant={Variant.Callout} type="semibold" className="text-white">
                          You have successfully completed the checklist
                        </Typography>
                        <Typography variant={Variant.Footnote} className="text-white">
                          Now you can generate a detailed report.
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <FormatListBulleted className="text-orange-500 w-7 h-7" />
                      <div className="flex flex-col grow">
                        <Typography variant={Variant.Callout} type="semibold" className="text-white">
                          Checklist is incomplete
                        </Typography>
                        <Typography variant={Variant.Footnote} className="text-white">
                          You can anyway generate a report.
                        </Typography>
                      </div>
                    </>
                  )}
                  {!generating ? (
                    <div onClick={handleGenerateClick} className="flex gap-1 items-center cursor-pointer">
                      <TemplatesSubType className="h-3.5" pathClassName="stroke-indigo-400" />
                      <Typography variant={Variant.Callout} type="semibold" className="text-indigo-400">
                        Generate Report
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex gap-1 items-center cursor-not-allowed">
                      <LoadingIcon className="w-3.5" />
                      <Typography variant={Variant.Callout} type="semibold" className="text-indigo-400">
                        Generating Report
                      </Typography>
                    </div>
                  )}
                </BorderlessBox>
                <div className="">
                  <Typography variant={Variant.Callout} type="semibold" className="text-gray-300">
                    Complete the following modules
                  </Typography>
                  <div className="flex justify-between mt-3 gap-2">
                    {moduleConfig.map(mod => {
                      return (
                        <ModuleCard
                          icon={mod.icon}
                          title={mod.title}
                          completed={mod.completed}
                          active={mod.active}
                          moduleKey={mod.key}
                          key={mod.key}
                          disabled={mod.disabled}
                          disabledTooltipText={mod.disabledTooltipText}
                          setActiveModule={setActiveModule}
                        />
                      )
                    })}
                  </div>
                  {!checklistsComplete && (
                    <span className="text-gray-400 text-xs leading-6 flex items-center gap-1 mt-1.5">
                      <Info className="h-4 w-4 text-orange-500" /> Update this checklist to generate a detailed report
                    </span>
                  )}
                  <CompanyOverview active={activeModule == 'company_overview'} overviewConfig={overviewConfig} />
                  <LocalEntityOverview
                    active={activeModule == 'local_entity_overview'}
                    legalEntityConfig={legalEntityConfig}
                  />
                  <TransactionsChecklist active={activeModule == 'transactions'} txnConfig={txnConfig} />
                  {activeModule == 'appendices' && (
                    <AppendixChecklist key={selectedTemplate?.value} appendicesConfig={appendicesConfig} />
                  )}
                </div>
              </>
            )}
          </div>
          {(isFetching || loading) && (
            <Loading className="absolute left-0 right-0 bottom-0 top-14 bg-[#ffffff01] backdrop-blur-sm !mt-0	 flex items-center justify-center" />
          )}
        </div>
      </Transition>
      <Transition
        enterFrom="translate-y-[500px]"
        enterTo="translate-y-0"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-[500px]"
        style={{
          bottom: stickyFooterHeight,
        }}
        className={`transition-transform z-50 duration-300 ease-in-out fixed right-0 bg-[#0A0929] h-[56px] w-[620px]`}
        show={isActive && !isExpanded}>
        <div className="p-4 w-full h-full flex items-center">
          <div className="flex items-center cursor-pointer" onClick={() => setIsExpanded(true)}>
            <FileMaximize />
          </div>
          <Tooltip title={reportName}>
            <div onClick={() => setIsExpanded(true)} className="ms-2 cursor-pointer">
              <Typography variant={Variant.Heading2} type="semibold" className="text-gray-300 max-w-[400px] truncate">
                {reportName}
              </Typography>
            </div>
          </Tooltip>
          {typeof checklistData?.progress_in_percentage == 'number' && (
            <PercentageBar
              className="ms-2"
              secondaryColor={colors.gray[700]}
              primaryColor={colors.indigo[500]}
              value={checklistData?.progress_in_percentage || 0}
            />
          )}
          <div className="ms-auto flex items-center cursor-pointer" onClick={() => setIsActive(false)}>
            <Cross className="w-[18px] h-[18px]" pathClassName="fill-gray-300" />
          </div>
        </div>
      </Transition>
    </>
  )
}

export default ReportWizard
